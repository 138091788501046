import {LocalStorageKey} from 'core/types';

// TODO: Remove this file in august 2024

const oldKeyToNewKeyMap = {
  downloadMobileAppLastDialogTime: LocalStorageKey.DownloadMobileAppLastDialogTime,
  AI_WELCOME: LocalStorageKey.AIWelcome,
  ChatsStore: LocalStorageKey.ChatsStore,
  decisionsRecentFiltersByTenant: LocalStorageKey.DecisionsRecentFiltersByTenant,
  decisionsSavedFiltersByTenant: LocalStorageKey.DecisionsSavedFiltersByTenant,
  'markets-carrier-level-filter': LocalStorageKey.MarketsCarrierLevelFilterStore,
  carrierAppetite: LocalStorageKey.CarrierAppetiteStore,
  monolineHighlights: LocalStorageKey.MonolineHighlights,
  renewalsPageSavedPeriod: LocalStorageKey.RenewalsPageSavedPeriod,
  renewalsSavedPeriodDataBase: LocalStorageKey.RenewalsSavedPeriodDataBase,
  emulateLimitError: LocalStorageKey.EmulateLimitError,
  GROWTH_SIDEBAR_LISTS_SHOW_MORE: LocalStorageKey.GrowthSidebarListsShowMore,
  MODIFIERS: LocalStorageKey.Modifiers,
  withDevServiceWorker: LocalStorageKey.WithDevServiceWorker,
  decisionsRecentQueries: LocalStorageKey.DecisionsRecentQueries,
  ENNABL_RDE_API_HOST: LocalStorageKey.EnnablRdeApiHost,
  RETENTION_FILTERS_STORE: LocalStorageKey.RetentionFiltersStore,
  DocumentsStore: LocalStorageKey.DocumentsStore,
  O360_SCORE: LocalStorageKey.O360Score,
  O360_UNISON_BOOK: LocalStorageKey.O360UnisonBook,
  favoritesMigrationInfo: LocalStorageKey.FavoritesMigrationInfo,
};

export const migrateLocalStorageKeys = () => {
  Object.entries(oldKeyToNewKeyMap).forEach(([oldKey, newKey]) => {
    const value = localStorage.getItem(oldKey);

    if (value) {
      localStorage.setItem(newKey, value);
      localStorage.removeItem(oldKey);
    }
  });
};
