import {Components, Theme} from '@mui/material/styles';

export const paper = (theme: Theme): {MuiPaper: Components['MuiPaper']} => {
  return {
    MuiPaper: {
      defaultProps: {
        variant: 'elevation',
        elevation: 0,
      },
      styleOverrides: {
        elevation0: {
          borderRadius: theme.shape.borderRadiusLarge,
          backgroundColor: theme.palette.background.white,
        },
      },
      variants: [
        {
          props: {variant: 'filled'},
          style: {
            borderRadius: theme.shape.borderRadiusLarge,
            backgroundColor: theme.palette.background.grey,
          },
        },
      ],
    },
  };
};
