import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/apolloWrapper';
const defaultOptions = {} as const;
export type RequestsLimitsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RequestsLimitsQuery = { __typename?: 'Query', integrations: { __typename?: 'Integrations', monthlyLimit: { __typename?: 'IntegrationLimitInfo', calls: number, limit: number } } };


export const RequestsLimitsDocument = gql`
    query RequestsLimits {
  integrations {
    monthlyLimit {
      calls
      limit
    }
  }
}
    `;

/**
 * __useRequestsLimitsQuery__
 *
 * To run a query within a React component, call `useRequestsLimitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestsLimitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestsLimitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRequestsLimitsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RequestsLimitsQuery, RequestsLimitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<RequestsLimitsQuery, RequestsLimitsQueryVariables>(RequestsLimitsDocument, options);
      }
export function useRequestsLimitsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RequestsLimitsQuery, RequestsLimitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<RequestsLimitsQuery, RequestsLimitsQueryVariables>(RequestsLimitsDocument, options);
        }
export function useRequestsLimitsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<RequestsLimitsQuery, RequestsLimitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<RequestsLimitsQuery, RequestsLimitsQueryVariables>(RequestsLimitsDocument, options);
        }
export type RequestsLimitsQueryHookResult = ReturnType<typeof useRequestsLimitsQuery>;
export type RequestsLimitsLazyQueryHookResult = ReturnType<typeof useRequestsLimitsLazyQuery>;
export type RequestsLimitsSuspenseQueryHookResult = ReturnType<typeof useRequestsLimitsSuspenseQuery>;
export type RequestsLimitsQueryResult = Apollo.QueryResult<RequestsLimitsQuery, RequestsLimitsQueryVariables>;