import {alpha, Components, Theme} from '@mui/material/styles';
import {loadingButtonClasses} from '@mui/lab/LoadingButton';
import {LabComponents} from '@mui/lab/themeAugmentation';
import CircularProgress from '@mui/material/CircularProgress';

export const Button = (
  theme: Theme
): {
  MuiButton: Components['MuiButton'];
  MuiButtonBase: Components['MuiButtonBase'];
  MuiIconButton: Components['MuiIconButton'];
  MuiLoadingButton: LabComponents['MuiLoadingButton'];
} => {
  return {
    MuiButtonBase: {
      defaultProps: {disableRipple: true},
    },
    MuiButton: {
      defaultProps: {disableRipple: true},
      variants: [
        {
          props: {
            variant: 'action',
          },
          style: {
            background: theme.palette.text.primary,
            border: `1px solid ${theme.palette.text.primary}`,
            color: theme.palette.common.white,

            ':hover': {
              background: 'transparent',
              color: theme.palette.text.primary,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          display: 'inline-grid',
          gridAutoFlow: 'column',
          gap: theme.spacing(2),
          borderRadius: theme.shape.borderRadiusRegular,
          boxShadow: 'none',

          '&: hover': {
            boxShadow: 'none',
          },
        },

        outlinedSizeSmall: {
          padding: theme.spacing(1.75),
          borderColor: theme.palette.common.black,
          color: theme.palette.common.black,

          '&:hover': {
            borderColor: theme.palette.common.black,
            backgroundColor: alpha(theme.palette.common.black, theme.palette.action.hoverOpacity),
          },
        },
        outlinedSizeMedium: {
          padding: theme.spacing(2.75),
        },
        outlinedSizeLarge: {
          padding: theme.spacing(3.75),
        },

        sizeSmall: {
          ...theme.typography.buttonSmall,
          padding: theme.spacing(2),
          minWidth: 32,

          [`& svg, > svg`]: {
            width: 16,
            height: 16,
          },

          [`& > .${loadingButtonClasses.loadingIndicator}`]: {
            fontSize: 16,
          },
        },
        sizeMedium: {
          ...theme.typography.buttonMedium,
          padding: theme.spacing(3),
          minWidth: 44,

          [`& svg, > svg`]: {
            width: 20,
            height: 20,
          },

          [`& > .${loadingButtonClasses.loadingIndicator}`]: {
            fontSize: 20,
          },
        },
        sizeLarge: {
          ...theme.typography.buttonLarge,
          padding: theme.spacing(4),
          minWidth: 56,

          [`& svg, > svg`]: {
            width: 24,
            height: 24,
          },

          [`& > .${loadingButtonClasses.loadingIndicator}`]: {
            fontSize: 24,
          },
        },
        iconSizeSmall: {margin: 'unset'},
        iconSizeMedium: {margin: 'unset'},
        iconSizeLarge: {margin: 'unset'},
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadiusRegular,
        },
        colorSecondary: {
          color: theme.palette.text.primary,
          backgroundColor: 'transparent',

          ':hover': {
            backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),

            // Reset on touch devices, it doesn't add specificity.
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        sizeSmall: {
          padding: theme.spacing(1),
        },
        sizeMedium: {
          padding: theme.spacing(3),
        },
        sizeLarge: {
          padding: theme.spacing(4),
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        loadingIndicator: <CircularProgress size='1em' color='inherit' />,
      },
    },
  };
};
