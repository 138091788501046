import LogRocket from 'logrocket';
import {isObject, isString} from 'lodash/fp';

import {LocalStorageKey} from 'core/types';

import {dayjs, DATE_FORMAT_ISO8601_LOCAL} from 'utils/dateUtils';
import {IS_DEV_ENV, IS_PROD_ENV} from 'config';
import {parseJSON} from 'utils/json';

type LoggerType = 'console' | 'logrocket';

type LoggerConfig = {
  additionalLoggers: LoggerType[];
  skipMessagePart: Array<'time' | 'message'>;
  enabled: boolean;
};

interface LoggerStrategy {
  log(...args: any[]): void;
  error(...args: any[]): void;
  captureException(exception: Error, ...args: any[]): void;
}

class ConsoleLogger implements LoggerStrategy {
  log(...args: any[]): void {
    // eslint-disable-next-line no-console
    console.log(...args);
  }

  error(...args: any[]): void {
    console.error(...args);
  }

  captureException(exception: Error, ...args: any[]): void {
    console.error(exception, ...args);
  }
}

class LogRocketLogger implements LoggerStrategy {
  log(...args: any[]): void {
    LogRocket.info(...args);
  }

  error(...args: any[]): void {
    LogRocket.error(...args);
  }

  captureException(exception: Error, options: any): void {
    LogRocket.captureException(exception, options);
  }
}

class LoggerFactory {
  static createLogger(type: LoggerType): LoggerStrategy {
    switch (type) {
      case 'console':
        return new ConsoleLogger();
      case 'logrocket':
        return new LogRocketLogger();
      default:
        throw new Error(`Invalid logger type ${type}`);
    }
  }
}

/**
 * Ennabl Logger class.
 *
 * This logger class allows logging messages to multiple loggers (e.g., console, LogRocket)
 * based on the environment (development or production). It supports logging any number of
 * arguments of any type.
 *
 * LocalStorage config example (JSON format):
 * {
 *   "additionalLoggers": ["logrocket"],
 *   "skipMessagePart": ["time"],
 *   "enabled": true
 * }
 *
 * Example usage:
 * Ennabl.log(['foo', 'bar'], 'Important log message', {key: 'value'});
 * Result message: 2024-09-10T18:00:11.378+02:00 [foo][bar] Important log message {key: 'value'}
 *
 * Additional exmaples:
 * Ennabl.log('Sent PING');
 * Ennabl.log(['tag1', 'tag2'], 'Sent PING');
 * Ennabl.log(['tags', 'tag2'], 'Sent PING', {first: true, sss: [1, 2, 3]});
 * Ennabl.log('Sent PING', {first: true, sss: [1, 2, 3]});
 */
export class Ennabl {
  private static loggerStrategies: LoggerStrategy[] = [];
  private static initialized: boolean = false;
  private static defaultConfig: LoggerConfig = {
    additionalLoggers: [],
    skipMessagePart: [],
    enabled: true,
  };

  private static lastLogOnceData: Map<string, Record<string, any>> = new Map();

  private static initializeLoggers() {
    if (!Ennabl.initialized) {
      Ennabl.loggerStrategies = Ennabl.getActiveLoggers().map(loggerType => LoggerFactory.createLogger(loggerType));
      Ennabl.initialized = true;
    }
  }

  private static getConfig(): LoggerConfig {
    const storedConfig: LoggerConfig = parseJSON(
      localStorage.getItem(LocalStorageKey.EnnablLoggerConfig),
      Ennabl.defaultConfig
    );
    const isObjectConfig = storedConfig && isObject(storedConfig);

    if (!isObjectConfig) {
      return Ennabl.defaultConfig;
    }

    return {
      ...Ennabl.defaultConfig,
      ...storedConfig,
      additionalLoggers: [
        ...new Set([...Ennabl.defaultConfig.additionalLoggers, ...(storedConfig?.additionalLoggers || [])]),
      ],
    };
  }

  private static formatTime(): string {
    return dayjs().format(DATE_FORMAT_ISO8601_LOCAL);
  }

  private static parseLogArguments(
    first: string | string[],
    second?: string | Record<string, any>,
    third?: Record<string, any>
  ): {tags: string[]; uniqueId?: string; data?: Record<string, any>} {
    let tags: string[] = [];
    let uniqueId: string | undefined;
    let data: Record<string, any> | undefined;

    if (Array.isArray(first)) {
      if (!isString(second)) {
        throw new Error('When the first parameter is an array, the second parameter must be a string as a unique ID.');
      }

      tags = first;
      uniqueId = second;
      data = third;
    } else if (isString(first)) {
      uniqueId = first;
      data = second as Record<string, any>;
    } else {
      throw new Error('First parameter must be either a string or an array of strings.');
    }

    return {tags, uniqueId, data};
  }

  private static formatMessage(uniqueId?: string, data?: Record<string, any>, tags: string[] = []): any[] {
    const config = Ennabl.getConfig();
    const parts: any[] = [];

    if (!config.skipMessagePart.includes('time')) {
      parts.push(Ennabl.formatTime());
    }

    if (tags.length > 0) {
      parts.push(...tags.map(tag => `[${tag}]`));
    }

    if (uniqueId) {
      parts.push(uniqueId);
    }

    if (!config.skipMessagePart.includes('message') && data) {
      parts.push(data);
    }

    return parts;
  }

  static getActiveLoggers(): Array<LoggerType> {
    const config = Ennabl.getConfig();
    const loggers: Array<LoggerType> = [];

    if (IS_DEV_ENV) {
      loggers.push('console');
    }

    if (IS_PROD_ENV) {
      loggers.push('logrocket');
    }

    return [...new Set([...loggers, ...config.additionalLoggers])];
  }

  static error(first: string | string[], second?: string | Record<string, any>, third?: Array<Record<string, any>>) {
    const config = Ennabl.getConfig();

    if (!config.enabled) {
      return;
    }

    Ennabl.initializeLoggers();
    const {uniqueId, tags, data} = Ennabl.parseLogArguments(first, second, third);
    const errorMessage = Ennabl.formatMessage(uniqueId, data, tags);
    Ennabl.loggerStrategies.forEach(logger => {
      logger.error(...errorMessage);
    });
  }

  static captureException(error: Error, ...args: any[]) {
    const config = Ennabl.getConfig();

    if (!config.enabled) {
      return;
    }

    Ennabl.initializeLoggers();
    Ennabl.loggerStrategies.forEach(logger => {
      logger.captureException(error, ...args);
    });
  }

  static log(first: string, second?: Record<string, any>): void;
  // eslint-disable-next-line no-dupe-class-members
  static log(first: string[], second: string, third?: Record<string, any>): void;
  // eslint-disable-next-line no-dupe-class-members
  static log(first: string | string[], second?: string | Record<string, any>, third?: Record<string, any>) {
    const config = Ennabl.getConfig();

    if (!config.enabled) {
      return;
    }

    Ennabl.initializeLoggers();

    const {tags, uniqueId, data} = Ennabl.parseLogArguments(first, second, third);
    const logMessage = Ennabl.formatMessage(uniqueId, data, tags);
    Ennabl.loggerStrategies.forEach(logger => logger.log(...logMessage));
  }

  static logOnce(first: string, second?: Record<string, any>): void;
  // eslint-disable-next-line no-dupe-class-members
  static logOnce(first: string[], second: string, third?: Record<string, any>): void;
  // eslint-disable-next-line no-dupe-class-members
  static logOnce(first: string | string[], second?: string | Record<string, any>, third: Record<string, any> = {}) {
    const {uniqueId, data} = Ennabl.parseLogArguments(first, second, third);

    if (uniqueId === undefined) {
      throw new Error('Unique ID is required for logOnce.');
    }

    const logArgs = {uniqueId, ...data};
    const lastArgs = Ennabl.lastLogOnceData.get(uniqueId);

    // Shallow check to see if data has changed
    if (!lastArgs || !Ennabl.shallowEqual(lastArgs, logArgs)) {
      //@ts-ignore first argument either string or string[]
      Ennabl.log(first, second, third);

      Ennabl.lastLogOnceData.set(uniqueId, {...logArgs});
    }
  }

  private static shallowEqual(obj1: Record<string, any>, obj2: Record<string, any>): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  }
}
