import {ReactNode, useRef} from 'react';
import {css} from '@linaria/core';
import cx from 'clsx';
import {styled} from '@linaria/react';

import {colorVariables, styleVariables} from 'core/constants';
import {useElementSize} from 'utils/hooks';

import Typo from 'components/Typo';

type Size = 'small' | 'medium' | 'large';

export type TabPlaceholderProps = {
  heading: string;
  subheading: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  size?: Size;
  /**
   * Placeholder located within container. it is not replacing it
   */
  inline?: boolean;
  className?: string;
};

const SRoot = styled.div`
  height: inherit;
  /* don't allow content to go outside parent */
  overflow-y: hidden;
  flex: 1;
`;

const SContainer = styled.div<{
  size?: Size;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
`;

const sBlock = css`
  border: 1px solid ${colorVariables.Global.border};
  border-radius: 24px;
`;

function getStyles(width: number, height: number) {
  if (width < 1280) {
    return {gap: 16, padding: '16px 0'};
  }

  if (width < 1440) {
    if (height < 250) {
      return {gap: 16, padding: '16px 0'};
    }

    return {gap: 32, padding: '32px 0'};
  }

  if (height < 500) {
    return {gap: 16, padding: '16px 0'};
  }

  return {gap: 28, padding: '70px 0'};
}

export default function TabPlaceholder({
  heading,
  subheading,
  children,
  inline = false,
  size,
  footer,
  className,
}: TabPlaceholderProps) {
  const rootRef = useRef<HTMLDivElement>(null);
  const {width, height} = useElementSize(rootRef);

  return (
    <SRoot ref={rootRef}>
      <SContainer className={cx({[sBlock]: !inline}, className)} size={size} style={getStyles(width, height)}>
        {children}
        <Typo variant='main-page-heading' color={styleVariables.colorPalette.blueBoldness(600)}>
          {heading}
        </Typo>
        <Typo variant='standard' color={styleVariables.colorPalette.blueBoldness(600)}>
          {subheading}
        </Typo>
        {footer}
      </SContainer>
    </SRoot>
  );
}
