import {LocalStorageKey} from 'core/types';

import {WrongEnvironmentError} from 'core/utils/errors';

export const isCypressApp = () => {
  return 'Cypress' in window;
};

export const getCypressAuthToken = () => {
  if (!isCypressApp()) {
    throw new WrongEnvironmentError('Cypress');
  }

  const token = localStorage.getItem(LocalStorageKey.CypressToken);
  const expiresIn = Number(localStorage.getItem(LocalStorageKey.CypressExpiresIn));

  if (!token || !expiresIn || !isFinite(expiresIn)) {
    throw new Error('Cypress auth token not found');
  }

  return {token, expiresAt: Date.now() + expiresIn * 1000};
};
