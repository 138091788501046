import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {CSSProperties, ReactNode} from 'react';

import {shouldNotForwardProp} from 'utils/styled';

const SPlaceholder = styled(
  'div',
  shouldNotForwardProp('minHeight')
)<{minHeight?: CSSProperties['minHeight']}>(({theme, minHeight}) => ({
  height: '100%',
  minHeight,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
}));

const SPlaceholderContent = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  alignItems: 'center',
  textAlign: 'center',
}));

const SIconWrapper = styled('div')(({theme}) => ({
  width: 'max-content',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  padding: theme.spacing(11),
  borderRadius: '50%',
  backgroundColor: theme.palette.background.grey,

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(6),

    '& svg': {
      width: 32,
      height: 32,
    },
  },
}));

export type PlaceholderProps = {
  icon?: ReactNode;
  heading: string;
  subheading?: ReactNode;
  actions?: ReactNode;
  minHeight?: CSSProperties['minHeight'];
};

export function Placeholder({icon, heading, subheading, actions, minHeight}: PlaceholderProps) {
  return (
    <SPlaceholder minHeight={minHeight}>
      <SPlaceholderContent>
        {icon && <SIconWrapper>{icon}</SIconWrapper>}
        <Typography variant='h5'>{heading}</Typography>
        {subheading && <Typography variant='body2'>{subheading}</Typography>}
        {actions}
      </SPlaceholderContent>
    </SPlaceholder>
  );
}
