import {useNavigate} from 'react-router-dom';

import type {SidebarSection} from 'modules/decisionsNext/hooks/sidebar/types';

import {useSidebarSections} from 'modules/decisionsNext/hooks/sidebar/useSidebarSections';
import {withUrlIcons} from 'modules/decisionsNext/hooks/sidebar';
import {isWebViewApp, updateWebViewSidebar} from 'core/utils/webView';

export const useInitializeWebViewApp = () => {
  const navigate = useNavigate();
  const sections = useSidebarSections();

  if (!isWebViewApp()) {
    return;
  }

  updateWebViewSidebar(withUrlIcons(sections) as SidebarSection[]);

  window.ReactNativeEvents = {
    navigate,
  };
};
