import {Components, Theme} from '@mui/material/styles';

export const tabs = (theme: Theme): {MuiTab: Components['MuiTab']; MuiTabs: Components['MuiTabs']} => {
  return {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          ['&::before']: {
            backgroundColor: theme.palette.text.primary,
          },
        },
      },
      variants: [
        {
          props: {variant: 'fullWidth'},
          style: {
            '.MuiTabs-flexContainer': {
              gridAutoColumns: 'auto',
            },
          },
        },
      ],
    },
    MuiTab: {
      defaultProps: {disableRipple: true},
    },
  };
};
