import {toast} from 'sonner';

import {EnnablEnv} from 'core/constants/globalVariables';

async function loadCurrentVersionHash() {
  return fetch('/static/version.json').then(res => res.json());
}

const reload = () => window.location.reload();

const initialVersionLoadDelay = 5_000;
const updateInterval = (process.env.ENNABL_ENV === EnnablEnv.development ? 10 : 30) * 60 * 1000; // Every 30 min;

const renderAutoCloseToast = ({
  id,
  reloadDelay,
  onClick,
}: {
  id?: string | number;
  reloadDelay: number;
  onClick?: () => void;
}) => {
  return toast.warning('We updated the app', {
    description: 'The page will be reloaded automatically. Hang on!',
    duration: reloadDelay,
    cancel: {
      label: `Prevent reload (${reloadDelay / 1000})`,
      onClick: () => onClick?.(),
    },
    id,
  });
};

export function showUpdateNotification(autoReload: boolean = false) {
  if (!autoReload) {
    return toast.warning('We updated the app recently', {
      description: 'Please save your changes and hit reload.',
      action: {
        label: 'Reload now',
        onClick: reload,
      },
    });
  }

  let reloadDelay = 10_000;
  const id = renderAutoCloseToast({
    reloadDelay,
  });

  const intervalId = setInterval(() => {
    if (reloadDelay <= 0) {
      clearInterval(intervalId);
      reload();
    } else {
      reloadDelay -= 1000;
      renderAutoCloseToast({
        id: id,
        reloadDelay,
        onClick: () => clearInterval(intervalId),
      });
    }
  }, 1000);
}

export function watchAppVersion() {
  let versionHash: string;

  async function fetchVersionAndNotifyIfNeeded() {
    try {
      if (document.hidden) {
        return;
      }

      const hash = await loadCurrentVersionHash();

      if (hash && versionHash && hash !== versionHash) {
        showUpdateNotification();
      }

      versionHash = hash;
    } catch (e) {
      console.error('Failed to update app version', e);
    }
  }

  setTimeout(fetchVersionAndNotifyIfNeeded, initialVersionLoadDelay);
  setInterval(fetchVersionAndNotifyIfNeeded, updateInterval);
}
