import {styled} from '@mui/material/styles';

import LoadingIndicator from 'components/LoadingIndicator';

const SRoot = styled('div')`
  display: grid;
  grid-template-rows: max-content auto;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export function LoadingScreen() {
  return (
    <SRoot data-testid='loading-screen'>
      <LoadingIndicator />
    </SRoot>
  );
}
