import {PropsWithChildren} from 'react';
import {styled, ThemeProvider} from '@mui/material/styles';

import {themeDecisions} from 'core/themes/themeDecisions';

import {LogoHeader} from './LogoHeader';

import AppVersionFooter from 'modules/decisionsNext/components/AppVersionFooter';

const SRoot = styled('main')({
  display: 'grid',
  minHeight: '100vh',
  gridTemplateRows: 'min-content 1fr min-content',
});

const SWrapper = styled('section')({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
});

const SContent = styled('div')(({theme}) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
  width: 'max-content',
  backgroundColor: theme.palette.background.white,
  padding: theme.spacing(10, 10),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(10, 4),
  },
}));

export const ErrorPageLayout = ({children}: PropsWithChildren) => {
  return (
    <ThemeProvider theme={themeDecisions}>
      <SRoot>
        <LogoHeader />
        <SWrapper>
          <SContent>{children}</SContent>
        </SWrapper>
        <AppVersionFooter />
      </SRoot>
    </ThemeProvider>
  );
};
