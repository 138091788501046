import {useTheme} from '@mui/material';
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';

import {useIsDownSm} from 'utils/hooks/useMediaQuery';
import {useBackToHomeUrl} from 'utils/hooks/useBackToHomeUrl';

import {ErrorPageLayout} from 'components/ErrorPageLayout';
import {Placeholder} from 'components/Placeholder';
import NoResultsIcon from 'components/icons/placeholders/noResults.svg';

const PageContent = () => {
  const theme = useTheme();
  const isDownSm = useIsDownSm();
  const backToHomeUrl = useBackToHomeUrl();

  const buttonSize = isDownSm ? 'small' : 'medium';

  return (
    <Placeholder
      icon={<NoResultsIcon color={theme.palette.text.secondary} />}
      heading='We couldn’t load your data'
      subheading='Please, reload the page or try again later.'
      actions={
        <Button variant='contained' size={buttonSize} component={Link} to={backToHomeUrl}>
          Back to Home
        </Button>
      }
    />
  );
};

export function SomethingWentWrongPage() {
  return (
    <ErrorPageLayout>
      <PageContent />
    </ErrorPageLayout>
  );
}
