import {SafeReadonly} from '@apollo/client/cache/core/types/common';
import type {ObservableQuery} from '@apollo/client';
import {identity, isString, get} from 'lodash/fp';

import {Exact, InputMaybe, Scalars} from 'modules/command/api/types';
import type {PageInfo} from 'modules/decisionsNext/api/types';

import type {FieldMergeFunction, FieldFunctionOptions} from 'utils/apolloWrapper';
import {wait} from 'utils/wait';

import {parseJSON} from './json';

export type PaginationVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;
export type SearchVariables = Exact<{
  searchQuery?: InputMaybe<Scalars['String']>;
}>;

/**
 * Creates a function that merges incoming data with the cached data for lazy loading
 *
 * The default apollo cache behaviour is to override the existing data with the incoming data.
 * This merge function appends loaded data to existing one based on the offset.
 *
 * The query MUST be called with the arguments limit, offset and searchQuery
 *
 * The merge is applied to the list returned by the response, so if the cached field is not an array
 * must pass listExtractor and dataBuilder to let this function find the list inside the field
 */
export function lazyLoadingMergeFactory<TData, TListItem = TData>({
  listExtractor = identity,
  dataBuilder = identity,
  offsetPath = 'offset',
}: {
  listExtractor?: (data: TData | undefined) => TListItem[] | undefined | null;
  dataBuilder?: (mergedList: TListItem[], incoming: TData) => SafeReadonly<TData>;
  offsetPath?: string;
} = {}) {
  const merge: FieldMergeFunction<TData, TData, FieldFunctionOptions<any, any>> = (existing, incoming, {variables}) => {
    const offset = get(offsetPath, variables) || 0;
    const existingList = listExtractor(existing) || [];
    const incomingList = listExtractor(incoming) || [];
    const mergedList = existingList.concat();
    incomingList.forEach((item, index) => {
      mergedList[offset + index] = item;
    });
    return dataBuilder(mergedList, incoming);
  };

  return merge;
}

/**
 * This function keep PageInfo object consistent across both-directions pagination
 * When we move forward, we must keep the cursor of start of list unchangeable, because there
 * is a change what we would like to change direction. And the same for moving
 * backward: we must keep cursor of the end of list
 */
export function buildPageInfo(
  entityId: string,
  existingPageInfo: PageInfo,
  incomingPageInfo: PageInfo,
  args: Record<string, any> | null
) {
  if (!args || !args[entityId]) {
    return {};
  }

  // when we are moving forward, we have to save start cursor from the previous pack
  if (args.after && args.first) {
    return {
      ...incomingPageInfo,
      endCursor: incomingPageInfo.endCursor,
      startCursor: existingPageInfo.startCursor,
      hasPreviousPage: existingPageInfo.hasPreviousPage,
      hasNextPage: incomingPageInfo.hasNextPage,
    };
  }

  // when we are moving backward, we have to save end cursor
  return {
    ...incomingPageInfo,
    endCursor: existingPageInfo.endCursor,
    startCursor: incomingPageInfo.startCursor,
    hasPreviousPage: incomingPageInfo.hasPreviousPage,
    hasNextPage: existingPageInfo.hasNextPage,
  };
}

export const cursorMerge =
  (entityId: string) =>
  (existing: any = {}, incoming: any, {args}: FieldFunctionOptions) => {
    if (!args) {
      return incoming;
    }

    const requestedItem = args[entityId] ? {requestedItem: incoming.requestedItem} : {};

    // first data receiving
    if (!isString(args.after) && !isString(args.before)) {
      return incoming;
    }

    const edges = isString(args.after)
      ? // forward pagination
        [...existing.items.edges, ...incoming.items.edges]
      : // backward pagination
        [...incoming.items.edges, ...existing.items.edges];

    const pageInfo = buildPageInfo(entityId, existing.items.pageInfo, incoming.items.pageInfo, args);

    return {
      ...incoming,
      items: {
        ...incoming.items,
        edges,
        pageInfo,
      },
      ...requestedItem,
    };
  };

export function omitTypenames(obj: any) {
  return parseJSON(JSON.stringify(obj), {}, (key: string, value: any) => (key === '__typename' ? undefined : value));
}

/**
 * This is helpful when you have to wait before async DB updates will be
 * done, before refetch related queries
 */
export const delayRefetchedQuery =
  (delay = 4000) =>
  <T>(observableQuery: ObservableQuery<T>) => {
    // we intentionally call this w/o return, because we don't want
    // to stop mutation completion because of this refetch
    wait(delay).then(() => observableQuery.refetch());
  };
