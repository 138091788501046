import {styled} from '@mui/material/styles';
import {CSSProperties, ReactNode} from 'react';
import IconButton from '@mui/material/IconButton';
import MuiDialogTitle from '@mui/material/DialogTitle';
import {Typography} from '@mui/material';

import {shouldNotForwardProp} from 'utils/styled';

import CloseIcon from 'components/icons/cross.svg';
import ArrowLeftIcon from 'components/icons/arrowLeft.svg';

type DialogTitleProps = {
  children?: ReactNode;
  onClose?: () => void;
  onBack?: () => void;
  textAlign?: CSSProperties['textAlign'];
};

const SIconButton = styled(IconButton)(({theme}) => ({
  color: theme.palette.common.black,
  flex: 0,
  padding: theme.spacing(1),

  '&:first-of-type': {
    marginLeft: theme.spacing(-1),
  },

  '&:last-of-child': {
    marginRight: theme.spacing(-1),
  },
}));

const STypography = styled(
  Typography,
  shouldNotForwardProp('textAlign')
)<{textAlign: CSSProperties['textAlign']}>(({textAlign = 'center'}) => ({
  flex: 1,
  textAlign,
}));

/**
 * @deprecated use DecisionsDialog instead
 */
export function DialogTitle(props: DialogTitleProps) {
  const {children, onClose, onBack, textAlign, ...other} = props;

  return (
    <MuiDialogTitle component='div' color='text.primary' {...other}>
      {onBack && (
        <SIconButton size='small' onClick={onBack}>
          <ArrowLeftIcon width={20} height={20} />
        </SIconButton>
      )}

      <STypography textAlign={textAlign} variant='h4'>
        {children}
      </STypography>

      {onClose && <DialogTitleCloseButton onClose={onClose} />}
    </MuiDialogTitle>
  );
}

export function DialogTitleCloseButton({onClose, className}: {onClose: () => void; className?: string}) {
  return (
    <SIconButton aria-label='close' size='small' onClick={onClose} className={className}>
      <CloseIcon width={20} height={20} />
    </SIconButton>
  );
}
