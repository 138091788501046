import {useEffect, useMemo, useState} from 'react';
import {StringKeyOf} from 'type-fest';

import {SortOrder} from 'types';

import {sortCaseInsensitively} from 'utils/arrayUtils';
import {GenericTreeNode} from 'utils/treeUtils';

type MaybeGenericTreeNode<T> = T extends object
  ? T
  : {
      [Prop in keyof T]: Prop extends 'children' ? GenericTreeNode<T> : T;
    };

export type UseSortOptions<K, T extends MaybeGenericTreeNode<K>> = {
  data: T[];
  defaultSortKey?: StringKeyOf<T> | string;
  defaultSortOrder?: SortOrder;
  getChildren?: (item: GenericTreeNode<T>) => GenericTreeNode<T>[] | undefined;
};

export const useSort = <K, T extends MaybeGenericTreeNode<K>>({
  data,
  defaultSortKey,
  defaultSortOrder,
  getChildren,
}: UseSortOptions<K, T>) => {
  const [sortParams, setSortParams] = useState<{
    key?: StringKeyOf<T> | string;
    order?: SortOrder;
  }>({
    key: defaultSortKey,
    order: defaultSortOrder,
  });

  const sortedData = useMemo(() => {
    if (!sortParams.key || sortParams.order == null) {
      return data;
    }

    return deepSort({data, key: sortParams.key, order: sortParams.order, getChildren});
  }, [sortParams.key, sortParams.order, data, getChildren]);

  useEffect(() => {
    setSortParams({key: defaultSortKey, order: defaultSortOrder});
  }, [defaultSortKey, defaultSortOrder]);

  return {
    sortedData,
    sortParams,
    setSortParams,
  };
};

function deepSort<K, T extends MaybeGenericTreeNode<K>>({
  data,
  getChildren,
  key,
  order,
}: Pick<UseSortOptions<K, T>, 'data' | 'getChildren'> & {key?: StringKeyOf<T> | string; order?: SortOrder}): T[] {
  const sorted = sortCaseInsensitively(data, key as any, order);

  if (!getChildren) {
    return sorted;
  }

  return sorted.map(item => {
    const children = getChildren(item);

    if (!children) {
      return item;
    }

    return {
      ...item,
      children: deepSort({data: children, getChildren, key, order}),
    };
  });
}
