import type {CSSProperties} from 'react';
// no need to install it explicitly
// eslint-disable-next-line import/no-unresolved
import {OverridableStringUnion} from '@mui/types';
import {Variant} from '@mui/material/styles/createTypography';
import {TypographyPropsVariantOverrides} from '@mui/material/Typography/Typography';

const fontFamily = '"Inter", sans-serif';
const letterSpacing = 'normal';

const fontStyle = {
  fontFamily,
  letterSpacing,
};

const variants = {
  h1: {
    fontSize: 48,
    fontWeight: 400,
    lineHeight: 52 / 48,
  },
  h2: {
    fontSize: 34,
    fontWeight: 400,
    lineHeight: 40 / 34,
    letterSpacing: 0.25,
  },
  h3: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 32 / 24,
    letterSpacing: 0.25,
  },
  h4: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 28 / 20,
    letterSpacing: 0.15,
  },
  h5: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 16 / 14,
    letterSpacing: 0.15,
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 24 / 16,
    letterSpacing: 0.15,
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 20 / 14,
    letterSpacing: 0.17,
  },
  body3: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 16 / 12,
    letterSpacing: 0.17,
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 24 / 16,
    letterSpacing: 0.15,
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 16 / 14,
    letterSpacing: 0.1,
  },
  subtitle3: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 16 / 12,
    letterSpacing: 0.1,
  },
  captionRegular: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 16 / 12,
    letterSpacing: 0.4,
  },
  captionBold: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 16 / 12,
    letterSpacing: 0.4,
  },
  code: {
    fontFamily: 'monospace',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 16 / 12,
    letterSpacing: 0.17,
  },
  dataName: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 16 / 14,
    letterSpacing: 0.17,
  },
  overlineRegular: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 20 / 12,
    letterSpacing: 1,
    textTransform: 'uppercase' as CSSProperties['textTransform'],
  },
  overlineBold: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 20 / 12,
    letterSpacing: 1,
    textTransform: 'uppercase' as CSSProperties['textTransform'],
  },
  cellHeader: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 24 / 14,
    letterSpacing: 0.1,
  },
  cellText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 24 / 14,
    letterSpacing: 0.15,
  },
  pillTitle: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 16 / 12,
    letterSpacing: 0.15,
  },
  pillNumber: {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: 12 / 10,
    letterSpacing: 0.15,
  },
  pillNumberSemiBold: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 12 / 10,
    letterSpacing: 0.15,
  },
  buttonLarge: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 24 / 16,
    letterSpacing: 0.15,
    textTransform: 'none' as 'none',
  },
  buttonMedium: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 20 / 14,
    letterSpacing: 0.15,
    textTransform: 'none' as 'none',
  },
  buttonSmall: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 16 / 12,
    letterSpacing: 0.15,
    textTransform: 'none' as 'none',
  },
  tabLarge: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 20 / 16,
    letterSpacing: 0.15,
    textTransform: 'none' as 'none',
  },
  tabMedium: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 16 / 14,
    letterSpacing: 0.15,
    textTransform: 'none' as 'none',
  },
  tabSmall: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 16 / 12,
    letterSpacing: 0.15,
    textTransform: 'none' as 'none',
  },
  tableHeader: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 24,
    letterSpacing: 0.1,
  },
};

export type DecisionsTypographyVariants = keyof typeof variants;

export type AllTypographyVariants = OverridableStringUnion<
  Exclude<Variant, 'h6' | 'button' | 'caption' | 'overline'>,
  TypographyPropsVariantOverrides
>;

const typography = {
  typography: {
    /**
     * Get correct height of the typography
     */
    height: (variant: AllTypographyVariants) =>
      Number(variants[variant].lineHeight) * Number(variants[variant].fontSize),
    ...fontStyle,
    ...variants,
  },
};

export default typography;
