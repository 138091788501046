import {ReactElement, useState} from 'react';

import {isBrowserOutdated} from 'components/UnsupportedBrowserWarning/utils';
import {UnsupportedBrowserWarning} from 'components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';

export function CheckBrowserSupport({children}: {children: ReactElement}) {
  const [isWarningSeen, setIsWarningSeen] = useState(false);
  const isOutdated = isBrowserOutdated();

  return isOutdated && !isWarningSeen ? <UnsupportedBrowserWarning setIsWarningSeen={setIsWarningSeen} /> : children;
}
