import {Divider, SidebarSection} from 'modules/decisionsNext/hooks/sidebar/types';

import {iconUrlMap} from 'modules/decisionsNext/hooks/sidebar/iconUrlMap';

export const isDivider = (section: SidebarSection): section is Divider => (section as Divider).divider;

export const withUrlIcons = (sections: SidebarSection[]) => {
  return sections.map(section => {
    return isDivider(section)
      ? section
      : {
          ...section,
          items: section.items.map(item => {
            return {
              ...item,
              icon: iconUrlMap[item.icon] || item.icon,
            };
          }),
        };
  });
};
