import {graphQLClient} from 'modules/decisionsNext/api';
import {MyInfoDocument, MyInfoQuery} from 'modules/decisionsNext/api/profileInfos/myInfo.generated';

export type MyInfo = MyInfoQuery;

export async function getMyInfo() {
  const result = await graphQLClient.query<MyInfo>({
    query: MyInfoDocument,
    fetchPolicy: 'no-cache',
  });

  if (result.error) {
    throw result.error;
  }

  return result.data;
}
