import {Link, useTheme} from '@mui/material';
import Button from '@mui/material/Button';

import {useIsDownSm} from 'utils/hooks/useMediaQuery';
import {SUPPORT_EMAIL} from 'modules/decisionsNext/constants';
import {logout} from 'core/utils/auth';

import {ErrorPageLayout} from 'components/ErrorPageLayout';
import {Placeholder} from 'components/Placeholder';
import LockIcon from 'components/icons/placeholders/lock.svg';

const PageContent = () => {
  const theme = useTheme();
  const isDownSm = useIsDownSm();

  const buttonSize = isDownSm ? 'small' : 'medium';

  return (
    <Placeholder
      icon={<LockIcon color={theme.palette.text.secondary} />}
      heading='Sorry, you don’t have permissions'
      subheading={
        <>
          Please contact your administrator or{' '}
          <Link variant='body2' href={`mailto:${SUPPORT_EMAIL}`} color={theme.palette.primary.main}>
            {SUPPORT_EMAIL}
          </Link>
        </>
      }
      actions={
        <Button variant='contained' size={buttonSize} onClick={logout}>
          Log out
        </Button>
      }
    />
  );
};

export function NoPermissionPage() {
  return (
    <ErrorPageLayout>
      <PageContent />
    </ErrorPageLayout>
  );
}
