import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

/**
 * This is useful for a long page layout. we have to
 * always scroll to top when go to the next page
 *
 * @see https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
 */
export function useScrollToAnchor() {
  const {pathname, hash} = useLocation();

  useEffect(() => {
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }, 0);
  }, [pathname, hash]);
}
