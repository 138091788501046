import {Components, Theme} from '@mui/material/styles';

export const link = (theme: Theme): {MuiLink: Components['MuiLink']} => {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,

          '&:hover': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  };
};
