import {styled} from '@mui/material/styles';
import {DialogActions, Typography} from '@mui/material';
import {ReactElement} from 'react';

import {useIsUpSm} from 'utils/hooks/useMediaQuery';

const SDialogActions = styled(DialogActions)(({theme}) => ({
  '&&': {
    padding: theme.spacing(2, 4),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      padding: theme.spacing(4),
    },
  },
}));

const STypography = styled(Typography)(({theme}) => ({
  gridColumn: '1 / span 2',
  padding: theme.spacing(3, 0),
}));

const SDivider = styled('div')(() => ({
  flex: 1,
}));

type DecisionsDialogActionConfirmationProps = {
  label?: string;
  primaryAction: ReactElement;
  secondaryAction?: ReactElement;
};

export const DecisionsDialogActionConfirmation = ({
  label = 'Are you sure?',
  primaryAction,
  secondaryAction,
}: DecisionsDialogActionConfirmationProps) => {
  const isUpSm = useIsUpSm();

  return (
    <SDialogActions>
      <STypography variant='buttonMedium' textAlign='center'>
        {label}
      </STypography>
      {isUpSm && <SDivider />}
      {secondaryAction}
      {primaryAction}
    </SDialogActions>
  );
};
