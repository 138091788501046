import {createWithEqualityFn} from 'zustand/traditional';
import {devtools} from 'zustand/middleware';
import {shallow} from 'zustand/shallow';

import {IS_DEV_ENV} from 'config';

export type RevealState = {
  isInitialized: boolean;
};

export const useRevealPluginStore = createWithEqualityFn<RevealState>()(
  devtools<RevealState>(
    () => ({
      isInitialized: false,
    }),
    {
      enabled: IS_DEV_ENV,
      name: 'Reveal Pligin',
      store: 'reveal_plugin_store',
    }
  ),
  shallow
);

export const setIsInitialized = (isInitialized: boolean) =>
  useRevealPluginStore.setState(
    state => ({
      ...state,
      isInitialized,
    }),
    false,
    'setIsInitialized'
  );
