import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {APP_VERSION} from 'core/constants/globalVariables';

import PoweredByIcon from 'components/icons/poweredBy.svg';

type ShrinkProp = {
  shrink?: boolean;
};

const SRoot = styled('footer')<ShrinkProp>(({theme, shrink}) => ({
  padding: theme.spacing(shrink ? 4 : 8),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // for compatibility w/ upper logic
  flexShrink: 0,
}));

const SWrapper = styled('div')(({theme}) => ({
  height: 20,
  display: 'flex',
  alignItems: 'baseline',
  gap: theme.spacing(4),
}));

export default function AppVersionFooter({shrink}: ShrinkProp) {
  return (
    <SRoot shrink={shrink}>
      <SWrapper>
        <PoweredByIcon />
        <Typography variant='body3'>VERSION {APP_VERSION}</Typography>
      </SWrapper>
    </SRoot>
  );
}
