import AiAssistIcon from 'components/icons/aiAssist.svg?url';
import HomeIcon from 'components/icons/home.svg?url';
import AccountsIcon from 'components/icons/metrics/accounts.svg?url';
import BookmarkIcon from 'components/icons/bookmark.svg?url';
import CarrierAppetiteIcon from 'components/icons/carrier-appetite.svg?url';
import ProducersIcon from 'components/icons/metrics/producers.svg?url';
import ProductsIcon from 'components/icons/products.svg?url';
import MarketsIcon from 'components/icons/markets.svg?url';
import ReportsIcon from 'components/icons/reportsMenu.svg?url';
import AccountManagersIcon from 'components/icons/accountManagers.svg?url';
import IndustriesIcon from 'components/icons/industries.svg?url';
import ProspectsIcon from 'components/icons/prospect.svg?url';
import UnionIcon from 'components/icons/union.svg?url';
import RetentionIcon from 'components/icons/retention.svg?url';
import RenewalsIcon from 'components/icons/renewals.svg?url';
import OverviewIcon from 'components/icons/overview.svg?url';
import O360ScoreIcon from 'components/icons/o360score.svg?url';
import DocumentsIcon from 'components/icons/files/document.svg?url';
import KPIIcon from 'components/icons/kpi.svg?url';
import LossRunsIcon from 'components/icons/lossRuns.svg?url';

import {IconKey} from './types';

export const iconUrlMap = {
  [IconKey.AI]: AiAssistIcon,
  [IconKey.Home]: HomeIcon,
  [IconKey.Accounts]: AccountsIcon,
  [IconKey.Bookmark]: BookmarkIcon,
  [IconKey.CarrierAppetite]: CarrierAppetiteIcon,
  [IconKey.Producers]: ProducersIcon,
  [IconKey.Products]: ProductsIcon,
  [IconKey.Markets]: MarketsIcon,
  [IconKey.Reports]: ReportsIcon,
  [IconKey.AccountManagers]: AccountManagersIcon,
  [IconKey.Industries]: IndustriesIcon,
  [IconKey.Prospects]: ProspectsIcon,
  [IconKey.Union]: UnionIcon,
  [IconKey.Retention]: RetentionIcon,
  [IconKey.Renewals]: RenewalsIcon,
  [IconKey.Overview]: OverviewIcon,
  [IconKey.O360Score]: O360ScoreIcon,
  [IconKey.Documents]: DocumentsIcon,
  [IconKey.KPI]: KPIIcon,
  [IconKey.LossRuns]: LossRunsIcon,
} satisfies Record<IconKey, string>;
