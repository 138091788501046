import mitt from 'mitt';
import {useEffect, useState} from 'react';

import ConfirmDialog from 'components/ConfirmDialog';
import {ConfirmDialogProps} from 'components/ConfirmDialog/ConfirmDialog';

import {useBooleanToggle} from './hooks';

export type ConfirmationRejectReason = 'cancel' | 'close';

type Options = Partial<ConfirmDialogProps>;

type RequestEvent = {
  resolve: () => void;
  reject: (reason?: ConfirmationRejectReason) => void;
  options: Options;
};

type Events = {
  'request-confirmation': RequestEvent;
};

const eventEmitter = mitt<Events>();

export const confirmation = (options: Options) => {
  return new Promise<void>((resolve, reject) => {
    eventEmitter.emit('request-confirmation', {
      resolve,
      reject,
      options,
    });
  });
};

export function ConfirmationManager() {
  const {setTrue: openDialog, setFalse: closeDialog, value: isDialogOpen} = useBooleanToggle(false);
  const [requestEvent, setRequestEvent] = useState<RequestEvent | null>(null);

  useEffect(() => {
    const onConfirmationRequest = (e: RequestEvent) => {
      setRequestEvent(e);
      openDialog();
    };

    eventEmitter.on('request-confirmation', onConfirmationRequest);
    return () => eventEmitter.off('request-confirmation', onConfirmationRequest);
  }, [openDialog]);

  return (
    <ConfirmDialog
      open={isDialogOpen}
      onClose={() => {
        requestEvent?.reject('close');
        closeDialog();
      }}
      onCancel={() => {
        requestEvent?.reject('cancel');
        closeDialog();
      }}
      onConfirm={() => {
        requestEvent?.resolve();
        closeDialog();
      }}
      TransitionProps={{
        onTransitionEnd: () => {
          if (isDialogOpen) {
            return;
          }

          setRequestEvent(null);
        },
      }}
      {...requestEvent?.options}
      content={undefined}
    >
      {requestEvent?.options.content}
    </ConfirmDialog>
  );
}
