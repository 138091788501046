import {Alert, styled} from '@mui/material';
import {useNetworkState} from 'react-use';

import {createPortalComponent} from 'utils/portalUtils';

const SAlert = styled(Alert)(({theme}) => ({
  position: 'fixed',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  width: 'max-content',
  maxWidth: '100vw',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  zIndex: theme.zIndex.tooltip + 1,
}));

const SRootPortal = createPortalComponent(null, SAlert);

export const OfflineBanner = () => {
  const {online} = useNetworkState();
  return online ? null : (
    <SRootPortal variant='filled' severity='warning'>
      You are currently offline. Please check your internet connection.
    </SRootPortal>
  );
};
