import {toast} from 'sonner';

import type {ApolloError} from 'utils/apolloWrapper';

type ValidationError = {
  /**
   * A list of errors for the field
   */
  constraints: Record<string, string>;
  /**
   * The field where the errors occurred
   */
  property: string;
};

const Errors = ({errors}: {errors: string[]}) => (
  <ul>
    {errors.map(error => (
      <li key={error}>{error}</li>
    ))}
  </ul>
);

const defaultErrorHandler = (err: ApolloError, isForm = false) => {
  // this object is for form validation
  const errors = {} as Record<string, string>;
  // this one for `toasts`
  const flatErrors = [] as string[];

  if (Array.isArray(err.graphQLErrors) && err.graphQLErrors.length) {
    err.graphQLErrors.forEach(graphQLError => {
      if (graphQLError.extensions.exception?.validationErrors) {
        (graphQLError.extensions.exception.validationErrors as ValidationError[]).forEach(({constraints, property}) => {
          const fieldErrors = Object.values(constraints);
          errors[property] = fieldErrors.join(', ');
          fieldErrors.forEach(fieldError => flatErrors.push(fieldError));
        });
      } else if (graphQLError.message) {
        flatErrors.push(graphQLError.message);
      }
    });
  }

  if (isForm && Object.keys(errors).length) {
    return errors;
  }

  if (flatErrors.length) {
    if (flatErrors.length === 1) {
      toast.error(flatErrors[0]);
    } else {
      toast.error(<Errors errors={flatErrors} />);
    }
  } else {
    toast.error('Something went wrong');
  }
};

export const onError = (err: ApolloError) => defaultErrorHandler(err);

export const catchHandler = (isForm?: boolean) => (err: ApolloError) => defaultErrorHandler(err, isForm);

export function injectOnError<
  T extends {onError?: (error: ApolloError) => void},
  H extends (arg: any, arg2?: T) => any
>(hook: H) {
  return (...args: Parameters<H>) => {
    const [arg, options = {}] = args;
    return hook(arg, {onError, ...options} as T);
  };
}
