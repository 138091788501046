import {Auth0Error, WebAuth} from 'auth0-js';

import {AuthToken} from 'core/types';

import {AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_API_AUDIENCE} from 'config';

const auth0Client = new WebAuth({
  clientID: AUTH0_CLIENT_ID,
  domain: AUTH0_DOMAIN,
  redirectUri: window.location.origin,
  responseType: 'code',
  audience: AUTH0_API_AUDIENCE,
  scope: 'openid profile email',
});

export function getAuth0AuthToken() {
  return new Promise<AuthToken>((resolve, reject) => {
    auth0Client.checkSession(
      {responseType: 'token'},
      (error: Auth0Error | null, result?: {accessToken: string; expiresIn: number}) => {
        if (error || !result) {
          reject(error);
          return;
        }

        const expiresIn = result.expiresIn;

        resolve({token: result.accessToken, expiresAt: Date.now() + expiresIn * 1000});
      }
    );
  });
}

export const authorize = auth0Client.authorize;
