export class WrongEnvironmentError extends Error {
  constructor(evnironment: 'WebView' | 'Cypress', message = `This function should be used only in ${evnironment} app`) {
    super(message);
  }
}

export class NoPermissionError extends Error {
  constructor(message = 'No permission') {
    super(message);
  }
}

export class NotFoundError extends Error {
  constructor(message = 'Not found') {
    super(message);
  }
}
