import {styled} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import {styleVariables} from 'core/constants';

type Props = {
  inline?: boolean;
  size?: string | number | undefined;
  color?: string;
};

const SRoot = styled('div')(() => ({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',

  height: '100%',
  padding: styleVariables.defaultPadding,
}));

const SProgress = styled(CircularProgress)<{$color?: string}>(({theme, $color}) => ({
  color: $color || theme.palette.background.black, //
}));

const LoadingIndicator = ({color, size = 36}: Props) => {
  return (
    <SRoot>
      <SProgress size={size} $color={color} />
    </SRoot>
  );
};

export default LoadingIndicator;
