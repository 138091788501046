import {useFeatureFlag, useIsProducer, useUserInfo} from 'core/hooks/authHooks';
import {FeatureFlags} from 'core/constants/featureFlags';

export function useProducerViewWithCarrierAppetiteFlag() {
  const user = useUserInfo();
  const isProducer = useIsProducer();

  const isDemoUserWithProducerRoleAndCarrierAppetite =
    user?.profile?.email === 'demouser+producer+carrier_appetite@ennabl.com';

  // Replace insights / decisions with carrier appetite
  const producerViewWithCarrierAppetite =
    useFeatureFlag(FeatureFlags.ProducerViewWithCarrierAppetite) || isDemoUserWithProducerRoleAndCarrierAppetite;

  return {
    producerViewWithCarrierAppetite,
    isProducerWithCarrierAppetite: isProducer && producerViewWithCarrierAppetite,
  };
}
