import {PropsWithChildren} from 'react';

import {useAuthStore} from 'core/stores/auth';

import {LoadingScreen} from 'components/LoadingScreen';

export const AuthorizedArea = ({children}: PropsWithChildren) => {
  const authorized = useAuthStore(state => state.authorized);

  return authorized ? children : <LoadingScreen />;
};
