import {alpha, Components, Theme} from '@mui/material/styles';
import {autocompleteClasses} from '@mui/material/Autocomplete';
import {formControlLabelClasses} from '@mui/material/FormControlLabel';
import {formGroupClasses} from '@mui/material/FormGroup';
import {checkboxClasses} from '@mui/material/Checkbox';
import {filledInputClasses} from '@mui/material/FilledInput';
import {selectClasses} from '@mui/material/Select';
import {inputBaseClasses} from '@mui/material/InputBase';

import CheckboxCheckedIcon from 'components/icons/checkboxChecked.svg';
import CheckboxUncheckedIcon from 'components/icons/checkboxUnchecked.svg';
import CheckboxIndeterminateIcon from 'components/icons/checkboxIndeterminate.svg';
import RadioCheckedIcon from 'components/icons/inputs/radioCheckedIcon.svg';
import RadioIcon from 'components/icons/inputs/radioIcon.svg';
import DropdownIcon from 'components/icons/dropdown.svg';

export const input = (theme: Theme): Partial<Components> => ({
  MuiCheckbox: {
    defaultProps: {
      checkedIcon: <CheckboxCheckedIcon />,
      icon: <CheckboxUncheckedIcon />,
      indeterminateIcon: <CheckboxIndeterminateIcon />,
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      filled: params => {
        const {filled, focused, adornedStart} = params.ownerState.formControl as {
          filled: boolean;
          focused: boolean;
          adornedStart: boolean;
        };

        const shrink = filled || focused;

        const sizeSmall = {
          cursor: 'text',
          transform: 'translate(14px, 10px)',
          ...(adornedStart && {transform: 'translate(50px, 10px)'}),
          ...(shrink && {visibility: 'hidden' as const}),
        };

        return {
          ...theme.typography.subtitle1,
          ...(adornedStart && {transform: 'translate(50px, 16px)'}),
          ...(shrink && {
            ...theme.typography.body3,
            transform: adornedStart ? 'translate(50px, 7px)' : 'translate(14px, 7px)',
          }),
          ...(params.ownerState.size === 'small' && sizeSmall),

          [`&&:has(+ div > .${selectClasses.select})`]: {
            // you can add some rules specifically when used by Select component
          },

          // For such kind of inputs w/ placeholders, we keep label stuck to top
          [`&&:has(+ div:not(.${autocompleteClasses.inputRoot}) > .${filledInputClasses.input}:placeholder-shown)`]: {
            ...theme.typography.body3,
            transform: adornedStart ? 'translate(50px, 7px)' : 'translate(14px, 7px)',
          },

          [`&&:has(+ .${autocompleteClasses.inputRoot} > :where(.${autocompleteClasses.tag}, .${filledInputClasses.input}:placeholder-shown))`]:
            {
              ...theme.typography.body3,
              color: !focused ? theme.palette.primary.main : undefined,
              transform: 'translate(14px, 7px)',
            },

          [theme.breakpoints.down('sm')]: {
            ...theme.typography.body2,
          },
        };
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: params => ({
        ...theme.typography.subtitle1,
        backgroundColor: theme.palette.common.white,
        border: `2px solid transparent`,
        borderRadius: theme.shape.borderRadiusRegular,
        transition: theme.transitions.create(['border-color'], {
          duration: theme.transitions.duration.shortest,
        }),
        padding: theme.spacing(0, 3),
        gap: theme.spacing(3),

        '> svg': {
          flexShrink: 0,
          color: theme.palette.text.secondary,
        },

        ':hover': {
          ':not(.Mui-disabled)': {
            backgroundColor: theme.palette.common.white,

            '> svg': {
              color: theme.palette.text.primary,
            },
          },

          ':not(.Mui-disabled, .Mui-focused, .Mui-error)': {
            border: `2px solid ${theme.palette.divider}`,
          },
        },

        '&.Mui-focused': {
          backgroundColor: theme.palette.common.white,

          '&:not(.Mui-error)': {
            border: `2px solid ${theme.palette[params.ownerState.color || 'primary'].main}`,
          },

          '> svg': {
            color: theme.palette.text.primary,
          },
        },

        ':before, :after': {
          display: 'none',
        },

        '&.Mui-disabled': {
          backgroundColor: theme.palette.common.white,

          '> svg': {
            color: theme.palette.text.disabled,
          },
        },
      }),
      adornedStart: {
        '> svg': {
          width: 24,
          height: 24,
        },
      },
      adornedEnd: {
        '> svg': {
          width: 24,
          height: 24,
        },
      },
      colorSecondary: {
        backgroundColor: theme.palette.background.grey,

        ':hover': {
          ':not(.Mui-disabled)': {
            backgroundColor: theme.palette.background.grey,

            '> svg': {
              color: theme.palette.text.primary,
            },
          },

          ':not(.Mui-disabled, .Mui-focused, .Mui-error)': {
            border: `2px solid ${theme.palette.divider}`,
          },
        },

        '&.Mui-focused': {
          backgroundColor: theme.palette.background.grey,

          '&:not(.Mui-error)': {
            border: `2px solid ${theme.palette.primary.main}`,
          },
        },

        '&.Mui-disabled': {
          backgroundColor: alpha(theme.palette.common.black, theme.palette.action.hoverOpacity),
        },
      },
      error: {
        border: `2px solid ${theme.palette.error.main}`,
      },
      input: {
        padding: '22px 0 6px 0',
        height: 'auto',
      },
      inputSizeSmall: {
        padding: '8px 0 8px 0',
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        [`.${checkboxClasses.root} + .${formControlLabelClasses.label}`]: {
          ...theme.typography.body3,
        },

        [`&:has(.${checkboxClasses.root})`]: {
          marginLeft: theme.spacing(-2),
        },
      },
    },
  },
  MuiFormGroup: {
    styleOverrides: {
      root: {
        '&[role=radiogroup]': {
          gap: theme.spacing(2),

          [`& .${formControlLabelClasses.root}`]: {
            display: 'grid',
            gridAutoFlow: 'column',
            gap: theme.spacing(2),
            justifyContent: 'start',
            padding: theme.spacing(3.25, 4),
            margin: 'unset',

            borderRadius: theme.shape.borderRadiusRegular,
            border: `1px solid ${alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)}`,
          },
          [`& .${formControlLabelClasses.label}`]: {
            ...theme.typography.buttonSmall,
          },
        },
        [`&:not(.${formGroupClasses.row})[role=radiogroup]`]: {
          [`& .${formControlLabelClasses.root}`]: {
            borderRadius: 0,
            borderBottom: 0,
            '&:first-child': {
              borderTopLeftRadius: theme.shape.borderRadiusRegular,
              borderTopRightRadius: theme.shape.borderRadiusRegular,
            },
            '&:last-child': {
              borderBottom: `1px solid ${alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)}`,
              borderBottomLeftRadius: theme.shape.borderRadiusRegular,
              borderBottomRightRadius: theme.shape.borderRadiusRegular,
            },
          },
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        ...theme.typography.body3,
        margin: theme.spacing(0.5, 3, 0),
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        ...theme.typography.body2,
        // colorPrimary does not exist in MuiFormLabel api, that's why we extend it manually
        '&.MuiFormLabel-colorPrimary': {
          color: theme.palette.text.primary,
        },
      },
      colorSecondary: params => {
        const {filled, focused} = params.ownerState;
        const shrink = filled || focused;
        return {
          ...(shrink && {
            color: theme.palette.primary.main,
          }),

          '&.Mui-focused:not(.Mui-error)': {
            color: theme.palette.text.secondary,
          },
        };
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      checkedIcon: <RadioCheckedIcon width={16} />,
      icon: <RadioIcon width={16} />,
    },
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      IconComponent: DropdownIcon,
    },
    styleOverrides: {
      root: {
        padding: 'unset',
      },
      select: {
        ...theme.typography.body3,
        // get height from font's line-height
        height: 'unset',
        minHeight: 'unset',

        [`&:not(.${inputBaseClasses.inputSizeSmall})`]: {
          padding: theme.spacing('14px', 4),
        },
      },
      filled: {
        ...theme.typography.subtitle1,

        [`&:not(.${inputBaseClasses.inputSizeSmall})`]: {
          padding: theme.spacing('22px', '30px', '6px', 3),
        },

        [`&.${filledInputClasses.input}`]: {
          '&:focus': {
            backgroundColor: 'unset',
          },
        },
      },
      icon: {width: 16, height: 16, right: 17},
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'filled',
    },
  },
});
