import {useMemo} from 'react';

import {useBooleanToggle} from './useBooleanToggle';

type UseCollapsedListWithExpansionProps<T> = {
  items: T[];
  initialItemsCount?: number;
};

export function useCollapsedListWithExpansion<T>({
  items,
  initialItemsCount = 2,
}: UseCollapsedListWithExpansionProps<T>) {
  const {
    value: isExpanded,
    setValue: setIsExpanded,
    toggle: toggleIsExpanded,
    setTrue: expand,
    setFalse: collapse,
  } = useBooleanToggle();

  const visibleItems = useMemo(() => {
    if (isExpanded) {
      return items;
    }

    return items.slice(0, initialItemsCount);
  }, [initialItemsCount, items, isExpanded]);

  const hiddenItemsCount = items.length - visibleItems.length;

  return {
    visibleItems,
    hiddenItemsCount,

    isExpanded,
    toggleIsExpanded,
    expand,
    collapse,
    setIsExpanded,
  };
}
