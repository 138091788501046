export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {[_ in K]?: never};
export type Incremental<T> = T | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string};
  String: {input: string; output: string};
  Boolean: {input: boolean; output: boolean};
  Int: {input: number; output: number};
  Float: {input: number; output: number};
  /** Date in format 'YYYY-MM-DD' */
  SimpleDate: {input: any; output: any};
  /** ISO date string */
  Timestamp: {input: number | string; output: number | string};
};

export enum AccountsReportEntity {
  AccountExecutive = 'ACCOUNT_EXECUTIVE',
  Carrier = 'CARRIER',
  Department = 'DEPARTMENT',
  Industry = 'INDUSTRY',
  PolicyType = 'POLICY_TYPE',
  Producer = 'PRODUCER',
  Product = 'PRODUCT',
}

export type Activities = {
  __typename?: 'Activities';
  byClient: ActivitiesPaginatedResult;
};

export type ActivitiesByClientArgs = {
  clientId: Scalars['ID']['input'];
  filter?: InputMaybe<ClientActivityFilter>;
  pagination: ActivityPaginationInput;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ActivitiesPaginatedResult = {
  __typename?: 'ActivitiesPaginatedResult';
  items: Array<Activity>;
  totalHits: Scalars['Int']['output'];
};

export type Activity = {
  __typename?: 'Activity';
  client?: Maybe<Client>;
  closedDate?: Maybe<Scalars['Timestamp']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  codeDescription?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  policy?: Maybe<Policy>;
  product?: Maybe<Product>;
  subject?: Maybe<Scalars['String']['output']>;
  type: ActivityType;
  user?: Maybe<User>;
};

export type ActivityPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy: ActivitySortingField;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export enum ActivitySortingField {
  ActivityType = 'ACTIVITY_TYPE',
  Code = 'CODE',
  CodeDescription = 'CODE_DESCRIPTION',
  Date = 'DATE',
  PolicyNumber = 'POLICY_NUMBER',
  ProductName = 'PRODUCT_NAME',
  Subject = 'SUBJECT',
  UserName = 'USER_NAME',
}

export enum ActivityType {
  Ams = 'AMS',
  Email = 'EMAIL',
}

export type AddResponseToFavoritesPayload = {
  __typename?: 'AddResponseToFavoritesPayload';
  success: Scalars['Boolean']['output'];
};

export type Address = {
  __typename?: 'Address';
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  streetLine1?: Maybe<Scalars['String']['output']>;
  streetLine2?: Maybe<Scalars['String']['output']>;
};

export type AdminCompany = {
  __typename?: 'AdminCompany';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AdminInsuranceCompany = {
  __typename?: 'AdminInsuranceCompany';
  aliases: Array<Scalars['String']['output']>;
  amBestNumber?: Maybe<Scalars['Int']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  duns?: Maybe<Scalars['String']['output']>;
  fein?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  naic?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  numberOfBrokers: Scalars['Int']['output'];
  numberOfMappedMappings: Scalars['Int']['output'];
  parentDeleted: Scalars['Boolean']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
  parentName?: Maybe<Scalars['String']['output']>;
  primaryRelationship: Scalars['String']['output'];
  primaryRole: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  ultimateParentId?: Maybe<Scalars['ID']['output']>;
  ultimateParentName?: Maybe<Scalars['String']['output']>;
};

export type AdminProduct = {
  __typename?: 'AdminProduct';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AdminProductExtended = {
  __typename?: 'AdminProductExtended';
  groups: Array<Scalars['String']['output']>;
  hideOnDecisions: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isFee: Scalars['Boolean']['output'];
  isOpportunity: Scalars['Boolean']['output'];
  isPackage: Scalars['Boolean']['output'];
  isRenewable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type AgencyCode = {
  __typename?: 'AgencyCode';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AgencyCodeCategory = {
  __typename?: 'AgencyCodeCategory';
  codes: Array<AgencyCode>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum AggClientStatus {
  New = 'NEW',
  Retained = 'RETAINED',
}

/** Policy and Policy Group Status. Details here: https://www.notion.so/ennabl/Policy-Client-Statuses-e2ccfd19b9bc475a9a296f7018c51cd9 */
export enum AggPolicyStatus {
  ClientLost = 'CLIENT_LOST',
  LostCancelled = 'LOST_CANCELLED',
  LostLost = 'LOST_LOST',
  LostNonRenewable = 'LOST_NON_RENEWABLE',
  LostTotal = 'LOST_TOTAL',
  NewClientNew = 'NEW_CLIENT_NEW',
  NewCrossSell = 'NEW_CROSS_SELL',
  NewNew = 'NEW_NEW',
  NewPolicyNew = 'NEW_POLICY_NEW',
  NewTotal = 'NEW_TOTAL',
  NewWinBack = 'NEW_WIN_BACK',
  Other = 'OTHER',
  RenewalRenewal = 'RENEWAL_RENEWAL',
  RenewalRewrite = 'RENEWAL_REWRITE',
  RenewalTotal = 'RENEWAL_TOTAL',
}

export type AggregateCrossSellOpportunity = {
  __typename?: 'AggregateCrossSellOpportunity';
  /** @deprecated Outdated */
  byMonth: Array<OpportunityAggregationByMonth>;
  /** @deprecated Outdated */
  byProducer: Array<OpportunityAggregationByProducer>;
};

export type AggregateCrossSellOpportunityByMonthArgs = {
  filter?: InputMaybe<OpportunityFilter>;
};

export type AggregateCrossSellOpportunityByProducerArgs = {
  filter?: InputMaybe<OpportunityFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AggregateMonolineOpportunity = {
  __typename?: 'AggregateMonolineOpportunity';
  /** @deprecated Outdated, use byMonthsV2 */
  byMonth: Array<OpportunityAggregationByMonth>;
  byMonthV2: Array<OpportunityAggregationByMonth>;
  /** @deprecated Outdated, use byProducerV2 */
  byProducer: Array<OpportunityAggregationByProducer>;
  byProducerV2: Array<OpportunityAggregationByProducer>;
};

export type AggregateMonolineOpportunityByMonthArgs = {
  filter?: InputMaybe<OpportunityFilter>;
};

export type AggregateMonolineOpportunityByMonthV2Args = {
  currentTime?: InputMaybe<Scalars['Timestamp']['input']>;
  filter?: InputMaybe<PolicyFilter>;
};

export type AggregateMonolineOpportunityByProducerArgs = {
  filter?: InputMaybe<OpportunityFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AggregateMonolineOpportunityByProducerV2Args = {
  currentTime?: InputMaybe<Scalars['Timestamp']['input']>;
  filter?: InputMaybe<PolicyFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AggregateWinBackOpportunity = {
  __typename?: 'AggregateWinBackOpportunity';
  /** @deprecated Outdated, use byClientV2 */
  byClient: Array<WinBackOpportunityAggregationByClient>;
  byClientV2: Array<WinBackOpportunityAggregationByClient>;
};

export type AggregateWinBackOpportunityByClientArgs = {
  filter?: InputMaybe<OpportunityFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AggregateWinBackOpportunityByClientV2Args = {
  currentTime?: InputMaybe<Scalars['Timestamp']['input']>;
  filter?: InputMaybe<PolicyFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AggregatedPolicy = {
  __typename?: 'AggregatedPolicy';
  byAccountManager: Array<AggregatedPolicyByAccountManager>;
  byAccountSize: Array<AggregatedPolicyByAccountSize>;
  byCarrier: Array<AggregatedPolicyByCarrier>;
  byCarrierRating: Array<AggregatedPolicyByCarrierRating>;
  byClient: Array<AggregatedPolicyByClient>;
  byClientStatus: Array<AggregatedPolicyByClientStatus>;
  byCommissionPctBand: Array<AggregatedPolicyByCommissionPctBand>;
  byCounty: Array<AggregatedPolicyByCounty>;
  byDataIssues: Array<AggregatedPolicyByDataIssues>;
  byDepartment: Array<AggregatedPolicyByDepartment>;
  byDirect: Array<AggregatedPolicyByDirect>;
  /** aggregates by policy expiration date. Makes more sense with InclusionDateType.EXPIRATION */
  byExpirationMonth: Array<AggregatedPolicyByMonth>;
  /** aggregates by policy start date. Makes more sense with InclusionDateType.INCEPTION */
  byInceptionMonth: Array<AggregatedPolicyByMonth>;
  byIndustry: Array<AggregatedPolicyByIndustry>;
  byIntermediary: Array<AggregatedPolicyByIntermediary>;
  byIssuingPaper: Array<AggregatedPolicyByCarrier>;
  byLeafDepartment: Array<AggregatedPolicyByDepartment>;
  byPayTo: Array<AggregatedPolicyByCarrier>;
  byPolicyStatus: Array<AggregatedPolicyByPolicyStatus>;
  byPremiumAccountSize: Array<AggregatedPolicyByAccountSize>;
  byProducer: Array<AggregatedPolicyByProducer>;
  byProducerWithMissing: Array<AggregatedPolicyByProducerWithMissing>;
  byProduct: Array<AggregatedPolicyByProduct>;
  byState: Array<AggregatedPolicyByState>;
  /** aggregates by transaction date. Doesn't work for WRITTEN amounts. Makes more sense with with InclusionDateType.TRANSACTION */
  byTransactionMonth: Array<AggregatedPolicyByMonth>;
  byUserWithRole: Array<AggregatedPolicyByUser>;
  byUserWithRoleWithMissing: Array<AggregatedPolicyByUserWithMissing>;
  growth: Growth;
  metric: Scalars['Float']['output'];
  /**
   * Metric with filter against with no filter. Don't make sense if filter is not provided.
   * @deprecated Not used anymore
   */
  ofTotal: OfTotal;
  /** @deprecated Use retention resolver instead */
  retention: Retention;
};

export type AggregatedPolicyByAccountManagerArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type AggregatedPolicyByCarrierArgs = {
  level?: InputMaybe<CarrierLevel>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type AggregatedPolicyByClientArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type AggregatedPolicyByCommissionPctBandArgs = {
  from?: InputMaybe<Scalars['Float']['input']>;
  includeLowerBound?: InputMaybe<Scalars['Boolean']['input']>;
  includeUpperBound?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
  step?: InputMaybe<Scalars['Float']['input']>;
  to?: InputMaybe<Scalars['Float']['input']>;
};

export type AggregatedPolicyByCountyArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
  withMissing?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AggregatedPolicyByDataIssuesArgs = {
  dataIssuesGroups?: InputMaybe<Array<PolicyDataIssuesGroup>>;
};

export type AggregatedPolicyByDepartmentArgs = {
  parentDepartmentId?: InputMaybe<Scalars['String']['input']>;
};

export type AggregatedPolicyByDirectArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type AggregatedPolicyByExpirationMonthArgs = {
  from?: InputMaybe<Scalars['Timestamp']['input']>;
  to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type AggregatedPolicyByIndustryArgs = {
  level?: InputMaybe<IndustryLevel>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type AggregatedPolicyByIntermediaryArgs = {
  level?: InputMaybe<IntermediaryLevel>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
  withMissing?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AggregatedPolicyByIssuingPaperArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type AggregatedPolicyByLeafDepartmentArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type AggregatedPolicyByPayToArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type AggregatedPolicyByPolicyStatusArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
  statuses: Array<AggPolicyStatus>;
};

export type AggregatedPolicyByProducerArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type AggregatedPolicyByProducerWithMissingArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type AggregatedPolicyByProductArgs = {
  level?: InputMaybe<ProductLevel>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type AggregatedPolicyByStateArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
  withMissing?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AggregatedPolicyByUserWithRoleArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  role: UserAmsRole;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type AggregatedPolicyByUserWithRoleWithMissingArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  role: UserAmsRole;
  sortBy?: InputMaybe<PolicyMetric>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type AggregatedPolicyGrowthArgs = {
  metric: PolicyMetric;
};

export type AggregatedPolicyMetricArgs = {
  metric: PolicyMetric;
};

export type AggregatedPolicyOfTotalArgs = {
  metric: PolicyMetric;
};

export type AggregatedPolicyRetentionArgs = {
  metric: PolicyMetric;
};

export type AggregatedPolicyByAccountManager = {
  __typename?: 'AggregatedPolicyByAccountManager';
  accountManager: User;
  agg: AggregatedPolicy;
};

export type AggregatedPolicyByAccountSize = {
  __typename?: 'AggregatedPolicyByAccountSize';
  accountSize?: Maybe<Band>;
  agg: AggregatedPolicy;
};

export type AggregatedPolicyByCarrier = {
  __typename?: 'AggregatedPolicyByCarrier';
  agg: AggregatedPolicy;
  carrier: Market;
};

export type AggregatedPolicyByCarrierRating = {
  __typename?: 'AggregatedPolicyByCarrierRating';
  agg: AggregatedPolicy;
  rating: Rating;
};

export type AggregatedPolicyByClient = {
  __typename?: 'AggregatedPolicyByClient';
  agg: AggregatedPolicy;
  client: Client;
  premiumBand?: Maybe<Band>;
  revenueBand?: Maybe<Band>;
};

/** Aggregated policy by status. Details here: https://www.notion.so/ennabl/Policy-Client-Statuses-e2ccfd19b9bc475a9a296f7018c51cd9 */
export type AggregatedPolicyByClientStatus = {
  __typename?: 'AggregatedPolicyByClientStatus';
  agg: AggregatedPolicy;
  status: AggClientStatus;
};

export type AggregatedPolicyByCommissionPctBand = {
  __typename?: 'AggregatedPolicyByCommissionPctBand';
  agg: AggregatedPolicy;
  commissionPctBand: CommissionPctBand;
};

export type AggregatedPolicyByCounty = {
  __typename?: 'AggregatedPolicyByCounty';
  agg: AggregatedPolicy;
  county?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type AggregatedPolicyByDataIssues = {
  __typename?: 'AggregatedPolicyByDataIssues';
  agg: AggregatedPolicy;
  group: DataIssuesGroupOutput;
};

export type AggregatedPolicyByDepartment = {
  __typename?: 'AggregatedPolicyByDepartment';
  agg: AggregatedPolicy;
  department: Department;
};

export type AggregatedPolicyByDirect = {
  __typename?: 'AggregatedPolicyByDirect';
  agg: AggregatedPolicy;
  isDirect: Scalars['Boolean']['output'];
};

export type AggregatedPolicyByIndustry = {
  __typename?: 'AggregatedPolicyByIndustry';
  agg: AggregatedPolicy;
  industry: Industry;
};

export type AggregatedPolicyByIntermediary = {
  __typename?: 'AggregatedPolicyByIntermediary';
  agg: AggregatedPolicy;
  intermediary?: Maybe<Market>;
};

export type AggregatedPolicyByMonth = {
  __typename?: 'AggregatedPolicyByMonth';
  agg: AggregatedPolicy;
  month: Month;
};

/** Aggregated policy by status. Details here: https://www.notion.so/ennabl/Policy-Client-Statuses-e2ccfd19b9bc475a9a296f7018c51cd9 */
export type AggregatedPolicyByPolicyStatus = {
  __typename?: 'AggregatedPolicyByPolicyStatus';
  agg: AggregatedPolicy;
  status: AggPolicyStatus;
};

export type AggregatedPolicyByProducer = {
  __typename?: 'AggregatedPolicyByProducer';
  agg: AggregatedPolicy;
  producer: User;
};

export type AggregatedPolicyByProducerWithMissing = {
  __typename?: 'AggregatedPolicyByProducerWithMissing';
  agg: AggregatedPolicy;
  producer?: Maybe<User>;
};

export type AggregatedPolicyByProduct = {
  __typename?: 'AggregatedPolicyByProduct';
  agg: AggregatedPolicy;
  product: Product;
};

export type AggregatedPolicyByState = {
  __typename?: 'AggregatedPolicyByState';
  agg: AggregatedPolicy;
  state?: Maybe<Scalars['String']['output']>;
};

export type AggregatedPolicyByUser = {
  __typename?: 'AggregatedPolicyByUser';
  agg: AggregatedPolicy;
  user: User;
};

export type AggregatedPolicyByUserWithMissing = {
  __typename?: 'AggregatedPolicyByUserWithMissing';
  agg: AggregatedPolicy;
  /** null for no-value */
  user?: Maybe<User>;
};

export type Amount = {
  __typename?: 'Amount';
  commission: Scalars['Float']['output'];
  commissionPct?: Maybe<Scalars['Float']['output']>;
  fee: Scalars['Float']['output'];
  premium: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
};

export enum AmsPolicyStatus {
  Lost = 'LOST',
  New = 'NEW',
  Other = 'OTHER',
  Renewal = 'RENEWAL',
  Rewrite = 'REWRITE',
}

export type AppPermission = {
  __typename?: 'AppPermission';
  description: Scalars['String']['output'];
  id: AppPermissionId;
  isAdmin: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export enum AppPermissionId {
  CrosscheckDevRealData = 'CROSSCHECK_DEV_REAL_DATA',
  EditCommand = 'EDIT_COMMAND',
  ExportDecisions = 'EXPORT_DECISIONS',
  OnlyLossRuns = 'ONLY_LOSS_RUNS',
  OnlyReportStudio = 'ONLY_REPORT_STUDIO',
  ViewDecisions = 'VIEW_DECISIONS',
}

export type AuthUser = {
  __typename?: 'AuthUser';
  email?: Maybe<Scalars['String']['output']>;
  ennablEmployeeId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use fullName instead */
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type AuthUsers = {
  __typename?: 'AuthUsers';
  list: Array<AuthUser>;
};

export type Band = {
  __typename?: 'Band';
  id: Scalars['ID']['output'];
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  valueType: BandValueType;
};

export enum BandValueType {
  Premium = 'PREMIUM',
  Revenue = 'REVENUE',
  Sales = 'SALES',
}

export type Bands = {
  __typename?: 'Bands';
  premium: Array<Band>;
  revenue: Array<Band>;
  sales: Array<Band>;
};

export enum BrokerMarketStatus {
  NonStrategic = 'NON_STRATEGIC',
  Other = 'OTHER',
  Strategic = 'STRATEGIC',
}

export type BrokerMarketStatusUpdateResult = {
  __typename?: 'BrokerMarketStatusUpdateResult';
  success: Scalars['Boolean']['output'];
};

export type CarrierAppetite = {
  __typename?: 'CarrierAppetite';
  kpi: CarrierAppetiteKpi;
  table: CarrierAppetitePaginatedResult;
};

export type CarrierAppetiteKpiArgs = {
  componentFilter: PolicyFilter;
  globalFilter: PolicyFilter;
  level?: InputMaybe<CarrierLevel>;
};

export type CarrierAppetiteTableArgs = {
  componentFilter: PolicyFilter;
  globalFilter: PolicyFilter;
  level?: InputMaybe<CarrierLevel>;
  pagination?: InputMaybe<CarrierAppetiteListPaginationInput>;
};

export type CarrierAppetiteFilter = {
  accountLocation?: InputMaybe<CarrierAppetiteLocationFilter>;
  /** Client revenue band in cents. Range must be wider or equal to $5000 */
  accountSize?: InputMaybe<Range>;
  industry?: InputMaybe<CarrierAppetiteIndustryFilter>;
  insuranceCompany?: InputMaybe<CarrierAppetiteInsuranceCompanyFilter>;
  product?: InputMaybe<CarrierAppetiteProductFilter>;
};

export type CarrierAppetiteIndustryFilter = {
  /** 6-digit NAICS codes */
  industries?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Generated (xx naics) throws error */
  industryGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 2-digit NAICS codes */
  industryGroups?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Generated (xx naics) throws error */
  industryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Generated (xx naics) throws error */
  industrySubGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 4-digit NAICS codes */
  industrySubGroups?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CarrierAppetiteInsuranceCompanyFilter = {
  childIntermediaryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  issuingPaperIds?: InputMaybe<Array<Scalars['String']['input']>>;
  parentCarrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  parentIntermediaryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ultimateCarrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ultimateIntermediaryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ultimateParentCarrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ultimateParentIntermediaryIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CarrierAppetiteKpi = {
  __typename?: 'CarrierAppetiteKpi';
  carrierCount: Scalars['Float']['output'];
  directPolicies: Scalars['Float']['output'];
  directPremium: Scalars['Float']['output'];
  indirectPolicies: Scalars['Float']['output'];
  indirectPremium: Scalars['Float']['output'];
};

export type CarrierAppetiteListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** sorting by status is not supported for global CA */
  sortBy?: InputMaybe<CarrierAppetiteSortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export type CarrierAppetiteLocationFilter = {
  /** ${state}-${county}. case-insensitive. e.g. MD-Talbot */
  counties?: InputMaybe<Array<Scalars['String']['input']>>;
  postalCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  postalCodesRadius?: InputMaybe<LocationRadius>;
  /** ISO 3166-2 region code */
  states?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CarrierAppetitePaginatedResult = {
  __typename?: 'CarrierAppetitePaginatedResult';
  items: Array<CarrierAppetiteRow>;
  totalHits: Scalars['Int']['output'];
};

export type CarrierAppetiteProductFilter = {
  insuranceSegments?: InputMaybe<Array<Scalars['String']['input']>>;
  productIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CarrierAppetiteRow = {
  __typename?: 'CarrierAppetiteRow';
  carrier: Market;
  directCommission: OutputRange;
  directPolicies: Scalars['Float']['output'];
  indirectCommission: OutputRange;
  indirectPolicies: Scalars['Float']['output'];
  status: BrokerMarketStatus;
};

export enum CarrierAppetiteSortingField {
  DirectCommission = 'DIRECT_COMMISSION',
  DirectPolicies = 'DIRECT_POLICIES',
  IndirectCommission = 'INDIRECT_COMMISSION',
  IndirectPolicies = 'INDIRECT_POLICIES',
  Name = 'NAME',
  Status = 'STATUS',
}

export enum CarrierLevel {
  IssuingPaper = 'ISSUING_PAPER',
  ParentCarrier = 'PARENT_CARRIER',
  UltimateParentCarrier = 'ULTIMATE_PARENT_CARRIER',
}

export type CarrierRank = {
  __typename?: 'CarrierRank';
  filter?: Maybe<Scalars['String']['output']>;
  item: Market;
  metric?: Maybe<Scalars['Float']['output']>;
  metricGrowth?: Maybe<Scalars['Float']['output']>;
  metricGrowthPct?: Maybe<Scalars['Float']['output']>;
  metricPrevious?: Maybe<Scalars['Float']['output']>;
  metricType?: Maybe<Scalars['String']['output']>;
  premium?: Maybe<Growth>;
  rank?: Maybe<Scalars['Int']['output']>;
  /** null represents that previous rank is more than 1000 or entity is not exists in previous period. Also, rankGrowth for rankByGrowth is not provided. */
  rankGrowth?: Maybe<Scalars['Int']['output']>;
  revenue?: Maybe<Growth>;
};

export type CarrierRankConnection = {
  __typename?: 'CarrierRankConnection';
  edges: Array<CarrierRankEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CarrierRankEdge = {
  __typename?: 'CarrierRankEdge';
  cursor: Scalars['String']['output'];
  filter?: Maybe<Scalars['String']['output']>;
  metricType?: Maybe<Scalars['String']['output']>;
  node: CarrierRank;
};

export enum CarrierSortingField {
  Commission = 'COMMISSION',
  Id = 'ID',
  Name = 'NAME',
  Premium = 'PREMIUM',
  Revenue = 'REVENUE',
}

export type Client = {
  __typename?: 'Client';
  accountManagers: Array<User>;
  clientNumber?: Maybe<Scalars['String']['output']>;
  /**
   * Might work slowly. Do not use in list
   * @deprecated use contactsV2
   */
  contacts?: Maybe<Array<ClientContact>>;
  /** Might work slowly. Do not use in list */
  contactsV2?: Maybe<Array<Contact>>;
  dnbCompany?: Maybe<DnbCompany>;
  domesticUltimateDuns?: Maybe<Scalars['String']['output']>;
  domesticUltimateName?: Maybe<Scalars['String']['output']>;
  duns?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employees?: Maybe<Scalars['Int']['output']>;
  fein?: Maybe<Scalars['String']['output']>;
  forms5500: Array<Form5500>;
  hasForms5500: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inForceData: ClientInForceData;
  industry?: Maybe<Industry>;
  isAmsProspect: Scalars['Boolean']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isMonoline: Scalars['Boolean']['output'];
  limitedAccess: Scalars['Boolean']['output'];
  marketCap?: Maybe<Scalars['Float']['output']>;
  /** Might work slowly. Do not use in list */
  marketContacts?: Maybe<Array<Contact>>;
  metrics?: Maybe<EntityMetrics>;
  /** @deprecated use industry.naics */
  naics?: Maybe<Scalars['String']['output']>;
  naicsSources?: Maybe<Array<ClientNaicsSource>>;
  name: Scalars['String']['output'];
  nextRenewal?: Maybe<Scalars['SimpleDate']['output']>;
  opportunityRecommendations?: Maybe<Array<OpportunityRecommendation>>;
  policies: Array<Policy>;
  policyAgg: AggregatedPolicy;
  primaryAddress?: Maybe<Address>;
  producers?: Maybe<Array<User>>;
  productAmount: Scalars['Int']['output'];
  products: Array<Product>;
  prospectFor: Array<AuthUser>;
  renewals: Array<RenewalsOpportunity>;
  /** Might work slowly. Do not use in list */
  rocketReachPerson?: Maybe<RocketReachPersonCached>;
  sales?: Maybe<Scalars['Float']['output']>;
  salesBand?: Maybe<Band>;
  savedOpportunity?: Maybe<SavedOpportunity>;
  /** @deprecated use sicCode */
  sic?: Maybe<Scalars['String']['output']>;
  sicCode?: Maybe<SicCode>;
  sources?: Maybe<Array<ClientSourceData>>;
  specialists?: Maybe<Array<Specialist>>;
  statistic: ClientStatistic;
  telephoneNumber?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ClientType>;
  url?: Maybe<Scalars['String']['output']>;
  /** @deprecated use winBackV2 */
  winBack: Array<WinBackOpportunityV2>;
  winBackV2: Array<WinBackOpportunityV2>;
};

export type ClientOpportunityRecommendationsArgs = {
  producerId?: InputMaybe<Scalars['String']['input']>;
  withSaved?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientPolicyAggArgs = {
  filter?: InputMaybe<PolicyFilter>;
};

export type ClientRenewalsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type ClientSavedOpportunityArgs = {
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type ClientWinBackArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type ClientWinBackV2Args = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type ClientActivityFilter = {
  period?: InputMaybe<FromToPeriod>;
};

export type ClientContact = {
  __typename?: 'ClientContact';
  address?: Maybe<Address>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ClientFilter = {
  carrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  carrierName?: InputMaybe<Scalars['String']['input']>;
  clientType?: InputMaybe<ClientType>;
  companyNameOrForm5500PlanSponsor?: InputMaybe<Scalars['String']['input']>;
  form5500FilingYears?: InputMaybe<Array<Scalars['String']['input']>>;
  form5500Participants?: InputMaybe<FilterRange>;
  form5500PlanEffectiveDate?: InputMaybe<Array<DateRange>>;
  form5500PolicyExpirationDate?: InputMaybe<Array<DateRange>>;
  form5500SponsorSignedName?: InputMaybe<Scalars['String']['input']>;
  /** True - only forms with a broker, False - only forms without a broker, Not provided - both */
  form5500WithBroker?: InputMaybe<Scalars['Boolean']['input']>;
  /** True - with filled form 5500, False - without forms, Not provided - both */
  hasForm5500Data?: InputMaybe<Scalars['Boolean']['input']>;
  industryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  isMonoline?: InputMaybe<Scalars['Boolean']['input']>;
  numberOfEmployees?: InputMaybe<FilterRange>;
  region?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ClientInForceData = {
  __typename?: 'ClientInForceData';
  nextRenewal?: Maybe<Scalars['SimpleDate']['output']>;
  policyCount: Scalars['Float']['output'];
  premium?: Maybe<Scalars['Float']['output']>;
  productLines: Array<Product>;
  products: Array<Product>;
  revenue?: Maybe<Scalars['Float']['output']>;
  /** synonym of account size */
  revenueBand: Band;
};

export type ClientListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<ClientSortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export type ClientNaicsSource = {
  __typename?: 'ClientNaicsSource';
  industry?: Maybe<Industry>;
  isSelected: Scalars['Boolean']['output'];
  naics: Scalars['String']['output'];
  sourceName: Scalars['String']['output'];
};

export type ClientPaginatedResult = {
  __typename?: 'ClientPaginatedResult';
  items: Array<Client>;
  totalHits: Scalars['Int']['output'];
};

export type ClientRank = {
  __typename?: 'ClientRank';
  filter?: Maybe<Scalars['String']['output']>;
  item: Client;
  metric?: Maybe<Scalars['Float']['output']>;
  metricGrowth?: Maybe<Scalars['Float']['output']>;
  metricGrowthPct?: Maybe<Scalars['Float']['output']>;
  metricPrevious?: Maybe<Scalars['Float']['output']>;
  metricType?: Maybe<Scalars['String']['output']>;
  premium?: Maybe<Growth>;
  rank?: Maybe<Scalars['Int']['output']>;
  /** null represents that previous rank is more than 1000 or entity is not exists in previous period. Also, rankGrowth for rankByGrowth is not provided. */
  rankGrowth?: Maybe<Scalars['Int']['output']>;
  revenue?: Maybe<Growth>;
};

export type ClientRankConnection = {
  __typename?: 'ClientRankConnection';
  edges: Array<ClientRankEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ClientRankEdge = {
  __typename?: 'ClientRankEdge';
  cursor: Scalars['String']['output'];
  filter?: Maybe<Scalars['String']['output']>;
  metricType?: Maybe<Scalars['String']['output']>;
  node: ClientRank;
};

export type ClientSearchItemRequest = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  duns?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
};

export type ClientSearchItemResponse = {
  __typename?: 'ClientSearchItemResponse';
  client?: Maybe<Client>;
  /** Use some ID to map clients */
  requestId: Scalars['String']['output'];
};

export type ClientSearchRequest = {
  request: ClientSearchItemRequest;
  /** Use some ID to map clients */
  requestId: Scalars['String']['input'];
};

export enum ClientSortingField {
  Commission = 'COMMISSION',
  Employees = 'EMPLOYEES',
  Id = 'ID',
  MarketCap = 'MARKET_CAP',
  Name = 'NAME',
  Premium = 'PREMIUM',
  PrimaryAddress = 'PRIMARY_ADDRESS',
  Revenue = 'REVENUE',
  Sales = 'SALES',
}

export type ClientSourceData = {
  __typename?: 'ClientSourceData';
  sourceId: Scalars['String']['output'];
  sourceName?: Maybe<Scalars['String']['output']>;
  sourceNumber?: Maybe<Scalars['String']['output']>;
  systemId?: Maybe<Scalars['String']['output']>;
  systemName?: Maybe<Scalars['String']['output']>;
};

export type ClientStatistic = {
  __typename?: 'ClientStatistic';
  activePolicies: Scalars['Int']['output'];
  activeProducts: Scalars['Int']['output'];
  activeSegments: Array<Product>;
  clientLost?: Maybe<Scalars['Timestamp']['output']>;
  clientSince?: Maybe<Scalars['Timestamp']['output']>;
  lifetimePolicies: Scalars['Int']['output'];
  lifetimeRevenue: Scalars['Float']['output'];
  lostSegments: Array<Product>;
};

export enum ClientType {
  Company = 'COMPANY',
  Person = 'PERSON',
}

export type Clients = {
  __typename?: 'Clients';
  byIds: Array<Client>;
  /** @deprecated Use paginated instead */
  list: Array<Client>;
  paginated: ClientPaginatedResult;
};

export type ClientsByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type ClientsListArgs = {
  pagination?: InputMaybe<ClientListPaginationInput>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ClientsPaginatedArgs = {
  filter?: InputMaybe<ClientFilter>;
  pagination?: InputMaybe<ClientListPaginationInput>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type CommissionPctBand = {
  __typename?: 'CommissionPctBand';
  id: Scalars['ID']['output'];
  max?: Maybe<Scalars['Float']['output']>;
  min: Scalars['Float']['output'];
};

export type CompanyConditions = {
  /** elements of this array will be joined using OR clause */
  orConditions: Array<CompanyIdsFilter>;
};

export type CompanyConditionsOutput = {
  __typename?: 'CompanyConditionsOutput';
  /** elements of this array will be joined using OR clause */
  orConditions: Array<CompanyIdsFilterOutput>;
};

export enum CompanyFilterType {
  Carrier = 'CARRIER',
  Intermediary = 'INTERMEDIARY',
  PayTo = 'PAY_TO',
}

export type CompanyFilters = {
  /** elements of this array will be joined using AND clause */
  andConditions: Array<CompanyConditions>;
};

export type CompanyFiltersOutput = {
  __typename?: 'CompanyFiltersOutput';
  /** elements of this array will be joined using AND clause */
  andConditions: Array<CompanyConditionsOutput>;
};

/** Companies inside one CompanyFilter are filtered using OR logic. */
export type CompanyIdsFilter = {
  anyLevelIds?: InputMaybe<Array<Scalars['String']['input']>>;
  childIds?: InputMaybe<Array<Scalars['String']['input']>>;
  filterType: CompanyFilterType;
  parentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ultimateParentIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CompanyIdsFilterOutput = {
  __typename?: 'CompanyIdsFilterOutput';
  anyLevelIds?: Maybe<Array<Scalars['String']['output']>>;
  childIds?: Maybe<Array<Scalars['String']['output']>>;
  filterType: CompanyFilterType;
  parentIds?: Maybe<Array<Scalars['String']['output']>>;
  ultimateParentIds?: Maybe<Array<Scalars['String']['output']>>;
};

export enum CompanyPrimaryRole {
  Carrier = 'CARRIER',
  Intermediary = 'INTERMEDIARY',
}

export type CompanyRating = {
  __typename?: 'CompanyRating';
  financialSizeCategory?: Maybe<Scalars['Int']['output']>;
  ratingLastDate?: Maybe<Scalars['Timestamp']['output']>;
  title: Scalars['String']['output'];
};

export enum CompanyViewType {
  Intermediary = 'INTERMEDIARY',
  IssuingPaper = 'ISSUING_PAPER',
  ParentCarrier = 'PARENT_CARRIER',
  ParentIntermediary = 'PARENT_INTERMEDIARY',
  UltimateParentCarrier = 'ULTIMATE_PARENT_CARRIER',
  UltimateParentIntermediary = 'ULTIMATE_PARENT_INTERMEDIARY',
}

export type Contact = {
  __typename?: 'Contact';
  addresses?: Maybe<Array<ContactAddress>>;
  client?: Maybe<Client>;
  description?: Maybe<Scalars['String']['output']>;
  emails?: Maybe<Array<ContactEmail>>;
  id: Scalars['ID']['output'];
  market?: Maybe<Market>;
  name?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<ContactPhone>>;
  urls?: Maybe<Array<ContactUrls>>;
};

export type ContactAddress = {
  __typename?: 'ContactAddress';
  type: ContactType;
  value: Address;
};

export type ContactEmail = {
  __typename?: 'ContactEmail';
  type: ContactType;
  value: Scalars['String']['output'];
};

export type ContactPaginatedResult = {
  __typename?: 'ContactPaginatedResult';
  items: Array<Contact>;
  totalHits: Scalars['Int']['output'];
};

export type ContactPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy: ContactSortingField;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export type ContactPhone = {
  __typename?: 'ContactPhone';
  type: ContactType;
  value: Scalars['String']['output'];
};

export enum ContactSortingField {
  Country = 'COUNTRY',
  County = 'COUNTY',
  Email = 'EMAIL',
  Locality = 'LOCALITY',
  Name = 'NAME',
  Phone = 'PHONE',
  Region = 'REGION',
  Url = 'URL',
}

export enum ContactType {
  Other = 'OTHER',
  Primary = 'PRIMARY',
}

export type ContactUrls = {
  __typename?: 'ContactUrls';
  type: ContactType;
  value: Scalars['String']['output'];
};

export type Contacts = {
  __typename?: 'Contacts';
  contact?: Maybe<Contact>;
  paginated: ContactPaginatedResult;
};

export type ContactsContactArgs = {
  id: Scalars['ID']['input'];
};

export type ContactsPaginatedArgs = {
  pagination?: InputMaybe<ContactPaginationInput>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCompanyProspectPayload = {
  __typename?: 'CreateCompanyProspectPayload';
  created: Prospect;
};

export type CreateCrossSellOpportunityPayload = {
  __typename?: 'CreateCrossSellOpportunityPayload';
  created: Array<CrossSellOpportunity>;
  existed: Array<CrossSellOpportunity>;
};

export type CreateDolCompanyProspectPayload = {
  __typename?: 'CreateDolCompanyProspectPayload';
  created: Prospect;
};

export type CreateIndividualProspectPayload = {
  __typename?: 'CreateIndividualProspectPayload';
  created: Prospect;
};

export type CreateUserReportPresetPayload = {
  __typename?: 'CreateUserReportPresetPayload';
  created: UserReportPreset;
};

export type CrossSellFilter = {
  __typename?: 'CrossSellFilter';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CrossSellGenerator = {
  __typename?: 'CrossSellGenerator';
  /** @deprecated use generateCrossSellReport mutation */
  report: OpportunityCrossSellReport;
};

export type CrossSellGeneratorReportArgs = {
  filter: CrossSellGeneratorFilter;
};

export type CrossSellGeneratorFilter = {
  accountManagerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  carrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Any level */
  companyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** IDs from producerPortal.crossSellFilters */
  crossSellFilterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  employeesRange?: InputMaybe<FilterRange>;
  expirationDate?: InputMaybe<DateRange>;
  expirationDateRange?: InputMaybe<FilterRange>;
  industryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  isMonoline?: InputMaybe<Scalars['Boolean']['input']>;
  issuingPaperIds?: InputMaybe<Array<Scalars['String']['input']>>;
  producerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Either productBoughtIds or productNotBoughtIds must include at least one product ID */
  productBoughtIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Either productBoughtIds or productNotBoughtIds must include at least one product ID */
  productNotBoughtIds?: InputMaybe<Array<Scalars['String']['input']>>;
  riskLocations?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Cents */
  salesSizeRange?: InputMaybe<FilterRange>;
};

export enum CrossSellGeneratorSoringFields {
  ClientEmail = 'CLIENT_EMAIL',
  ClientName = 'CLIENT_NAME',
  ClientPhone = 'CLIENT_PHONE',
  Country = 'COUNTRY',
  County = 'COUNTY',
  Industry = 'INDUSTRY',
  Locality = 'LOCALITY',
  Naics = 'NAICS',
  NextRenewal = 'NEXT_RENEWAL',
  Producer = 'PRODUCER',
  ProductAmount = 'PRODUCT_AMOUNT',
  Region = 'REGION',
  Revenue_12Months = 'REVENUE_12_MONTHS',
  RevenueInForce = 'REVENUE_IN_FORCE',
  Sales = 'SALES',
}

export type CrossSellListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<CrossSellSortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export type CrossSellOpportunity = {
  __typename?: 'CrossSellOpportunity';
  accountManager?: Maybe<User>;
  carrier?: Maybe<Market>;
  client: Client;
  commission: Scalars['Float']['output'];
  commissionPct?: Maybe<Scalars['Float']['output']>;
  createdBy?: Maybe<AuthUser>;
  createdByAdmin: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  industry?: Maybe<Industry>;
  industryGroup?: Maybe<Industry>;
  nextRenewal?: Maybe<Scalars['SimpleDate']['output']>;
  policy?: Maybe<Policy>;
  premium: Scalars['Float']['output'];
  /** @deprecated use producers */
  producer?: Maybe<User>;
  producers?: Maybe<Array<User>>;
  product?: Maybe<Product>;
  productsBought: Array<Product>;
  productsBoughtSelection: Array<Product>;
  productsNotBought: Array<Product>;
  productsNotBoughtSelection: Array<Product>;
  reportId?: Maybe<Scalars['String']['output']>;
  revenue: Scalars['Float']['output'];
  source: OpportunitySourceType;
};

export type CrossSellOpportunityBatchInput = {
  inputs: Array<CrossSellOpportunityInput>;
};

export type CrossSellOpportunityInput = {
  clientId: Scalars['String']['input'];
  productBoughtIds: Array<Scalars['String']['input']>;
  productNotBoughtIds: Array<Scalars['String']['input']>;
  reportId: Scalars['String']['input'];
};

export type CrossSellOpportunityPaginatedResult = {
  __typename?: 'CrossSellOpportunityPaginatedResult';
  items: Array<CrossSellOpportunity>;
  totalHits: Scalars['Int']['output'];
};

export type CrossSellOpportunityRecommendation = {
  __typename?: 'CrossSellOpportunityRecommendation';
  client: Client;
  id: Scalars['ID']['output'];
  recommendations: Array<OpportunityRecommendation>;
};

export type CrossSellOpportunityRecommendationPaginatedResult = {
  __typename?: 'CrossSellOpportunityRecommendationPaginatedResult';
  items: Array<CrossSellOpportunityRecommendation>;
  totalHits: Scalars['Int']['output'];
};

export type CrossSellOpportunityRecommendationPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<CrossSellOpportunityRecommendationSortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export enum CrossSellOpportunityRecommendationSortingField {
  ClientName = 'CLIENT_NAME',
  NextRenewal = 'NEXT_RENEWAL',
  Rate = 'RATE',
}

export type CrossSellPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<CrossSellGeneratorSoringFields>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export type CrossSellReportCriteria = {
  __typename?: 'CrossSellReportCriteria';
  accountManagerIds?: Maybe<Array<Scalars['String']['output']>>;
  carrierIds?: Maybe<Array<Scalars['String']['output']>>;
  carriers?: Maybe<Array<Market>>;
  /** Any level */
  companyIds?: Maybe<Array<Scalars['String']['output']>>;
  /** IDs from producerPortal.crossSellFilters */
  crossSellFilterIds?: Maybe<Array<Scalars['String']['output']>>;
  departmentIds?: Maybe<Array<Scalars['String']['output']>>;
  employeesRange?: Maybe<FilterRangeOutput>;
  expirationDate?: Maybe<DateRangeOutput>;
  /** @deprecated Use expirationDate instead */
  expirationDateRange?: Maybe<FilterRangeOutput>;
  /** Could be null if mapping was changed */
  industries?: Maybe<Array<Maybe<Industry>>>;
  industryIds?: Maybe<Array<Scalars['String']['output']>>;
  isMonoline?: Maybe<Scalars['Boolean']['output']>;
  issuingPaperIds?: Maybe<Array<Scalars['String']['output']>>;
  issuingPapers?: Maybe<Array<Market>>;
  producerIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Either productBoughtIds or productNotBoughtIds must include at least one product ID */
  productBoughtIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Either productBoughtIds or productNotBoughtIds must include at least one product ID */
  productNotBoughtIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Could be null if mapping was changed */
  productsBought?: Maybe<Array<Maybe<Product>>>;
  /** Could be null if mapping was changed */
  productsNotBought?: Maybe<Array<Maybe<Product>>>;
  riskLocations?: Maybe<Array<Scalars['String']['output']>>;
  /** Cents */
  salesSizeRange?: Maybe<FilterRangeOutput>;
};

export enum CrossSellSortingField {
  Client = 'CLIENT',
  ClientEmail = 'CLIENT_EMAIL',
  ClientPhone = 'CLIENT_PHONE',
  Industry = 'INDUSTRY',
  Locality = 'LOCALITY',
  Naics = 'NAICS',
  NextRenewal = 'NEXT_RENEWAL',
  PostalCode = 'POSTAL_CODE',
  Producer = 'PRODUCER',
  ProductAmount = 'PRODUCT_AMOUNT',
  ProductBought = 'PRODUCT_BOUGHT',
  ProductNotBought = 'PRODUCT_NOT_BOUGHT',
  Region = 'REGION',
  Sales = 'SALES',
}

export type CustomAggregatedPreviewParams = {
  filters: Array<CustomReportingFilter>;
  rows: Array<CustomReportingRowInput>;
  table: CustomReportingTable;
  useDataMartsData?: InputMaybe<Scalars['Boolean']['input']>;
  values: Array<CustomReportingValueInput>;
};

export type CustomAggregatedPreviewParamsOutput = {
  __typename?: 'CustomAggregatedPreviewParamsOutput';
  filters: Array<CustomReportingFilterOutput>;
  rows: Array<CustomReportingRow>;
  table: CustomReportingTable;
  useDataMartsData?: Maybe<Scalars['Boolean']['output']>;
  values: Array<CustomReportingValue>;
};

export type CustomPlainPreviewParams = {
  columns: Array<CustomReportingPlainColumnInput>;
  filters: Array<CustomReportingFilter>;
  table: CustomReportingTable;
  useDataMartsData?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomPlainPreviewParamsOutput = {
  __typename?: 'CustomPlainPreviewParamsOutput';
  columns: Array<CustomReportingPlainColumn>;
  filters: Array<CustomReportingFilterOutput>;
  table: CustomReportingTable;
  useDataMartsData?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomReporting = {
  __typename?: 'CustomReporting';
  fields: Array<CustomReportingField>;
  filterValues: CustomReportingFilterValues;
  previewAggregated: CustomReportingPreview;
  previewPlain: CustomReportingPreview;
};

export type CustomReportingFilterValuesArgs = {
  fieldId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  table?: InputMaybe<CustomReportingTable>;
  useDataMartsData?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomReportingPreviewAggregatedArgs = {
  params: CustomAggregatedPreviewParams;
};

export type CustomReportingPreviewPlainArgs = {
  params: CustomPlainPreviewParams;
};

export type CustomReportingBoolFilter = {
  equal: Scalars['Boolean']['input'];
};

export type CustomReportingBoolFilterOutput = {
  __typename?: 'CustomReportingBoolFilterOutput';
  equal: Scalars['Boolean']['output'];
};

export enum CustomReportingBooleanAggregateFn {
  TrueCount = 'TRUE_COUNT',
}

export type CustomReportingBooleanValue = {
  __typename?: 'CustomReportingBooleanValue';
  aggregateFunction: CustomReportingBooleanAggregateFn;
};

export type CustomReportingBooleanValueInput = {
  aggregateFunction: CustomReportingBooleanAggregateFn;
};

export enum CustomReportingColumnType {
  Row = 'ROW',
  Value = 'VALUE',
}

export enum CustomReportingDateAggregateFn {
  Max = 'MAX',
  Min = 'MIN',
}

export type CustomReportingDateFilter = {
  equal?: InputMaybe<Scalars['Timestamp']['input']>;
  range?: InputMaybe<DateRange>;
  relative?: InputMaybe<CustomReportingDateRelativeFilter>;
};

export type CustomReportingDateFilterOutput = {
  __typename?: 'CustomReportingDateFilterOutput';
  /** @deprecated not used */
  equal?: Maybe<Scalars['Timestamp']['output']>;
  range?: Maybe<DateRangeOutput>;
  relative?: Maybe<CustomReportingDateRelativeFilterOutput>;
};

export type CustomReportingDateRelativeFilter = {
  type: CustomReportingDateRelativeFilterType;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type CustomReportingDateRelativeFilterOutput = {
  __typename?: 'CustomReportingDateRelativeFilterOutput';
  type: CustomReportingDateRelativeFilterType;
  value?: Maybe<Scalars['Float']['output']>;
};

export enum CustomReportingDateRelativeFilterType {
  InFuture = 'IN_FUTURE',
  InPast = 'IN_PAST',
  IsBlank = 'IS_BLANK',
  LastNDays = 'LAST_N_DAYS',
  LastNMonths = 'LAST_N_MONTHS',
  NextNDays = 'NEXT_N_DAYS',
  NextNMonths = 'NEXT_N_MONTHS',
  PreviousNYears = 'PREVIOUS_N_YEARS',
  PreviousYear = 'PREVIOUS_YEAR',
  Ytd = 'YTD',
  YtdAcct = 'YTD_ACCT',
}

export type CustomReportingDateValue = {
  __typename?: 'CustomReportingDateValue';
  aggregateFunction: CustomReportingDateAggregateFn;
};

export type CustomReportingDateValueInput = {
  aggregateFunction: CustomReportingDateAggregateFn;
};

export type CustomReportingField = {
  __typename?: 'CustomReportingField';
  deprecated: Scalars['Boolean']['output'];
  group: CustomReportingGroup;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  stringFilteringMode?: Maybe<CustomReportingStringFilteringMode>;
  supportAggregation: Scalars['Boolean']['output'];
  supportDataOutput: Scalars['Boolean']['output'];
  supportFiltering: Scalars['Boolean']['output'];
  tables: Array<CustomReportingTable>;
  type: CustomReportingFieldType;
};

export enum CustomReportingFieldType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  DateStrict = 'DATE_STRICT',
  Money = 'MONEY',
  Number = 'NUMBER',
  Percent = 'PERCENT',
  String = 'STRING',
}

export type CustomReportingFilter = {
  boolFilter?: InputMaybe<CustomReportingBoolFilter>;
  dateFilter?: InputMaybe<CustomReportingDateFilter>;
  fieldId: Scalars['ID']['input'];
  numberFilter?: InputMaybe<CustomReportingNumberFilter>;
  stringFilter?: InputMaybe<CustomReportingStringFilter>;
};

export type CustomReportingFilterOutput = {
  __typename?: 'CustomReportingFilterOutput';
  boolFilter?: Maybe<CustomReportingBoolFilterOutput>;
  dateFilter?: Maybe<CustomReportingDateFilterOutput>;
  fieldId: Scalars['ID']['output'];
  numberFilter?: Maybe<CustomReportingNumberFilterOutput>;
  stringFilter?: Maybe<CustomReportingStringFilterOutput>;
};

export type CustomReportingFilterValues = {
  __typename?: 'CustomReportingFilterValues';
  values: Array<Scalars['String']['output']>;
};

export type CustomReportingGroup = {
  __typename?: 'CustomReportingGroup';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum CustomReportingNumberAggregateFn {
  Avg = 'AVG',
  Max = 'MAX',
  Min = 'MIN',
  Sum = 'SUM',
}

export type CustomReportingNumberFilter = {
  equal?: InputMaybe<Scalars['Float']['input']>;
  range?: InputMaybe<Range>;
};

export type CustomReportingNumberFilterOutput = {
  __typename?: 'CustomReportingNumberFilterOutput';
  equal?: Maybe<Scalars['Float']['output']>;
  range?: Maybe<OutputRange>;
};

export type CustomReportingNumberValue = {
  __typename?: 'CustomReportingNumberValue';
  aggregateFunction: CustomReportingNumberAggregateFn;
};

export type CustomReportingNumberValueInput = {
  aggregateFunction: CustomReportingNumberAggregateFn;
};

export type CustomReportingPlainColumn = {
  __typename?: 'CustomReportingPlainColumn';
  fieldId: Scalars['ID']['output'];
};

export type CustomReportingPlainColumnInput = {
  fieldId: Scalars['ID']['input'];
};

export type CustomReportingPreview = {
  __typename?: 'CustomReportingPreview';
  columns: Array<CustomReportingPreviewColumn>;
  data: Array<CustomReportingPreviewRow>;
};

export type CustomReportingPreviewColumn = {
  __typename?: 'CustomReportingPreviewColumn';
  title: Scalars['String']['output'];
  type: CustomReportingColumnType;
};

export type CustomReportingPreviewRow = {
  __typename?: 'CustomReportingPreviewRow';
  values: Array<Scalars['String']['output']>;
};

export type CustomReportingRow = {
  __typename?: 'CustomReportingRow';
  fieldId: Scalars['ID']['output'];
};

export type CustomReportingRowInput = {
  fieldId: Scalars['ID']['input'];
};

export type CustomReportingStringFilter = {
  equal?: InputMaybe<Scalars['String']['input']>;
  exclude?: InputMaybe<Array<Scalars['String']['input']>>;
  oneOf?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CustomReportingStringFilterOutput = {
  __typename?: 'CustomReportingStringFilterOutput';
  equal?: Maybe<Scalars['String']['output']>;
  exclude?: Maybe<Array<Scalars['String']['output']>>;
  oneOf?: Maybe<Array<Scalars['String']['output']>>;
};

export enum CustomReportingStringFilteringMode {
  Enum = 'ENUM',
  FullList = 'FULL_LIST',
  Search = 'SEARCH',
}

export type CustomReportingStringValue = {
  __typename?: 'CustomReportingStringValue';
  aggregateFunction: CustomReportingStringValueAggregateFn;
};

export enum CustomReportingStringValueAggregateFn {
  Count = 'COUNT',
}

export type CustomReportingStringValueInput = {
  aggregateFunction: CustomReportingStringValueAggregateFn;
};

export enum CustomReportingTable {
  ActivityAgg = 'ACTIVITY_AGG',
  ActivityPlain = 'ACTIVITY_PLAIN',
  Policy = 'POLICY',
  PolicyPlain = 'POLICY_PLAIN',
  Transaction = 'TRANSACTION',
  TransactionPlain = 'TRANSACTION_PLAIN',
}

export type CustomReportingValue = {
  __typename?: 'CustomReportingValue';
  boolSettings?: Maybe<CustomReportingBooleanValue>;
  dateSettings?: Maybe<CustomReportingDateValue>;
  fieldId: Scalars['ID']['output'];
  /** Used for MONEY, PERCENT and NUMBER */
  numberSettings?: Maybe<CustomReportingNumberValue>;
  stringSettings?: Maybe<CustomReportingStringValue>;
};

export type CustomReportingValueInput = {
  boolSettings?: InputMaybe<CustomReportingBooleanValueInput>;
  dateSettings?: InputMaybe<CustomReportingDateValueInput>;
  fieldId: Scalars['ID']['input'];
  /** Used for MONEY, PERCENT and NUMBER */
  numberSettings?: InputMaybe<CustomReportingNumberValueInput>;
  stringSettings?: InputMaybe<CustomReportingStringValueInput>;
};

export enum DataAuditReportType {
  BasicDq = 'BASIC_DQ',
  DuplicatePolicies = 'DUPLICATE_POLICIES',
  FlatCanceled = 'FLAT_CANCELED',
  NonBilledPolicies = 'NON_BILLED_POLICIES',
  OutstandingRevenue = 'OUTSTANDING_REVENUE',
  PoliciesWithoutProduct = 'POLICIES_WITHOUT_PRODUCT',
  UnderestimatedPolicies = 'UNDERESTIMATED_POLICIES',
}

export type DataIssuesGroupOutput = {
  __typename?: 'DataIssuesGroupOutput';
  /** To identify the group in the response */
  id: Scalars['String']['output'];
  issues: Array<PolicyDataIssue>;
};

export enum DatasourceType {
  /** Agency level policy or transaction data */
  AgencyData = 'AGENCY_DATA',
  /** Production credit data. Policies and transaction broken down by producer */
  ProductionCredit = 'PRODUCTION_CREDIT',
}

export type DateRange = {
  from?: InputMaybe<Scalars['SimpleDate']['input']>;
  to?: InputMaybe<Scalars['SimpleDate']['input']>;
};

export type DateRangeOutput = {
  __typename?: 'DateRangeOutput';
  from?: Maybe<Scalars['SimpleDate']['output']>;
  to?: Maybe<Scalars['SimpleDate']['output']>;
};

export type DecisionsPermission = {
  __typename?: 'DecisionsPermission';
  clientIds?: Maybe<Array<Scalars['String']['output']>>;
  departmentIds?: Maybe<Array<Scalars['String']['output']>>;
  industryIds?: Maybe<Array<Scalars['String']['output']>>;
  productIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type DecisionsPermissions = {
  __typename?: 'DecisionsPermissions';
  allowed: Array<DecisionsPermission>;
};

export type DeleteCrossSellOpportunityPayload = {
  __typename?: 'DeleteCrossSellOpportunityPayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteOpportunityListPayload = {
  __typename?: 'DeleteOpportunityListPayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteOpportunityReportPayload = {
  __typename?: 'DeleteOpportunityReportPayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteProspectPayload = {
  __typename?: 'DeleteProspectPayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteRemindersPayload = {
  __typename?: 'DeleteRemindersPayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteSavedPagePayload = {
  __typename?: 'DeleteSavedPagePayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteUserReportPresetPayload = {
  __typename?: 'DeleteUserReportPresetPayload';
  success: Scalars['Boolean']['output'];
};

export type Department = {
  __typename?: 'Department';
  children: Array<Department>;
  id: Scalars['ID']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  parent?: Maybe<Department>;
};

export type Departments = {
  __typename?: 'Departments';
  list: Array<Department>;
  tree: Array<Department>;
};

export enum DetailedPolicyStatus {
  CrossSell = 'CROSS_SELL',
  New = 'NEW',
  Renewal = 'RENEWAL',
  Rewrite = 'REWRITE',
}

export type DnbAddress = {
  __typename?: 'DnbAddress';
  countryCode?: Maybe<Scalars['String']['output']>;
  countryName?: Maybe<Scalars['String']['output']>;
  countyName?: Maybe<Scalars['String']['output']>;
  /** Indicates if this address is strictly accurate or precise. */
  isInexact?: Maybe<Scalars['Boolean']['output']>;
  isResidentialAddress?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  /** Name of the city, town, township, village, borough, etc */
  localityName?: Maybe<Scalars['String']['output']>;
  locationOwnership?: Maybe<DnbAddressLocationOwnership>;
  longitude?: Maybe<Scalars['Float']['output']>;
  /** The name of the village, town or suburb when it is not a Primary Town or City (e.g., the Booker in Booker, High Wycombe) */
  minorTownName?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  /** Identifies a street or building within the geographic area covered by the Postal Code */
  postalCodeExtension?: Maybe<Scalars['String']['output']>;
  premisesArea?: Maybe<DnbAddressPremisesArea>;
  /** The shortened name of the locally */
  regionAbbreviatedName?: Maybe<Scalars['String']['output']>;
  regionName?: Maybe<Scalars['String']['output']>;
  statisticalAreaPopulationRankDescription?: Maybe<Scalars['String']['output']>;
  /** The details of the street address where the entity is located */
  streetLine1?: Maybe<Scalars['String']['output']>;
  streetLine2?: Maybe<Scalars['String']['output']>;
  streetLine3?: Maybe<Scalars['String']['output']>;
  streetLine4?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
};

export type DnbAddressLocationOwnership = {
  __typename?: 'DnbAddressLocationOwnership';
  description?: Maybe<Scalars['String']['output']>;
  dnbCode?: Maybe<Scalars['Float']['output']>;
};

export type DnbAddressPremisesArea = {
  __typename?: 'DnbAddressPremisesArea';
  measurement?: Maybe<Scalars['Float']['output']>;
  reliabilityDescription?: Maybe<Scalars['String']['output']>;
  reliabilityDnBCode?: Maybe<Scalars['Float']['output']>;
  unitDescription?: Maybe<Scalars['String']['output']>;
  unitDnBCode?: Maybe<Scalars['Float']['output']>;
};

export type DnbCompaniesByIndustryRequest = {
  addressCounty?: InputMaybe<Scalars['String']['input']>;
  /** 1 to 50 characters used to find entities by the name of the city, town, village, etc. */
  addressLocality?: InputMaybe<Scalars['String']['input']>;
  /** 1 to 50 characters used to find entities by the name or US State abbreviation */
  addressRegion?: InputMaybe<Scalars['String']['input']>;
  /** 2 letter ISO 3166-1 scheme */
  countryCode?: InputMaybe<Scalars['String']['input']>;
  employees?: InputMaybe<Range>;
  isOutOfBusiness?: InputMaybe<Scalars['Boolean']['input']>;
  locationRadius?: InputMaybe<DnbLocationRadius>;
  /** 6 digit NAICS codes. If empty - we send all known codes */
  naicsCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 1 to 20 characters used to find the entity by the postal code. If search with radius - postalCode must be specified in locationRadius instead */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** find entities by their primary or tradestyle names or D-U-N-S, Former primary names (last 5) or stockTicker */
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  streetAddressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Identifying the Global Ultimate country/market of the entity. 2 letter ISO 3166-1 scheme */
  ultimateCountryCode?: InputMaybe<Scalars['String']['input']>;
  /** In dollars */
  yearlyRevenue?: InputMaybe<Range>;
};

export type DnbCompaniesByIndustryResponse = {
  __typename?: 'DnbCompaniesByIndustryResponse';
  items: Array<DnbSuggestion>;
  matched: Scalars['Int']['output'];
  returned: Scalars['Int']['output'];
};

export type DnbCompany = {
  __typename?: 'DnbCompany';
  businessEntityType?: Maybe<Scalars['String']['output']>;
  controlOwnershipDate?: Maybe<Scalars['String']['output']>;
  controlOwnershipTypeDescription?: Maybe<Scalars['String']['output']>;
  corporateLinkage?: Maybe<DnbCorporateLinkage>;
  /** The D-U-N-S Number */
  duns: Scalars['String']['output'];
  /** @deprecated Use integration.searchClient query instead */
  ennablClientId?: Maybe<Scalars['String']['output']>;
  fein?: Maybe<Scalars['String']['output']>;
  financials: Array<DnbFinancials>;
  foundationDate?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  industries?: Maybe<Array<Industry>>;
  industryCodes?: Maybe<Array<DnbIndustryCode>>;
  isMinorityOwned?: Maybe<Scalars['Boolean']['output']>;
  isSmallBusiness?: Maybe<Scalars['Boolean']['output']>;
  mailingAddress?: Maybe<DnbAddress>;
  naicsCodes: Array<DnbNaicsCode>;
  name: Scalars['String']['output'];
  numberOfEmployees?: Maybe<Scalars['Int']['output']>;
  numberOfEmployeesInformationScopeDescription?: Maybe<Scalars['String']['output']>;
  phones: Array<DnbPhone>;
  primaryAddress?: Maybe<DnbAddress>;
  principals: Array<DnbPrincipal>;
  prospect?: Maybe<Prospect>;
  registeredAddress?: Maybe<DnbAddress>;
  status?: Maybe<DnbControlStatus>;
  tradeStyleNames?: Maybe<Array<Scalars['String']['output']>>;
  websites: Array<Scalars['String']['output']>;
};

export type DnbCompanyProspectArgs = {
  anyOwner?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DnbCompanyRequest = {
  addressCounty?: InputMaybe<Scalars['String']['input']>;
  /** identifies the city, town, township, village, borough, etc. where the entity is located. */
  addressLocality?: InputMaybe<Scalars['String']['input']>;
  /** If country is US or CA, the Region must be 2 characters. For other countries, it can be up to 64 characters. */
  addressRegion?: InputMaybe<Scalars['String']['input']>;
  /** The 2-letter country/market ISO code. In the U.S. this would be a State. */
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  streetAddressLine1?: InputMaybe<Scalars['String']['input']>;
  streetAddressLine2?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type DnbControlStatus = {
  __typename?: 'DnbControlStatus';
  fullReportDate?: Maybe<Scalars['Timestamp']['output']>;
  isDelisted?: Maybe<Scalars['Boolean']['output']>;
  isMailUndeliverable?: Maybe<Scalars['Boolean']['output']>;
  isMarketable?: Maybe<Scalars['Boolean']['output']>;
  isOutOfBusiness?: Maybe<Scalars['Boolean']['output']>;
  isPhoneDisconnected?: Maybe<Scalars['Boolean']['output']>;
  operatingStatus?: Maybe<DnbOperatingStatus>;
  operatingStatusDescription?: Maybe<Scalars['String']['output']>;
};

export type DnbCorporateLinkage = {
  __typename?: 'DnbCorporateLinkage';
  domesticUltimate?: Maybe<DnbUltimate>;
  familytreeRolesPlayed?: Maybe<Array<DnbFamilyTreeRole>>;
  globalUltimate?: Maybe<DnbUltimate>;
  globalUltimateFamilyTreeMembersCount?: Maybe<Scalars['Int']['output']>;
  headQuarter?: Maybe<DnbUltimate>;
  hierarchyLevel?: Maybe<Scalars['Int']['output']>;
  isBranch?: Maybe<Scalars['Boolean']['output']>;
  parent?: Maybe<DnbUltimate>;
};

export enum DnbCriteriaSearchSorting {
  CountryCode = 'COUNTRY_CODE',
  IsBranch = 'IS_BRANCH',
  IsOutOfBusiness = 'IS_OUT_OF_BUSINESS',
  NumberOfEmployees = 'NUMBER_OF_EMPLOYEES',
  PrimaryName = 'PRIMARY_NAME',
  YearlyRevenue = 'YEARLY_REVENUE',
}

export type DnbFamilyTreeRole = {
  __typename?: 'DnbFamilyTreeRole';
  description?: Maybe<Scalars['String']['output']>;
  dnbCode?: Maybe<Scalars['Int']['output']>;
};

export type DnbFinancials = {
  __typename?: 'DnbFinancials';
  accountantName?: Maybe<Scalars['String']['output']>;
  /** The date when the accounting period ended. */
  financialStatementToDate?: Maybe<Scalars['Timestamp']['output']>;
  reliabilityDescription?: Maybe<Scalars['String']['output']>;
  unitCode?: Maybe<Scalars['String']['output']>;
  yearlyRevenue?: Maybe<Array<DnbYearlyRevenue>>;
};

export type DnbIndustryCode = {
  __typename?: 'DnbIndustryCode';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  typeDescription?: Maybe<Scalars['String']['output']>;
  typeDnBCode?: Maybe<Scalars['Int']['output']>;
};

/** Max radius is 100mi or equivalent */
export type DnbLocationRadius = {
  /** Search radius around specified location */
  radius: Scalars['Float']['input'];
  /** Unit of measurement for the radius */
  unit: LocationRadiusUnit;
};

export type DnbNaicsCode = {
  __typename?: 'DnbNaicsCode';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  /** Priority is based on the percentage of business the entity does in the industry. */
  priority: Scalars['Int']['output'];
};

export enum DnbOperatingStatus {
  Active = 'ACTIVE',
  OutOfService = 'OUT_OF_SERVICE',
}

export type DnbPhone = {
  __typename?: 'DnbPhone';
  isUnreachable?: Maybe<Scalars['Boolean']['output']>;
  isdCode?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
};

export type DnbPrincipal = {
  __typename?: 'DnbPrincipal';
  familyName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  isImportant: Scalars['Boolean']['output'];
  jobTitles?: Maybe<Array<Scalars['String']['output']>>;
  namePrefix?: Maybe<Scalars['String']['output']>;
  nameSuffix?: Maybe<Scalars['String']['output']>;
};

export type DnbSuggestion = {
  __typename?: 'DnbSuggestion';
  corporateLinkage?: Maybe<DnbCorporateLinkage>;
  /** The D-U-N-S Number */
  duns: Scalars['String']['output'];
  /** @deprecated Use integration.searchClient query instead */
  ennablClientId?: Maybe<Scalars['String']['output']>;
  /** Only available for search by industry */
  financials?: Maybe<Array<DnbFinancials>>;
  id: Scalars['ID']['output'];
  industries?: Maybe<Array<Industry>>;
  /** Only available for search by industry */
  industryCodes?: Maybe<Array<DnbIndustryCode>>;
  mailingAddress?: Maybe<DnbAddress>;
  /** Only available for search by industry */
  naicsCodes?: Maybe<Array<DnbNaicsCode>>;
  name: Scalars['String']['output'];
  /** Only available for search by industry */
  numberOfEmployees?: Maybe<Scalars['Int']['output']>;
  /** Only available for search by industry */
  numberOfEmployeesInformationScopeDescription?: Maybe<Scalars['String']['output']>;
  phones: Array<DnbPhone>;
  primaryAddress?: Maybe<DnbAddress>;
  prospect?: Maybe<Prospect>;
  status?: Maybe<DnbControlStatus>;
  websites: Array<Scalars['String']['output']>;
};

export type DnbSuggestionProspectArgs = {
  anyOwner?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DnbTrend = {
  __typename?: 'DnbTrend';
  growthRate?: Maybe<Scalars['Float']['output']>;
  timePeriod?: Maybe<DnbTrendTimePeriod>;
};

export type DnbTrendTimePeriod = {
  __typename?: 'DnbTrendTimePeriod';
  description?: Maybe<Scalars['String']['output']>;
  dnbCode?: Maybe<Scalars['Float']['output']>;
};

export type DnbUltimate = {
  __typename?: 'DnbUltimate';
  duns?: Maybe<Scalars['String']['output']>;
  primaryAddress?: Maybe<DnbAddress>;
  primaryName?: Maybe<Scalars['String']['output']>;
};

export type DnbYearlyRevenue = {
  __typename?: 'DnbYearlyRevenue';
  /** The three-letter currency code, defined in the ISO 4217 scheme */
  currency?: Maybe<Scalars['String']['output']>;
  trend?: Maybe<Array<DnbTrend>>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type Document = {
  __typename?: 'Document';
  client?: Maybe<Client>;
  createdDate?: Maybe<Scalars['Timestamp']['output']>;
  documentClass?: Maybe<Scalars['String']['output']>;
  documentSize?: Maybe<Scalars['Float']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastModifiedDate?: Maybe<Scalars['Timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  policy?: Maybe<Policy>;
  policyNumber?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type DocumentFilter = {
  clientIds?: InputMaybe<Array<Scalars['String']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
  sourceSystem?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<DocumentSortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export type DocumentPaginatedResult = {
  __typename?: 'DocumentPaginatedResult';
  items: Array<Document>;
  totalHits: Scalars['Int']['output'];
};

export enum DocumentSortingField {
  Address = 'ADDRESS',
  ClientId = 'CLIENT_ID',
  ClientName = 'CLIENT_NAME',
  CreatedDate = 'CREATED_DATE',
  FileType = 'FILE_TYPE',
  Name = 'NAME',
  PolicyNumber = 'POLICY_NUMBER',
  SourceSystem = 'SOURCE_SYSTEM',
  State = 'STATE',
}

export type Documents = {
  __typename?: 'Documents';
  byIds: Array<Document>;
  paginated: DocumentPaginatedResult;
};

export type DocumentsByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type DocumentsPaginatedArgs = {
  filter?: InputMaybe<DocumentFilter>;
  pagination?: InputMaybe<DocumentListPaginationInput>;
};

export type DolCompany = {
  __typename?: 'DolCompany';
  /** FEIN of the company */
  fein: Scalars['ID']['output'];
  mailAddress?: Maybe<Form5500Address>;
  name: Scalars['String']['output'];
  participantsAmount?: Maybe<OutputRange>;
  phone?: Maybe<Scalars['String']['output']>;
  prospect?: Maybe<Prospect>;
  /** @deprecated Removed */
  usAddress?: Maybe<Form5500Address>;
};

export type DolCompanyProspectArgs = {
  anyOwner?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DolCompanySearchRequest = {
  brokerName?: InputMaybe<Scalars['String']['input']>;
  carrierName?: InputMaybe<Scalars['String']['input']>;
  /** Federal Employer Identification Number */
  fein?: InputMaybe<Scalars['String']['input']>;
  /** Location radius for zipCodes field which must be provided if locationRadius is provided */
  locationRadius?: InputMaybe<LocationRadius>;
  /** NAICS 2022 codes */
  naicsCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Sponsor name in terms of form5500 or company name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Participants amount for the beginning of the year form published */
  participantsAmount?: InputMaybe<Range>;
  /** One of policy expiration dates is in the range */
  policyExpirationDate?: InputMaybe<DateRange>;
  /** The two symbol state code */
  stateCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Zip-5 codes */
  zipCodes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DolCompanySearchResult = {
  __typename?: 'DolCompanySearchResult';
  items: Array<DolCompany>;
  totalHits: Scalars['Float']['output'];
};

export type DownloadOpportunityReportPayload = {
  __typename?: 'DownloadOpportunityReportPayload';
  url: Scalars['String']['output'];
};

export type EnnablRole = {
  __typename?: 'EnnablRole';
  appPermissions: Array<AppPermission>;
  decisionsPermissions?: Maybe<DecisionsPermissions>;
  id: Scalars['ID']['output'];
  /** This is custom access role created for specific user */
  isPrivate: Scalars['Boolean']['output'];
  /** Predefined roles are not allowed to be removed or updated (except name) */
  isProtected: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export enum EnnablRoleType {
  AccountManager = 'ACCOUNT_MANAGER',
  Admin = 'ADMIN',
  Custom = 'CUSTOM',
  Producer = 'PRODUCER',
}

export type EnrichmentMappings = {
  __typename?: 'EnrichmentMappings';
  insuranceCompanyMappingSearchMappings: InsuranceCompanySearchMappingResponse;
  productList: InsuranceCompanySearchMappingResponse;
};

export type EnrichmentMappingsInsuranceCompanyMappingSearchMappingsArgs = {
  externalSystemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  linkedWithAdminCompany?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<InsuranceCompanySearchMappingPagination>;
};

export type EnrichmentMappingsProductListArgs = {
  externalSystemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  linkedWithAdminProduct?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnrichmentProcessingTokenResponse = {
  __typename?: 'EnrichmentProcessingTokenResponse';
  token?: Maybe<Scalars['String']['output']>;
};

export type EntityMetrics = {
  __typename?: 'EntityMetrics';
  commission: Scalars['Float']['output'];
  premium: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
};

export type FilterRange = {
  /** Lower bound for search */
  from?: InputMaybe<Scalars['Float']['input']>;
  mode?: InputMaybe<FilterRangeMode>;
  notExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Upper bound search */
  to?: InputMaybe<Scalars['Float']['input']>;
};

export enum FilterRangeMode {
  /** Exclude limit values */
  ExcludeLimits = 'EXCLUDE_LIMITS',
  /** Include limit values */
  IncludeLimits = 'INCLUDE_LIMITS',
  /** Include right end values */
  IncludeMax = 'INCLUDE_MAX',
  /** Include left end values (Default) */
  IncludeMin = 'INCLUDE_MIN',
}

export type FilterRangeOutput = {
  __typename?: 'FilterRangeOutput';
  /** Lower bound for search */
  from?: Maybe<Scalars['Float']['output']>;
  mode?: Maybe<FilterRangeMode>;
  notExists?: Maybe<Scalars['Boolean']['output']>;
  /** Upper bound search */
  to?: Maybe<Scalars['Float']['output']>;
};

export type Form5500 = {
  __typename?: 'Form5500';
  /** Source: admin_* */
  admin?: Maybe<Form5500Admin>;
  /** Source: admin_manual_signed_* */
  adminManualSigned?: Maybe<Form5500Signed>;
  /** Source: admin_signed_* */
  adminSigned?: Maybe<Form5500Signed>;
  /** Source: dfe_manual_signed_* */
  dfeManualSigned?: Maybe<Form5500Signed>;
  /** Source: dfe_signed_* */
  dfeSigned?: Maybe<Form5500Signed>;
  /** Source: filing_year */
  filingYear?: Maybe<Scalars['Int']['output']>;
  /** Source: ack_id */
  id: Scalars['ID']['output'];
  /** Source: industry_desc */
  industryDesc?: Maybe<Scalars['String']['output']>;
  /** Source: business_code */
  naics?: Maybe<Scalars['String']['output']>;
  /** calculated in resolver and might be undefined if unavailable to download */
  optionalPdfDownloadLink?: Maybe<Scalars['String']['output']>;
  participantsCount: ParticipantsCount;
  pdfDownloadLink: Scalars['String']['output'];
  /** Source: plan_eff_date */
  planEffectiveDate?: Maybe<Scalars['Timestamp']['output']>;
  /** Source: plan_name */
  planName?: Maybe<Scalars['String']['output']>;
  /** Source: type_plan_entity_desc */
  planTypeDesc?: Maybe<Scalars['String']['output']>;
  /** Source: form_plan_year_begin_date */
  planYearBegin?: Maybe<Scalars['Timestamp']['output']>;
  /** Source: form_plan_year_end_date */
  planYearEnd?: Maybe<Scalars['Timestamp']['output']>;
  /** Source: date_received */
  received?: Maybe<Scalars['Timestamp']['output']>;
  scheduleA: Array<Form5500ScheduleA>;
  /** Source: sponsor_dfe_* */
  sponsorDfe?: Maybe<Form5500SponsorDfe>;
  /** Source: spons_manual_signed_* */
  sponsorManualSigned?: Maybe<Form5500Signed>;
  /** Source: spons_signed_* */
  sponsorSigned?: Maybe<Form5500Signed>;
  /** Source: filing_form_type */
  type?: Maybe<Form5500Type>;
};

export type Form5500Address = {
  __typename?: 'Form5500Address';
  city?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type Form5500Admin = {
  __typename?: 'Form5500Admin';
  ein?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneForeign?: Maybe<Scalars['String']['output']>;
  usAddress?: Maybe<Form5500Address>;
};

export type Form5500ScheduleA = {
  __typename?: 'Form5500ScheduleA';
  /** Source: sch_aein */
  ein?: Maybe<Scalars['String']['output']>;
  /** unique identifier of this record inside form5500 record, starting with 1 */
  formId: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  /** Source: ins_broker_comm_tot_amt */
  insuranceBrokerCommissionTotal?: Maybe<Scalars['Float']['output']>;
  /** Source: ins_broker_fees_tot_amt */
  insuranceBrokerFeeTotal?: Maybe<Scalars['Float']['output']>;
  /** Source: ins_broker_fees_tot_amt */
  insuranceBrokerRevenueTotal?: Maybe<Scalars['Float']['output']>;
  /** Source: ins_carrier_ein */
  insuranceCarrierEin?: Maybe<Scalars['String']['output']>;
  /** Source: ins_carrier_naic_code */
  insuranceCarrierNaics?: Maybe<Scalars['String']['output']>;
  /** Source: ins_carrier_name */
  insuranceCarrierName?: Maybe<Scalars['String']['output']>;
  /** Source: ins_contract_num */
  insuranceContractNumber?: Maybe<Scalars['String']['output']>;
  /** Source: ins_prsn_covered_eoy_cnt */
  insurancePersonCoveredCount?: Maybe<Scalars['Int']['output']>;
  /** Source: ins_policy_from_date */
  insurancePolicyFromDate?: Maybe<Scalars['Timestamp']['output']>;
  /** Source: ins_policy_to_date */
  insurancePolicyToDate?: Maybe<Scalars['Timestamp']['output']>;
  /** Information Concerning Insurance Contract Coverage, Fees, and Commissions. Provide info for each contract of a separate Schedule A. */
  part1: Array<Form5500ScheduleAPart1>;
  /** Source: sch_aplan_num */
  planNum?: Maybe<Scalars['String']['output']>;
  /** Source: sch_aplan_year_begin_date */
  planYearBeginDate?: Maybe<Scalars['String']['output']>;
  /** Source: sch_aplan_year_end_date */
  planYearEndDate?: Maybe<Scalars['String']['output']>;
  /** Source: wlfr_* */
  welfareBenefitAmounts?: Maybe<Form5500WelfareBenefitAmounts>;
  /** Source: parsed wlfr_bnft_ind_* + wlfr_type_bnft_oth_text */
  welfareBenefitProducts?: Maybe<Array<Scalars['String']['output']>>;
};

export type Form5500ScheduleAPart1 = {
  __typename?: 'Form5500ScheduleAPart1';
  /** Source: ins_broker_comm_pd_amt */
  brokerCommission?: Maybe<Scalars['Float']['output']>;
  /** Source: ins_broker_fees_pd_amt */
  brokerFee?: Maybe<Scalars['Float']['output']>;
  /** Source: ins_broker_name */
  brokerName?: Maybe<Scalars['String']['output']>;
  /** Source: ins_broker_fees_pd_amt */
  brokerRevenue?: Maybe<Scalars['Float']['output']>;
  /** Source: ins_broker_us* */
  brokerUsAddress1?: Maybe<Form5500Address>;
  /** unique identifier of this record inside ScheduleA record, starting with 1 */
  formId: Scalars['Float']['output'];
  /** Source: ack_id */
  id: Scalars['ID']['output'];
  /** unique identifier of this record inside ScheduleA record, starting with 1 */
  rowOrder: Scalars['String']['output'];
};

export type Form5500SearchPagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Form5500SearchRequest = {
  /** Federal Employer Identification Number */
  fein?: InputMaybe<Scalars['String']['input']>;
  /** Year for was created */
  filingYears?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Sponsor name in terms of form5500 or company name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Name of the plan */
  planName?: InputMaybe<Scalars['String']['input']>;
  /** The two symbol state code */
  stateCodes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Form5500SearchResponse = {
  __typename?: 'Form5500SearchResponse';
  items: Array<Form5500>;
  totalHits: Scalars['Int']['output'];
};

export type Form5500Signed = {
  __typename?: 'Form5500Signed';
  date?: Maybe<Scalars['Timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Form5500SponsorDfe = {
  __typename?: 'Form5500SponsorDFE';
  dbaName?: Maybe<Scalars['String']['output']>;
  ein?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneForeign?: Maybe<Scalars['String']['output']>;
  usLocalAddress?: Maybe<Form5500Address>;
  usMailAddress?: Maybe<Form5500Address>;
};

export enum Form5500Type {
  Full = 'FULL',
  Short = 'SHORT',
}

export type Form5500WelfareBenefitAmounts = {
  __typename?: 'Form5500WelfareBenefitAmounts';
  /** Source: wlfr_tot_charges_paid_amt */
  paidTotalCharges?: Maybe<Scalars['Float']['output']>;
  /** Source: wlfr_premium_rcvd_amt */
  premiumReceived?: Maybe<Scalars['Float']['output']>;
  /** Source: wlfr_tot_earned_prem_a */
  premiumTotalEarned?: Maybe<Scalars['Float']['output']>;
};

export type FromToPeriod = {
  from: Scalars['Timestamp']['input'];
  to: Scalars['Timestamp']['input'];
};

export type FromToPeriodOutput = {
  __typename?: 'FromToPeriodOutput';
  from: Scalars['Timestamp']['output'];
  to: Scalars['Timestamp']['output'];
};

export type GlobalCarrierAppetite = {
  __typename?: 'GlobalCarrierAppetite';
  kpi: GlobalCarrierAppetiteKpi;
  list: GlobalCarrierAppetitePaginatedResult;
  marketTree: Array<GlobalInsuranceCompany>;
  productTree: Array<GlobalInsuranceSegment>;
};

export type GlobalCarrierAppetiteKpiArgs = {
  carrierLevel: CarrierLevel;
  filter: CarrierAppetiteFilter;
};

export type GlobalCarrierAppetiteListArgs = {
  carrierLevel: CarrierLevel;
  filter: CarrierAppetiteFilter;
  pagination: CarrierAppetiteListPaginationInput;
};

export type GlobalCarrierAppetiteMarketTreeArgs = {
  primaryRole: CompanyPrimaryRole;
};

export type GlobalCarrierAppetiteKpi = {
  __typename?: 'GlobalCarrierAppetiteKpi';
  carrierCount: Scalars['Float']['output'];
  /** if null - count of policies is less than 100 */
  directPolicies?: Maybe<Scalars['Float']['output']>;
  /** if null - count of policies is less than 100 */
  indirectPolicies?: Maybe<Scalars['Float']['output']>;
};

export type GlobalCarrierAppetitePaginatedResult = {
  __typename?: 'GlobalCarrierAppetitePaginatedResult';
  items: Array<GlobalCarrierAppetiteRow>;
  totalHits: Scalars['Int']['output'];
};

export type GlobalCarrierAppetiteRow = {
  __typename?: 'GlobalCarrierAppetiteRow';
  /** children field is not resolved here */
  carrier: GlobalInsuranceCompany;
  directCommission: OutputRange;
  /** if null - count of policies is less than 100 */
  directPolicies?: Maybe<Scalars['Float']['output']>;
  indirectCommission: OutputRange;
  /** if null - count of policies is less than 100 */
  indirectPolicies?: Maybe<Scalars['Float']['output']>;
};

export type GlobalInsuranceCompany = {
  __typename?: 'GlobalInsuranceCompany';
  /** for child level this list is always empty */
  children: Array<GlobalInsuranceCompany>;
  id: Scalars['String']['output'];
  /** corresponding market in broker's DB, if present */
  market?: Maybe<Market>;
  name: Scalars['String']['output'];
};

export type GlobalInsuranceSegment = {
  __typename?: 'GlobalInsuranceSegment';
  name: Scalars['String']['output'];
  /** corresponding product in broker's DB, if present */
  product?: Maybe<Product>;
  products: Array<GlobalProduct>;
};

export type GlobalProduct = {
  __typename?: 'GlobalProduct';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** corresponding product in broker's DB, if present */
  product?: Maybe<Product>;
};

export type Growth = {
  __typename?: 'Growth';
  currentValue?: Maybe<Scalars['Float']['output']>;
  diff?: Maybe<Scalars['Float']['output']>;
  diffPct?: Maybe<Scalars['Float']['output']>;
  prevValue?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubAaisFireProtectionClassParams = {
  __typename?: 'HazardhubAaisFireProtectionClassParams';
  availableWaterType?: Maybe<Scalars['String']['output']>;
  class?: Maybe<Scalars['String']['output']>;
  hydrantFireStationDistance?: Maybe<Scalars['String']['output']>;
};

export type HazardhubAirportNoise = {
  __typename?: 'HazardhubAirportNoise';
  decibels?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HazardhubAspectRisk = {
  __typename?: 'HazardhubAspectRisk';
  aspect?: Maybe<Scalars['String']['output']>;
  direction?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
};

export type HazardhubAssessment = {
  __typename?: 'HazardhubAssessment';
  airConditioning?: Maybe<Scalars['String']['output']>;
  airConditioningType?: Maybe<Scalars['String']['output']>;
  amenities?: Maybe<Scalars['String']['output']>;
  amenities2?: Maybe<Scalars['String']['output']>;
  assessedImprovementValue?: Maybe<Scalars['Float']['output']>;
  assessedLandValue?: Maybe<Scalars['Float']['output']>;
  assessmentYear?: Maybe<Scalars['String']['output']>;
  assessorsParcelNumber?: Maybe<Scalars['String']['output']>;
  basement?: Maybe<Scalars['String']['output']>;
  buildingArea?: Maybe<Scalars['Float']['output']>;
  buildingArea1?: Maybe<Scalars['Float']['output']>;
  buildingClass?: Maybe<Scalars['String']['output']>;
  buildingCondition?: Maybe<Scalars['String']['output']>;
  buildingQuality?: Maybe<Scalars['String']['output']>;
  condoProjectBldgName?: Maybe<Scalars['String']['output']>;
  currentOwnerName?: Maybe<Scalars['String']['output']>;
  fireplace?: Maybe<Scalars['String']['output']>;
  garageCars?: Maybe<Scalars['Float']['output']>;
  garageType?: Maybe<Scalars['String']['output']>;
  heating?: Maybe<Scalars['String']['output']>;
  legalBriefDescriptionFull?: Maybe<Scalars['String']['output']>;
  legalSubdivisionName?: Maybe<Scalars['String']['output']>;
  listSaleDocumentNumber?: Maybe<Scalars['String']['output']>;
  listSalePrice?: Maybe<Scalars['String']['output']>;
  listSalePriceCode?: Maybe<Scalars['String']['output']>;
  listSaleRecordingDate?: Maybe<Scalars['String']['output']>;
  listValidPrice?: Maybe<Scalars['String']['output']>;
  lotSizeAcres?: Maybe<Scalars['Float']['output']>;
  lotSizeDepthFeet?: Maybe<Scalars['String']['output']>;
  lotSizeFrontageFeet?: Maybe<Scalars['String']['output']>;
  lotSizeSquareFeet?: Maybe<Scalars['Float']['output']>;
  mainBuildingAreaIndicator?: Maybe<Scalars['String']['output']>;
  nOfPlumbingFixtures?: Maybe<Scalars['String']['output']>;
  neighborhoodCode?: Maybe<Scalars['String']['output']>;
  noOfBuildings?: Maybe<Scalars['Float']['output']>;
  noOfStories?: Maybe<Scalars['String']['output']>;
  numberOfBaths?: Maybe<Scalars['Float']['output']>;
  numberOfBedrooms?: Maybe<Scalars['Float']['output']>;
  numberOfPartialBaths?: Maybe<Scalars['Float']['output']>;
  numberOfUnits?: Maybe<Scalars['Float']['output']>;
  owner1FirstName?: Maybe<Scalars['String']['output']>;
  owner1LastName?: Maybe<Scalars['String']['output']>;
  owner2Firstname?: Maybe<Scalars['String']['output']>;
  owner2LastName?: Maybe<Scalars['String']['output']>;
  ownerOccupied?: Maybe<Scalars['String']['output']>;
  pool?: Maybe<Scalars['String']['output']>;
  previousSalePrice?: Maybe<Scalars['String']['output']>;
  roofCover?: Maybe<Scalars['String']['output']>;
  roofType?: Maybe<Scalars['String']['output']>;
  schoolTaxDistrict1?: Maybe<Scalars['String']['output']>;
  schoolTaxDistrict1Indicator?: Maybe<Scalars['String']['output']>;
  schoolTaxDistrict2?: Maybe<Scalars['String']['output']>;
  schoolTaxDistrict2Indicator?: Maybe<Scalars['String']['output']>;
  schoolTaxDistrict3?: Maybe<Scalars['String']['output']>;
  schoolTaxDistrict3Indicator?: Maybe<Scalars['String']['output']>;
  siteInfluence?: Maybe<Scalars['String']['output']>;
  standardizedLandUseCode?: Maybe<Scalars['String']['output']>;
  taxAmount?: Maybe<Scalars['Float']['output']>;
  taxDelinquentYear?: Maybe<Scalars['String']['output']>;
  taxMarketValue?: Maybe<Scalars['Float']['output']>;
  taxRateCodeArea?: Maybe<Scalars['String']['output']>;
  topography?: Maybe<Scalars['String']['output']>;
  totalAssessedValue?: Maybe<Scalars['Float']['output']>;
  totalMarketValue?: Maybe<Scalars['Float']['output']>;
  totalNumberOfRooms?: Maybe<Scalars['String']['output']>;
  typeConstruction?: Maybe<Scalars['String']['output']>;
  yearBuilt?: Maybe<Scalars['String']['output']>;
};

export type HazardhubBaseFloodElevation = {
  __typename?: 'HazardhubBaseFloodElevation';
  distance?: Maybe<HazardhubDistanceNumber>;
  elevation?: Maybe<HazardhubDistanceNumber>;
  meter?: Maybe<Scalars['Float']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HazardhubCachedResponse = {
  __typename?: 'HazardhubCachedResponse';
  data: HazardhubResponse;
  lastUpdated: Scalars['Timestamp']['output'];
};

export type HazardhubCbrsParams = {
  __typename?: 'HazardhubCbrsParams';
  cbrsType?: Maybe<Scalars['String']['output']>;
  designation?: Maybe<Scalars['String']['output']>;
  fastAcres?: Maybe<Scalars['Float']['output']>;
  fiDate?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shoreMile?: Maybe<Scalars['Float']['output']>;
  suDate?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  wetAcres?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubCoastDistance = {
  __typename?: 'HazardhubCoastDistance';
  beachDistance?: Maybe<HazardhubDistanceString>;
  distance?: Maybe<Scalars['String']['output']>;
  highResDistance?: Maybe<HazardhubDistanceString>;
  lowResDistance?: Maybe<HazardhubDistanceString>;
  units?: Maybe<Scalars['String']['output']>;
};

export type HazardhubDetail = {
  __typename?: 'HazardhubDetail';
  areaDesc?: Maybe<Scalars['String']['output']>;
  siteDesc?: Maybe<Scalars['String']['output']>;
};

export type HazardhubDistanceNumber = {
  __typename?: 'HazardhubDistanceNumber';
  units?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type HazardhubDistanceString = {
  __typename?: 'HazardhubDistanceString';
  units?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type HazardhubDistanceToSignificantFloodParams = {
  __typename?: 'HazardhubDistanceToSignificantFloodParams';
  distanceTo100YrFloodplain?: Maybe<Scalars['String']['output']>;
  elevation100?: Maybe<Scalars['String']['output']>;
  elevationDifference100?: Maybe<Scalars['String']['output']>;
  elevationInFeet?: Maybe<HazardhubValueString>;
};

export type HazardhubDistanceToSignificantWfRiskParams = {
  __typename?: 'HazardhubDistanceToSignificantWfRiskParams';
  high?: Maybe<Scalars['String']['output']>;
  moderate?: Maybe<Scalars['String']['output']>;
  risk?: Maybe<Scalars['String']['output']>;
  veryHigh?: Maybe<Scalars['String']['output']>;
};

export type HazardhubDriveDistanceFireStation = {
  __typename?: 'HazardhubDriveDistanceFireStation';
  distance?: Maybe<Scalars['Float']['output']>;
  units?: Maybe<Scalars['String']['output']>;
};

export type HazardhubDriveTimeFireStation = {
  __typename?: 'HazardhubDriveTimeFireStation';
  duration?: Maybe<Scalars['Float']['output']>;
  units?: Maybe<Scalars['String']['output']>;
};

export type HazardhubDroughtFrequencyIndex = {
  __typename?: 'HazardhubDroughtFrequencyIndex';
  desc?: Maybe<Scalars['String']['output']>;
  pct?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HazardhubEarthquake = {
  __typename?: 'HazardhubEarthquake';
  mmi?: Maybe<Scalars['String']['output']>;
  peakGroundAcceleration?: Maybe<Scalars['Float']['output']>;
  richterScale?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  shake?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HazardhubEnhancedHailParams = {
  __typename?: 'HazardhubEnhancedHailParams';
  countWithin25MilesForLastDecade?: Maybe<Scalars['String']['output']>;
  haddrisk?: Maybe<Scalars['String']['output']>;
  historicalHailEvents?: Maybe<HazardhubHistoricalHailEvents>;
  hpctrisk?: Maybe<Scalars['String']['output']>;
  nearestHailIncident?: Maybe<HazardhubNearestIncident>;
  numOfDamagingHailIncidentsWithin25MilesSince1996?: Maybe<Scalars['Float']['output']>;
  scale?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HazardhubEnhancedHhFloodParams = {
  __typename?: 'HazardhubEnhancedHhFloodParams';
  diff?: Maybe<Scalars['Float']['output']>;
  dist?: Maybe<Scalars['Float']['output']>;
  elevPt?: Maybe<Scalars['Float']['output']>;
  elevnff?: Maybe<Scalars['Float']['output']>;
  linesDescrip?: Maybe<Scalars['String']['output']>;
  linesDiff?: Maybe<Scalars['Float']['output']>;
  linesDistance?: Maybe<Scalars['Float']['output']>;
  linesName?: Maybe<Scalars['String']['output']>;
  linesScore?: Maybe<Scalars['String']['output']>;
  polygonDescrip?: Maybe<Scalars['String']['output']>;
  polygonName?: Maybe<Scalars['String']['output']>;
  polygonType?: Maybe<Scalars['String']['output']>;
  polygonsDiff?: Maybe<Scalars['Float']['output']>;
  polygonsDistance?: Maybe<Scalars['Float']['output']>;
  polygonsScore?: Maybe<Scalars['String']['output']>;
};

export type HazardhubEnhancedHhPfs = {
  __typename?: 'HazardhubEnhancedHhPfs';
  averagePctLoss?: Maybe<Scalars['Float']['output']>;
  estimatedLossAmt?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type HazardhubEnhancedHurricaneParams = {
  __typename?: 'HazardhubEnhancedHurricaneParams';
  annualChanceEventLessThan950Mb?: Maybe<Scalars['Float']['output']>;
  annualChanceEventMoreThan50Knots?: Maybe<Scalars['Float']['output']>;
  avgBarometricPressMb?: Maybe<Scalars['Float']['output']>;
  avgWindSpeedKnots?: Maybe<Scalars['Float']['output']>;
  maxWindSpeedKnots?: Maybe<Scalars['Float']['output']>;
  minBarometricPressMb?: Maybe<Scalars['Float']['output']>;
  nameYearMaxWind?: Maybe<Scalars['String']['output']>;
  nameYearMinPress?: Maybe<Scalars['String']['output']>;
  numCat0Hurricanes?: Maybe<Scalars['Float']['output']>;
  numCat1Hurricanes?: Maybe<Scalars['Float']['output']>;
  numCat2Hurricanes?: Maybe<Scalars['Float']['output']>;
  numCat3Hurricanes?: Maybe<Scalars['Float']['output']>;
  numCat4Hurricanes?: Maybe<Scalars['Float']['output']>;
  numCat5Hurricanes?: Maybe<Scalars['Float']['output']>;
  numDisturbances?: Maybe<Scalars['Float']['output']>;
  numEventsGt50Knots?: Maybe<Scalars['Float']['output']>;
  numEventsPressLt950Mb?: Maybe<Scalars['Float']['output']>;
  numExtratropicalSystems?: Maybe<Scalars['Float']['output']>;
  numHurricanes?: Maybe<Scalars['Float']['output']>;
  numLows?: Maybe<Scalars['Float']['output']>;
  numSubtropicalDepressions?: Maybe<Scalars['Float']['output']>;
  numSubtropicalStorms?: Maybe<Scalars['Float']['output']>;
  numTropicalDepressions?: Maybe<Scalars['Float']['output']>;
  numTropicalStorms?: Maybe<Scalars['Float']['output']>;
  numTropicalWaves?: Maybe<Scalars['Float']['output']>;
  scale?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  totalEventsSince1980?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubEnhancedLightningParams = {
  __typename?: 'HazardhubEnhancedLightningParams';
  laddrisk?: Maybe<Scalars['String']['output']>;
  lpctrisk?: Maybe<Scalars['String']['output']>;
  lsqmi?: Maybe<Scalars['String']['output']>;
  scale?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  strikesYr?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HazardhubEnhancedProperty = {
  __typename?: 'HazardhubEnhancedProperty';
  assessment?: Maybe<HazardhubAssessment>;
  images?: Maybe<Array<Scalars['String']['output']>>;
  listingRecord?: Maybe<HazardhubListingRecord>;
  mlsListingRecordDetails?: Maybe<HazardhubMlsListingDetails>;
  property?: Maybe<HazardhubProperty>;
  valuation?: Maybe<HazardhubValuation>;
};

export type HazardhubEnhancedTornadoParams = {
  __typename?: 'HazardhubEnhancedTornadoParams';
  countWithin25MilesForLastDecade?: Maybe<Scalars['String']['output']>;
  historicalTornadoEvents?: Maybe<HazardhubHistoricalTornadoEvents>;
  nearestTornadoIncident?: Maybe<HazardhubNearestIncident>;
  numOfDamagingTornadoIncidentsWithin25MilesSince1996?: Maybe<Scalars['Float']['output']>;
  scale?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  taddrisk?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  tpctrisk?: Maybe<Scalars['String']['output']>;
};

export type HazardhubEnhancedWildfire = {
  __typename?: 'HazardhubEnhancedWildfire';
  fireSeasonPrecipRisk?: Maybe<HazardhubFireSeasonPrecipRisk>;
  katabaticWindRisk?: Maybe<HazardhubKatabaticWindRisk>;
  landcoverRisk?: Maybe<HazardhubLandcoverRisk>;
  riskScores?: Maybe<HazardhubRiskScores>;
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  vegetationBurnSiteRisk?: Maybe<HazardhubVegetationBurnSiteRisk>;
  wildfirePerimeterRisk?: Maybe<HazardhubWildfirePerimeterRisk>;
};

export type HazardhubEnhancedWindParams = {
  __typename?: 'HazardhubEnhancedWindParams';
  countWithin25MilesForLastDecade?: Maybe<Scalars['String']['output']>;
  historicalWindEvents?: Maybe<HazardhubHistoricalWindEvents>;
  nearestWindIncident?: Maybe<HazardhubNearestIncident>;
  numOfDamagingWindIncidentsWithin25MilesSince1996?: Maybe<Scalars['Float']['output']>;
  scale?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  waddrisk?: Maybe<Scalars['String']['output']>;
  wpctrisk?: Maybe<Scalars['String']['output']>;
};

export type HazardhubFemaAllFloodParams = {
  __typename?: 'HazardhubFemaAllFloodParams';
  arRevert?: Maybe<Scalars['String']['output']>;
  arSubtrv?: Maybe<Scalars['String']['output']>;
  bfeRevert?: Maybe<Scalars['String']['output']>;
  depRevert?: Maybe<Scalars['String']['output']>;
  depth?: Maybe<Scalars['Float']['output']>;
  dfirmId?: Maybe<Scalars['String']['output']>;
  dualZone?: Maybe<Scalars['String']['output']>;
  fldArId?: Maybe<Scalars['String']['output']>;
  fldZone?: Maybe<Scalars['String']['output']>;
  lenUnit?: Maybe<Scalars['String']['output']>;
  sfhaTf?: Maybe<Scalars['String']['output']>;
  sourceCit?: Maybe<Scalars['String']['output']>;
  staticBfe?: Maybe<Scalars['Float']['output']>;
  studyTyp?: Maybe<Scalars['String']['output']>;
  vDatum?: Maybe<Scalars['String']['output']>;
  velUnit?: Maybe<Scalars['String']['output']>;
  velocity?: Maybe<Scalars['Float']['output']>;
  versionId?: Maybe<Scalars['String']['output']>;
  zoneSubty?: Maybe<Scalars['String']['output']>;
};

export type HazardhubFemaClaim = {
  __typename?: 'HazardhubFemaClaim';
  value?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubFemaClaims = {
  __typename?: 'HazardhubFemaClaims';
  claims?: Maybe<Array<HazardhubFemaClaim>>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubFemaFirmDate = {
  __typename?: 'HazardhubFemaFirmDate';
  cid?: Maybe<Scalars['String']['output']>;
  communityName?: Maybe<Scalars['String']['output']>;
  crsEntryDate?: Maybe<Scalars['String']['output']>;
  currentClass?: Maybe<Scalars['String']['output']>;
  currentEffectiveDate?: Maybe<Scalars['String']['output']>;
  currentEffectiveDateFloodMap?: Maybe<Scalars['String']['output']>;
  dateEnteredProgram?: Maybe<Scalars['String']['output']>;
  initialFirmIdDate?: Maybe<Scalars['String']['output']>;
  initialFloodHazardBoundayMapIdDate?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  percentDiscountForNonSfha?: Maybe<Scalars['String']['output']>;
  percentDiscountForSfha?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type HazardhubFemaFirmPanels = {
  __typename?: 'HazardhubFemaFirmPanels';
  effectiveDate?: Maybe<Scalars['String']['output']>;
  firmPanel?: Maybe<Scalars['String']['output']>;
};

export type HazardhubFemaMapParams = {
  __typename?: 'HazardhubFemaMapParams';
  femaFloodMapUrl?: Maybe<Scalars['String']['output']>;
};

export type HazardhubFireProtection = {
  __typename?: 'HazardhubFireProtection';
  aaisProxy?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HazardhubFireSeasonPrecipRisk = {
  __typename?: 'HazardhubFireSeasonPrecipRisk';
  desc?: Maybe<Scalars['String']['output']>;
  monthlyAvgPrecip?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubFireSeasonPrecipitationParams = {
  __typename?: 'HazardhubFireSeasonPrecipitationParams';
  fireSeasonPrecipitation?: Maybe<Scalars['String']['output']>;
  risk?: Maybe<Scalars['String']['output']>;
  scale?: Maybe<Scalars['String']['output']>;
};

export type HazardhubGroundSnowLoad = {
  __typename?: 'HazardhubGroundSnowLoad';
  desc?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HazardhubHardiness = {
  __typename?: 'HazardhubHardiness';
  avextmin?: Maybe<Scalars['String']['output']>;
  avextmin5?: Maybe<Scalars['String']['output']>;
  zone?: Maybe<Scalars['String']['output']>;
  zoneseg?: Maybe<Scalars['String']['output']>;
};

export type HazardhubHhElevation = {
  __typename?: 'HazardhubHhElevation';
  aspect?: Maybe<Scalars['String']['output']>;
  elevation?: Maybe<Scalars['String']['output']>;
  slope?: Maybe<Scalars['String']['output']>;
};

export type HazardhubHhSnowLoad = {
  __typename?: 'HazardhubHhSnowLoad';
  desc?: Maybe<Scalars['String']['output']>;
  scale?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HazardhubHistoricalHailEvents = {
  __typename?: 'HazardhubHistoricalHailEvents';
  hailRad?: Maybe<Scalars['String']['output']>;
};

export type HazardhubHistoricalTornadoEvents = {
  __typename?: 'HazardhubHistoricalTornadoEvents';
  tornadoRad?: Maybe<Scalars['String']['output']>;
};

export type HazardhubHistoricalWindEvents = {
  __typename?: 'HazardhubHistoricalWindEvents';
  windRad?: Maybe<Scalars['String']['output']>;
};

export type HazardhubHospital = {
  __typename?: 'HazardhubHospital';
  address?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['String']['output']>;
  helipad?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfHospitalsWithin5Miles?: Maybe<Scalars['Float']['output']>;
  numberOfHospitalsWithin10Miles?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type HazardhubHydrantParams = {
  __typename?: 'HazardhubHydrantParams';
  distanceFromNearestHydrant?: Maybe<Scalars['String']['output']>;
  hydrantsWithin1000Ft?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubIndex = {
  __typename?: 'HazardhubIndex';
  index?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HazardhubKatabaticWindRisk = {
  __typename?: 'HazardhubKatabaticWindRisk';
  desc?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubLandcoverAreas = {
  __typename?: 'HazardhubLandcoverAreas';
  distanceTo?: Maybe<Scalars['Float']['output']>;
  pctWildland?: Maybe<Scalars['Float']['output']>;
  riskCategory?: Maybe<Scalars['String']['output']>;
};

export type HazardhubLandcoverRisk = {
  __typename?: 'HazardhubLandcoverRisk';
  desc?: Maybe<Scalars['String']['output']>;
  detail?: Maybe<HazardhubDetail>;
  proximateLandcoverAreas?: Maybe<Array<HazardhubLandcoverAreas>>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubLavaFlow = {
  __typename?: 'HazardhubLavaFlow';
  desc?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  zone?: Maybe<Scalars['String']['output']>;
};

export type HazardhubListingRecord = {
  __typename?: 'HazardhubListingRecord';
  date?: Maybe<Scalars['Timestamp']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type HazardhubLivingArea = {
  __typename?: 'HazardhubLivingArea';
  squareFootage?: Maybe<Scalars['String']['output']>;
  yearBuilt?: Maybe<Scalars['String']['output']>;
};

export type HazardhubLomaParams = {
  __typename?: 'HazardhubLomaParams';
  case?: Maybe<Scalars['String']['output']>;
  cid?: Maybe<Scalars['String']['output']>;
  communityName?: Maybe<Scalars['String']['output']>;
  dateEnded?: Maybe<Scalars['String']['output']>;
  determination?: Maybe<Scalars['String']['output']>;
  docUrl?: Maybe<Scalars['String']['output']>;
  lotType?: Maybe<Scalars['String']['output']>;
  outcome?: Maybe<Scalars['String']['output']>;
  projectCat?: Maybe<Scalars['String']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  revalStat?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type HazardhubLomrParams = {
  __typename?: 'HazardhubLomrParams';
  caseNo?: Maybe<Scalars['String']['output']>;
  dfirmId?: Maybe<Scalars['String']['output']>;
  effDate?: Maybe<Scalars['String']['output']>;
  lomrId?: Maybe<Scalars['String']['output']>;
  sourceCit?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type HazardhubMaxWindParams = {
  __typename?: 'HazardhubMaxWindParams';
  maxRecordedWindMph?: Maybe<Scalars['Float']['output']>;
  maxWindScale?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubMlsListingDetails = {
  __typename?: 'HazardhubMlsListingDetails';
  adGeoAltitude?: Maybe<Scalars['String']['output']>;
  adZoneFeatures?: Maybe<Scalars['String']['output']>;
  exterior: HazardhubMlsListingExterior;
  interior: HazardhubMlsListingInterior;
  interiorFinishes: HazardhubMlsListingInteriorFinishes;
  rooms: HazardhubMlsListingRooms;
  schoolDistrict?: Maybe<Scalars['String']['output']>;
};

export type HazardhubMlsListingExterior = {
  __typename?: 'HazardhubMlsListingExterior';
  constructionFeatures?: Maybe<Scalars['String']['output']>;
  fenceFeatures?: Maybe<Scalars['String']['output']>;
  foundationFeatures?: Maybe<Scalars['String']['output']>;
  garageFeatures?: Maybe<Scalars['String']['output']>;
  garageSpaces?: Maybe<Scalars['Int']['output']>;
  generalFeatures?: Maybe<Scalars['String']['output']>;
  locationFeatures?: Maybe<Scalars['String']['output']>;
  lotSizeAcres?: Maybe<Scalars['Float']['output']>;
  lotSizeFeatures?: Maybe<Scalars['String']['output']>;
  lotSizeSquareFeet?: Maybe<Scalars['Float']['output']>;
  parkingFeatures?: Maybe<Scalars['String']['output']>;
  parkingSpaces?: Maybe<Scalars['String']['output']>;
  patio?: Maybe<Scalars['Boolean']['output']>;
  patioFeatures?: Maybe<Scalars['String']['output']>;
  pool?: Maybe<Scalars['Boolean']['output']>;
  poolFeatures?: Maybe<Scalars['String']['output']>;
  roadFeatures?: Maybe<Scalars['String']['output']>;
  roofFeatures?: Maybe<Scalars['String']['output']>;
  sewerFeatures?: Maybe<Scalars['String']['output']>;
  spa?: Maybe<Scalars['Boolean']['output']>;
  styleFeatures?: Maybe<Scalars['String']['output']>;
  viewFeatures?: Maybe<Scalars['String']['output']>;
  wallFeatures?: Maybe<Scalars['String']['output']>;
  wateraccessFeatures?: Maybe<Scalars['String']['output']>;
  waterfrontFeatures?: Maybe<Scalars['String']['output']>;
};

export type HazardhubMlsListingInterior = {
  __typename?: 'HazardhubMlsListingInterior';
  associationDues1?: Maybe<Scalars['String']['output']>;
  livingSquareFeet?: Maybe<Scalars['Float']['output']>;
  propertyType?: Maybe<Scalars['String']['output']>;
  publicRemarks?: Maybe<Scalars['String']['output']>;
  soldDate?: Maybe<Scalars['String']['output']>;
  soldPrice?: Maybe<Scalars['String']['output']>;
  subtype?: Maybe<Scalars['String']['output']>;
  yearBuilt?: Maybe<Scalars['Int']['output']>;
};

export type HazardhubMlsListingInteriorFinishes = {
  __typename?: 'HazardhubMlsListingInteriorFinishes';
  applianceFeatures?: Maybe<Scalars['String']['output']>;
  basement?: Maybe<Scalars['Boolean']['output']>;
  basementFeatures?: Maybe<Scalars['String']['output']>;
  cooling?: Maybe<Scalars['Boolean']['output']>;
  coolingFeatures?: Maybe<Scalars['String']['output']>;
  fireplace?: Maybe<Scalars['Boolean']['output']>;
  fireplaceFeatures?: Maybe<Scalars['String']['output']>;
  fireplaceNumber?: Maybe<Scalars['Int']['output']>;
  floorFeatures?: Maybe<Scalars['String']['output']>;
  generalFeatures?: Maybe<Scalars['String']['output']>;
  heatingFeatures?: Maybe<Scalars['String']['output']>;
  levelsFeatures?: Maybe<Scalars['String']['output']>;
  securityFeatures?: Maybe<Scalars['String']['output']>;
  securitySystem?: Maybe<Scalars['Boolean']['output']>;
  utilitiesFeatures?: Maybe<Scalars['String']['output']>;
  waterFeatures?: Maybe<Scalars['String']['output']>;
  windowFeatures?: Maybe<Scalars['String']['output']>;
};

export type HazardhubMlsListingRooms = {
  __typename?: 'HazardhubMlsListingRooms';
  bathsFull?: Maybe<Scalars['Float']['output']>;
  bathsHalf?: Maybe<Scalars['Float']['output']>;
  bathsTotal?: Maybe<Scalars['Float']['output']>;
  bedroomsTotal?: Maybe<Scalars['Float']['output']>;
  diningFeatures?: Maybe<Scalars['String']['output']>;
  family?: Maybe<Scalars['Boolean']['output']>;
  generalFeatures?: Maybe<Scalars['String']['output']>;
  kitchenFeatures?: Maybe<Scalars['String']['output']>;
  laundryFeatures?: Maybe<Scalars['String']['output']>;
  room11Features?: Maybe<Scalars['String']['output']>;
  room11Type?: Maybe<Scalars['String']['output']>;
  room13Features?: Maybe<Scalars['String']['output']>;
  room13Type?: Maybe<Scalars['String']['output']>;
  roomsTotal?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubMudslideRisk = {
  __typename?: 'HazardhubMudslideRisk';
  pcl?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HazardhubNearestFireStation = {
  __typename?: 'HazardhubNearestFireStation';
  distance?: Maybe<Scalars['Float']['output']>;
  driveDistanceFireStation?: Maybe<HazardhubDriveDistanceFireStation>;
  driveDurationUnits?: Maybe<Scalars['String']['output']>;
  driveDurationValue?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  responseArea?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  units?: Maybe<Scalars['String']['output']>;
};

export type HazardhubNearestIncident = {
  __typename?: 'HazardhubNearestIncident';
  cropDam?: Maybe<Scalars['String']['output']>;
  deaths?: Maybe<Scalars['String']['output']>;
  distanceMiles?: Maybe<Scalars['Float']['output']>;
  inches?: Maybe<Scalars['String']['output']>;
  injuries?: Maybe<Scalars['String']['output']>;
  propDam?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type HazardhubPerennialWater = {
  __typename?: 'HazardhubPerennialWater';
  answer?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HazardhubPfa = {
  __typename?: 'HazardhubPfa';
  score?: Maybe<Scalars['String']['output']>;
  siteName?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type HazardhubPoliceStations = {
  __typename?: 'HazardhubPoliceStations';
  distance?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numberWithin5Miles?: Maybe<Scalars['String']['output']>;
};

export type HazardhubPremiumTax = {
  __typename?: 'HazardhubPremiumTax';
  text?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type HazardhubPremiumTaxParams = {
  __typename?: 'HazardhubPremiumTaxParams';
  authTaxingAuthority?: Maybe<HazardhubValueString>;
  city?: Maybe<HazardhubValueString>;
  cityDestination?: Maybe<HazardhubValueString>;
  county?: Maybe<HazardhubValueString>;
  dateVintage?: Maybe<HazardhubValueString>;
  fips?: Maybe<HazardhubValueString>;
  fipsTaxingAuthority?: Maybe<HazardhubValueString>;
  fireZone?: Maybe<HazardhubValueString>;
  /** Geographic Name Information System Code */
  gnis?: Maybe<HazardhubValueString>;
  id?: Maybe<HazardhubValueString>;
  taxCode1?: Maybe<HazardhubValueString>;
  /** Only used for Florida */
  taxCode2?: Maybe<HazardhubValueString>;
};

export type HazardhubProperty = {
  __typename?: 'HazardhubProperty';
  address?: Maybe<Scalars['String']['output']>;
  apn?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  useCode?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type HazardhubReplacementCost = {
  __typename?: 'HazardhubReplacementCost';
  architecturalStyle?: Maybe<Scalars['String']['output']>;
  constructionQuality?: Maybe<Scalars['String']['output']>;
  constructionType?: Maybe<Scalars['String']['output']>;
  livingArea?: Maybe<HazardhubLivingArea>;
  locale?: Maybe<Scalars['String']['output']>;
  physicalShape?: Maybe<Scalars['String']['output']>;
  primaryExterior?: Maybe<Scalars['String']['output']>;
  primaryRoofCovering?: Maybe<Scalars['String']['output']>;
  replacementCost?: Maybe<Scalars['String']['output']>;
  slopeOfSite?: Maybe<Scalars['String']['output']>;
};

export type HazardhubRequest = {
  address: Scalars['String']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** 2 letter */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Unit number of the property */
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type HazardhubResponse = {
  __typename?: 'HazardhubResponse';
  /** ID in the enrichment cache */
  cachedId: Scalars['String']['output'];
  enhancedProperty: HazardhubEnhancedProperty;
  replacementCost: HazardhubReplacementCost;
  risks: HazardhubRisks;
  savedOpportunity?: Maybe<SavedOpportunity>;
};

export type HazardhubResponseSavedOpportunityArgs = {
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type HazardhubRingParams = {
  __typename?: 'HazardhubRingParams';
  cumulative0to1Mile?: Maybe<Scalars['Float']['output']>;
  cumulative0to2Mile?: Maybe<Scalars['Float']['output']>;
  cumulative0to3Mile?: Maybe<Scalars['Float']['output']>;
  cumulative0to4Mile?: Maybe<Scalars['Float']['output']>;
  cumulative0to5Mile?: Maybe<Scalars['Float']['output']>;
  ring0to1Mile?: Maybe<Scalars['Float']['output']>;
  ring1to2Mile?: Maybe<Scalars['Float']['output']>;
  ring2to3Mile?: Maybe<Scalars['Float']['output']>;
  ring3to4Mile?: Maybe<Scalars['Float']['output']>;
  ring4to5Mile?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubRiskScores = {
  __typename?: 'HazardhubRiskScores';
  base?: Maybe<Scalars['Float']['output']>;
  composite?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubRiskStandartizedAddress = {
  __typename?: 'HazardhubRiskStandartizedAddress';
  address?: Maybe<Scalars['String']['output']>;
  googleMapsUrl?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  locationType?: Maybe<Scalars['String']['output']>;
  /** houseNumber, city, state, etc. */
  matchLevel?: Maybe<Scalars['String']['output']>;
  /** From 0 to 1 */
  matchScore?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type HazardhubRisks = {
  __typename?: 'HazardhubRisks';
  aaisFireProtectionClassParams?: Maybe<HazardhubAaisFireProtectionClassParams>;
  airportNoise?: Maybe<HazardhubAirportNoise>;
  aspectRisk?: Maybe<HazardhubAspectRisk>;
  baseFloodElevation?: Maybe<HazardhubBaseFloodElevation>;
  burglary?: Maybe<HazardhubScoreTextValue>;
  cbrsParams?: Maybe<HazardhubCbrsParams>;
  coastDistance?: Maybe<HazardhubCoastDistance>;
  convectionStorm?: Maybe<HazardhubScoreWithText>;
  convectionStormPerDecade?: Maybe<HazardhubValueString>;
  crime?: Maybe<HazardhubScoreTextValue>;
  designatedFault?: Maybe<HazardhubScoreWithText>;
  distanceToSignificantFloodParams?: Maybe<HazardhubDistanceToSignificantFloodParams>;
  distanceToSignificantWildfireHazard?: Maybe<HazardhubDistanceToSignificantWfRiskParams>;
  driveDistanceFireStation?: Maybe<HazardhubDriveDistanceFireStation>;
  driveTimeFireStation?: Maybe<HazardhubDriveTimeFireStation>;
  drought?: Maybe<HazardhubScoreWithText>;
  droughtFrequencyIndex?: Maybe<HazardhubDroughtFrequencyIndex>;
  drugAlcoholDeaths?: Maybe<HazardhubScoreWithText>;
  drugAlcoholDeathsDeaths?: Maybe<HazardhubValueString>;
  drugAlcoholDeathsRate100K?: Maybe<HazardhubValueString>;
  drugAlcoholDeathsScore?: Maybe<HazardhubValueString>;
  earthquake?: Maybe<HazardhubEarthquake>;
  enhancedHailParams?: Maybe<HazardhubEnhancedHailParams>;
  enhancedHazardhubFlood?: Maybe<HazardhubScoreWithText>;
  enhancedHazardhubFloodParams?: Maybe<HazardhubEnhancedHhFloodParams>;
  enhancedHazardhubPfs?: Maybe<HazardhubEnhancedHhPfs>;
  enhancedHurricaneParams?: Maybe<HazardhubEnhancedHurricaneParams>;
  enhancedLightningParams?: Maybe<HazardhubEnhancedLightningParams>;
  enhancedTornadoParams?: Maybe<HazardhubEnhancedTornadoParams>;
  enhancedWildfire?: Maybe<HazardhubEnhancedWildfire>;
  enhancedWindParams?: Maybe<HazardhubEnhancedWindParams>;
  faultEarthquake?: Maybe<HazardhubScoreWithText>;
  femaAllFlood?: Maybe<HazardhubScoreWithText>;
  femaAllFloodParams?: Maybe<HazardhubFemaAllFloodParams>;
  femaClaims?: Maybe<HazardhubFemaClaims>;
  femaFirmDate?: Maybe<HazardhubFemaFirmDate>;
  femaFirmPanels?: Maybe<HazardhubFemaFirmPanels>;
  femaMapParams?: Maybe<HazardhubFemaMapParams>;
  fireProtection?: Maybe<HazardhubFireProtection>;
  fireSeasonPrecipitationParams?: Maybe<HazardhubFireSeasonPrecipitationParams>;
  fireStationType?: Maybe<HazardhubValueString>;
  forcibleRape?: Maybe<HazardhubScoreTextValue>;
  forcibleRobbery?: Maybe<HazardhubScoreTextValue>;
  frackingEarthquake?: Maybe<HazardhubScoreWithText>;
  frozenPipeIndex?: Maybe<HazardhubIndex>;
  groundSnowLoad?: Maybe<HazardhubGroundSnowLoad>;
  hailRingParams?: Maybe<HazardhubRingParams>;
  hardiness?: Maybe<HazardhubHardiness>;
  hazardhubCatastrophicFlood?: Maybe<HazardhubScoreWithText>;
  hazardhubCatastrophicFloodParams?: Maybe<HazardhubEnhancedHhFloodParams>;
  hazardhubElevation?: Maybe<HazardhubHhElevation>;
  hazardhubFpcCode?: Maybe<HazardhubValueString>;
  hazardhubSnowLoad?: Maybe<HazardhubHhSnowLoad>;
  hospital?: Maybe<HazardhubHospital>;
  hydrant?: Maybe<HazardhubScoreWithText>;
  hydrantParams?: Maybe<HazardhubHydrantParams>;
  iceDamIndex?: Maybe<HazardhubIndex>;
  landslide?: Maybe<HazardhubScoreWithText>;
  larceny?: Maybe<HazardhubScoreTextValue>;
  lavaFlow?: Maybe<HazardhubLavaFlow>;
  lomaParams?: Maybe<HazardhubLomaParams>;
  lomrParams?: Maybe<HazardhubLomrParams>;
  matchedAddress: HazardhubRiskStandartizedAddress;
  maxWindParams?: Maybe<HazardhubMaxWindParams>;
  mineSubsidence?: Maybe<HazardhubScoreWithText>;
  moldIndex?: Maybe<HazardhubIndex>;
  motorVehicleTheft?: Maybe<HazardhubScoreTextValue>;
  mudslideRisk?: Maybe<HazardhubMudslideRisk>;
  murder?: Maybe<HazardhubScoreTextValue>;
  nearestFireStations?: Maybe<Array<HazardhubNearestFireStation>>;
  nuclearSite?: Maybe<HazardhubScoreWithText>;
  numberOfFireStationsWithin5Miles?: Maybe<Scalars['Float']['output']>;
  numberOfFireStationsWithin10Miles?: Maybe<Scalars['Float']['output']>;
  perennialWater?: Maybe<HazardhubPerennialWater>;
  pfa?: Maybe<HazardhubPfa>;
  policeStations?: Maybe<HazardhubPoliceStations>;
  potentialCatastrophicPrecipitation?: Maybe<Scalars['String']['output']>;
  potentialMaximumPrecipitation?: Maybe<Scalars['String']['output']>;
  premiumTax?: Maybe<HazardhubPremiumTax>;
  premiumTaxParams?: Maybe<HazardhubPremiumTaxParams>;
  radon?: Maybe<HazardhubScoreWithText>;
  reTierCounties?: Maybe<HazardhubValueNumber>;
  schoolDistrict?: Maybe<HazardhubSchoolDistrict>;
  seaLevelRise?: Maybe<HazardhubSeaLevelRise>;
  sinkhole?: Maybe<HazardhubSinkhole>;
  sinkholeRingParams?: Maybe<HazardhubRingParams>;
  sinkholeRiskParams?: Maybe<HazardhubSinkholeRiskParams>;
  slopeRisk?: Maybe<HazardhubSlopeRisk>;
  soilShearVelocity?: Maybe<HazardhubSoilShearVelocity>;
  surgeMax?: Maybe<HazardhubSurgeMax>;
  tornadoRingParams?: Maybe<HazardhubRingParams>;
  tsunami?: Maybe<HazardhubScoreWithText>;
  urgentCare?: Maybe<HazardhubUrgentCare>;
  volcano?: Maybe<HazardhubScoreWithText>;
  volcanoNearest?: Maybe<HazardhubVolcanoNearest>;
  waterType?: Maybe<HazardhubValueString>;
  weatherParams?: Maybe<HazardhubWeatherParams>;
  wildfire?: Maybe<HazardhubScoreWithText>;
  wildfireDescription?: Maybe<HazardhubValueString>;
  wildfireDistanceToHighArea?: Maybe<HazardhubDistanceString>;
  wildfireHistoryParams?: Maybe<HazardhubWildfireHistoryParams>;
  wildfireHousingDensity?: Maybe<HazardhubValueNumber>;
  wildfireRisk?: Maybe<HazardhubValueString>;
  wildfireRiskScore?: Maybe<HazardhubWildfireRiskScore>;
  windBornDebris?: Maybe<HazardhubWindBornDebris>;
  windPool?: Maybe<HazardhubWindPool>;
  windRegion?: Maybe<HazardhubWindRegion>;
  windRingParams?: Maybe<HazardhubRingParams>;
};

export type HazardhubSchoolDistrict = {
  __typename?: 'HazardhubSchoolDistrict';
  name?: Maybe<Scalars['String']['output']>;
};

export type HazardhubScoreTextValue = {
  __typename?: 'HazardhubScoreTextValue';
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubScoreWithText = {
  __typename?: 'HazardhubScoreWithText';
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HazardhubSeaLevelRise = {
  __typename?: 'HazardhubSeaLevelRise';
  floodAt?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HazardhubSinkhole = {
  __typename?: 'HazardhubSinkhole';
  distance?: Maybe<HazardhubDistanceString>;
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HazardhubSinkholeRiskParams = {
  __typename?: 'HazardhubSinkholeRiskParams';
  climate?: Maybe<Scalars['String']['output']>;
  closedDepressionGrade?: Maybe<Scalars['String']['output']>;
  closedDepressionRisk?: Maybe<Scalars['String']['output']>;
  karstType?: Maybe<Scalars['String']['output']>;
  rocktype?: Maybe<Scalars['String']['output']>;
  sinkholeDistance?: Maybe<HazardhubDistanceString>;
  sinkholePointGrade?: Maybe<Scalars['String']['output']>;
  sinkholeSusceptibilityGrade?: Maybe<Scalars['String']['output']>;
  sinkholeSusceptibilityRisk?: Maybe<Scalars['String']['output']>;
};

export type HazardhubSlopeRisk = {
  __typename?: 'HazardhubSlopeRisk';
  description?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  slope?: Maybe<Scalars['String']['output']>;
};

export type HazardhubSoilShearVelocity = {
  __typename?: 'HazardhubSoilShearVelocity';
  units?: Maybe<Scalars['String']['output']>;
  vs30?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubSurgeMax = {
  __typename?: 'HazardhubSurgeMax';
  category?: Maybe<Scalars['String']['output']>;
  category1?: Maybe<Scalars['String']['output']>;
  category2?: Maybe<Scalars['String']['output']>;
  category3?: Maybe<Scalars['String']['output']>;
  category4?: Maybe<Scalars['String']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  desc1?: Maybe<Scalars['String']['output']>;
  desc2?: Maybe<Scalars['String']['output']>;
  desc3?: Maybe<Scalars['String']['output']>;
  desc4?: Maybe<Scalars['String']['output']>;
  potentialInundationLevel?: Maybe<Scalars['String']['output']>;
  potentialInundationLevel1?: Maybe<Scalars['String']['output']>;
  potentialInundationLevel2?: Maybe<Scalars['String']['output']>;
  potentialInundationLevel3?: Maybe<Scalars['String']['output']>;
  potentialInundationLevel4?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HazardhubUrgentCare = {
  __typename?: 'HazardhubUrgentCare';
  address?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type HazardhubValuation = {
  __typename?: 'HazardhubValuation';
  priceRangeMax?: Maybe<Scalars['Float']['output']>;
  priceRangeMin?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubValueNumber = {
  __typename?: 'HazardhubValueNumber';
  value?: Maybe<Scalars['String']['output']>;
};

export type HazardhubValueString = {
  __typename?: 'HazardhubValueString';
  value?: Maybe<Scalars['String']['output']>;
};

export type HazardhubVegetationBurnSiteRisk = {
  __typename?: 'HazardhubVegetationBurnSiteRisk';
  desc?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  withinHalfMile?: Maybe<Scalars['Float']['output']>;
  withinOneMile?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubVolcanoNearest = {
  __typename?: 'HazardhubVolcanoNearest';
  volcanoDistance?: Maybe<HazardhubDistanceString>;
  volcanoElev?: Maybe<HazardhubValueString>;
  volcanoName?: Maybe<HazardhubValueString>;
  volcanoStatus?: Maybe<HazardhubValueString>;
  volcanoType?: Maybe<HazardhubValueString>;
};

export type HazardhubWeatherParams = {
  __typename?: 'HazardhubWeatherParams';
  annualAverageDaysLessThan0?: Maybe<Scalars['String']['output']>;
  annualAverageDaysLessThan10?: Maybe<Scalars['String']['output']>;
  annualAverageDaysLessThan20?: Maybe<Scalars['String']['output']>;
  annualAverageDaysMoreThan40?: Maybe<Scalars['String']['output']>;
  annualAverageDaysMoreThan50?: Maybe<Scalars['String']['output']>;
  annualFallDaysLessThan0?: Maybe<Scalars['String']['output']>;
  annualFallDaysLessThan10?: Maybe<Scalars['String']['output']>;
  annualFallDaysLessThan20?: Maybe<Scalars['String']['output']>;
  annualFallDaysLessThan32?: Maybe<Scalars['String']['output']>;
  annualSpringDaysLessThan0?: Maybe<Scalars['String']['output']>;
  annualSpringDaysLessThan10?: Maybe<Scalars['String']['output']>;
  annualSpringDaysLessThan20?: Maybe<Scalars['String']['output']>;
  annualSpringDaysLessThan32?: Maybe<Scalars['String']['output']>;
  annualWinterDaysLessThan0?: Maybe<Scalars['String']['output']>;
  annualWinterDaysLessThan10?: Maybe<Scalars['String']['output']>;
  annualWinterDaysLessThan20?: Maybe<Scalars['String']['output']>;
  annualWinterDaysLessThan40?: Maybe<Scalars['String']['output']>;
  averageAnnualPrecipitation?: Maybe<Scalars['String']['output']>;
  averageAnnualSnowfall?: Maybe<Scalars['String']['output']>;
  averageAnnualTemperatureMax?: Maybe<Scalars['String']['output']>;
  averageAnnualTemperatureMin?: Maybe<Scalars['String']['output']>;
  averageDaysSnowfallGreaterThan1Inch?: Maybe<Scalars['String']['output']>;
  averageDaysSnowfallGreaterThan10Inches?: Maybe<Scalars['String']['output']>;
  averageFallSnowfall?: Maybe<Scalars['String']['output']>;
  averageSpringSnowfall?: Maybe<Scalars['String']['output']>;
  averageWinterSnowfall?: Maybe<Scalars['String']['output']>;
  avgDaysSnowDepthAbove10In?: Maybe<Scalars['String']['output']>;
  avgDaysSnowfallAbove1In?: Maybe<Scalars['String']['output']>;
  avgNumDaysBelow32Degrees?: Maybe<Scalars['String']['output']>;
  avgNumWinterDaysBelow32Degrees?: Maybe<Scalars['String']['output']>;
  coolingDegreeDays?: Maybe<Scalars['String']['output']>;
  fallDaysSnowDepthGreaterThan1Inch?: Maybe<Scalars['String']['output']>;
  fallDaysSnowDepthGreaterThan3Inches?: Maybe<Scalars['String']['output']>;
  fallDaysSnowDepthGreaterThan5Inches?: Maybe<Scalars['String']['output']>;
  fallDaysSnowDepthGreaterThan10Inches?: Maybe<Scalars['String']['output']>;
  fallDaysWithMaxTempLessThan32?: Maybe<Scalars['String']['output']>;
  fallDiurnalRange?: Maybe<Scalars['String']['output']>;
  heatingDegreeDays?: Maybe<Scalars['String']['output']>;
  springDaysSnowDepthGreaterThan1Inch?: Maybe<Scalars['String']['output']>;
  springDaysSnowDepthGreaterThan3Inches?: Maybe<Scalars['String']['output']>;
  springDaysSnowDepthGreaterThan5Inches?: Maybe<Scalars['String']['output']>;
  springDaysSnowDepthGreaterThan10Inches?: Maybe<Scalars['String']['output']>;
  springDaysWithMaxTempLessThan32?: Maybe<Scalars['String']['output']>;
  springDiurnalRange?: Maybe<Scalars['String']['output']>;
  winterDaysSnowDepthGreaterThan1Inches?: Maybe<Scalars['String']['output']>;
  winterDaysSnowDepthGreaterThan3Inches?: Maybe<Scalars['String']['output']>;
  winterDaysSnowDepthGreaterThan5Inches?: Maybe<Scalars['String']['output']>;
  winterDaysSnowDepthGreaterThan10Inches?: Maybe<Scalars['String']['output']>;
  winterDaysWithMaxTempLessThan32?: Maybe<Scalars['String']['output']>;
  winterDiurnalRange?: Maybe<Scalars['String']['output']>;
};

export type HazardhubWildfireHistoryParams = {
  __typename?: 'HazardhubWildfireHistoryParams';
  numberOfWildfires?: Maybe<Scalars['Float']['output']>;
  numberOfWildfiresNear?: Maybe<Scalars['Float']['output']>;
  risk?: Maybe<Scalars['String']['output']>;
  yearName?: Maybe<Scalars['String']['output']>;
  yearNameDistance?: Maybe<Scalars['String']['output']>;
};

export type HazardhubWildfirePerimeterRisk = {
  __typename?: 'HazardhubWildfirePerimeterRisk';
  desc?: Maybe<Scalars['String']['output']>;
  proximateWildfirePerimeters?: Maybe<Array<Scalars['String']['output']>>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type HazardhubWildfireRiskScore = {
  __typename?: 'HazardhubWildfireRiskScore';
  composite?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type HazardhubWindBornDebris = {
  __typename?: 'HazardhubWindBornDebris';
  score?: Maybe<Scalars['String']['output']>;
  terrain?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  year2001?: Maybe<HazardhubScoreWithText>;
  year2007?: Maybe<HazardhubScoreWithText>;
  year2010?: Maybe<HazardhubScoreWithText>;
  year2014?: Maybe<HazardhubScoreWithText>;
};

export type HazardhubWindPool = {
  __typename?: 'HazardhubWindPool';
  name?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HazardhubWindRegion = {
  __typename?: 'HazardhubWindRegion';
  score?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  text2?: Maybe<Scalars['String']['output']>;
};

export enum InclusionDateType {
  /** By policy expiration (end or cancel) date */
  Expiration = 'EXPIRATION',
  /** By policy effective (start) date */
  Inception = 'INCEPTION',
  /** By transaction dates */
  Transaction = 'TRANSACTION',
}

export type Industries = {
  __typename?: 'Industries';
  /**
   * Returns only low-level industries
   * @deprecated Use paginated instead
   */
  list: Array<Industry>;
  /** Returns only low-level industries */
  paginated: IndustryPaginatedResult;
  /** Returns all industries groups with children */
  tree: Array<Industry>;
};

export type IndustriesListArgs = {
  pagination?: InputMaybe<IndustryListPaginationInput>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type IndustriesPaginatedArgs = {
  pagination?: InputMaybe<IndustryListPaginationInput>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type Industry = {
  __typename?: 'Industry';
  children?: Maybe<Array<Industry>>;
  id: Scalars['ID']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  level: IndustryLevel;
  metrics?: Maybe<EntityMetrics>;
  naics: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<Industry>;
  /** @deprecated use sicCodes */
  sic?: Maybe<Array<Scalars['String']['output']>>;
  sicCodes?: Maybe<Array<SicCode>>;
};

/** Industries inside one IndustryFilter are filtered using OR logic */
export type IndustryFilter = {
  industryGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  industryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  industrySubGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IndustryFilterOutput = {
  __typename?: 'IndustryFilterOutput';
  industryGroupIds?: Maybe<Array<Scalars['String']['output']>>;
  industryIds?: Maybe<Array<Scalars['String']['output']>>;
  industrySubGroupIds?: Maybe<Array<Scalars['String']['output']>>;
};

export enum IndustryLevel {
  Group = 'GROUP',
  Industry = 'INDUSTRY',
  SubGroup = 'SUB_GROUP',
}

export type IndustryListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<IndustrySortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export type IndustryPaginatedResult = {
  __typename?: 'IndustryPaginatedResult';
  items: Array<Industry>;
  totalHits: Scalars['Int']['output'];
};

export type IndustryRank = {
  __typename?: 'IndustryRank';
  filter?: Maybe<Scalars['String']['output']>;
  item: Industry;
  metric?: Maybe<Scalars['Float']['output']>;
  metricGrowth?: Maybe<Scalars['Float']['output']>;
  metricGrowthPct?: Maybe<Scalars['Float']['output']>;
  metricPrevious?: Maybe<Scalars['Float']['output']>;
  metricType?: Maybe<Scalars['String']['output']>;
  premium?: Maybe<Growth>;
  rank?: Maybe<Scalars['Int']['output']>;
  /** null represents that previous rank is more than 1000 or entity is not exists in previous period. Also, rankGrowth for rankByGrowth is not provided. */
  rankGrowth?: Maybe<Scalars['Int']['output']>;
  revenue?: Maybe<Growth>;
};

export type IndustryRankConnection = {
  __typename?: 'IndustryRankConnection';
  edges: Array<IndustryRankEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type IndustryRankEdge = {
  __typename?: 'IndustryRankEdge';
  cursor: Scalars['String']['output'];
  filter?: Maybe<Scalars['String']['output']>;
  metricType?: Maybe<Scalars['String']['output']>;
  node: IndustryRank;
};

export enum IndustrySortingField {
  Commission = 'COMMISSION',
  Id = 'ID',
  Name = 'NAME',
  Premium = 'PREMIUM',
  Revenue = 'REVENUE',
}

export enum InsuranceCompanyMappingSortBy {
  AdminCompanyName = 'ADMIN_COMPANY_NAME',
  AdminCompanyParentName = 'ADMIN_COMPANY_PARENT_NAME',
  AdminCompanyPrimaryRole = 'ADMIN_COMPANY_PRIMARY_ROLE',
  AdminCompanyUltimateParentName = 'ADMIN_COMPANY_ULTIMATE_PARENT_NAME',
  ExternalSystemUiLabel = 'EXTERNAL_SYSTEM_UI_LABEL',
  Id = 'ID',
  LinkedWithAdminCompany = 'LINKED_WITH_ADMIN_COMPANY',
  MappingCompanyType = 'MAPPING_COMPANY_TYPE',
  MappingName = 'MAPPING_NAME',
  MappingRegion = 'MAPPING_REGION',
  NumberOfPolicies = 'NUMBER_OF_POLICIES',
}

export enum InsuranceCompanyRelationshipType {
  Carrier = 'CARRIER',
  Intermediary = 'INTERMEDIARY',
}

export type InsuranceCompanySearchMapping = {
  __typename?: 'InsuranceCompanySearchMapping';
  companyName?: Maybe<Scalars['String']['output']>;
  companyType?: Maybe<InsuranceCompanyType>;
  externalSystemLabel: Scalars['String']['output'];
  id: Scalars['String']['output'];
  linkedWithAdmin: Scalars['Boolean']['output'];
  manualChangeInProgress: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parentName?: Maybe<Scalars['String']['output']>;
  policyCount: Scalars['Float']['output'];
  primaryRole?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  ultimateParentName?: Maybe<Scalars['String']['output']>;
};

export type InsuranceCompanySearchMappingPagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<InsuranceCompanyMappingSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type InsuranceCompanySearchMappingResponse = {
  __typename?: 'InsuranceCompanySearchMappingResponse';
  items: Array<InsuranceCompanySearchMapping>;
  totalHits: Scalars['Int']['output'];
};

export enum InsuranceCompanyType {
  Broker = 'BROKER',
  Company = 'COMPANY',
}

export type IntegrationLimitInfo = {
  __typename?: 'IntegrationLimitInfo';
  calls: Scalars['Float']['output'];
  limit: Scalars['Float']['output'];
};

export type IntegrationResponseFavoritesStatus = {
  __typename?: 'IntegrationResponseFavoritesStatus';
  isFavorite: Scalars['Boolean']['output'];
  updated?: Maybe<Scalars['Timestamp']['output']>;
};

export enum IntegrationServiceName {
  Dnb = 'DNB',
  Fenris = 'FENRIS',
  Hazardhub = 'HAZARDHUB',
  RocketReach = 'ROCKET_REACH',
}

export type Integrations = {
  __typename?: 'Integrations';
  companies: Array<DnbSuggestion>;
  companyById: DnbCompany;
  companyByIndustry: DnbCompaniesByIndustryResponse;
  dolCompanies: DolCompanySearchResult;
  dolCompanyByFein?: Maybe<DolCompany>;
  /** @deprecated Use dolCompanies instead */
  dolCompanySearch: DolCompanySearchResult;
  form5500ByFein: Array<Form5500>;
  form5500Search: Form5500SearchResponse;
  /** Overall limit for hazardhub */
  hazardhubLimit: IntegrationLimitInfo;
  home?: Maybe<HazardhubResponse>;
  homeCached?: Maybe<HazardhubCachedResponse>;
  isIntegrationResponseInFavorites: IntegrationResponseFavoritesStatus;
  /** Monthly limit for all services */
  monthlyLimit: IntegrationLimitInfo;
  people: Array<PiplSuggestion>;
  personById: PiplPerson;
  rocketReachPeople: RocketReachSearchResult;
  rocketReachPersonById?: Maybe<RocketReachPerson>;
  rocketReachPersonByIdCached?: Maybe<RocketReachPersonCached>;
  searchClient: Array<ClientSearchItemResponse>;
};

export type IntegrationsCompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  request: DnbCompanyRequest;
};

export type IntegrationsCompanyByIdArgs = {
  id: Scalars['ID']['input'];
};

export type IntegrationsCompanyByIndustryArgs = {
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  request: DnbCompaniesByIndustryRequest;
  sortBy?: InputMaybe<DnbCriteriaSearchSorting>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type IntegrationsDolCompaniesArgs = {
  pagination?: InputMaybe<SimplePagination>;
  request: DolCompanySearchRequest;
};

export type IntegrationsDolCompanyByFeinArgs = {
  fein: Scalars['String']['input'];
};

export type IntegrationsDolCompanySearchArgs = {
  request: Form5500SearchRequest;
};

export type IntegrationsForm5500ByFeinArgs = {
  fein: Scalars['String']['input'];
};

export type IntegrationsForm5500SearchArgs = {
  pagination?: InputMaybe<Form5500SearchPagination>;
  request: Form5500SearchRequest;
};

export type IntegrationsHomeArgs = {
  request: HazardhubRequest;
};

export type IntegrationsHomeCachedArgs = {
  request: HazardhubRequest;
};

export type IntegrationsIsIntegrationResponseInFavoritesArgs = {
  integrationResponseId: Scalars['ID']['input'];
  service: IntegrationServiceName;
};

export type IntegrationsPeopleArgs = {
  request: PiplPersonRequest;
};

export type IntegrationsPersonByIdArgs = {
  id: Scalars['ID']['input'];
};

export type IntegrationsRocketReachPeopleArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query: RocketReachQuery;
};

export type IntegrationsRocketReachPersonByIdArgs = {
  id: Scalars['ID']['input'];
};

export type IntegrationsRocketReachPersonByIdCachedArgs = {
  id: Scalars['ID']['input'];
};

export type IntegrationsSearchClientArgs = {
  request: Array<ClientSearchRequest>;
};

export enum IntermediaryLevel {
  ChildIntermediary = 'CHILD_INTERMEDIARY',
  ParentIntermediary = 'PARENT_INTERMEDIARY',
  UltimateParentIntermediary = 'ULTIMATE_PARENT_INTERMEDIARY',
}

export type KpiDashboard = {
  __typename?: 'KpiDashboard';
  created: Scalars['Timestamp']['output'];
  id: Scalars['String']['output'];
  sections: Array<KpiDashboardSection>;
  title: Scalars['String']['output'];
  updated: Scalars['Timestamp']['output'];
};

export type KpiDashboardCreateInput = {
  sections: Array<KpiDashboardSectionCreateInput>;
  title: Scalars['String']['input'];
};

export type KpiDashboardSection = {
  __typename?: 'KpiDashboardSection';
  collapsed: Scalars['Boolean']['output'];
  created: Scalars['Timestamp']['output'];
  id: Scalars['String']['output'];
  kpiList: Array<UserKpi>;
  title: Scalars['String']['output'];
  updated: Scalars['Timestamp']['output'];
};

export type KpiDashboardSectionCreateInput = {
  collapsed: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  kpiList: Array<UserKpiDashboardCreateKpiInput>;
  title: Scalars['String']['input'];
};

export type KpiDashboardUpdateInput = {
  id: Scalars['String']['input'];
  sections: Array<KpiDashboardSectionCreateInput>;
  title: Scalars['String']['input'];
};

export enum LeaderboardReportEntity {
  Carrier = 'CARRIER',
  Department = 'DEPARTMENT',
  Industry = 'INDUSTRY',
  IndustryGroup = 'INDUSTRY_GROUP',
  IndustrySubgroup = 'INDUSTRY_SUBGROUP',
  Producer = 'PRODUCER',
  Product = 'PRODUCT',
}

export type Lifetime = {
  __typename?: 'Lifetime';
  metrics: LifetimeValueMetrics;
  paginatedClients: LifetimeAccountsPaginatedResult;
  paginatedIndustries: LifetimeIndustriesPaginatedResult;
};

export type LifetimeMetricsArgs = {
  filter: PolicyFilter;
};

export type LifetimePaginatedClientsArgs = {
  filter: PolicyFilter;
  pagination?: InputMaybe<LifetimeAccountsPaginationInput>;
};

export type LifetimePaginatedIndustriesArgs = {
  filter: PolicyFilter;
  pagination?: InputMaybe<LifetimeIndustriesPaginationInput>;
};

export type LifetimeAccount = {
  __typename?: 'LifetimeAccount';
  /** Account ID */
  accountId?: Maybe<Scalars['String']['output']>;
  /** Account lifetime revenue */
  accountLifetimeRevenue: Scalars['Float']['output'];
  /** Account name */
  accountName?: Maybe<Scalars['String']['output']>;
  /** Gross margin for the account (0-1) */
  grossMargin: Scalars['Float']['output'];
  /** Industry ID */
  industryId?: Maybe<Scalars['String']['output']>;
  /** Industry level */
  industryLevel?: Maybe<Scalars['String']['output']>;
  /** Industry name */
  industryName?: Maybe<Scalars['String']['output']>;
  /** Industry NAICS */
  naics?: Maybe<Scalars['String']['output']>;
  /** Total costs for the account */
  totalCosts: Scalars['Float']['output'];
};

export type LifetimeAccountsPaginatedResult = {
  __typename?: 'LifetimeAccountsPaginatedResult';
  items: Array<LifetimeAccount>;
  totalHits: Scalars['Int']['output'];
};

export type LifetimeAccountsPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy: LifetimeAccountsSortingField;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export enum LifetimeAccountsSortingField {
  AccountLifetimeRevenue = 'ACCOUNT_LIFETIME_REVENUE',
  GrossMargin = 'GROSS_MARGIN',
  TotalCosts = 'TOTAL_COSTS',
}

export type LifetimeIndustriesPaginatedResult = {
  __typename?: 'LifetimeIndustriesPaginatedResult';
  items: Array<LifetimeIndustry>;
  totalHits: Scalars['Int']['output'];
};

export type LifetimeIndustriesPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy: LifetimeIndustriesSortingField;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export enum LifetimeIndustriesSortingField {
  AvgAccountLifetimeRevenue = 'AVG_ACCOUNT_LIFETIME_REVENUE',
  AvgTotalCosts = 'AVG_TOTAL_COSTS',
  ChurnRate = 'CHURN_RATE',
  GrossMargin = 'GROSS_MARGIN',
  LifetimeValue = 'LIFETIME_VALUE',
}

export type LifetimeIndustry = {
  __typename?: 'LifetimeIndustry';
  /** Avg by account lifetime revenue */
  avgAccountLifetimeRevenue: Scalars['Float']['output'];
  /** Avg by account total cost */
  avgTotalCosts: Scalars['Float']['output'];
  /** Churn rate by account (0-1) */
  churnRate: Scalars['Float']['output'];
  /** Gross margin for the industry (0-1) */
  grossMargin: Scalars['Float']['output'];
  /** Industry ID */
  industryId: Scalars['String']['output'];
  /** Industry level */
  industryLevel: Scalars['String']['output'];
  /** Industry name */
  industryName: Scalars['String']['output'];
  /** Account LTV by industry */
  lifetimeValue: Scalars['Float']['output'];
  /** Industry NAICS */
  naics?: Maybe<Scalars['String']['output']>;
};

/** Lifetime metrics for multiple accounts */
export type LifetimeValueMetrics = {
  __typename?: 'LifetimeValueMetrics';
  /** Avg lifetime revenue by accounts */
  avgLifetimeRevenue: Scalars['Float']['output'];
  /** Avg by costs for accounts */
  avgTotalCost: Scalars['Float']['output'];
  /** Churn rate for accounts (0-1) */
  churnRate: Scalars['Float']['output'];
  /** Gross margin for accounts (0-1) */
  grossMargin: Scalars['Float']['output'];
  /** LTV for accounts */
  lifetimeValue: Scalars['Float']['output'];
  /** Total account(s) costs */
  totalCost: Scalars['Float']['output'];
  /** Sum lifetime revenue by accounts */
  totalLifetimeRevenue: Scalars['Float']['output'];
};

export type ListsOfSavedOpportunityUpdateResult = {
  __typename?: 'ListsOfSavedOpportunityUpdateResult';
  updated: Array<SavedOpportunity>;
};

/** Use OR logic between counties/states. Postal codes must be provided if radius is used */
export type LocationFilter = {
  counties?: InputMaybe<Array<Scalars['String']['input']>>;
  postalCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  postalCodesRadius?: InputMaybe<LocationRadius>;
  states?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LocationFilterOutput = {
  __typename?: 'LocationFilterOutput';
  counties?: Maybe<Array<Scalars['String']['output']>>;
  postalCodes?: Maybe<Array<Scalars['String']['output']>>;
  postalCodesRadius?: Maybe<LocationRadiusOutput>;
  states?: Maybe<Array<Scalars['String']['output']>>;
};

export type LocationRadius = {
  /** Search radius around specified location */
  radius: Scalars['Float']['input'];
  /** Unit of measurement for the radius */
  unit: LocationRadiusUnit;
};

export type LocationRadiusOutput = {
  __typename?: 'LocationRadiusOutput';
  /** Search radius around specified location */
  radius: Scalars['Float']['output'];
  /** Unit of measurement for the radius */
  unit: LocationRadiusUnit;
};

export enum LocationRadiusUnit {
  Kilometers = 'KILOMETERS',
  Miles = 'MILES',
}

export enum LossRunFolderStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  InUploading = 'IN_UPLOADING',
  ReadyForProcessing = 'READY_FOR_PROCESSING',
  Success = 'SUCCESS',
}

export enum LossRunStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS',
}

export type LossRuns = {
  __typename?: 'LossRuns';
  dataFrameDownloadUrl?: Maybe<Scalars['String']['output']>;
  filesList: Array<LossRunsFile>;
  folderData: LossRunsFolder;
  foldersList: Array<LossRunsFolder>;
};

export type LossRunsDataFrameDownloadUrlArgs = {
  folderId: Scalars['String']['input'];
};

export type LossRunsFilesListArgs = {
  folderId: Scalars['String']['input'];
};

export type LossRunsFolderDataArgs = {
  folderId: Scalars['String']['input'];
};

export type LossRunsFile = {
  __typename?: 'LossRunsFile';
  /** Optionally provided if status === LossRunsStatus.FAILED */
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: LossRunStatus;
  uploadedAt: Scalars['Timestamp']['output'];
  url: Scalars['String']['output'];
};

export type LossRunsFileUpload = {
  __typename?: 'LossRunsFileUpload';
  uploadUrl: Scalars['String']['output'];
};

export type LossRunsFolder = {
  __typename?: 'LossRunsFolder';
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** Exists when status is SUCCESS */
  reportDownloadUrl?: Maybe<Scalars['String']['output']>;
  status: LossRunFolderStatus;
};

export type Market = {
  __typename?: 'Market';
  adminCompany?: Maybe<AdminCompany>;
  availableViewModes: Array<CompanyViewType>;
  children: Array<Market>;
  /**
   * Possible values: 1, 2, 3. The Issuing Paper level has value 1.
   * Numeration from Issuing Paper to Ultimate
   */
  companyLevel: Scalars['Int']['output'];
  companyStatus: BrokerMarketStatus;
  id: Scalars['ID']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  metrics?: Maybe<EntityMetrics>;
  mode?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parent?: Maybe<Market>;
  /** @deprecated Use availableViewModes instead */
  primaryRole?: Maybe<CompanyPrimaryRole>;
  rating?: Maybe<CompanyRating>;
  ultimateParent?: Maybe<Market>;
};

export type MarketChildrenArgs = {
  primaryRole?: InputMaybe<CompanyPrimaryRole>;
  viewMode?: InputMaybe<CompanyViewType>;
};

export type MarketListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<CarrierSortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export type MarketPaginatedResult = {
  __typename?: 'MarketPaginatedResult';
  items: Array<Market>;
  totalHits: Scalars['Int']['output'];
};

export type Markets = {
  __typename?: 'Markets';
  list: Array<Market>;
  /** @deprecated Please use `list` instead */
  paginated: MarketPaginatedResult;
};

export type MarketsListArgs = {
  primaryRole?: InputMaybe<CompanyPrimaryRole>;
  viewMode?: InputMaybe<CompanyViewType>;
};

export type MarketsPaginatedArgs = {
  pagination?: InputMaybe<MarketListPaginationInput>;
  primaryRole?: InputMaybe<CompanyPrimaryRole>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type MonolineListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<MonolineSortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export type MonolineOpportunity = {
  __typename?: 'MonolineOpportunity';
  accountManager?: Maybe<User>;
  carrier?: Maybe<Market>;
  client: Client;
  commission: Scalars['Float']['output'];
  commissionPct?: Maybe<Scalars['Float']['output']>;
  /** the date that last policy of the client was expired */
  endDate: Scalars['SimpleDate']['output'];
  id: Scalars['ID']['output'];
  industry?: Maybe<Industry>;
  industryGroup?: Maybe<Industry>;
  policy?: Maybe<Policy>;
  premium: Scalars['Float']['output'];
  /** @deprecated use producers */
  producer?: Maybe<User>;
  producers?: Maybe<Array<User>>;
  product?: Maybe<Product>;
  reportId?: Maybe<Scalars['String']['output']>;
  revenue: Scalars['Float']['output'];
  source: OpportunitySourceType;
  /** the start date of the monoline policy */
  startDate: Scalars['SimpleDate']['output'];
};

export type MonolineOpportunityPaginatedResult = {
  __typename?: 'MonolineOpportunityPaginatedResult';
  items: Array<MonolineOpportunity>;
  totalHits: Scalars['Int']['output'];
};

export type MonolineOpportunityV2 = {
  __typename?: 'MonolineOpportunityV2';
  id: Scalars['ID']['output'];
  policy: Policy;
  saved?: Maybe<SavedOpportunityMonoline>;
};

export type MonolineOpportunityV2PaginatedResult = {
  __typename?: 'MonolineOpportunityV2PaginatedResult';
  items: Array<MonolineOpportunityV2>;
  totalHits: Scalars['Int']['output'];
};

export enum MonolineSortingField {
  AccountManager = 'ACCOUNT_MANAGER',
  Client = 'CLIENT',
  EndDate = 'END_DATE',
  Industry = 'INDUSTRY',
  Producer = 'PRODUCER',
  Product = 'PRODUCT',
  Revenue = 'REVENUE',
}

export type Month = {
  __typename?: 'Month';
  date: Scalars['Timestamp']['output'];
  isoString: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addIntegrationResponseToFavorites: AddResponseToFavoritesPayload;
  addOpportunitiesToList: SavedOpportunitiesPutResult;
  addOpportunityList: OpportunityList;
  addSavedPage: SavedPage;
  addSavedPages: SaveManyPagesResult;
  createAggregatedCustomReport: UserReport;
  /** @deprecated deprecated */
  createCompanyProspect: CreateCompanyProspectPayload;
  /** @deprecated deprecated */
  createDolCompanyProspect: CreateDolCompanyProspectPayload;
  /** @deprecated deprecated */
  createIndividualProspect: CreateIndividualProspectPayload;
  createKpiDashboard: KpiDashboard;
  createPlainCustomReport: UserReport;
  createReminder: Reminder;
  createUserKpi: UserKpiCreateResponse;
  createUserReportPreset: CreateUserReportPresetPayload;
  /** @deprecated outdated, use Growth / SavedOpportunities */
  deleteAllCrossSellOpportunities: DeleteCrossSellOpportunityPayload;
  /** @deprecated outdated, use Growth / SavedOpportunities */
  deleteCrossSellOpportunities: DeleteCrossSellOpportunityPayload;
  deleteFilter: StoredFiltersDeleteResponse;
  deleteKpiDashboard: KpiDashboard;
  deleteOpportunityList: DeleteOpportunityListPayload;
  /** @deprecated deprecated */
  deleteOpportunityReport: DeleteOpportunityReportPayload;
  deletePermanentlySavedOpportunities: SavedOpportunitiesPermanentlyDeleteResult;
  /** @deprecated Not needed anymore */
  deleteProspect: DeleteProspectPayload;
  /** @deprecated deprecated */
  deleteProspectsByIds: DeleteProspectPayload;
  deleteReminders: DeleteRemindersPayload;
  deleteSavedOpportunities: SavedOpportunitiesPutResult;
  deleteSavedOpportunityItem: SavedOpportunitiesPutOneResult;
  deleteSavedPage: DeleteSavedPagePayload;
  deleteUserKpi: UserKpiDeleteResponse;
  deleteUserReportPreset: DeleteUserReportPresetPayload;
  documentProcessingCreateFolder: LossRunsFolder;
  documentProcessingDeleteFolder: Scalars['Boolean']['output'];
  documentProcessingRun: LossRunsFolder;
  documentProcessingUploadFile: LossRunsFileUpload;
  downloadAccountGrowthReport: UserReport;
  downloadAccountLeaderboardReport: UserReport;
  downloadAccountLostReport: UserReport;
  downloadAccountManagerAccountBookProfile: UserReport;
  downloadAccountManagerAccountSizeReport: UserReport;
  downloadAccountManagerProducerSupportReport: UserReport;
  downloadAccountManagerRenewalReport: UserReport;
  downloadAccountManagerReport: UserReport;
  downloadAccountManagerTopCarriersReport: UserReport;
  downloadAccountManagerTopIndustriesReport: UserReport;
  downloadAccountManagerTopProductsReport: UserReport;
  downloadAccountRetentionReport: UserReport;
  downloadAvgAccountSizeReport: UserReport;
  downloadCarrierAppetiteReport: UserReport;
  downloadCarrierDowngradesReport: UserReport;
  downloadCarrierPDFReport: UserReport;
  downloadClientNaicsReport: UserReport;
  /** @deprecated deprecated */
  downloadCrossSellOpportunityCsvReport: UserReport;
  downloadDataAuditReport: UserReport;
  /** @deprecated use downloadLeaderboardReportV2 */
  downloadLeaderboardReport: UserReport;
  downloadLeaderboardReportV2: UserReport;
  downloadLocationPDFReport: UserReport;
  /** @deprecated deprecated */
  downloadMonolineOpportunityCsvReport: UserReport;
  downloadOpportunityRecommendationDnbData?: Maybe<DnbCompany>;
  /** @deprecated deprecated */
  downloadOpportunityReport: DownloadOpportunityReportPayload;
  downloadOutflowInflowReport: UserReport;
  /** @deprecated use downloadPolicyXlsxReport */
  downloadPolicyCsvReport: UserReport;
  downloadPolicyXlsxReport: UserReport;
  downloadPremiumAccountSizeReport: UserReport;
  downloadPremiumFlowReport: UserReport;
  downloadProducerCreditReport: UserReport;
  downloadProducerTopIndustryReport: UserReport;
  downloadProducerTopProductReport: UserReport;
  downloadProductionCreditXlsxReport: UserReport;
  /** @deprecated deprecated */
  downloadProspectsReport: UserReport;
  /** @deprecated use downloadRetentionXLSXReport */
  downloadRetentionCsvReport: UserReport;
  downloadRetentionInsightsReport: UserReport;
  downloadRetentionXLSXReport: UserReport;
  downloadRoiCrossSellReport: UserReport;
  downloadSavedOpportunitiesReport: UserReport;
  downloadScore360ClientsReport: UserReport;
  downloadScore360ProducersReport: UserReport;
  /** @deprecated deprecated */
  downloadTargetIndustryOpportunityCsvReport: UserReport;
  downloadUserReportByGroupId: UserReport;
  downloadUserReportByPresetId: UserReport;
  /** @deprecated deprecated */
  downloadWinBackOpportunityCsvReport: UserReport;
  generateCrossSellReport: OpportunityCrossSellReport;
  getTableauSecret: TableauToken;
  insuranceCompanyMappingSearchUsedAdminCompanies: EnrichmentProcessingTokenResponse;
  insuranceCompanyMappingSelectSuggestion: EnrichmentProcessingTokenResponse;
  lifetimeMultipleAccountsReport: UserReport;
  lifetimeMultipleIndustriesReport: UserReport;
  lifetimeSingleAccountReport: UserReport;
  productMappingOverride: EnrichmentProcessingTokenResponse;
  productMappingSelectSuggestion: EnrichmentProcessingTokenResponse;
  productMappingUpdateTree: EnrichmentProcessingTokenResponse;
  removeIntegrationResponseFromFavorites: RemoveResponseFromFavoritesPayload;
  removeOpportunitiesFromList: SavedOpportunitiesPutResult;
  /** @deprecated deprecated */
  renameOpportunityReport: RenameOpportunityReportPayload;
  reorderUserKpi: UserKpiReorderResponse;
  reportLossRunIssue: Scalars['Boolean']['output'];
  restoreSavedOpportunities: SavedOpportunitiesPutResult;
  /** @deprecated outdated, use Growth / SavedOpportunities */
  saveCrossSellOpportunities: CreateCrossSellOpportunityPayload;
  saveFilter: StoredFiltersCreateResponse;
  /** @deprecated deprecated */
  saveOpportunityReport: SaveOpportunityReportPayload;
  savePageFilter: PageSettings;
  saveToListClientOpportunities: SavedOpportunitiesPutResult;
  saveToListCrossSellByReportId: SavedOpportunitiesCountPutResult;
  saveToListDnbNetNewOpportunities: SavedOpportunitiesPutResult;
  saveToListDolCompanies: SavedOpportunitiesPutResult;
  saveToListHazardhubAddress: SavedOpportunitiesPutOneResult;
  saveToListNetNewOpportunityRecommendations: SavedOpportunitiesPutResult;
  saveToListRenewalsByFilter: SavedOpportunitiesCountPutResult;
  saveToListRocketReachNetNewOpportunities: SavedOpportunitiesPutResult;
  saveToListTopCrossSellRecommendations: SavedOpportunitiesCountPutResult;
  saveToListWinBackByFilter: SavedOpportunitiesCountPutResult;
  saveUserPageSettings: PageSettings;
  setCompanyStatus: BrokerMarketStatusUpdateResult;
  setCrossSellProducerGoal: UpdateProducerGoalPayload;
  setCurrentKpiDashboard: Scalars['Boolean']['output'];
  setHazardhubCachedIdToClient: SetHazardhubCachedIdResponse;
  setNetNewProducerGoal: UpdateProducerGoalPayload;
  setRetentionProducerGoal: UpdateProducerGoalPayload;
  setRocketReachIdToClient: SetRocketReachIdResponse;
  setUserTimeZoneSettings: Scalars['Boolean']['output'];
  updateFilter: StoredFiltersUpdateResponse;
  updateFilterLastUsed: StoredFiltersUpdateLastUserResponse;
  updateKpiDashboard: KpiDashboard;
  updateListsOfOpportunities: ListsOfSavedOpportunityUpdateResult;
  updateOpportunityList: OpportunityList;
  updatePeriodUserKpi: UserKpiUpdateResponse;
  updateUserReportPreset: UpdateUserReportPresetPayload;
  updateUserReportPresetSchedule: UpdateUserReportPresetPayload;
};

export type MutationAddIntegrationResponseToFavoritesArgs = {
  integrationResponseId: Scalars['ID']['input'];
  policyId?: InputMaybe<Scalars['ID']['input']>;
  service: IntegrationServiceName;
};

export type MutationAddOpportunitiesToListArgs = {
  listId: Scalars['ID']['input'];
  opportunityIds: Array<Scalars['ID']['input']>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAddOpportunityListArgs = {
  opportunityList: OpportunityListCreateInput;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAddSavedPageArgs = {
  savedPage: SavedPageInput;
};

export type MutationAddSavedPagesArgs = {
  savedPages: Array<SavedPageInput>;
};

export type MutationCreateAggregatedCustomReportArgs = {
  params: CustomAggregatedPreviewParams;
  reportName?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateCompanyProspectArgs = {
  params: ProspectCompanyCreateInput;
};

export type MutationCreateDolCompanyProspectArgs = {
  params: ProspectDolCompanyCreateInput;
};

export type MutationCreateIndividualProspectArgs = {
  params: ProspectIndividualCreateInput;
};

export type MutationCreateKpiDashboardArgs = {
  dashboard: KpiDashboardCreateInput;
};

export type MutationCreatePlainCustomReportArgs = {
  params: CustomPlainPreviewParams;
  reportName?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateReminderArgs = {
  producerId?: InputMaybe<Scalars['String']['input']>;
  reminder: ReminderInput;
  savedOpportunityId: Scalars['String']['input'];
};

export type MutationCreateUserKpiArgs = {
  input: UserKpiCreateInput;
};

export type MutationCreateUserReportPresetArgs = {
  preset: UserReportPresetInput;
};

export type MutationDeleteCrossSellOpportunitiesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type MutationDeleteFilterArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteKpiDashboardArgs = {
  dashboardId: Scalars['String']['input'];
};

export type MutationDeleteOpportunityListArgs = {
  id: Scalars['ID']['input'];
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeleteOpportunityReportArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeletePermanentlySavedOpportunitiesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type MutationDeleteProspectArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteProspectsByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type MutationDeleteRemindersArgs = {
  producerId?: InputMaybe<Scalars['String']['input']>;
  reminderIds: Array<Scalars['String']['input']>;
};

export type MutationDeleteSavedOpportunitiesArgs = {
  ids: Array<Scalars['ID']['input']>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeleteSavedOpportunityItemArgs = {
  itemId: Scalars['ID']['input'];
  producerId?: InputMaybe<Scalars['String']['input']>;
  savedOpportunityId: Scalars['ID']['input'];
};

export type MutationDeleteSavedPageArgs = {
  url: Scalars['String']['input'];
};

export type MutationDeleteUserKpiArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteUserReportPresetArgs = {
  presetId: Scalars['String']['input'];
};

export type MutationDocumentProcessingCreateFolderArgs = {
  name: Scalars['String']['input'];
};

export type MutationDocumentProcessingDeleteFolderArgs = {
  folderId: Scalars['String']['input'];
};

export type MutationDocumentProcessingRunArgs = {
  folderId: Scalars['String']['input'];
};

export type MutationDocumentProcessingUploadFileArgs = {
  filename: Scalars['String']['input'];
  folderId: Scalars['String']['input'];
};

export type MutationDownloadAccountGrowthReportArgs = {
  entity: AccountsReportEntity;
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadAccountLeaderboardReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadAccountLostReportArgs = {
  entity: AccountsReportEntity;
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadAccountManagerAccountBookProfileArgs = {
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadAccountManagerAccountSizeReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadAccountManagerProducerSupportReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadAccountManagerRenewalReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadAccountManagerReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadAccountManagerTopCarriersReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadAccountManagerTopIndustriesReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadAccountManagerTopProductsReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadAccountRetentionReportArgs = {
  carrierLevel?: InputMaybe<CarrierLevel>;
  entity: AccountsReportEntity;
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadAvgAccountSizeReportArgs = {
  entity: AccountsReportEntity;
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadCarrierAppetiteReportArgs = {
  componentFilter: PolicyFilter;
  globalFilter: PolicyFilter;
  level?: InputMaybe<CarrierLevel>;
};

export type MutationDownloadCarrierPdfReportArgs = {
  carrierId: Scalars['String']['input'];
  carrierLevel: CarrierLevel;
  templateUrl: Scalars['String']['input'];
};

export type MutationDownloadCrossSellOpportunityCsvReportArgs = {
  filter?: InputMaybe<OpportunityFilter>;
};

export type MutationDownloadDataAuditReportArgs = {
  auditType: DataAuditReportType;
  fileType: UserReportFileType;
  includeSourceData?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationDownloadLeaderboardReportArgs = {
  carrierLevel?: InputMaybe<CarrierLevel>;
  entity: ReportEntity;
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadLeaderboardReportV2Args = {
  carrierLevel?: InputMaybe<CarrierLevel>;
  entity: LeaderboardReportEntity;
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadLocationPdfReportArgs = {
  jsonBody: Scalars['String']['input'];
  templateUrl: Scalars['String']['input'];
};

export type MutationDownloadMonolineOpportunityCsvReportArgs = {
  filter?: InputMaybe<OpportunityFilter>;
};

export type MutationDownloadOpportunityRecommendationDnbDataArgs = {
  recommendationId: Scalars['String']['input'];
};

export type MutationDownloadOpportunityReportArgs = {
  id: Scalars['String']['input'];
};

export type MutationDownloadOutflowInflowReportArgs = {
  carrierLevel?: InputMaybe<CarrierLevel>;
  filter?: InputMaybe<PolicyFilter>;
  outflowInflowDrillDownFilter?: InputMaybe<OutflowInflowDrillDownFilter>;
  outflowInflowFilter?: InputMaybe<OutflowInflowFilter>;
};

export type MutationDownloadPolicyCsvReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
  includeSourceData?: InputMaybe<Scalars['Boolean']['input']>;
  reportName?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDownloadPolicyXlsxReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
  includeSourceData?: InputMaybe<Scalars['Boolean']['input']>;
  reportName?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDownloadPremiumAccountSizeReportArgs = {
  carrierLevel?: InputMaybe<CarrierLevel>;
  entity: AccountsReportEntity;
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadPremiumFlowReportArgs = {
  carrierLevel?: InputMaybe<CarrierLevel>;
  filter?: InputMaybe<PolicyFilter>;
  premiumFlowType: PremiumFlowType;
};

export type MutationDownloadProducerCreditReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDownloadProducerTopIndustryReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadProducerTopProductReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadProductionCreditXlsxReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadRetentionCsvReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
  params: RetentionParametersInput;
};

export type MutationDownloadRetentionInsightsReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
  params: RetentionInsightsParametersInput;
};

export type MutationDownloadRetentionXlsxReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
  params?: InputMaybe<RetentionParametersInput>;
};

export type MutationDownloadSavedOpportunitiesReportArgs = {
  filter?: InputMaybe<SavedOpportunitiesFilter>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDownloadScore360ClientsReportArgs = {
  filter?: InputMaybe<Score360Filter>;
  viewMode?: InputMaybe<Score360ViewMode>;
};

export type MutationDownloadScore360ProducersReportArgs = {
  filter?: InputMaybe<Score360Filter>;
};

export type MutationDownloadTargetIndustryOpportunityCsvReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
};

export type MutationDownloadUserReportByGroupIdArgs = {
  groupId: Scalars['String']['input'];
};

export type MutationDownloadUserReportByPresetIdArgs = {
  presetId: Scalars['String']['input'];
};

export type MutationDownloadWinBackOpportunityCsvReportArgs = {
  filter?: InputMaybe<OpportunityFilter>;
};

export type MutationGenerateCrossSellReportArgs = {
  filter: CrossSellGeneratorFilter;
};

export type MutationInsuranceCompanyMappingSearchUsedAdminCompaniesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  ultimateParentPrimaryRelationship?: InputMaybe<InsuranceCompanyRelationshipType>;
  usedInExternalSystemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationInsuranceCompanyMappingSelectSuggestionArgs = {
  dataSourceId: Scalars['String']['input'];
  mappingsIds: Array<Scalars['ID']['input']>;
  suggestionId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationLifetimeMultipleAccountsReportArgs = {
  filter: PolicyFilter;
};

export type MutationLifetimeMultipleIndustriesReportArgs = {
  filter: PolicyFilter;
};

export type MutationLifetimeSingleAccountReportArgs = {
  clientId: Scalars['ID']['input'];
  filter: PolicyFilter;
};

export type MutationProductMappingOverrideArgs = {
  enrichedProductId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationProductMappingSelectSuggestionArgs = {
  dataSourceId: Scalars['String']['input'];
  mappingIds: Array<Scalars['ID']['input']>;
  suggestionId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationProductMappingUpdateTreeArgs = {
  group?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
  insuranceSegment?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  superGroup?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRemoveIntegrationResponseFromFavoritesArgs = {
  integrationResponseId: Scalars['ID']['input'];
  service: IntegrationServiceName;
};

export type MutationRemoveOpportunitiesFromListArgs = {
  listId: Scalars['ID']['input'];
  opportunityIds: Array<Scalars['ID']['input']>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRenameOpportunityReportArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationReorderUserKpiArgs = {
  orderedIds: Array<Scalars['String']['input']>;
};

export type MutationReportLossRunIssueArgs = {
  folderId: Scalars['String']['input'];
  message: Scalars['String']['input'];
};

export type MutationRestoreSavedOpportunitiesArgs = {
  ids: Array<Scalars['ID']['input']>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSaveCrossSellOpportunitiesArgs = {
  input: CrossSellOpportunityBatchInput;
};

export type MutationSaveFilterArgs = {
  payload: StoredFiltersCreatePayload;
};

export type MutationSaveOpportunityReportArgs = {
  id: Scalars['String']['input'];
};

export type MutationSavePageFilterArgs = {
  filter: PolicyFilter;
  pageId: Scalars['ID']['input'];
};

export type MutationSaveToListClientOpportunitiesArgs = {
  label?: InputMaybe<SavedOpportunityLabel>;
  listIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  opportunities: Array<SavedOpportunityCrossSellRecommendationInput>;
  producerId?: InputMaybe<Scalars['String']['input']>;
  reminder?: InputMaybe<ReminderInput>;
};

export type MutationSaveToListCrossSellByReportIdArgs = {
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  listIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  producerId?: InputMaybe<Scalars['String']['input']>;
  reportId: Scalars['String']['input'];
};

export type MutationSaveToListDnbNetNewOpportunitiesArgs = {
  inputs: Array<SavedOpportunityDnbNetNewInput>;
  listIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  producerId?: InputMaybe<Scalars['String']['input']>;
  reminder?: InputMaybe<ReminderInput>;
};

export type MutationSaveToListDolCompaniesArgs = {
  inputs: Array<SavedOpportunityDolNetNewInput>;
  listIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  producerId?: InputMaybe<Scalars['String']['input']>;
  reminder?: InputMaybe<ReminderInput>;
};

export type MutationSaveToListHazardhubAddressArgs = {
  cachedId: Scalars['String']['input'];
  listIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  producerId?: InputMaybe<Scalars['String']['input']>;
  reminder?: InputMaybe<ReminderInput>;
};

export type MutationSaveToListNetNewOpportunityRecommendationsArgs = {
  listIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  recommendationIds: Array<Scalars['String']['input']>;
  reminder?: InputMaybe<ReminderInput>;
};

export type MutationSaveToListRenewalsByFilterArgs = {
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  filter: PolicyFilter;
  listIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSaveToListRocketReachNetNewOpportunitiesArgs = {
  inputs: Array<SavedOpportunityRocketReachNetNewInput>;
  listIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  producerId?: InputMaybe<Scalars['String']['input']>;
  reminder?: InputMaybe<ReminderInput>;
};

export type MutationSaveToListTopCrossSellRecommendationsArgs = {
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  listIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSaveToListWinBackByFilterArgs = {
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  filter: PolicyFilter;
  listIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSaveUserPageSettingsArgs = {
  settings: PageSettingsInput;
};

export type MutationSetCompanyStatusArgs = {
  companyId: Scalars['String']['input'];
  status: BrokerMarketStatus;
};

export type MutationSetCrossSellProducerGoalArgs = {
  metric?: InputMaybe<ProducerGoalMetric>;
  producerId?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  year: Scalars['Int']['input'];
};

export type MutationSetCurrentKpiDashboardArgs = {
  dashboardId: Scalars['String']['input'];
};

export type MutationSetHazardhubCachedIdToClientArgs = {
  clientId: Scalars['ID']['input'];
  hazardhubCachedId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationSetNetNewProducerGoalArgs = {
  metric?: InputMaybe<ProducerGoalMetric>;
  producerId?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  year: Scalars['Int']['input'];
};

export type MutationSetRetentionProducerGoalArgs = {
  metric?: InputMaybe<ProducerGoalMetric>;
  producerId?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  year: Scalars['Int']['input'];
};

export type MutationSetRocketReachIdToClientArgs = {
  clientId: Scalars['ID']['input'];
  rocketReachId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationSetUserTimeZoneSettingsArgs = {
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateFilterArgs = {
  id: Scalars['ID']['input'];
  payload: StoredFiltersUpdatePayload;
};

export type MutationUpdateFilterLastUsedArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUpdateKpiDashboardArgs = {
  dashboard: KpiDashboardUpdateInput;
};

export type MutationUpdateListsOfOpportunitiesArgs = {
  listIds: Array<Scalars['ID']['input']>;
  opportunityIds: Array<Scalars['ID']['input']>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateOpportunityListArgs = {
  opportunityList: OpportunityListUpdateInput;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdatePeriodUserKpiArgs = {
  id: Scalars['String']['input'];
  period: UserKpiPeriodInput;
};

export type MutationUpdateUserReportPresetArgs = {
  preset: UserReportPresetInput;
  presetId: Scalars['String']['input'];
};

export type MutationUpdateUserReportPresetScheduleArgs = {
  presetId: Scalars['String']['input'];
  schedule?: InputMaybe<ReportSubscriptionScheduleInput>;
};

export type NetNewOpportunityRecommendation = {
  __typename?: 'NetNewOpportunityRecommendation';
  address?: Maybe<ProspectAddress>;
  employees?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  industry?: Maybe<Industry>;
  /** Company or Individual name */
  name: Scalars['String']['output'];
  prospect?: Maybe<Prospect>;
  revenue?: Maybe<Scalars['Float']['output']>;
};

export type NetNewOpportunityRecommendationPaginatedResult = {
  __typename?: 'NetNewOpportunityRecommendationPaginatedResult';
  items: Array<NetNewOpportunityRecommendation>;
  totalHits: Scalars['Int']['output'];
};

export type NetNewOpportunityRecommendationPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<NetNewOpportunityRecommendationSortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export enum NetNewOpportunityRecommendationSortingField {
  CompanyName = 'COMPANY_NAME',
}

/** Value compared to total value (without filters) */
export type OfTotal = {
  __typename?: 'OfTotal';
  currentValue: Scalars['Float']['output'];
  pctOfTotal?: Maybe<Scalars['Float']['output']>;
  totalValue: Scalars['Float']['output'];
};

export type Opportunities = {
  __typename?: 'Opportunities';
  /** @deprecated use producerPortal.crossSell */
  crossSell: Array<CrossSellOpportunity>;
  /** @deprecated use producerPortal.crossSell */
  crossSellPaginated: CrossSellOpportunityPaginatedResult;
  /** @deprecated deprecated */
  kpi: OpportunityKpi;
  /** @deprecated use producerPortal.monoline */
  monoline: Array<MonolineOpportunity>;
  /** @deprecated use producerPortal.monoline */
  monolinePaginated: MonolineOpportunityPaginatedResult;
  targetIndustry: Array<TargetIndustryOpportunity>;
  /** @deprecated use producerPortal.winBackV2 */
  winBack: Array<WinBackOpportunity>;
  /** @deprecated use producerPortal.winBackV2 */
  winBackPaginated: WinBackOpportunityPaginatedResult;
};

export type OpportunitiesCrossSellArgs = {
  filter?: InputMaybe<OpportunityFilter>;
};

export type OpportunitiesCrossSellPaginatedArgs = {
  filter?: InputMaybe<OpportunityFilter>;
  pagination?: InputMaybe<CrossSellListPaginationInput>;
};

export type OpportunitiesKpiArgs = {
  filter?: InputMaybe<OpportunityFilter>;
};

export type OpportunitiesMonolineArgs = {
  filter?: InputMaybe<OpportunityFilter>;
};

export type OpportunitiesMonolinePaginatedArgs = {
  filter?: InputMaybe<OpportunityFilter>;
  pagination?: InputMaybe<MonolineListPaginationInput>;
};

export type OpportunitiesTargetIndustryArgs = {
  filter?: InputMaybe<PolicyFilter>;
};

export type OpportunitiesWinBackArgs = {
  filter?: InputMaybe<OpportunityFilter>;
};

export type OpportunitiesWinBackPaginatedArgs = {
  filter?: InputMaybe<OpportunityFilter>;
  pagination?: InputMaybe<WinBackListPaginationInput>;
};

export type OpportunityAggregationByMonth = {
  __typename?: 'OpportunityAggregationByMonth';
  count: Scalars['Int']['output'];
  month: Month;
};

export type OpportunityAggregationByProducer = {
  __typename?: 'OpportunityAggregationByProducer';
  count: Scalars['Int']['output'];
  producer: User;
};

export type OpportunityCrossSellRecord = {
  __typename?: 'OpportunityCrossSellRecord';
  client: Client;
  /** Item could be null if mapping was changed */
  productsBought: Array<Maybe<Product>>;
  /** Item could be null if mapping was changed */
  productsNotBought: Array<Maybe<Product>>;
};

export type OpportunityCrossSellReport = {
  __typename?: 'OpportunityCrossSellReport';
  records: Array<OpportunityCrossSellRecord>;
  report: OpportunityReport;
  total: OpportunityOverviewTotal;
};

export type OpportunityFilter = {
  carrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  clientIds?: InputMaybe<Array<Scalars['String']['input']>>;
  industryGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  industryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  industrySubGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  period?: InputMaybe<FromToPeriod>;
  producerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  productIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** search opportunities by keyword */
  query?: InputMaybe<Scalars['String']['input']>;
};

export type OpportunityFilterOutput = {
  __typename?: 'OpportunityFilterOutput';
  carrierIds?: Maybe<Array<Scalars['String']['output']>>;
  clientIds?: Maybe<Array<Scalars['String']['output']>>;
  industryGroupIds?: Maybe<Array<Scalars['String']['output']>>;
  industryIds?: Maybe<Array<Scalars['String']['output']>>;
  industrySubGroupIds?: Maybe<Array<Scalars['String']['output']>>;
  period?: Maybe<FromToPeriodOutput>;
  producerIds?: Maybe<Array<Scalars['String']['output']>>;
  productIds?: Maybe<Array<Scalars['String']['output']>>;
  /** search opportunities by keyword */
  query?: Maybe<Scalars['String']['output']>;
};

export type OpportunityKpi = {
  __typename?: 'OpportunityKpi';
  crossSell: Scalars['Int']['output'];
  monoline: Scalars['Int']['output'];
  /** Total of all Opportunity types */
  total: Scalars['Int']['output'];
  winBack: Scalars['Int']['output'];
};

export type OpportunityList = {
  __typename?: 'OpportunityList';
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['Timestamp']['output'];
};

export type OpportunityListCreateInput = {
  title: Scalars['String']['input'];
};

export type OpportunityListUpdateInput = {
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type OpportunityLists = {
  __typename?: 'OpportunityLists';
  all: Array<OpportunityList>;
};

export type OpportunityListsAllArgs = {
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type OpportunityOverviewTotal = {
  __typename?: 'OpportunityOverviewTotal';
  clients: Scalars['Int']['output'];
  policies: Scalars['Int']['output'];
  /** @deprecated Not needed */
  revenue?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Not needed */
  revenueBand: Band;
  /** ((Unique count of agency clients purchasing at least one products bought AND at least one products not bought) / (Unique count of agency clients purchasing at least one products bought)) */
  similarClientsBuyingPct?: Maybe<Scalars['Float']['output']>;
  totalUpsellAmount?: Maybe<Scalars['Float']['output']>;
};

export type OpportunityRecommendation = {
  __typename?: 'OpportunityRecommendation';
  product: Product;
  rate: Scalars['Float']['output'];
  saved?: Maybe<SavedOpportunityCrossSell>;
};

export type OpportunityRecords = {
  __typename?: 'OpportunityRecords';
  records: Array<OpportunityCrossSellRecord>;
  report: OpportunityReport;
};

export type OpportunityReport = {
  __typename?: 'OpportunityReport';
  category: OpportunityReportCategory;
  createdAt: Scalars['Timestamp']['output'];
  criteria: CrossSellReportCriteria;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: OpportunityReportType;
};

export enum OpportunityReportCategory {
  Recent = 'RECENT',
  Saved = 'SAVED',
}

export type OpportunityReportList = {
  __typename?: 'OpportunityReportList';
  items: Array<OpportunityReport>;
};

export enum OpportunityReportType {
  CrossSell = 'CROSS_SELL',
}

export type OpportunityReports = {
  __typename?: 'OpportunityReports';
  getById?: Maybe<OpportunityCrossSellReport>;
  /** @deprecated deprecated */
  list: OpportunityReportList;
  /** @deprecated deprecated */
  recent: OpportunityReportList;
  /** @deprecated deprecated */
  reportRecords: OpportunityRecords;
};

export type OpportunityReportsGetByIdArgs = {
  id: Scalars['String']['input'];
  pagination?: InputMaybe<CrossSellPaginationInput>;
};

export type OpportunityReportsRecentArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type OpportunityReportsReportRecordsArgs = {
  id: Scalars['String']['input'];
  pagination?: InputMaybe<CrossSellPaginationInput>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export enum OpportunitySourceType {
  Ennabl = 'ENNABL',
  OpportunityReport = 'OPPORTUNITY_REPORT',
}

export type OutflowInflow = {
  __typename?: 'OutflowInflow';
  chartData: OutflowInflowChartData;
  drillDownData: Array<Policy>;
};

export type OutflowInflowChartDataArgs = {
  carrierLevel?: InputMaybe<CarrierLevel>;
  filter?: InputMaybe<PolicyFilter>;
  outflowInflowFilter?: InputMaybe<OutflowInflowFilter>;
};

export type OutflowInflowDrillDownDataArgs = {
  carrierLevel?: InputMaybe<CarrierLevel>;
  filter?: InputMaybe<PolicyFilter>;
  outflowInflowDrillDownFilter?: InputMaybe<OutflowInflowDrillDownFilter>;
  outflowInflowFilter?: InputMaybe<OutflowInflowFilter>;
};

export type OutflowInflowChartData = {
  __typename?: 'OutflowInflowChartData';
  flows: Array<OutflowInflowFlowItem>;
  inflowTotal: OutflowInflowTotal;
  inflows: Array<OutflowInflowMarket>;
  outflowTotal: OutflowInflowTotal;
  outflows: Array<OutflowInflowMarket>;
  products: Array<OutflowInflowProduct>;
};

export type OutflowInflowDrillDownFilter = {
  inflowMarketId?: InputMaybe<Scalars['String']['input']>;
  outflowMarketId?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
};

export type OutflowInflowFilter = {
  industryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  inflowCarrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  outflowCarrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  productIds?: InputMaybe<Array<Scalars['String']['input']>>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type OutflowInflowFlowItem = {
  __typename?: 'OutflowInflowFlowItem';
  inflowMarket: Market;
  outflowMarket: Market;
  policyCount: Scalars['Int']['output'];
  premium: Scalars['Float']['output'];
  product: Product;
};

export type OutflowInflowMarket = {
  __typename?: 'OutflowInflowMarket';
  id: Scalars['ID']['output'];
  market: Market;
  policyCount: Scalars['Int']['output'];
  premium: Scalars['Float']['output'];
};

export type OutflowInflowProduct = {
  __typename?: 'OutflowInflowProduct';
  id: Scalars['ID']['output'];
  policyCount: Scalars['Int']['output'];
  premium: Scalars['Float']['output'];
  product: Product;
};

export type OutflowInflowTotal = {
  __typename?: 'OutflowInflowTotal';
  marketCount: Scalars['Int']['output'];
  policyCount: Scalars['Int']['output'];
  premium: Scalars['Float']['output'];
  productCount: Scalars['Int']['output'];
  revenue: Scalars['Float']['output'];
};

export type OutputRange = {
  __typename?: 'OutputRange';
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

export type PageCustomSettings = {
  __typename?: 'PageCustomSettings';
  id: Scalars['ID']['output'];
  /** string or JSON containing data for a custom setting */
  value: Scalars['String']['output'];
};

export type PageCustomSettingsInput = {
  id: Scalars['ID']['input'];
  /** string or JSON containing data for a custom setting */
  value: Scalars['String']['input'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PageSectionSettings = {
  __typename?: 'PageSectionSettings';
  collapsed: Scalars['Boolean']['output'];
  hidden: Scalars['Boolean']['output'];
  sectionId: Scalars['ID']['output'];
};

export type PageSectionSettingsInput = {
  collapsed: Scalars['Boolean']['input'];
  hidden: Scalars['Boolean']['input'];
  sectionId: Scalars['ID']['input'];
};

export type PageSettings = {
  __typename?: 'PageSettings';
  customSettings?: Maybe<Array<PageCustomSettings>>;
  filter?: Maybe<PolicyFilterOutput>;
  pageId: Scalars['ID']['output'];
  sections?: Maybe<Array<PageSectionSettings>>;
};

export type PageSettingsInput = {
  customSettings?: InputMaybe<Array<PageCustomSettingsInput>>;
  pageId: Scalars['ID']['input'];
  sections?: InputMaybe<Array<PageSectionSettingsInput>>;
};

export type ParticipantsCount = {
  __typename?: 'ParticipantsCount';
  /** Source: participants_boy_total */
  beginOfYearTotal?: Maybe<Scalars['Int']['output']>;
  /** Source: participants_boy_total_active */
  beginOfYearTotalActive?: Maybe<Scalars['Int']['output']>;
  /** Source: participants_eoy_total_active */
  endOfYearTotal?: Maybe<Scalars['Int']['output']>;
  /** Source: participants_eoy_total_active */
  endOfYearTotalActive?: Maybe<Scalars['Int']['output']>;
};

/** Builds percentile filters for entity by metric */
export type PercentileFilter = {
  entity: PolicyEntity;
  from?: InputMaybe<Scalars['Float']['input']>;
  metric: PolicyMetric;
  to?: InputMaybe<Scalars['Float']['input']>;
};

export type PercentileFilterOutput = {
  __typename?: 'PercentileFilterOutput';
  entity: PolicyEntity;
  from?: Maybe<Scalars['Float']['output']>;
  metric: PolicyMetric;
  to?: Maybe<Scalars['Float']['output']>;
};

export type PercentileRanking = {
  __typename?: 'PercentileRanking';
  /** Null if entity is not present at all. Normalized to [0, 1] */
  percentile?: Maybe<Scalars['Float']['output']>;
  /** Null if entity is not present at all */
  rank?: Maybe<Scalars['Int']['output']>;
  totalCount: Scalars['Int']['output'];
};

export type Period = {
  /** BILLED - get metrics from billed transaction. WRITTEN - get metrics from written policies. */
  amountType?: InputMaybe<PolicyAmountType>;
  /** Only for FROM_TO */
  from?: InputMaybe<Scalars['SimpleDate']['input']>;
  /** Not working for IN_FORCE */
  inclusionDateType?: InputMaybe<InclusionDateType>;
  /** Only for YTD, ROLLING_12, and IN_FORCE */
  timestamp?: InputMaybe<Scalars['SimpleDate']['input']>;
  /** Only for FROM_TO */
  to?: InputMaybe<Scalars['SimpleDate']['input']>;
  type?: InputMaybe<PeriodType>;
};

export type PeriodOutput = {
  __typename?: 'PeriodOutput';
  /** BILLED - get metrics from billed transaction. WRITTEN - get metrics from written policies. */
  amountType?: Maybe<PolicyAmountType>;
  /** Only for FROM_TO */
  from?: Maybe<Scalars['SimpleDate']['output']>;
  /** Not working for IN_FORCE */
  inclusionDateType?: Maybe<InclusionDateType>;
  /** Only for YTD, ROLLING_12, and IN_FORCE */
  timestamp?: Maybe<Scalars['SimpleDate']['output']>;
  /** Only for FROM_TO */
  to?: Maybe<Scalars['SimpleDate']['output']>;
  type?: Maybe<PeriodType>;
};

/** Time period or exact date */
export enum PeriodType {
  AllTime = 'ALL_TIME',
  FromTo = 'FROM_TO',
  InForce = 'IN_FORCE',
  Rolling_12Months = 'ROLLING_12_MONTHS',
  Ytd = 'YTD',
}

export type PiplAddress = PiplCommonField & {
  __typename?: 'PiplAddress';
  apartment?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  /** A two-letter, Alpha-2 ISO-3166 country code */
  countryISOAlpha2Code?: Maybe<Scalars['String']['output']>;
  current?: Maybe<Scalars['Boolean']['output']>;
  /** The full address for display purposes */
  display: Scalars['String']['output'];
  house?: Maybe<Scalars['String']['output']>;
  inferred?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  poBox?: Maybe<Scalars['String']['output']>;
  /** 2 letters state code, if country is one of US, Canada or Brazil. 3 letters state code if country is Australia or UK. */
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  type?: Maybe<PiplAddressType>;
  typeSource?: Maybe<Scalars['String']['output']>;
  validSince?: Maybe<Scalars['Timestamp']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export enum PiplAddressType {
  Home = 'HOME',
  Old = 'OLD',
  Work = 'WORK',
}

export type PiplCommonField = {
  current?: Maybe<Scalars['Boolean']['output']>;
  inferred?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  validSince?: Maybe<Scalars['Timestamp']['output']>;
};

export type PiplDateOfBirth = PiplCommonField & {
  __typename?: 'PiplDateOfBirth';
  current?: Maybe<Scalars['Boolean']['output']>;
  dateRange: PiplDateRange;
  /** The person’s estimated age represented by this DOB for display purposes. */
  display: Scalars['String']['output'];
  inferred?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  validSince?: Maybe<Scalars['Timestamp']['output']>;
};

export type PiplDateRange = {
  __typename?: 'PiplDateRange';
  end?: Maybe<Scalars['Timestamp']['output']>;
  start?: Maybe<Scalars['Timestamp']['output']>;
};

export type PiplDirectPiplCallRelationship = PiplCommonField & {
  __typename?: 'PiplDirectPiplCallRelationship';
  current?: Maybe<Scalars['Boolean']['output']>;
  /** usually it contains only name, but according to docs it could contain any other field */
  data: PiplPerson;
  inferred?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  /** A free text providing further info on the association. For example, Father. */
  subtype?: Maybe<Scalars['String']['output']>;
  type?: Maybe<PiplRelationshipType>;
  typeSource?: Maybe<Scalars['String']['output']>;
  validSince?: Maybe<Scalars['Timestamp']['output']>;
};

export type PiplEducation = PiplCommonField & {
  __typename?: 'PiplEducation';
  current?: Maybe<Scalars['Boolean']['output']>;
  /** Period of study. Date ranges might be partial to designate current study or an unknown start date. */
  dateRange?: Maybe<PiplDateRange>;
  /** Degree. B.Sc, PhD, etc */
  degree?: Maybe<Scalars['String']['output']>;
  /** The full degree headline for display purposes */
  display: Scalars['String']['output'];
  inferred?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  /** Name of the educational institution. */
  school?: Maybe<Scalars['String']['output']>;
  validSince?: Maybe<Scalars['Timestamp']['output']>;
};

export type PiplEmail = PiplCommonField & {
  __typename?: 'PiplEmail';
  address: Scalars['String']['output'];
  current?: Maybe<Scalars['Boolean']['output']>;
  /** Whether this is a disposable email (for example guerillamail.com) */
  displosable: Scalars['Boolean']['output'];
  /** Whether this email came from a public provider (such as gmail.com). */
  emailProvider: Scalars['Boolean']['output'];
  inferred?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  type?: Maybe<PiplEmailType>;
  typeSource?: Maybe<Scalars['String']['output']>;
  validSince?: Maybe<Scalars['Timestamp']['output']>;
};

export enum PiplEmailType {
  Personal = 'PERSONAL',
  Work = 'WORK',
}

export type PiplEthnicity = PiplCommonField & {
  __typename?: 'PiplEthnicity';
  content?: Maybe<PiplEthnicityType>;
  contentSource: Scalars['String']['output'];
  current?: Maybe<Scalars['Boolean']['output']>;
  inferred?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  validSince?: Maybe<Scalars['Timestamp']['output']>;
};

export enum PiplEthnicityType {
  AlaskaNative = 'ALASKA_NATIVE',
  AmericanIndian = 'AMERICAN_INDIAN',
  Black = 'BLACK',
  Chamorro = 'CHAMORRO',
  Chinese = 'CHINESE',
  Filipino = 'FILIPINO',
  Guamanian = 'GUAMANIAN',
  Japanese = 'JAPANESE',
  Korean = 'KOREAN',
  NativeHawaiian = 'NATIVE_HAWAIIAN',
  Other = 'OTHER',
  OtherAsian = 'OTHER_ASIAN',
  OtherPacificIslander = 'OTHER_PACIFIC_ISLANDER',
  Samoan = 'SAMOAN',
  Vietnamese = 'VIETNAMESE',
  White = 'WHITE',
}

export type PiplGender = PiplCommonField & {
  __typename?: 'PiplGender';
  content?: Maybe<PiplGenderType>;
  contentSource: Scalars['String']['output'];
  current?: Maybe<Scalars['Boolean']['output']>;
  inferred?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  validSince?: Maybe<Scalars['Timestamp']['output']>;
};

export enum PiplGenderType {
  Female = 'FEMALE',
  Male = 'MALE',
}

export type PiplImage = PiplCommonField & {
  __typename?: 'PiplImage';
  current?: Maybe<Scalars['Boolean']['output']>;
  inferred?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  url: Scalars['String']['output'];
  validSince?: Maybe<Scalars['Timestamp']['output']>;
};

export type PiplJob = PiplCommonField & {
  __typename?: 'PiplJob';
  current?: Maybe<Scalars['Boolean']['output']>;
  /** Period of employment. Date ranges might be partial to designate current job or an unknown start date. */
  dateRange?: Maybe<PiplDateRange>;
  display: Scalars['String']['output'];
  /** The employing organization industry */
  industry?: Maybe<Scalars['String']['output']>;
  inferred?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  validSince?: Maybe<Scalars['Timestamp']['output']>;
};

export type PiplLanguage = PiplCommonField & {
  __typename?: 'PiplLanguage';
  current?: Maybe<Scalars['Boolean']['output']>;
  /** The representation of this language (for example “en_US”). */
  display: Scalars['String']['output'];
  inferred?: Maybe<Scalars['Boolean']['output']>;
  /** A 2-letter language code */
  language: Scalars['String']['output'];
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  /** Country code */
  region?: Maybe<Scalars['String']['output']>;
  validSince?: Maybe<Scalars['Timestamp']['output']>;
};

export type PiplName = PiplCommonField & {
  __typename?: 'PiplName';
  current?: Maybe<Scalars['Boolean']['output']>;
  /** The full name for display purposes */
  display: Scalars['String']['output'];
  first?: Maybe<Scalars['String']['output']>;
  inferred?: Maybe<Scalars['Boolean']['output']>;
  last?: Maybe<Scalars['String']['output']>;
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  /** Middle name or middle initial */
  middle?: Maybe<Scalars['String']['output']>;
  /** A person’s title */
  prefix?: Maybe<Scalars['String']['output']>;
  /** Additional information about a person */
  suffix?: Maybe<Scalars['String']['output']>;
  type?: Maybe<PiplNameType>;
  typeSource?: Maybe<Scalars['String']['output']>;
  validSince?: Maybe<Scalars['Timestamp']['output']>;
};

export enum PiplNameType {
  Alias = 'ALIAS',
  Former = 'FORMER',
  Maiden = 'MAIDEN',
  Present = 'PRESENT',
}

export type PiplOriginCountry = PiplCommonField & {
  __typename?: 'PiplOriginCountry';
  /** A country code */
  country: Scalars['String']['output'];
  current?: Maybe<Scalars['Boolean']['output']>;
  inferred?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  validSince?: Maybe<Scalars['Timestamp']['output']>;
};

export type PiplPerson = {
  __typename?: 'PiplPerson';
  addresses: Array<PiplAddress>;
  dateOfBirth?: Maybe<PiplDateOfBirth>;
  educations: Array<PiplEducation>;
  emails: Array<PiplEmail>;
  ethnicities: Array<PiplEthnicity>;
  gender?: Maybe<PiplGender>;
  images: Array<PiplImage>;
  jobs: Array<PiplJob>;
  languages: Array<PiplLanguage>;
  names: Array<PiplName>;
  originCountries: Array<PiplOriginCountry>;
  phones: Array<PiplPhone>;
  relationships: Array<PiplDirectPiplCallRelationship>;
  tags: Array<PiplTag>;
  thirdPartyServicesIds: Array<PiplThirdPartyId>;
  urls: Array<PiplUrl>;
  usernames: Array<PiplUsername>;
};

export type PiplPersonRequest = {
  addresses?: InputMaybe<Array<PiplRequestAddress>>;
  /** Used only in case [dateOfBirth] is not set */
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  /** Used only in case [dateOfBirth] is not set */
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Timestamp']['input']>;
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** This parameter will only be used in absence of firstName and lastName. */
  fullName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Profile URL in a supported service. This URL will be parsed to a username or user_id object. Example: https://www.linkedin.com/pub/superman/20/7a/365 */
  urls?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PiplPhone = PiplCommonField & {
  __typename?: 'PiplPhone';
  countryCode?: Maybe<Scalars['Float']['output']>;
  current?: Maybe<Scalars['Boolean']['output']>;
  /** The full national phone for display purposes. Usual format for US: "123-456-7890" */
  display: Scalars['String']['output'];
  /** The full international phone for display purposes. Usual format for US: "+1 123-456-7890" */
  displayInternational?: Maybe<Scalars['String']['output']>;
  extension?: Maybe<Scalars['Float']['output']>;
  inferred?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  number: Scalars['Float']['output'];
  type?: Maybe<PiplPhoneType>;
  typeSource?: Maybe<Scalars['String']['output']>;
  validSince?: Maybe<Scalars['Timestamp']['output']>;
};

export enum PiplPhoneType {
  HomeFax = 'HOME_FAX',
  HomePhone = 'HOME_PHONE',
  Mobile = 'MOBILE',
  Pager = 'PAGER',
  WorkFax = 'WORK_FAX',
  WorkPhone = 'WORK_PHONE',
}

export enum PiplRelationshipType {
  Family = 'FAMILY',
  Friend = 'FRIEND',
  Other = 'OTHER',
  Work = 'WORK',
}

export type PiplRequestAddress = {
  apartment?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  /** A two-letter, Alpha-2 ISO-3166 country code. */
  countryISOAlpha2Code?: InputMaybe<Scalars['String']['input']>;
  /** Full address. Use this parameter if the accurate address parts (country/state/city…) are not available. */
  fullAddress?: InputMaybe<Scalars['String']['input']>;
  /** House number */
  house?: InputMaybe<Scalars['String']['input']>;
  poBox?: InputMaybe<Scalars['String']['input']>;
  /** A United States, Canada, Great Britain or Australia state code. */
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type PiplSuggestion = {
  __typename?: 'PiplSuggestion';
  data: PiplPerson;
  id: Scalars['ID']['output'];
};

export type PiplTag = PiplCommonField & {
  __typename?: 'PiplTag';
  /** What the content is representing */
  classification?: Maybe<Scalars['String']['output']>;
  content: Scalars['String']['output'];
  current?: Maybe<Scalars['Boolean']['output']>;
  inferred?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  validSince?: Maybe<Scalars['Timestamp']['output']>;
};

export type PiplThirdPartyId = PiplCommonField & {
  __typename?: 'PiplThirdPartyId';
  current?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  inferred?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  service: Scalars['String']['output'];
  source: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  validSince?: Maybe<Scalars['Timestamp']['output']>;
};

export type PiplUrl = PiplCommonField & {
  __typename?: 'PiplUrl';
  category?: Maybe<PiplUrlCategory>;
  categorySource?: Maybe<Scalars['String']['output']>;
  current?: Maybe<Scalars['Boolean']['output']>;
  domain: Scalars['String']['output'];
  inferred?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** The source ID (if this URL is a source of data) */
  sourceId?: Maybe<Scalars['String']['output']>;
  /** Indicating whether additional data from this source is available behind a website paywall. */
  sponsored: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
  validSince?: Maybe<Scalars['Timestamp']['output']>;
};

export enum PiplUrlCategory {
  BackgroundReports = 'BACKGROUND_REPORTS',
  ContactDetails = 'CONTACT_DETAILS',
  EmailAddress = 'EMAIL_ADDRESS',
  Media = 'MEDIA',
  PersonalProfiles = 'PERSONAL_PROFILES',
  ProfessionalAndBusiness = 'PROFESSIONAL_AND_BUSINESS',
  Publications = 'PUBLICATIONS',
  PublicRecords = 'PUBLIC_RECORDS',
  SchoolAndClassmates = 'SCHOOL_AND_CLASSMATES',
  Webpages = 'WEBPAGES',
}

export type PiplUsername = PiplCommonField & {
  __typename?: 'PiplUsername';
  /** A person’s username at one or more online services */
  content: Scalars['String']['output'];
  current?: Maybe<Scalars['Boolean']['output']>;
  inferred?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Scalars['Timestamp']['output']>;
  validSince?: Maybe<Scalars['Timestamp']['output']>;
};

export type Policy = {
  __typename?: 'Policy';
  accountExecutives?: Maybe<Array<User>>;
  accountManager?: Maybe<User>;
  amsPolicyStatus: AmsPolicyStatus;
  billed: Amount;
  cancelDate?: Maybe<Scalars['SimpleDate']['output']>;
  carrier?: Maybe<Market>;
  client: Client;
  clientAgencyCodes?: Maybe<Array<Scalars['String']['output']>>;
  dataIssues?: Maybe<Array<PolicyDataIssue>>;
  detailedStatus: DetailedPolicyStatus;
  endDate: Scalars['SimpleDate']['output'];
  /** min of cancelDate and endDate */
  expirationDate: Scalars['SimpleDate']['output'];
  /** Amount for the policy after filter is applied. For written amount type equal to `written`, for billed - sum of amounts of filtered transactions */
  filteredAmount: Amount;
  id: Scalars['ID']['output'];
  industry?: Maybe<Industry>;
  industryGroup?: Maybe<Industry>;
  industrySubGroup?: Maybe<Industry>;
  insuranceSegment?: Maybe<Product>;
  intermediary?: Maybe<Market>;
  isClientLost?: Maybe<Scalars['Boolean']['output']>;
  isCrossSell: Scalars['Boolean']['output'];
  isInForce: Scalars['Boolean']['output'];
  isLost: Scalars['Boolean']['output'];
  isRewrite: Scalars['Boolean']['output'];
  isWinBack: Scalars['Boolean']['output'];
  issuingPaper?: Maybe<Market>;
  naicsSourceConfidenceScore?: Maybe<Scalars['String']['output']>;
  nextPolicyStartDate?: Maybe<Scalars['SimpleDate']['output']>;
  payTo?: Maybe<Market>;
  policyAgencyCodes?: Maybe<Array<Scalars['String']['output']>>;
  policyNumber?: Maybe<Scalars['String']['output']>;
  policySources?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated use producers */
  producer?: Maybe<User>;
  producers?: Maybe<Array<User>>;
  product?: Maybe<Product>;
  productGroup?: Maybe<Product>;
  productLines: Array<Product>;
  productSuperGroup?: Maybe<Product>;
  renewalStage?: Maybe<Scalars['String']['output']>;
  renewalStatus: RenewalStatus;
  revenueBand: Band;
  sourceCarrier?: Maybe<SimpleEntity>;
  sourceClient?: Maybe<SimpleEntity>;
  sourceIntermediary?: Maybe<SimpleEntity>;
  sourceProduct?: Maybe<SimpleEntity>;
  sourceWritten: Amount;
  startDate: Scalars['SimpleDate']['output'];
  transactions: Array<Transaction>;
  written: Amount;
};

export enum PolicyAmountType {
  /** Already transmitted payments */
  Billed = 'BILLED',
  /** AMS data of what is expected in total from policy */
  SourceWritten = 'SOURCE_WRITTEN',
  /** Ennabl Estimate of what is expected in total from policy */
  Written = 'WRITTEN',
}

export enum PolicyDataIssue {
  CompanyMissingNaics = 'COMPANY_MISSING_NAICS',
  Duplicate = 'DUPLICATE',
  FlatCanceled = 'FLAT_CANCELED',
  InvalidDates = 'INVALID_DATES',
  InvalidPolicyNumber = 'INVALID_POLICY_NUMBER',
  MissingAccountManager = 'MISSING_ACCOUNT_MANAGER',
  MissingBillings = 'MISSING_BILLINGS',
  MissingIssuingPaper = 'MISSING_ISSUING_PAPER',
  MissingLineOfBusiness = 'MISSING_LINE_OF_BUSINESS',
  MissingProducer = 'MISSING_PRODUCER',
  OutstandingRevenue = 'OUTSTANDING_REVENUE',
  Underestimated = 'UNDERESTIMATED',
  UnmappedMarket = 'UNMAPPED_MARKET',
  UnmappedProduct = 'UNMAPPED_PRODUCT',
}

export type PolicyDataIssuesGroup = {
  /** To identify the group in the response */
  id: Scalars['String']['input'];
  issues: Array<PolicyDataIssue>;
};

export enum PolicyEntity {
  AccountExecutive = 'ACCOUNT_EXECUTIVE',
  AccountManager = 'ACCOUNT_MANAGER',
  Client = 'CLIENT',
  Industry = 'INDUSTRY',
  IndustryGroup = 'INDUSTRY_GROUP',
  IndustrySubgroup = 'INDUSTRY_SUBGROUP',
  IssuingPaper = 'ISSUING_PAPER',
  Producer = 'PRODUCER',
  Product = 'PRODUCT',
  ProductGroup = 'PRODUCT_GROUP',
  ProductInsuranceSegment = 'PRODUCT_INSURANCE_SEGMENT',
  ProductSuperGroup = 'PRODUCT_SUPER_GROUP',
  UltimateParentCarrier = 'ULTIMATE_PARENT_CARRIER',
  UltimateParentIntermediary = 'ULTIMATE_PARENT_INTERMEDIARY',
}

export type PolicyFilter = {
  accountManagerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  accountSize?: InputMaybe<FilterRange>;
  /** Additional periods to refine processed policy set. It has limited support, consult before use */
  additionalPeriods?: InputMaybe<Array<Period>>;
  allLevelCarrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  allLevelIntermediaryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  brokerMarketStatuses?: InputMaybe<Array<BrokerMarketStatus>>;
  carrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  carrierRatings?: InputMaybe<Array<Scalars['String']['input']>>;
  childIntermediaryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  clientAgencyCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  clientIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** search policies by client name */
  clientName?: InputMaybe<Scalars['String']['input']>;
  clientStatuses?: InputMaybe<Array<AggClientStatus>>;
  /** Search bounds for commission percentage */
  commissionPctRange?: InputMaybe<FilterRange>;
  companyEmployeesSizeRange?: InputMaybe<FilterRange>;
  /** use this object to set up combination of company filters that is needed */
  companyFilters?: InputMaybe<CompanyFilters>;
  /** filters company by top level carrier, issuing paper or intermediary */
  companyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  companySalesRange?: InputMaybe<FilterRange>;
  counties?: InputMaybe<Array<Scalars['String']['input']>>;
  dataIssues?: InputMaybe<Array<PolicyDataIssue>>;
  datasourceType?: InputMaybe<DatasourceType>;
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Policies without intermediary */
  direct?: InputMaybe<Scalars['Boolean']['input']>;
  /** use this object to set up industry filters */
  industryFilters?: InputMaybe<Array<IndustryFilter>>;
  industryGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  industryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  industrySubGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  intermediaryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  isCancelled?: InputMaybe<Scalars['Boolean']['input']>;
  isClientCancelled?: InputMaybe<Scalars['Boolean']['input']>;
  isClientLost?: InputMaybe<Scalars['Boolean']['input']>;
  /** Either client is lost or all next policies are rewrite */
  isClientLostForCarrier?: InputMaybe<Scalars['Boolean']['input']>;
  isCrossSell?: InputMaybe<Scalars['Boolean']['input']>;
  /** filters lost policies by detailed lost reason */
  isExpiredAndNonRenewed?: InputMaybe<Scalars['Boolean']['input']>;
  isFee?: InputMaybe<Scalars['Boolean']['input']>;
  isLost?: InputMaybe<Scalars['Boolean']['input']>;
  /** Either policy is lost or next policy is rewrite */
  isLostForCarrier?: InputMaybe<Scalars['Boolean']['input']>;
  /** Works only in written/primary-producer mode */
  isMonoline?: InputMaybe<Scalars['Boolean']['input']>;
  isRenewable?: InputMaybe<Scalars['Boolean']['input']>;
  isRewrite?: InputMaybe<Scalars['Boolean']['input']>;
  issuingPaperIds?: InputMaybe<Array<Scalars['String']['input']>>;
  location?: InputMaybe<LocationFilter>;
  naicsSourceConfidenceScore?: InputMaybe<Array<FilterRange>>;
  /** All next policies are rewrite */
  nextPolicyClientIsRewrite?: InputMaybe<Scalars['Boolean']['input']>;
  nextPolicyIsRewrite?: InputMaybe<Scalars['Boolean']['input']>;
  parentCarrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  parentIntermediaryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Aggregation-based filter, incompatible with other aggregation-based filters */
  percentileFilter?: InputMaybe<PercentileFilter>;
  period?: InputMaybe<Period>;
  policyAgencyCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** explicitly specify policy set */
  policyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  policySources?: InputMaybe<Array<Scalars['String']['input']>>;
  policySourcesFilter?: InputMaybe<PolicySourceFilter>;
  policyStatusType?: InputMaybe<PolicyStatusType>;
  policyStatuses?: InputMaybe<Array<AggPolicyStatus>>;
  premiumAccountSize?: InputMaybe<FilterRange>;
  producerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  productFilters?: InputMaybe<Array<ProductPolicyFilter>>;
  productGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  productIds?: InputMaybe<Array<Scalars['String']['input']>>;
  productInsuranceSegmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  productSuperGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** search policies by keyword */
  query?: InputMaybe<Scalars['String']['input']>;
  /** Companies with AM Best rank */
  ranked?: InputMaybe<Scalars['Boolean']['input']>;
  renewalStatus?: InputMaybe<RenewalStatus>;
  /** if true, filters for carriers and intermediaries will be joined using AND operator */
  separateCompanyFilters?: InputMaybe<Scalars['Boolean']['input']>;
  /** if true, filters for producers and account managers will be joined using AND operator */
  separateUserFilters?: InputMaybe<Scalars['Boolean']['input']>;
  sourceCompanyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sourceProductIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** US states iso 3166 codes */
  states?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Used for updating of stored last used time */
  storedId?: InputMaybe<Scalars['String']['input']>;
  ultimateCarrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ultimateIntermediaryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userFilters?: InputMaybe<UserFilters>;
  userFiltersAnd?: InputMaybe<Array<UserFilters>>;
};

export type PolicyFilterOutput = {
  __typename?: 'PolicyFilterOutput';
  /** @deprecated use userFilters */
  accountManagerIds?: Maybe<Array<Scalars['String']['output']>>;
  accountSize?: Maybe<FilterRangeOutput>;
  /** Additional periods to refine processed policy set. It has limited support, consult before use */
  additionalPeriods?: Maybe<Array<PeriodOutput>>;
  /** @deprecated use CompanyFilters */
  allLevelCarrierIds?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated use CompanyFilters */
  allLevelIntermediaryIds?: Maybe<Array<Scalars['String']['output']>>;
  brokerMarketStatuses?: Maybe<Array<BrokerMarketStatus>>;
  /** @deprecated use CompanyFilters */
  carrierIds?: Maybe<Array<Scalars['String']['output']>>;
  carrierRatings?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated use CompanyFilters */
  childIntermediaryIds?: Maybe<Array<Scalars['String']['output']>>;
  clientAgencyCodes?: Maybe<Array<Scalars['String']['output']>>;
  clientIds?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * search policies by client name
   * @deprecated Not needed anymore. Let's implement flexible text-filter if needed.
   */
  clientName?: Maybe<Scalars['String']['output']>;
  clientStatuses?: Maybe<Array<AggClientStatus>>;
  /** Search bounds for commission percentage */
  commissionPctRange?: Maybe<FilterRangeOutput>;
  companyEmployeesSizeRange?: Maybe<FilterRangeOutput>;
  /** use this object to set up combination of company filters that is needed */
  companyFilters?: Maybe<CompanyFiltersOutput>;
  /**
   * filters company by top level carrier, issuing paper or intermediary
   * @deprecated use CompanyFilters
   */
  companyIds?: Maybe<Array<Scalars['String']['output']>>;
  companySalesRange?: Maybe<FilterRangeOutput>;
  /** @deprecated Use location filter */
  counties?: Maybe<Array<Scalars['String']['output']>>;
  dataIssues?: Maybe<Array<PolicyDataIssue>>;
  datasourceType?: Maybe<DatasourceType>;
  departmentIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Policies without intermediary */
  direct?: Maybe<Scalars['Boolean']['output']>;
  /** use this object to set up industry filters */
  industryFilters?: Maybe<Array<IndustryFilterOutput>>;
  /** @deprecated Use industryFilters */
  industryGroupIds?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Use industryFilters */
  industryIds?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Use industryFilters */
  industrySubGroupIds?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated use CompanyFilters */
  intermediaryIds?: Maybe<Array<Scalars['String']['output']>>;
  isCancelled?: Maybe<Scalars['Boolean']['output']>;
  isClientCancelled?: Maybe<Scalars['Boolean']['output']>;
  isClientLost?: Maybe<Scalars['Boolean']['output']>;
  /** Either client is lost or all next policies are rewrite */
  isClientLostForCarrier?: Maybe<Scalars['Boolean']['output']>;
  isCrossSell?: Maybe<Scalars['Boolean']['output']>;
  /** filters lost policies by detailed lost reason */
  isExpiredAndNonRenewed?: Maybe<Scalars['Boolean']['output']>;
  isFee?: Maybe<Scalars['Boolean']['output']>;
  isLost?: Maybe<Scalars['Boolean']['output']>;
  /** Either policy is lost or next policy is rewrite */
  isLostForCarrier?: Maybe<Scalars['Boolean']['output']>;
  /** Works only in written/primary-producer mode */
  isMonoline?: Maybe<Scalars['Boolean']['output']>;
  isRenewable?: Maybe<Scalars['Boolean']['output']>;
  isRewrite?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated use CompanyFilters */
  issuingPaperIds?: Maybe<Array<Scalars['String']['output']>>;
  location?: Maybe<LocationFilterOutput>;
  naicsSourceConfidenceScore?: Maybe<Array<FilterRangeOutput>>;
  /** All next policies are rewrite */
  nextPolicyClientIsRewrite?: Maybe<Scalars['Boolean']['output']>;
  nextPolicyIsRewrite?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated use CompanyFilters */
  parentCarrierIds?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated use CompanyFilters */
  parentIntermediaryIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Aggregation-based filter, incompatible with other aggregation-based filters */
  percentileFilter?: Maybe<PercentileFilterOutput>;
  period?: Maybe<PeriodOutput>;
  policyAgencyCodes?: Maybe<Array<Scalars['String']['output']>>;
  /** explicitly specify policy set */
  policyIds?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated use policySourcesFilter instead */
  policySources?: Maybe<Array<Scalars['String']['output']>>;
  policySourcesFilter?: Maybe<PolicySourceFilterOutput>;
  policyStatusType?: Maybe<PolicyStatusType>;
  policyStatuses?: Maybe<Array<AggPolicyStatus>>;
  premiumAccountSize?: Maybe<FilterRangeOutput>;
  /** @deprecated use userFilters */
  producerIds?: Maybe<Array<Scalars['String']['output']>>;
  productFilters?: Maybe<Array<ProductPolicyFilterOutput>>;
  /** @deprecated Use productFilters */
  productGroupIds?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Use productFilters */
  productIds?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Use productFilters */
  productInsuranceSegmentIds?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Use productFilters */
  productSuperGroupIds?: Maybe<Array<Scalars['String']['output']>>;
  /** search policies by keyword */
  query?: Maybe<Scalars['String']['output']>;
  /** Companies with AM Best rank */
  ranked?: Maybe<Scalars['Boolean']['output']>;
  renewalStatus?: Maybe<RenewalStatus>;
  /**
   * if true, filters for carriers and intermediaries will be joined using AND operator
   * @deprecated use CompanyFilters
   */
  separateCompanyFilters?: Maybe<Scalars['Boolean']['output']>;
  /**
   * if true, filters for producers and account managers will be joined using AND operator
   * @deprecated use userFilters
   */
  separateUserFilters?: Maybe<Scalars['Boolean']['output']>;
  sourceCompanyIds?: Maybe<Array<Scalars['String']['output']>>;
  sourceProductIds?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * US states iso 3166 codes
   * @deprecated Use location filter
   */
  states?: Maybe<Array<Scalars['String']['output']>>;
  /** Used for updating of stored last used time */
  storedId?: Maybe<Scalars['String']['output']>;
  /** @deprecated use CompanyFilters */
  ultimateCarrierIds?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated use CompanyFilters */
  ultimateIntermediaryIds?: Maybe<Array<Scalars['String']['output']>>;
  userFilters?: Maybe<UserFiltersOutput>;
  userFiltersAnd?: Maybe<Array<UserFiltersOutput>>;
};

export type PolicyListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PolicySortBy>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export enum PolicyMetric {
  AccountExecutiveDistinctCount = 'ACCOUNT_EXECUTIVE_DISTINCT_COUNT',
  AccountManagerDistinctCount = 'ACCOUNT_MANAGER_DISTINCT_COUNT',
  AvgPoliciesPerAm = 'AVG_POLICIES_PER_AM',
  AvgPremiumPerClient = 'AVG_PREMIUM_PER_CLIENT',
  AvgProducersPerAm = 'AVG_PRODUCERS_PER_AM',
  AvgProductPerClient = 'AVG_PRODUCT_PER_CLIENT',
  AvgRevenuePerAm = 'AVG_REVENUE_PER_AM',
  AvgRevenuePerClient = 'AVG_REVENUE_PER_CLIENT',
  /** @deprecated Use ULTIMATE_CARRIER_DISTINCT_COUNT instead */
  CarrierDistinctCount = 'CARRIER_DISTINCT_COUNT',
  ClientDistinctCount = 'CLIENT_DISTINCT_COUNT',
  CommissionAvg = 'COMMISSION_AVG',
  CommissionPctAvg = 'COMMISSION_PCT_AVG',
  CommissionSum = 'COMMISSION_SUM',
  DepartmentDistinctCount = 'DEPARTMENT_DISTINCT_COUNT',
  FeeSum = 'FEE_SUM',
  IndustryDistinctCount = 'INDUSTRY_DISTINCT_COUNT',
  IntermediaryDistinctCount = 'INTERMEDIARY_DISTINCT_COUNT',
  IssuingPaperDistinctCount = 'ISSUING_PAPER_DISTINCT_COUNT',
  MedianRevenuePerClient = 'MEDIAN_REVENUE_PER_CLIENT',
  NextRenewal = 'NEXT_RENEWAL',
  ParentCarrierDistinctCount = 'PARENT_CARRIER_DISTINCT_COUNT',
  PolicyCount = 'POLICY_COUNT',
  PremiumAvg = 'PREMIUM_AVG',
  PremiumSum = 'PREMIUM_SUM',
  PreviousCarrierDistinctCount = 'PREVIOUS_CARRIER_DISTINCT_COUNT',
  ProducerDistinctCount = 'PRODUCER_DISTINCT_COUNT',
  ProductDistinctCount = 'PRODUCT_DISTINCT_COUNT',
  RevenueAvg = 'REVENUE_AVG',
  RevenueSum = 'REVENUE_SUM',
  UltimateCarrierDistinctCount = 'ULTIMATE_CARRIER_DISTINCT_COUNT',
}

export enum PolicySortBy {
  BilledPremium = 'BILLED_PREMIUM',
  BilledRevenue = 'BILLED_REVENUE',
  EndDate = 'END_DATE',
  ExpirationDate = 'EXPIRATION_DATE',
  PolicyNumber = 'POLICY_NUMBER',
  Premium = 'PREMIUM',
  Revenue = 'REVENUE',
  StartDate = 'START_DATE',
}

/** Uses OR logic inside */
export type PolicySourceFilter = {
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  sources?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PolicySourceFilterOutput = {
  __typename?: 'PolicySourceFilterOutput';
  isEmpty?: Maybe<Scalars['Boolean']['output']>;
  sources?: Maybe<Array<Scalars['String']['output']>>;
};

export enum PolicyStatusType {
  /** Use retention fields taken from AMS */
  Ams = 'AMS',
  /** Use retention fields calculated by Ennabl */
  Ennabl = 'ENNABL',
}

export type PremiumFlow = {
  __typename?: 'PremiumFlow';
  bcoChart: PremiumFlowChartData;
  icoChart: PremiumFlowChartIcoData;
  intermediaryChart: PremiumFlowChartData;
  reportData: Array<PremiumFlowChartItem>;
};

export type PremiumFlowBcoChartArgs = {
  carrierLevel?: InputMaybe<CarrierLevel>;
  filter?: InputMaybe<PolicyFilter>;
};

export type PremiumFlowIcoChartArgs = {
  carrierLevel?: InputMaybe<CarrierLevel>;
  filter?: InputMaybe<PolicyFilter>;
};

export type PremiumFlowIntermediaryChartArgs = {
  carrierLevel?: InputMaybe<CarrierLevel>;
  filter?: InputMaybe<PolicyFilter>;
};

export type PremiumFlowReportDataArgs = {
  carrierLevel?: InputMaybe<CarrierLevel>;
  filter?: InputMaybe<PolicyFilter>;
  premiumFlowType: PremiumFlowType;
};

export type PremiumFlowChartData = {
  __typename?: 'PremiumFlowChartData';
  affiliates: PremiumFlowSectionData;
  direct: PremiumFlowSectionData;
};

export type PremiumFlowChartIcoData = {
  __typename?: 'PremiumFlowChartIcoData';
  direct: PremiumFlowSectionData;
  intermediary: PremiumFlowSectionData;
};

export type PremiumFlowChartItem = {
  __typename?: 'PremiumFlowChartItem';
  clientCount: Scalars['Int']['output'];
  commissionPctAvg?: Maybe<Scalars['Float']['output']>;
  commissionSum?: Maybe<Scalars['Float']['output']>;
  industryCount: Scalars['Int']['output'];
  /** @deprecated Use ultimateParent instead */
  issuingPaper?: Maybe<Market>;
  market: Market;
  /** @deprecated Use ultimateParent instead */
  payTo?: Maybe<Market>;
  policyCount: Scalars['Int']['output'];
  premiumSum: Scalars['Float']['output'];
  productCount: Scalars['Int']['output'];
  revenueSum: Scalars['Float']['output'];
  ultimateParent?: Maybe<Market>;
};

export type PremiumFlowSectionData = {
  __typename?: 'PremiumFlowSectionData';
  commissionPctAvg?: Maybe<Scalars['Float']['output']>;
  items: Array<PremiumFlowChartItem>;
  premiumSum: Scalars['Float']['output'];
};

export enum PremiumFlowType {
  Bco = 'BCO',
  Ico = 'ICO',
  Intermediary = 'INTERMEDIARY',
}

export type ProducerCredit = {
  __typename?: 'ProducerCredit';
  fullCredit: ProducerFullCreditPaginatedResult;
  sharedCredit: ProducerSharedCreditPaginatedResult;
};

export type ProducerCreditFullCreditArgs = {
  filter?: InputMaybe<PolicyFilter>;
  pagination?: InputMaybe<ProducerCreditPaginationInput>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type ProducerCreditSharedCreditArgs = {
  filter?: InputMaybe<PolicyFilter>;
  pagination?: InputMaybe<ProducerCreditPaginationInput>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type ProducerCreditPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<ProducerCreditSortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export enum ProducerCreditSortingField {
  ClientName = 'CLIENT_NAME',
  IndustryName = 'INDUSTRY_NAME',
  PolicyCount = 'POLICY_COUNT',
  ProducerShare = 'PRODUCER_SHARE',
  Revenue = 'REVENUE',
  SharedWith = 'SHARED_WITH',
}

export type ProducerCrossSellGoal = {
  __typename?: 'ProducerCrossSellGoal';
  metric: ProducerGoalMetric;
  /** In cents */
  value: Scalars['Float']['output'];
};

export type ProducerFullCreditPaginatedResult = {
  __typename?: 'ProducerFullCreditPaginatedResult';
  items: Array<ProducerFullCreditRow>;
  totalHits: Scalars['Int']['output'];
};

export type ProducerFullCreditRow = {
  __typename?: 'ProducerFullCreditRow';
  client: Client;
  policies: Scalars['Int']['output'];
  revenue: Scalars['Float']['output'];
};

export type ProducerGoal = {
  __typename?: 'ProducerGoal';
  crossSellGoal?: Maybe<ProducerCrossSellGoal>;
  netNewGoal?: Maybe<ProducerNetNewGoal>;
  performance: ProducerGoalPerformance;
  retentionGoal?: Maybe<ProducerRetentionGoal>;
  year: Scalars['Int']['output'];
};

export type ProducerGoalPerformanceArgs = {
  amountType?: InputMaybe<PolicyAmountType>;
};

export enum ProducerGoalMetric {
  Premium = 'PREMIUM',
  Revenue = 'REVENUE',
}

export type ProducerGoalPerformance = {
  __typename?: 'ProducerGoalPerformance';
  crossSell: ProducerGoalPerformanceValues;
  netNew: ProducerGoalPerformanceValues;
  retention: RetentionGoalPerformance;
};

export type ProducerGoalPerformanceValues = {
  __typename?: 'ProducerGoalPerformanceValues';
  clients: Scalars['Float']['output'];
  /** In cents */
  premium: Scalars['Float']['output'];
  /** In cents */
  revenue: Scalars['Float']['output'];
};

export type ProducerGoals = {
  __typename?: 'ProducerGoals';
  getForYear: ProducerGoal;
};

export type ProducerGoalsGetForYearArgs = {
  producerId?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type ProducerNetNewGoal = {
  __typename?: 'ProducerNetNewGoal';
  metric: ProducerGoalMetric;
  /** In cents */
  value: Scalars['Float']['output'];
};

export type ProducerPortal = {
  __typename?: 'ProducerPortal';
  crossSell: CrossSellOpportunityRecommendationPaginatedResult;
  crossSellFilters: Array<CrossSellFilter>;
  monoline: MonolineOpportunityV2PaginatedResult;
  netNew: NetNewOpportunityRecommendationPaginatedResult;
  netNewById?: Maybe<NetNewOpportunityRecommendation>;
  renewals: RenewalsOpportunityPaginatedResult;
  userIdByProducerId?: Maybe<AuthUser>;
  /** @deprecated use winBackV2 */
  winBack: WinBackOpportunityPaginatedResult;
  winBackV2: WinBackOpportunityV2PaginatedResult;
};

export type ProducerPortalCrossSellArgs = {
  pagination?: InputMaybe<CrossSellOpportunityRecommendationPaginationInput>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type ProducerPortalMonolineArgs = {
  filter?: InputMaybe<PolicyFilter>;
  pagination?: InputMaybe<PolicyListPaginationInput>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type ProducerPortalNetNewArgs = {
  pagination?: InputMaybe<NetNewOpportunityRecommendationPaginationInput>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type ProducerPortalNetNewByIdArgs = {
  recommendationId: Scalars['String']['input'];
};

export type ProducerPortalRenewalsArgs = {
  filter?: InputMaybe<PolicyFilter>;
  pagination?: InputMaybe<RenewalsListPaginationInput>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type ProducerPortalUserIdByProducerIdArgs = {
  producerId: Scalars['String']['input'];
};

export type ProducerPortalWinBackArgs = {
  filter?: InputMaybe<OpportunityFilter>;
  pagination?: InputMaybe<WinBackListPaginationInput>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type ProducerPortalWinBackV2Args = {
  filter?: InputMaybe<PolicyFilter>;
  pagination?: InputMaybe<PolicyListPaginationInput>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type ProducerRank = {
  __typename?: 'ProducerRank';
  filter?: Maybe<Scalars['String']['output']>;
  item: User;
  metric?: Maybe<Scalars['Float']['output']>;
  metricGrowth?: Maybe<Scalars['Float']['output']>;
  metricGrowthPct?: Maybe<Scalars['Float']['output']>;
  metricPrevious?: Maybe<Scalars['Float']['output']>;
  metricType?: Maybe<Scalars['String']['output']>;
  premium?: Maybe<Growth>;
  rank?: Maybe<Scalars['Int']['output']>;
  /** null represents that previous rank is more than 1000 or entity is not exists in previous period. Also, rankGrowth for rankByGrowth is not provided. */
  rankGrowth?: Maybe<Scalars['Int']['output']>;
  revenue?: Maybe<Growth>;
};

export type ProducerRankConnection = {
  __typename?: 'ProducerRankConnection';
  edges: Array<ProducerRankEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProducerRankEdge = {
  __typename?: 'ProducerRankEdge';
  cursor: Scalars['String']['output'];
  filter?: Maybe<Scalars['String']['output']>;
  metricType?: Maybe<Scalars['String']['output']>;
  node: ProducerRank;
};

export type ProducerRetentionGoal = {
  __typename?: 'ProducerRetentionGoal';
  metric: ProducerGoalMetric;
  /** Percentage, 0-1 */
  value: Scalars['Float']['output'];
};

export type ProducerSharedCreditPaginatedResult = {
  __typename?: 'ProducerSharedCreditPaginatedResult';
  items: Array<ProducerSharedCreditRow>;
  totalHits: Scalars['Int']['output'];
};

export type ProducerSharedCreditRow = {
  __typename?: 'ProducerSharedCreditRow';
  client: Client;
  myShare: Scalars['Float']['output'];
  policies: Scalars['Int']['output'];
  revenue: Scalars['Float']['output'];
  sharedWith: Array<User>;
};

export type Product = {
  __typename?: 'Product';
  adminProduct?: Maybe<AdminProduct>;
  children?: Maybe<Array<Product>>;
  id: Scalars['ID']['output'];
  /** Null for insurance segment itself */
  insuranceSegment?: Maybe<Product>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  level: ProductLevel;
  metrics?: Maybe<EntityMetrics>;
  name: Scalars['String']['output'];
  parent?: Maybe<Product>;
};

export type ProductAdmin = {
  __typename?: 'ProductAdmin';
  allProducts: Array<AdminProductExtended>;
  insuranceCompanySearchMappings: Array<AdminInsuranceCompany>;
  searchProducts: Array<AdminProductExtended>;
};

export type ProductAdminInsuranceCompanySearchMappingsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};

export type ProductAdminSearchProductsArgs = {
  query: Scalars['String']['input'];
};

export type ProductFilter = {
  isFee?: InputMaybe<Scalars['Boolean']['input']>;
  isOpportunity?: InputMaybe<Scalars['Boolean']['input']>;
  isPackage?: InputMaybe<Scalars['Boolean']['input']>;
  isRenewable?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ProductLevel {
  Group = 'GROUP',
  InsuranceSegment = 'INSURANCE_SEGMENT',
  Product = 'PRODUCT',
  SuperGroup = 'SUPER_GROUP',
}

export type ProductListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<ProductSortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export type ProductPaginatedResult = {
  __typename?: 'ProductPaginatedResult';
  items: Array<Product>;
  totalHits: Scalars['Int']['output'];
};

/** Filters policies and transactions by product ids. Products inside one ProductFilter are filtered using OR logic */
export type ProductPolicyFilter = {
  productGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  productIds?: InputMaybe<Array<Scalars['String']['input']>>;
  productInsuranceSegmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  productSuperGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ProductPolicyFilterOutput = {
  __typename?: 'ProductPolicyFilterOutput';
  productGroupIds?: Maybe<Array<Scalars['String']['output']>>;
  productIds?: Maybe<Array<Scalars['String']['output']>>;
  productInsuranceSegmentIds?: Maybe<Array<Scalars['String']['output']>>;
  productSuperGroupIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type ProductRank = {
  __typename?: 'ProductRank';
  filter?: Maybe<Scalars['String']['output']>;
  item: Product;
  metric?: Maybe<Scalars['Float']['output']>;
  metricGrowth?: Maybe<Scalars['Float']['output']>;
  metricGrowthPct?: Maybe<Scalars['Float']['output']>;
  metricPrevious?: Maybe<Scalars['Float']['output']>;
  metricType?: Maybe<Scalars['String']['output']>;
  premium?: Maybe<Growth>;
  rank?: Maybe<Scalars['Int']['output']>;
  /** null represents that previous rank is more than 1000 or entity is not exists in previous period. Also, rankGrowth for rankByGrowth is not provided. */
  rankGrowth?: Maybe<Scalars['Int']['output']>;
  revenue?: Maybe<Growth>;
};

export type ProductRankConnection = {
  __typename?: 'ProductRankConnection';
  edges: Array<ProductRankEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductRankEdge = {
  __typename?: 'ProductRankEdge';
  cursor: Scalars['String']['output'];
  filter?: Maybe<Scalars['String']['output']>;
  metricType?: Maybe<Scalars['String']['output']>;
  node: ProductRank;
};

export enum ProductSortingField {
  Commission = 'COMMISSION',
  Id = 'ID',
  Name = 'NAME',
  Premium = 'PREMIUM',
  Revenue = 'REVENUE',
}

export type ProductionCredit = {
  __typename?: 'ProductionCredit';
  accountManager?: Maybe<User>;
  amsPolicyStatus: AmsPolicyStatus;
  cancelDate?: Maybe<Scalars['SimpleDate']['output']>;
  carrier?: Maybe<Market>;
  client: Client;
  clientAgencyCodes?: Maybe<Array<Scalars['String']['output']>>;
  detailedStatus: DetailedPolicyStatus;
  endDate: Scalars['SimpleDate']['output'];
  /** min of cancelDate and endDate */
  expirationDate: Scalars['SimpleDate']['output'];
  /** Amount for the policy after filter is applied. For written amount type equal to `written`, for billed - sum of amounts of filtered transactions */
  filteredAmount: Amount;
  id: Scalars['ID']['output'];
  industry?: Maybe<Industry>;
  industryGroup?: Maybe<Industry>;
  industrySubGroup?: Maybe<Industry>;
  insuranceSegment?: Maybe<Product>;
  intermediary?: Maybe<Market>;
  isCrossSell: Scalars['Boolean']['output'];
  isInForce: Scalars['Boolean']['output'];
  isLost: Scalars['Boolean']['output'];
  isRewrite: Scalars['Boolean']['output'];
  isWinBack: Scalars['Boolean']['output'];
  issuingPaper?: Maybe<Market>;
  naicsSourceConfidenceScore?: Maybe<Scalars['String']['output']>;
  nextPolicyStartDate?: Maybe<Scalars['SimpleDate']['output']>;
  payTo?: Maybe<Market>;
  policyAgencyCodes?: Maybe<Array<Scalars['String']['output']>>;
  policyBilledAmount?: Maybe<Amount>;
  policyFilteredAmount: Amount;
  policyNumber?: Maybe<Scalars['String']['output']>;
  policySourceWrittenAmount?: Maybe<Amount>;
  policySources?: Maybe<Array<Scalars['String']['output']>>;
  policyWrittenAmount?: Maybe<Amount>;
  producer?: Maybe<User>;
  product?: Maybe<Product>;
  productGroup?: Maybe<Product>;
  productLines: Array<Product>;
  productSuperGroup?: Maybe<Product>;
  productionCreditPercentage: Scalars['Float']['output'];
  renewalStage?: Maybe<Scalars['String']['output']>;
  renewalStatus: RenewalStatus;
  startDate: Scalars['SimpleDate']['output'];
};

export type Products = {
  __typename?: 'Products';
  /** @deprecated Use paginated instead */
  list: Array<Product>;
  paginated: ProductPaginatedResult;
  tree: Array<Product>;
};

export type ProductsListArgs = {
  filter?: InputMaybe<ProductFilter>;
  pagination?: InputMaybe<ProductListPaginationInput>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ProductsPaginatedArgs = {
  filter?: InputMaybe<ProductFilter>;
  pagination?: InputMaybe<ProductListPaginationInput>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ProductsTreeArgs = {
  filter?: InputMaybe<ProductFilter>;
};

export type ProductsPerClient = {
  __typename?: 'ProductsPerClient';
  byAccountExecutive: Array<ProductsPerClientByUser>;
  byCarrier: Array<ProductsPerClientByCarrier>;
  byDepartment: Array<ProductsPerClientByDepartment>;
  byIndustry: Array<ProductsPerClientByIndustry>;
  byInsuranceSegment: Array<ProductsPerClientByInsuranceSegment>;
  byPolicyStatus: Array<ProductsPerClientByPolicyType>;
  byProducer: Array<ProductsPerClientByUser>;
};

export type ProductsPerClientByAccountExecutiveArgs = {
  filter: PolicyFilter;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductsPerClientByCarrierArgs = {
  filter: PolicyFilter;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductsPerClientByDepartmentArgs = {
  filter: PolicyFilter;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductsPerClientByIndustryArgs = {
  filter: PolicyFilter;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductsPerClientByInsuranceSegmentArgs = {
  filter: PolicyFilter;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductsPerClientByPolicyStatusArgs = {
  filter: PolicyFilter;
};

export type ProductsPerClientByProducerArgs = {
  filter: PolicyFilter;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductsPerClientByCarrier = {
  __typename?: 'ProductsPerClientByCarrier';
  entity: Market;
  statistic: ProductsPerClientStatistic;
};

export type ProductsPerClientByDepartment = {
  __typename?: 'ProductsPerClientByDepartment';
  entity: Department;
  statistic: ProductsPerClientStatistic;
};

export type ProductsPerClientByIndustry = {
  __typename?: 'ProductsPerClientByIndustry';
  entity: Industry;
  statistic: ProductsPerClientStatistic;
};

export type ProductsPerClientByInsuranceSegment = {
  __typename?: 'ProductsPerClientByInsuranceSegment';
  entity: Product;
  statistic: ProductsPerClientStatistic;
};

export type ProductsPerClientByPolicyType = {
  __typename?: 'ProductsPerClientByPolicyType';
  entity: AggPolicyStatus;
  statistic: ProductsPerClientStatistic;
};

export type ProductsPerClientByUser = {
  __typename?: 'ProductsPerClientByUser';
  entity: User;
  statistic: ProductsPerClientStatistic;
};

export type ProductsPerClientStatistic = {
  __typename?: 'ProductsPerClientStatistic';
  avg: Scalars['Float']['output'];
  max: Scalars['Float']['output'];
  maxClientCount: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
  minClientCount: Scalars['Float']['output'];
  mode: Scalars['Float']['output'];
  modeClientCount: Scalars['Float']['output'];
};

export type Prospect = {
  __typename?: 'Prospect';
  address?: Maybe<ProspectAddress>;
  created: Scalars['Timestamp']['output'];
  createdByAdmin: Scalars['Boolean']['output'];
  /** @deprecated Use prospectFor instead */
  createdByUser?: Maybe<AuthUser>;
  detailsFetched: Scalars['Boolean']['output'];
  /** Is not optimized for requesting in lists */
  dnbData?: Maybe<DnbCompany>;
  /** Applicable only for companies */
  duns?: Maybe<Scalars['String']['output']>;
  emails: Array<ProspectEmail>;
  forms5500?: Maybe<Array<Form5500>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  opportunityRecommendations?: Maybe<Array<OpportunityRecommendation>>;
  phones: Array<ProspectPhone>;
  prospectFor: Array<AuthUser>;
  /** Is not optimized for requesting in lists */
  rocketReachData?: Maybe<RocketReachPerson>;
  savedOpportunity?: Maybe<SavedOpportunity>;
  /** @deprecated Does not make sense anymore */
  source: ProspectSource;
  type: ProspectType;
};

export type ProspectSavedOpportunityArgs = {
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type ProspectAddress = {
  __typename?: 'ProspectAddress';
  city?: Maybe<Scalars['String']['output']>;
  /** USA or any other string for other countries. */
  country?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  regionCode?: Maybe<Scalars['String']['output']>;
  streetLine1?: Maybe<Scalars['String']['output']>;
  streetLine2?: Maybe<Scalars['String']['output']>;
};

export type ProspectCompanyCreateInput = {
  dnbId: Scalars['String']['input'];
};

export type ProspectDolCompanyCreateInput = {
  fein: Scalars['String']['input'];
};

export type ProspectEmail = {
  __typename?: 'ProspectEmail';
  email: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type ProspectIndividualCreateInput = {
  rocketReachId: Scalars['String']['input'];
};

export type ProspectPaginatedResult = {
  __typename?: 'ProspectPaginatedResult';
  items: Array<Prospect>;
  totalHits: Scalars['Int']['output'];
};

export type ProspectPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<ProspectSortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export type ProspectPhone = {
  __typename?: 'ProspectPhone';
  isdCode?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export enum ProspectSortingField {
  Name = 'NAME',
}

export enum ProspectSource {
  OpportunityFinder = 'OPPORTUNITY_FINDER',
}

export enum ProspectType {
  Company = 'COMPANY',
  DolCompany = 'DOL_COMPANY',
  Individual = 'INDIVIDUAL',
}

export type Prospects = {
  __typename?: 'Prospects';
  allProspects: Array<Prospect>;
  paginated: ProspectPaginatedResult;
  prospectById?: Maybe<Prospect>;
};

export type ProspectsPaginatedArgs = {
  pagination?: InputMaybe<ProspectPaginationInput>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ProspectsProspectByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Query = {
  __typename?: 'Query';
  activities: Activities;
  /** @deprecated Outdated */
  aggregateCrossSellOpportunity: AggregateCrossSellOpportunity;
  aggregateMonolineOpportunity: AggregateMonolineOpportunity;
  aggregatePolicy: AggregatedPolicy;
  aggregateWinBackOpportunity: AggregateWinBackOpportunity;
  bands: Bands;
  carrierAppetite: CarrierAppetite;
  client: Client;
  clients: Clients;
  contacts: Contacts;
  crossSellGenerator: CrossSellGenerator;
  customReporting: CustomReporting;
  departments: Departments;
  documents: Documents;
  ennablUsers: AuthUsers;
  enrichmentMappings: EnrichmentMappings;
  globalCarrierAppetite: GlobalCarrierAppetite;
  industries: Industries;
  industry: Industry;
  integrations: Integrations;
  lifetime: Lifetime;
  lossRuns: LossRuns;
  market: Market;
  markets: Markets;
  opportunities: Opportunities;
  opportunityReports: OpportunityReports;
  percentileRanking: PercentileRanking;
  policies: Array<Policy>;
  policiesByNumber: Array<Policy>;
  /** @deprecated use downloadPolicyCsvReport */
  policyReport: UserReport;
  premiumFlow: PremiumFlow;
  premiumOutflowInflow: OutflowInflow;
  producerCredit: ProducerCredit;
  producerGoals: ProducerGoals;
  producerPortal: ProducerPortal;
  product: Product;
  productAdmin: ProductAdmin;
  productionCreditTable: Array<ProductionCredit>;
  products: Products;
  productsPerClient: ProductsPerClient;
  prospects: Prospects;
  ranking: Ranking;
  reminders: Reminders;
  retention: RetentionResolverResponse;
  savedOpportunities: SavedOpportunities;
  savedPages: SavedPages;
  score360: Score360;
  score360Categories: Array<Score360Category>;
  search: SearchResult;
  sourceCodes: SourceCodes;
  storedFilters: StoredFilters;
  tableau: Tableau;
  /** @deprecated deprecated */
  topStatistics: TopStatistics;
  user: User;
  userInfo: UserInfo;
  userKpi: UserKpis;
  userKpiDashboards: UserKpiDashboards;
  userPageSettings?: Maybe<PageSettings>;
  userReportPresets: UserReportPresets;
  userReports: UserReports;
  userSettings?: Maybe<Array<PageSettings>>;
  users: Users;
};

export type QueryAggregatePolicyArgs = {
  filter?: InputMaybe<PolicyFilter>;
};

export type QueryClientArgs = {
  id: Scalars['ID']['input'];
};

export type QueryIndustryArgs = {
  id: Scalars['ID']['input'];
};

export type QueryIntegrationsArgs = {
  emulateLimitError?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMarketArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPercentileRankingArgs = {
  id: Scalars['String']['input'];
  policyEntity: PolicyEntity;
  policyFilter?: InputMaybe<PolicyFilter>;
  policyMetric: PolicyMetric;
};

export type QueryPoliciesArgs = {
  filter?: InputMaybe<PolicyFilter>;
  pagination?: InputMaybe<PolicyListPaginationInput>;
};

export type QueryPoliciesByNumberArgs = {
  policyNumber: Scalars['String']['input'];
};

export type QueryPolicyReportArgs = {
  filter?: InputMaybe<PolicyFilter>;
};

export type QueryProductArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProductionCreditTableArgs = {
  filter?: InputMaybe<PolicyFilter>;
  pagination?: InputMaybe<PolicyListPaginationInput>;
};

export type QuerySearchArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  producerMode?: InputMaybe<Scalars['Boolean']['input']>;
  query: Scalars['String']['input'];
  userFilter?: InputMaybe<SearchUserFilter>;
};

export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUserPageSettingsArgs = {
  pageId: Scalars['ID']['input'];
};

export type Range = {
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
};

export type Ranking = {
  __typename?: 'Ranking';
  byCarrier?: Maybe<RankingByCarrier>;
  byClient: RankingByClient;
  byIndustry?: Maybe<RankingByIndustry>;
  byProducer: RankingByProducer;
  byProduct?: Maybe<RankingByProduct>;
};

export type RankingByCarrierArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  carrierId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PolicyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  level?: InputMaybe<CompanyViewType>;
  metric: PolicyMetric;
  rankByGrowth?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<RankingSortingField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type RankingByClientArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PolicyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  metric: PolicyMetric;
  rankByGrowth?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<RankingSortingField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type RankingByIndustryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PolicyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  industryId?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  level?: InputMaybe<IndustryLevel>;
  metric: PolicyMetric;
  rankByGrowth?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<RankingSortingField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type RankingByProducerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PolicyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  metric: PolicyMetric;
  producerId?: InputMaybe<Scalars['String']['input']>;
  rankByGrowth?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<RankingSortingField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type RankingByProductArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PolicyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  metric: PolicyMetric;
  productId?: InputMaybe<Scalars['String']['input']>;
  rankByGrowth?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<RankingSortingField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type RankingByCarrier = {
  __typename?: 'RankingByCarrier';
  items: CarrierRankConnection;
  /** Carrier rank by id from params. */
  requestedItem?: Maybe<CarrierRank>;
};

export type RankingByClient = {
  __typename?: 'RankingByClient';
  items: ClientRankConnection;
  /** Client rank by id from params. */
  requestedItem?: Maybe<ClientRank>;
};

export type RankingByIndustry = {
  __typename?: 'RankingByIndustry';
  items: IndustryRankConnection;
  /** Industry rank by id from params. */
  requestedItem?: Maybe<IndustryRank>;
};

export type RankingByProducer = {
  __typename?: 'RankingByProducer';
  items: ProducerRankConnection;
  /** Producer rank by id from params. */
  requestedItem?: Maybe<ProducerRank>;
};

export type RankingByProduct = {
  __typename?: 'RankingByProduct';
  items: ProductRankConnection;
  /** Product rank by id from params. */
  requestedItem?: Maybe<ProductRank>;
};

export enum RankingSortingField {
  Metric = 'METRIC',
  Name = 'NAME',
  Rank = 'RANK',
}

export type Rating = {
  __typename?: 'Rating';
  title: Scalars['String']['output'];
};

export type Reminder = {
  __typename?: 'Reminder';
  createdAt: Scalars['Timestamp']['output'];
  date: Scalars['Timestamp']['output'];
  id: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  savedOpportunity: SavedOpportunity;
  types: Array<ReminderType>;
};

export type ReminderInput = {
  date: Scalars['Timestamp']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  types: Array<ReminderType>;
};

export enum ReminderType {
  Call = 'CALL',
  Email = 'EMAIL',
  Visit = 'VISIT',
}

export type Reminders = {
  __typename?: 'Reminders';
  paginated: RemindersPaginatedResult;
};

export type RemindersPaginatedArgs = {
  pagination?: InputMaybe<RemindersPaginationInput>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type RemindersPaginatedResult = {
  __typename?: 'RemindersPaginatedResult';
  items: Array<Reminder>;
  totalHits: Scalars['Int']['output'];
};

export type RemindersPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<RemindersPaginationSortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export enum RemindersPaginationSortingField {
  Date = 'DATE',
}

export type RemoveResponseFromFavoritesPayload = {
  __typename?: 'RemoveResponseFromFavoritesPayload';
  success: Scalars['Boolean']['output'];
};

export type RenameOpportunityReportPayload = {
  __typename?: 'RenameOpportunityReportPayload';
  report: OpportunityReport;
};

export enum RenewalStatus {
  New = 'NEW',
  Renewed = 'RENEWED',
}

export type RenewalsListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<RenewalsSortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export type RenewalsOpportunity = {
  __typename?: 'RenewalsOpportunity';
  accountManager?: Maybe<User>;
  /** @deprecated use policy instead */
  billed: Amount;
  carrier?: Maybe<Market>;
  client?: Maybe<Client>;
  /**
   * min of cancelDate and endDate
   * @deprecated use policy instead
   */
  expirationDate: Scalars['SimpleDate']['output'];
  /** to use for caching */
  id: Scalars['ID']['output'];
  policy: Policy;
  /** @deprecated use policy instead */
  policyNumber?: Maybe<Scalars['String']['output']>;
  producers?: Maybe<Array<User>>;
  product?: Maybe<Product>;
  saved?: Maybe<SavedOpportunityRenewal>;
  /** @deprecated use policy instead */
  written: Amount;
};

export type RenewalsOpportunityPaginatedResult = {
  __typename?: 'RenewalsOpportunityPaginatedResult';
  items: Array<RenewalsOpportunity>;
  totalClients: Scalars['Float']['output'];
  totalHits: Scalars['Int']['output'];
};

export enum RenewalsSortingField {
  BilledPremium = 'BILLED_PREMIUM',
  BilledRevenue = 'BILLED_REVENUE',
  ExpirationDate = 'EXPIRATION_DATE',
  PolicyNumber = 'POLICY_NUMBER',
  Premium = 'PREMIUM',
  Revenue = 'REVENUE',
  StartDate = 'START_DATE',
}

export enum ReportEntity {
  Carrier = 'CARRIER',
  Industry = 'INDUSTRY',
  Producer = 'PRODUCER',
  Product = 'PRODUCT',
}

/** contains parameters to generate a report */
export type ReportParameters = {
  __typename?: 'ReportParameters';
  /** applicable to account reports */
  accountReportEntity?: Maybe<AccountsReportEntity>;
  /** applicable to reports by carrier, default is Ultimate Parent */
  carrierLevel?: Maybe<CarrierLevel>;
  /** applicable to custom aggregated reports */
  customAggregatedParameters?: Maybe<CustomAggregatedPreviewParamsOutput>;
  /** applicable to custom aggregated reports */
  customPlainParameters?: Maybe<CustomPlainPreviewParamsOutput>;
  /** applicable to data audit reports */
  dataAuditReportType?: Maybe<DataAuditReportType>;
  fileType: UserReportFileType;
  filterName?: Maybe<Scalars['String']['output']>;
  /** applicable to policy and production credit lists reports */
  includeSourceData?: Maybe<Scalars['Boolean']['output']>;
  /** applicable to leaderboard reports */
  leaderboardReportEntity?: Maybe<LeaderboardReportEntity>;
  /** applicable to reports with OpportunityFilter parameter */
  opportunityFilter?: Maybe<OpportunityFilterOutput>;
  /** applicable to reports with PolicyFilter parameter */
  policyFilter?: Maybe<PolicyFilterOutput>;
  /** @deprecated We have id in the preset and it's not an appropriate cache key for ReportParameters */
  presetId?: Maybe<Scalars['ID']['output']>;
  /** applicable to leaderboard report */
  reportEntity?: Maybe<ReportEntity>;
  /** applicable to retention insight report */
  retentionInsightParameters?: Maybe<RetentionInsightsParameters>;
  /** applicable to retention reports */
  retentionParameters?: Maybe<RetentionParameters>;
  /** applicable to score-360 reports */
  score360Parameters?: Maybe<Score360ReportParameters>;
  /** applicable to saved opportunity reports */
  userId?: Maybe<Scalars['ID']['output']>;
};

export type ReportParametersInput = {
  /** applicable to custom aggregated reports */
  customAggregatedParameters?: InputMaybe<CustomAggregatedPreviewParams>;
  /** applicable to custom plain reports */
  customPlainParameters?: InputMaybe<CustomPlainPreviewParams>;
  /** applicable to data audit reports */
  dataAuditReportType?: InputMaybe<DataAuditReportType>;
  fileType: UserReportFileType;
  /** applicable to policy and production credit lists reports */
  includeSourceData?: InputMaybe<Scalars['Boolean']['input']>;
  /** applicable to reports with OpportunityFilter */
  opportunityFilter?: InputMaybe<OpportunityFilter>;
  /** applicable to reports with PolicyFilter */
  policyFilter?: InputMaybe<PolicyFilter>;
  /** applicable to leaderboard report */
  reportEntity?: InputMaybe<ReportEntity>;
};

export type ReportSubscriptionSchedule = {
  __typename?: 'ReportSubscriptionSchedule';
  /** 1-31 */
  daysOfMonth?: Maybe<Array<Scalars['Int']['output']>>;
  /** 1-7. 1 is Monday */
  daysOfWeek?: Maybe<Array<Scalars['Int']['output']>>;
  repeatEach?: Maybe<ReportSubscriptionScheduleRepetition>;
  subscribeEnnablUserIds?: Maybe<Array<Scalars['ID']['output']>>;
};

export type ReportSubscriptionScheduleInput = {
  /** 1-31 */
  daysOfMonth?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** 1-7. 1 is Monday */
  daysOfWeek?: InputMaybe<Array<Scalars['Int']['input']>>;
  repeatEach?: InputMaybe<ReportSubscriptionScheduleRepetitionInput>;
  subscribeEnnablUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ReportSubscriptionScheduleRepetition = {
  __typename?: 'ReportSubscriptionScheduleRepetition';
  startAt: Scalars['Timestamp']['output'];
  type: ReportSubscriptionScheduleRepetitionType;
  /** 1-inf */
  value: Scalars['Int']['output'];
};

export type ReportSubscriptionScheduleRepetitionInput = {
  startAt: Scalars['Timestamp']['input'];
  type: ReportSubscriptionScheduleRepetitionType;
  /** 1-inf */
  value: Scalars['Int']['input'];
};

export enum ReportSubscriptionScheduleRepetitionType {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
}

export type Retention = {
  __typename?: 'Retention';
  retainedPct?: Maybe<Scalars['Float']['output']>;
  retainedValue?: Maybe<Scalars['Float']['output']>;
  totalValue?: Maybe<Scalars['Float']['output']>;
};

export type RetentionByCarrier = {
  __typename?: 'RetentionByCarrier';
  item: Market;
  retention: RetentionByMetric;
};

export type RetentionByDepartment = {
  __typename?: 'RetentionByDepartment';
  item: Department;
  retention: RetentionByMetric;
};

export type RetentionByIndustry = {
  __typename?: 'RetentionByIndustry';
  item: Industry;
  retention: RetentionByMetric;
};

export type RetentionByMetric = {
  __typename?: 'RetentionByMetric';
  clientCount: Retention;
  policyCount: Retention;
  premium: Retention;
  revenue: Retention;
};

export type RetentionByProduct = {
  __typename?: 'RetentionByProduct';
  item: Product;
  retention: RetentionByMetric;
};

export type RetentionByUser = {
  __typename?: 'RetentionByUser';
  item?: Maybe<User>;
  retention: RetentionByMetric;
};

export type RetentionGoalPerformance = {
  __typename?: 'RetentionGoalPerformance';
  premiumPct: Scalars['Float']['output'];
  revenuePct: Scalars['Float']['output'];
};

export type RetentionInsightsParameters = {
  __typename?: 'RetentionInsightsParameters';
  entity: RetentionReportEntity;
  /** Provided Id. If not set then values without entity will return. */
  entityId?: Maybe<Scalars['String']['output']>;
};

export type RetentionInsightsParametersInput = {
  entity: RetentionReportEntity;
  /** Provided Id. If not set then values without entity will return. */
  entityId?: InputMaybe<Scalars['String']['input']>;
};

export type RetentionParameters = {
  __typename?: 'RetentionParameters';
  entity: RetentionReportEntity;
  entityIds?: Maybe<Array<Scalars['String']['output']>>;
  includeEmpty?: Maybe<Scalars['Boolean']['output']>;
  metric: RetentionPolicyMetric;
};

export type RetentionParametersInput = {
  entity: RetentionReportEntity;
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  includeEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  metric: RetentionPolicyMetric;
};

export enum RetentionPolicyMetric {
  ClientCount = 'CLIENT_COUNT',
  PolicyCount = 'POLICY_COUNT',
  Premium = 'PREMIUM',
  Revenue = 'REVENUE',
}

export enum RetentionReportEntity {
  AccountManager = 'ACCOUNT_MANAGER',
  Carrier = 'CARRIER',
  Department = 'DEPARTMENT',
  Industry = 'INDUSTRY',
  /** @deprecated don't use it, will be removed soon */
  PolicyType = 'POLICY_TYPE',
  Producer = 'PRODUCER',
  Product = 'PRODUCT',
}

export type RetentionResolverResponse = {
  __typename?: 'RetentionResolverResponse';
  byAccountManager: Array<RetentionByUser>;
  byCarrier: Array<RetentionByCarrier>;
  byDepartment: Array<RetentionByDepartment>;
  byIndustry: Array<RetentionByIndustry>;
  byProducer: Array<RetentionByUser>;
  byProduct: Array<RetentionByProduct>;
  total: RetentionByMetric;
};

export type RetentionResolverResponseByAccountManagerArgs = {
  filter: PolicyFilter;
};

export type RetentionResolverResponseByCarrierArgs = {
  filter: PolicyFilter;
};

export type RetentionResolverResponseByDepartmentArgs = {
  filter: PolicyFilter;
};

export type RetentionResolverResponseByIndustryArgs = {
  filter: PolicyFilter;
};

export type RetentionResolverResponseByProducerArgs = {
  filter: PolicyFilter;
};

export type RetentionResolverResponseByProductArgs = {
  filter: PolicyFilter;
};

export type RetentionResolverResponseTotalArgs = {
  filter: PolicyFilter;
};

export type RocketReachEducation = {
  __typename?: 'RocketReachEducation';
  degree?: Maybe<Scalars['String']['output']>;
  endYear?: Maybe<Scalars['Int']['output']>;
  major?: Maybe<Scalars['String']['output']>;
  school?: Maybe<Scalars['String']['output']>;
  startYear?: Maybe<Scalars['Int']['output']>;
};

export type RocketReachEmail = {
  __typename?: 'RocketReachEmail';
  email?: Maybe<Scalars['String']['output']>;
  lastValidationCheck?: Maybe<Scalars['Timestamp']['output']>;
  smtpValid?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type RocketReachJob = {
  __typename?: 'RocketReachJob';
  company?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['SimpleDate']['output']>;
  isCurrent: Scalars['Boolean']['output'];
  lastUpdated?: Maybe<Scalars['Timestamp']['output']>;
  startDate?: Maybe<Scalars['SimpleDate']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RocketReachLocation = {
  /** Country iso3166alpha2code */
  country?: InputMaybe<Scalars['String']['input']>;
  /** city, town, village, etc */
  locality?: InputMaybe<Scalars['String']['input']>;
  /** Search radius around specified locations. Postal code or either country, region and locality must be specified */
  locationRadius?: InputMaybe<RocketReachLocationRadius>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** state, province, region, etc */
  region?: InputMaybe<Scalars['String']['input']>;
};

export type RocketReachLocationRadius = {
  /** Search radius around specified location */
  radius: Scalars['Float']['input'];
  /** Unit of measurement for the radius */
  unit: LocationRadiusUnit;
};

export type RocketReachPerson = {
  __typename?: 'RocketReachPerson';
  /** Parsed from location */
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  currentTitle?: Maybe<Scalars['String']['output']>;
  educations: Array<RocketReachEducation>;
  emails: Array<RocketReachEmail>;
  id: Scalars['ID']['output'];
  jobs: Array<RocketReachJob>;
  links: Array<RocketReachUrl>;
  location?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  normalizedTitle?: Maybe<Scalars['String']['output']>;
  phones: Array<RocketReachPhone>;
  profilePic?: Maybe<Scalars['String']['output']>;
  prospect?: Maybe<Prospect>;
  /** 2-letter region code, only US, parsed from location */
  region?: Maybe<Scalars['String']['output']>;
  skills: Array<Scalars['String']['output']>;
  status: RocketReachUpdateStatus;
};

export type RocketReachPersonProspectArgs = {
  anyOwner?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RocketReachPersonCached = {
  __typename?: 'RocketReachPersonCached';
  data: RocketReachPerson;
  lastUpdated: Scalars['Timestamp']['output'];
};

export type RocketReachPhone = {
  __typename?: 'RocketReachPhone';
  number?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** If many parameters provided with multiple values, it's interpreted as an “AND” between filters, and “OR” within the filters. */
export type RocketReachQuery = {
  companyIndustryTags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Current employer */
  companyName?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Dollars */
  companyRevenue?: InputMaybe<Range>;
  /** Dollars */
  companySize?: InputMaybe<Range>;
  /** Country iso3166alpha2code */
  country?: InputMaybe<Array<Scalars['String']['input']>>;
  degree?: InputMaybe<Array<Scalars['String']['input']>>;
  department?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Array<Scalars['String']['input']>>;
  /** city, town, village, etc */
  locality?: InputMaybe<Array<Scalars['String']['input']>>;
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  locations?: InputMaybe<Array<RocketReachLocation>>;
  major?: InputMaybe<Array<Scalars['String']['input']>>;
  managementLevel?: InputMaybe<Array<Scalars['String']['input']>>;
  naicsCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** A fuzzy-matched name, or exact if surrounded by quotes */
  name?: InputMaybe<Array<Scalars['String']['input']>>;
  normalizedTitle?: InputMaybe<Array<Scalars['String']['input']>>;
  postalCode?: InputMaybe<Array<Scalars['String']['input']>>;
  /** state, province, region, etc */
  region?: InputMaybe<Array<Scalars['String']['input']>>;
  sicCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RocketReachSearchData = {
  __typename?: 'RocketReachSearchData';
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  currentTitle?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use integrations.searchClient query instead */
  ennablClientId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  links: Array<RocketReachUrl>;
  location?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  normalizedTitle?: Maybe<Scalars['String']['output']>;
  profilePic?: Maybe<Scalars['String']['output']>;
  prospect?: Maybe<Prospect>;
  /** 2-letter region code */
  region?: Maybe<Scalars['String']['output']>;
  teaser?: Maybe<RocketReachTeaser>;
};

export type RocketReachSearchDataProspectArgs = {
  anyOwner?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RocketReachSearchItem = {
  __typename?: 'RocketReachSearchItem';
  data: RocketReachSearchData;
  score: Scalars['Float']['output'];
};

export type RocketReachSearchResult = {
  __typename?: 'RocketReachSearchResult';
  items: Array<RocketReachSearchItem>;
  /** Offset for the next page */
  next: Scalars['Float']['output'];
  offset: Scalars['Float']['output'];
  totalHits: Scalars['Float']['output'];
};

export type RocketReachTeaser = {
  __typename?: 'RocketReachTeaser';
  emails: Array<Scalars['String']['output']>;
  officePhones: Array<Scalars['String']['output']>;
  phones: Array<Scalars['String']['output']>;
  preview: Array<Scalars['String']['output']>;
};

export enum RocketReachUpdateStatus {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  NotQueued = 'NOT_QUEUED',
  Progress = 'PROGRESS',
  Searching = 'SEARCHING',
  Waiting = 'WAITING',
}

export type RocketReachUrl = {
  __typename?: 'RocketReachUrl';
  type?: Maybe<RocketReachUrlType>;
  typeSource: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum RocketReachUrlType {
  Aboutme = 'ABOUTME',
  Angel = 'ANGEL',
  Angellist = 'ANGELLIST',
  Crunchbase = 'CRUNCHBASE',
  Facebook = 'FACEBOOK',
  Github = 'GITHUB',
  Google = 'GOOGLE',
  Googleplus = 'GOOGLEPLUS',
  Gravatar = 'GRAVATAR',
  Hi5 = 'HI5',
  Klout = 'KLOUT',
  Linkedin = 'LINKEDIN',
  Meetup = 'MEETUP',
  Pinterest = 'PINTEREST',
  Quora = 'QUORA',
  Twitter = 'TWITTER',
}

export type SaveManyPagesResult = {
  __typename?: 'SaveManyPagesResult';
  savedPages: Array<SavedPage>;
};

export type SaveOpportunityReportPayload = {
  __typename?: 'SaveOpportunityReportPayload';
  report: OpportunityReport;
};

export type SavedOpportunities = {
  __typename?: 'SavedOpportunities';
  byId?: Maybe<SavedOpportunity>;
  deleted: SavedOpportunityPaginatedResult;
  /** Net new opportunities count with no details fetched */
  netNewInProgressCount?: Maybe<Scalars['Int']['output']>;
  opportunityLists: OpportunityLists;
  paginated: SavedOpportunityPaginatedResult;
};

export type SavedOpportunitiesByIdArgs = {
  id: Scalars['ID']['input'];
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type SavedOpportunitiesDeletedArgs = {
  pagination?: InputMaybe<SavedOpportunityPaginationInput>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type SavedOpportunitiesNetNewInProgressCountArgs = {
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type SavedOpportunitiesPaginatedArgs = {
  filter?: InputMaybe<SavedOpportunitiesFilter>;
  pagination?: InputMaybe<SavedOpportunityPaginationInput>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type SavedOpportunitiesCountPutResult = {
  __typename?: 'SavedOpportunitiesCountPutResult';
  updated: Scalars['Float']['output'];
};

export type SavedOpportunitiesFilter = {
  label?: InputMaybe<SavedOpportunityLabel>;
  listId?: InputMaybe<Scalars['ID']['input']>;
};

export enum SavedOpportunitiesPaginationSortingField {
  CreateDate = 'CREATE_DATE',
  CustomerEmployees = 'CUSTOMER_EMPLOYEES',
  CustomerName = 'CUSTOMER_NAME',
  Industry = 'INDUSTRY',
  NextRenewalDate = 'NEXT_RENEWAL_DATE',
  PremiumInForce = 'PREMIUM_IN_FORCE',
  ProductAmount = 'PRODUCT_AMOUNT',
  Title = 'TITLE',
  UpdateDate = 'UPDATE_DATE',
}

export type SavedOpportunitiesPermanentlyDeleteResult = {
  __typename?: 'SavedOpportunitiesPermanentlyDeleteResult';
  success: Scalars['Boolean']['output'];
};

export type SavedOpportunitiesPutOneResult = {
  __typename?: 'SavedOpportunitiesPutOneResult';
  updated: SavedOpportunity;
};

export type SavedOpportunitiesPutResult = {
  __typename?: 'SavedOpportunitiesPutResult';
  updated: Array<Maybe<SavedOpportunity>>;
};

export type SavedOpportunity = {
  __typename?: 'SavedOpportunity';
  /** Long-time resolving, only for ADDRESS type */
  addressData?: Maybe<HazardhubResponse>;
  /** Only for CLIENT type */
  client?: Maybe<Client>;
  created: Scalars['Timestamp']['output'];
  deleted?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  labels: Array<SavedOpportunityLabel>;
  lists?: Maybe<Array<OpportunityList>>;
  /** Only for PROSPECT type */
  prospect?: Maybe<Prospect>;
  reminders?: Maybe<Array<Reminder>>;
  reportId?: Maybe<Scalars['String']['output']>;
  /** Only for CLIENT type */
  savedCrossSell: Array<SavedOpportunityCrossSell>;
  /** Only for CLIENT type */
  savedRenewals: Array<SavedOpportunityRenewal>;
  /** Only for CLIENT type */
  savedWinBack: Array<SavedOpportunityWinBack>;
  title: Scalars['String']['output'];
  type: SavedOpportunityType;
  updated: Scalars['Timestamp']['output'];
};

export type SavedOpportunityRemindersArgs = {
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type SavedOpportunityCrossSell = {
  __typename?: 'SavedOpportunityCrossSell';
  created: Scalars['Timestamp']['output'];
  fromNotBoughtSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  product?: Maybe<Product>;
};

export type SavedOpportunityCrossSellRecommendationInput = {
  clientId: Scalars['String']['input'];
  crossSellNotBoughtProductIds?: InputMaybe<Array<Scalars['String']['input']>>;
  crossSellProductIds?: InputMaybe<Array<Scalars['String']['input']>>;
  renewalPolicyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** policy IDs */
  winBackOpportunityIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SavedOpportunityDnbNetNewInput = {
  duns: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SavedOpportunityDolNetNewInput = {
  fein: Scalars['String']['input'];
};

export enum SavedOpportunityLabel {
  CrossSell = 'CROSS_SELL',
  Monoline = 'MONOLINE',
  NetNew = 'NET_NEW',
  Renewal = 'RENEWAL',
  WinBack = 'WIN_BACK',
}

export type SavedOpportunityMonoline = {
  __typename?: 'SavedOpportunityMonoline';
  created: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  /** Null if policy is not found */
  policy?: Maybe<Policy>;
};

export type SavedOpportunityPaginatedResult = {
  __typename?: 'SavedOpportunityPaginatedResult';
  items: Array<SavedOpportunity>;
  totalHits: Scalars['Int']['output'];
};

export type SavedOpportunityPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SavedOpportunitiesPaginationSortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export type SavedOpportunityRenewal = {
  __typename?: 'SavedOpportunityRenewal';
  created: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  /** Null if policy is not found */
  policy?: Maybe<Policy>;
};

export type SavedOpportunityRocketReachNetNewInput = {
  name: Scalars['String']['input'];
  rocketReachId: Scalars['String']['input'];
};

export enum SavedOpportunityType {
  Address = 'ADDRESS',
  Client = 'CLIENT',
  Prospect = 'PROSPECT',
}

export type SavedOpportunityWinBack = {
  __typename?: 'SavedOpportunityWinBack';
  created: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  /** Null if policy is not found */
  policy?: Maybe<Policy>;
};

export type SavedPage = {
  __typename?: 'SavedPage';
  createdAt: Scalars['Timestamp']['output'];
  iconType?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type SavedPageInput = {
  iconType?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type SavedPages = {
  __typename?: 'SavedPages';
  all?: Maybe<Array<SavedPage>>;
  isPageSaved: Scalars['Boolean']['output'];
};

export type SavedPagesIsPageSavedArgs = {
  url: Scalars['String']['input'];
};

export type Score360 = {
  __typename?: 'Score360';
  averageScore: Score360AverageScore;
  clients: Score360ClientPaginatedResult;
  clientsCount: Scalars['Int']['output'];
  producers: Score360ProducerPaginatedResult;
};

export type Score360AverageScoreArgs = {
  filter?: InputMaybe<Score360Filter>;
  viewMode?: InputMaybe<Score360ViewMode>;
};

export type Score360ClientsArgs = {
  filter?: InputMaybe<Score360Filter>;
  pagination?: InputMaybe<Score360ClientPaginationInput>;
  query?: InputMaybe<Scalars['String']['input']>;
  viewMode?: InputMaybe<Score360ViewMode>;
};

export type Score360ClientsCountArgs = {
  filter?: InputMaybe<Score360Filter>;
  viewMode?: InputMaybe<Score360ViewMode>;
};

export type Score360ProducersArgs = {
  filter?: InputMaybe<Score360Filter>;
  pagination?: InputMaybe<Score360ProducerPaginationInput>;
  query?: InputMaybe<Scalars['String']['input']>;
};

/** Average score per revenue band and total */
export type Score360AverageScore = {
  __typename?: 'Score360AverageScore';
  large: Scalars['Float']['output'];
  medium: Scalars['Float']['output'];
  small: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

/** Category to bucket clients by */
export type Score360Category = {
  __typename?: 'Score360Category';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** UI sort order of this category */
  order: Scalars['Float']['output'];
};

export type Score360ClientPaginatedResult = {
  __typename?: 'Score360ClientPaginatedResult';
  items: Array<Score360ClientRow>;
  totalHits: Scalars['Int']['output'];
};

export type Score360ClientPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Enables pagination by client field. Incompatible with sortByCategoryId field */
  sortBy?: InputMaybe<Score360ClientSortingField>;
  /** Enables pagination by score category. Incompatible with sortBy field */
  sortByCategoryId?: InputMaybe<Scalars['String']['input']>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

/** Primary producer of a client with business in category */
export type Score360ClientProducer = {
  __typename?: 'Score360ClientProducer';
  category: Score360Category;
  producer: User;
};

/** Model for client 360 table */
export type Score360ClientRow = {
  __typename?: 'Score360ClientRow';
  categories: Array<Score360Category>;
  client: Client;
  producers: Array<Score360ClientProducer>;
  score: Scalars['Float']['output'];
};

export enum Score360ClientSortingField {
  Name = 'NAME',
  Producers = 'PRODUCERS',
  RevenueBand = 'REVENUE_BAND',
  Score = 'SCORE',
}

export type Score360Filter = {
  accountSize?: InputMaybe<FilterRange>;
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  industryFilter?: InputMaybe<IndustryFilter>;
  producerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  score360?: InputMaybe<FilterRange>;
};

export type Score360FilterOutput = {
  __typename?: 'Score360FilterOutput';
  accountSize?: Maybe<FilterRangeOutput>;
  departmentIds?: Maybe<Array<Scalars['String']['output']>>;
  industryFilter?: Maybe<IndustryFilterOutput>;
  producerIds?: Maybe<Array<Scalars['String']['output']>>;
  score360?: Maybe<FilterRangeOutput>;
};

export type Score360ProducerPaginatedResult = {
  __typename?: 'Score360ProducerPaginatedResult';
  items: Array<Score360ProducerRow>;
  totalHits: Scalars['Int']['output'];
};

export type Score360ProducerPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Score360ProducerSortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

/** Model for producer 360 table */
export type Score360ProducerRow = {
  __typename?: 'Score360ProducerRow';
  averageScore: Score360AverageScore;
  producer: User;
};

export enum Score360ProducerSortingField {
  LargeScore = 'LARGE_SCORE',
  MediumScore = 'MEDIUM_SCORE',
  Name = 'NAME',
  SmallScore = 'SMALL_SCORE',
  TotalScore = 'TOTAL_SCORE',
}

export type Score360ReportParameters = {
  __typename?: 'Score360ReportParameters';
  filter: Score360FilterOutput;
  viewMode: Score360ViewMode;
};

/** Defines the flow for score360 page */
export enum Score360ViewMode {
  FullBook = 'FULL_BOOK',
  ProducerBook = 'PRODUCER_BOOK',
}

export type SearchEntity = Client | Industry | Market | Product | Prospect | SearchPage | User;

export type SearchPage = {
  __typename?: 'SearchPage';
  id: Scalars['ID']['output'];
  subTab?: Maybe<Scalars['String']['output']>;
  tab?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type SearchResult = {
  __typename?: 'SearchResult';
  results: Array<SearchResultItem>;
};

export type SearchResultItem = {
  __typename?: 'SearchResultItem';
  /** Unique identifier of search result. Could be identifier of entity or not. */
  id: Scalars['ID']['output'];
  item: SearchEntity;
  /** additional metadata for search item */
  meta?: Maybe<Scalars['String']['output']>;
  subTab?: Maybe<Scalars['String']['output']>;
  tab?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export type SearchUserFilter = {
  amsRoles?: InputMaybe<Array<UserAmsRole>>;
};

export type SetHazardhubCachedIdResponse = {
  __typename?: 'SetHazardhubCachedIdResponse';
  success: Scalars['Boolean']['output'];
};

export type SetRocketReachIdResponse = {
  __typename?: 'SetRocketReachIdResponse';
  success: Scalars['Boolean']['output'];
};

export type SicCode = {
  __typename?: 'SicCode';
  code: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
};

/** Base class for simple entities with id and name */
export type SimpleEntity = {
  __typename?: 'SimpleEntity';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type SimplePagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SourceCodes = {
  __typename?: 'SourceCodes';
  clientAgencyCodes: Array<AgencyCodeCategory>;
  policyAgencyCodes: Array<AgencyCodeCategory>;
  policySources: Array<AgencyCode>;
};

export type Specialist = {
  __typename?: 'Specialist';
  groupName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  role: Scalars['String']['output'];
  sourceRoleDescription: Scalars['String']['output'];
  user: User;
};

export type StoredFilter = {
  __typename?: 'StoredFilter';
  filter: PolicyFilterOutput;
  id: Scalars['ID']['output'];
  lastUsed?: Maybe<Scalars['Timestamp']['output']>;
  name: Scalars['String']['output'];
  type: StoredFilterType;
};

export enum StoredFilterType {
  Recent = 'RECENT',
  Saved = 'SAVED',
}

export type StoredFilters = {
  __typename?: 'StoredFilters';
  byId?: Maybe<StoredFilter>;
  recent: Array<StoredFilter>;
  saved: Array<StoredFilter>;
};

export type StoredFiltersByIdArgs = {
  id: Scalars['ID']['input'];
};

export type StoredFiltersRecentArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type StoredFiltersCreatePayload = {
  filter: PolicyFilter;
  /** Needed to simplify the migration from FE */
  lastUsed?: InputMaybe<Scalars['Timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type: StoredFilterType;
};

export type StoredFiltersCreateResponse = {
  __typename?: 'StoredFiltersCreateResponse';
  created: StoredFilter;
};

export type StoredFiltersDeleteResponse = {
  __typename?: 'StoredFiltersDeleteResponse';
  success: Scalars['Boolean']['output'];
};

export type StoredFiltersUpdateLastUserResponse = {
  __typename?: 'StoredFiltersUpdateLastUserResponse';
  success: Scalars['Boolean']['output'];
};

export type StoredFiltersUpdatePayload = {
  filter: PolicyFilter;
  name: Scalars['String']['input'];
  type: StoredFilterType;
};

export type StoredFiltersUpdateResponse = {
  __typename?: 'StoredFiltersUpdateResponse';
  updated: StoredFilter;
};

export type Tableau = {
  __typename?: 'Tableau';
  dashboards: Array<TableauDashboard>;
};

export type TableauDashboard = {
  __typename?: 'TableauDashboard';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type TableauToken = {
  __typename?: 'TableauToken';
  jwt: Scalars['String']['output'];
};

export type TargetIndustryOpportunity = {
  __typename?: 'TargetIndustryOpportunity';
  avgRevenuePerClient: Scalars['Float']['output'];
  clients: Scalars['Float']['output'];
  industrySubGroup: Industry;
  medianRevenuePerClient: Scalars['Float']['output'];
  productsPerClient: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
};

export type TopStatistics = {
  __typename?: 'TopStatistics';
  byCarrier: Array<TopStatisticsByCarrier>;
  byIndustry: Array<TopStatisticsByIndustry>;
  byProducer: Array<TopStatisticsByProducer>;
  byProduct: Array<TopStatisticsByProduct>;
};

export type TopStatisticsByCarrierArgs = {
  filter?: InputMaybe<PolicyFilter>;
  metric: PolicyMetric;
};

export type TopStatisticsByIndustryArgs = {
  filter?: InputMaybe<PolicyFilter>;
  level?: InputMaybe<IndustryLevel>;
  metric: PolicyMetric;
};

export type TopStatisticsByProducerArgs = {
  filter?: InputMaybe<PolicyFilter>;
  metric: PolicyMetric;
};

export type TopStatisticsByProductArgs = {
  filter?: InputMaybe<PolicyFilter>;
  metric: PolicyMetric;
};

export type TopStatisticsByCarrier = {
  __typename?: 'TopStatisticsByCarrier';
  items?: Maybe<Array<Market>>;
  statistics: TopStatisticsMeta;
};

export type TopStatisticsByIndustry = {
  __typename?: 'TopStatisticsByIndustry';
  items?: Maybe<Array<Industry>>;
  statistics: TopStatisticsMeta;
};

export type TopStatisticsByProducer = {
  __typename?: 'TopStatisticsByProducer';
  items?: Maybe<Array<User>>;
  statistics: TopStatisticsMeta;
};

export type TopStatisticsByProduct = {
  __typename?: 'TopStatisticsByProduct';
  items?: Maybe<Array<Product>>;
  statistics: TopStatisticsMeta;
};

export type TopStatisticsMeta = {
  __typename?: 'TopStatisticsMeta';
  metric?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  /** null for Other */
  topN?: Maybe<Scalars['Int']['output']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  amount: Amount;
  date: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  policy: Policy;
};

export type UpdateProducerGoalPayload = {
  __typename?: 'UpdateProducerGoalPayload';
  updated: ProducerGoal;
};

export type UpdateUserReportPresetPayload = {
  __typename?: 'UpdateUserReportPresetPayload';
  updated: UserReportPreset;
};

export type User = {
  __typename?: 'User';
  amsRoles: Array<UserAmsRole>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  metrics?: Maybe<EntityMetrics>;
  /** @deprecated Use fullName instead */
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  shortNames: Array<Scalars['String']['output']>;
};

export enum UserAmsRole {
  AccountExecutive = 'ACCOUNT_EXECUTIVE',
  AccountManager = 'ACCOUNT_MANAGER',
  Producer = 'PRODUCER',
}

export type UserFilterInput = {
  amsRoles?: InputMaybe<Array<UserAmsRole>>;
};

/** Uses OR logic inside */
export type UserFilters = {
  accountExecutiveIds?: InputMaybe<Array<Scalars['String']['input']>>;
  accountExecutiveIdsAndSourceCodes?: InputMaybe<Array<UserIdAndSourceCodes>>;
  accountExecutiveIsEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  accountManagerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  accountManagerIdsAndSourceCodes?: InputMaybe<Array<UserIdAndSourceCodes>>;
  accountManagerIsEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  producerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  producerIdsAndSourceCodes?: InputMaybe<Array<UserIdAndSourceCodes>>;
  producerIsEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserFiltersOutput = {
  __typename?: 'UserFiltersOutput';
  accountExecutiveIds?: Maybe<Array<Scalars['String']['output']>>;
  accountExecutiveIdsAndSourceCodes?: Maybe<Array<UserIdAndSourceCodesOutput>>;
  accountExecutiveIsEmpty?: Maybe<Scalars['Boolean']['output']>;
  accountManagerIds?: Maybe<Array<Scalars['String']['output']>>;
  accountManagerIdsAndSourceCodes?: Maybe<Array<UserIdAndSourceCodesOutput>>;
  accountManagerIsEmpty?: Maybe<Scalars['Boolean']['output']>;
  producerIds?: Maybe<Array<Scalars['String']['output']>>;
  producerIdsAndSourceCodes?: Maybe<Array<UserIdAndSourceCodesOutput>>;
  producerIsEmpty?: Maybe<Scalars['Boolean']['output']>;
};

export type UserIdAndSourceCodes = {
  sourceCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  userId: Scalars['String']['input'];
};

export type UserIdAndSourceCodesOutput = {
  __typename?: 'UserIdAndSourceCodesOutput';
  sourceCodes?: Maybe<Array<Scalars['String']['output']>>;
  userId: Scalars['String']['output'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  ennablEmployeeId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** @deprecated Use permissions field instead */
  permissionIds: Array<Scalars['String']['output']>;
  permissions: Array<AppPermission>;
  profile?: Maybe<UserProfile>;
  roleType: EnnablRoleType;
  roles: Array<EnnablRole>;
  tenantId: Scalars['String']['output'];
  tenantName: Scalars['String']['output'];
  timeZone?: Maybe<Scalars['String']['output']>;
};

export type UserKpi = {
  __typename?: 'UserKpi';
  created: Scalars['Timestamp']['output'];
  entity?: Maybe<UserKpiEntity>;
  entityDeclaration?: Maybe<UserKpiEntityDeclaration>;
  filteringMode: UserKpiFilteringMode;
  id: Scalars['String']['output'];
  metric: UserKpiMetric;
  period?: Maybe<UserKpiPeriod>;
  updated: Scalars['Timestamp']['output'];
};

export enum UserKpiCarrierMode {
  Bco = 'BCO',
  Ico = 'ICO',
}

export type UserKpiCreateInput = {
  entityDeclaration?: InputMaybe<UserKpiEntityDeclarationInput>;
  filteringMode?: InputMaybe<UserKpiFilteringMode>;
  metric: UserKpiMetric;
  period?: InputMaybe<UserKpiPeriodInput>;
};

export type UserKpiCreateResponse = {
  __typename?: 'UserKpiCreateResponse';
  kpi: UserKpi;
};

export type UserKpiDashboardCreateKpiInput = {
  entityDeclaration?: InputMaybe<UserKpiEntityDeclarationInput>;
  filteringMode?: InputMaybe<UserKpiFilteringMode>;
  id: Scalars['String']['input'];
  metric: UserKpiMetric;
  period?: InputMaybe<UserKpiPeriodInput>;
};

export type UserKpiDashboards = {
  __typename?: 'UserKpiDashboards';
  all: Array<KpiDashboard>;
  byId: KpiDashboard;
  currentDashboardId?: Maybe<Scalars['String']['output']>;
};

export type UserKpiDashboardsByIdArgs = {
  dashboardId: Scalars['String']['input'];
};

export type UserKpiDeleteResponse = {
  __typename?: 'UserKpiDeleteResponse';
  success: Scalars['Boolean']['output'];
};

export type UserKpiEntity = Department | Industry | Market | Product | User;

export type UserKpiEntityDeclaration = {
  __typename?: 'UserKpiEntityDeclaration';
  carrierMode?: Maybe<UserKpiCarrierMode>;
  id: Scalars['String']['output'];
  type: UserKpiEntityType;
};

export type UserKpiEntityDeclarationInput = {
  carrierMode?: InputMaybe<UserKpiCarrierMode>;
  id: Scalars['String']['input'];
  type: UserKpiEntityType;
};

export enum UserKpiEntityType {
  AccountExecutive = 'ACCOUNT_EXECUTIVE',
  AccountManager = 'ACCOUNT_MANAGER',
  CarrierParent = 'CARRIER_PARENT',
  CarrierUltimate = 'CARRIER_ULTIMATE',
  Department = 'DEPARTMENT',
  Industry = 'INDUSTRY',
  IndustryGroup = 'INDUSTRY_GROUP',
  IndustrySubgroup = 'INDUSTRY_SUBGROUP',
  InsuranceSegment = 'INSURANCE_SEGMENT',
  IntermediaryChild = 'INTERMEDIARY_CHILD',
  IntermediaryParent = 'INTERMEDIARY_PARENT',
  IntermediaryUltimate = 'INTERMEDIARY_ULTIMATE',
  IssuingPaper = 'ISSUING_PAPER',
  Producer = 'PRODUCER',
  Product = 'PRODUCT',
  ProductGroup = 'PRODUCT_GROUP',
  ProductSuperGroup = 'PRODUCT_SUPER_GROUP',
}

export enum UserKpiFilteringMode {
  Book = 'BOOK',
  CrossSell = 'CROSS_SELL',
  Lost = 'LOST',
}

export enum UserKpiMetric {
  AccountExecutiveCount = 'ACCOUNT_EXECUTIVE_COUNT',
  AccountManagerCount = 'ACCOUNT_MANAGER_COUNT',
  AvgCommissionPct = 'AVG_COMMISSION_PCT',
  CarrierCount = 'CARRIER_COUNT',
  ClientCount = 'CLIENT_COUNT',
  DirectPremium = 'DIRECT_PREMIUM',
  GrowthRevenue = 'GROWTH_REVENUE',
  IndirectPremium = 'INDIRECT_PREMIUM',
  IndustryCount = 'INDUSTRY_COUNT',
  IntermediaryCount = 'INTERMEDIARY_COUNT',
  PolicyCount = 'POLICY_COUNT',
  Premium = 'PREMIUM',
  ProducerCount = 'PRODUCER_COUNT',
  ProductCount = 'PRODUCT_COUNT',
  Revenue = 'REVENUE',
}

export type UserKpiPeriod = {
  __typename?: 'UserKpiPeriod';
  /** Only for IN_FORCE */
  timestamp?: Maybe<Scalars['Timestamp']['output']>;
  type: PeriodType;
};

export type UserKpiPeriodInput = {
  /** Only for IN_FORCE */
  timestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  type: PeriodType;
};

export type UserKpiReorderResponse = {
  __typename?: 'UserKpiReorderResponse';
  kpis: Array<UserKpi>;
};

export type UserKpiUpdateResponse = {
  __typename?: 'UserKpiUpdateResponse';
  kpi: UserKpi;
};

export type UserKpis = {
  __typename?: 'UserKpis';
  all: Array<UserKpi>;
};

export type UserListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<UserSortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export type UserPaginatedResult = {
  __typename?: 'UserPaginatedResult';
  items: Array<User>;
  totalHits: Scalars['Int']['output'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  email?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UserReport = {
  __typename?: 'UserReport';
  createdAt: Scalars['Timestamp']['output'];
  filename: Scalars['String']['output'];
  filterName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  presetDescription?: Maybe<Scalars['String']['output']>;
  presetId?: Maybe<Scalars['ID']['output']>;
  presetName?: Maybe<Scalars['String']['output']>;
  schedule?: Maybe<ReportSubscriptionSchedule>;
  status: UserReportStatus;
  type: UserReportType;
  /** for small reports operation is sync. URL returned in the payload */
  url?: Maybe<Scalars['String']['output']>;
};

export enum UserReportFileType {
  Csv = 'CSV',
  Pdf = 'PDF',
  Xlsx = 'XLSX',
}

export type UserReportGroup = {
  __typename?: 'UserReportGroup';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  presets: Array<UserReportPreset>;
  presetsCreatable: Scalars['Boolean']['output'];
  reportParameters: ReportParameters;
  skipPolicyFilter?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<UserReportType>;
};

/** This class allow users to create presets for the reports to download them later or schedule email */
export type UserReportPreset = {
  __typename?: 'UserReportPreset';
  createdByAdmin: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  group?: Maybe<UserReportGroup>;
  id: Scalars['ID']['output'];
  isPredefined: Scalars['Boolean']['output'];
  lastSent?: Maybe<Scalars['Timestamp']['output']>;
  name: Scalars['String']['output'];
  reportParameters: ReportParameters;
  reports?: Maybe<Array<UserReport>>;
  schedule?: Maybe<ReportSubscriptionSchedule>;
  type: UserReportType;
};

export type UserReportPresetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  reportParameters: ReportParametersInput;
  schedule?: InputMaybe<ReportSubscriptionScheduleInput>;
  type: UserReportType;
};

export type UserReportPresets = {
  __typename?: 'UserReportPresets';
  all: Array<UserReportPreset>;
  byId?: Maybe<UserReportPreset>;
  dataAudits: Array<UserReportPreset>;
  groups: Array<UserReportGroup>;
  subscribed: Array<UserReportPreset>;
};

export type UserReportPresetsByIdArgs = {
  presetId: Scalars['String']['input'];
};

export enum UserReportStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Ready = 'READY',
}

export enum UserReportType {
  AccountLeaderboard = 'ACCOUNT_LEADERBOARD',
  AccountManager = 'ACCOUNT_MANAGER',
  AccountManagerAccountSize = 'ACCOUNT_MANAGER_ACCOUNT_SIZE',
  AccountManagerBookProfile = 'ACCOUNT_MANAGER_BOOK_PROFILE',
  AccountManagerProducerSupport = 'ACCOUNT_MANAGER_PRODUCER_SUPPORT',
  AccountManagerRenewal = 'ACCOUNT_MANAGER_RENEWAL',
  AccountManagerTopCarriers = 'ACCOUNT_MANAGER_TOP_CARRIERS',
  AccountManagerTopIndustries = 'ACCOUNT_MANAGER_TOP_INDUSTRIES',
  AccountManagerTopProducts = 'ACCOUNT_MANAGER_TOP_PRODUCTS',
  AccountReportAvgAccSize = 'ACCOUNT_REPORT_AVG_ACC_SIZE',
  AccountReportGrowth = 'ACCOUNT_REPORT_GROWTH',
  AccountReportLost = 'ACCOUNT_REPORT_LOST',
  AccountReportPremiumAccSize = 'ACCOUNT_REPORT_PREMIUM_ACC_SIZE',
  AccountReportRetention = 'ACCOUNT_REPORT_RETENTION',
  CarrierAppetite = 'CARRIER_APPETITE',
  CarrierDowngrades = 'CARRIER_DOWNGRADES',
  CarrierPdfReport = 'CARRIER_PDF_REPORT',
  ClientNaics = 'CLIENT_NAICS',
  Contingency = 'CONTINGENCY',
  CrossSell = 'CROSS_SELL',
  CrossSellRecommendations = 'CROSS_SELL_RECOMMENDATIONS',
  CustomAggregated = 'CUSTOM_AGGREGATED',
  CustomPlain = 'CUSTOM_PLAIN',
  DataAudit = 'DATA_AUDIT',
  Leaderboard = 'LEADERBOARD',
  LifetimeMultipleClients = 'LIFETIME_MULTIPLE_CLIENTS',
  LifetimeMultipleIndustries = 'LIFETIME_MULTIPLE_INDUSTRIES',
  LifetimeSingleClient = 'LIFETIME_SINGLE_CLIENT',
  LocationPdfReport = 'LOCATION_PDF_REPORT',
  Mapping = 'MAPPING',
  Monoline = 'MONOLINE',
  OutflowInflowPolicies = 'OUTFLOW_INFLOW_POLICIES',
  Policy = 'POLICY',
  PremiumFlow = 'PREMIUM_FLOW',
  ProducerCredit = 'PRODUCER_CREDIT',
  ProducerTopIndustriesReport = 'PRODUCER_TOP_INDUSTRIES_REPORT',
  ProducerTopProductsReport = 'PRODUCER_TOP_PRODUCTS_REPORT',
  ProductionCredit = 'PRODUCTION_CREDIT',
  Prospect = 'PROSPECT',
  Retention = 'RETENTION',
  RetentionInsights = 'RETENTION_INSIGHTS',
  RoiCrossSell = 'ROI_CROSS_SELL',
  SavedOpportunities = 'SAVED_OPPORTUNITIES',
  Score_360Clients = 'SCORE_360_CLIENTS',
  Score_360Producers = 'SCORE_360_PRODUCERS',
  TargetIndustry = 'TARGET_INDUSTRY',
  WinBack = 'WIN_BACK',
}

export type UserReports = {
  __typename?: 'UserReports';
  byId?: Maybe<UserReport>;
};

export type UserReportsByIdArgs = {
  id: Scalars['String']['input'];
};

export enum UserSortingField {
  Commission = 'COMMISSION',
  FullName = 'FULL_NAME',
  Id = 'ID',
  Premium = 'PREMIUM',
  Revenue = 'REVENUE',
}

export type Users = {
  __typename?: 'Users';
  getByEmail?: Maybe<User>;
  /** @deprecated Use paginated instead */
  list: Array<User>;
  paginated: UserPaginatedResult;
};

export type UsersGetByEmailArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type UsersListArgs = {
  filter?: InputMaybe<UserFilterInput>;
  pagination?: InputMaybe<UserListPaginationInput>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type UsersPaginatedArgs = {
  filter?: InputMaybe<UserFilterInput>;
  pagination?: InputMaybe<UserListPaginationInput>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export enum WinBackEntityType {
  Client = 'CLIENT',
  Policy = 'POLICY',
}

export type WinBackListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<WinBackSortingField>;
  /** Total count will be different if we use sorting by metric with permissions */
  sortOrder?: InputMaybe<SortOrder>;
};

export type WinBackOpportunity = {
  __typename?: 'WinBackOpportunity';
  accountManager?: Maybe<User>;
  affectedEntityType: WinBackEntityType;
  carrier?: Maybe<Market>;
  client: Client;
  commission: Scalars['Float']['output'];
  commissionPct?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  industry?: Maybe<Industry>;
  industryGroup?: Maybe<Industry>;
  isClientLost: Scalars['Boolean']['output'];
  /** the date that last policy of the client was expired */
  lostDate: Scalars['SimpleDate']['output'];
  policy?: Maybe<Policy>;
  premium: Scalars['Float']['output'];
  /** @deprecated use producers */
  producer?: Maybe<User>;
  producers?: Maybe<Array<User>>;
  product?: Maybe<Product>;
  reportId?: Maybe<Scalars['String']['output']>;
  revenue: Scalars['Float']['output'];
  saved?: Maybe<SavedOpportunityWinBack>;
  source: OpportunitySourceType;
  /** the start date of the first policy of the client */
  startDate: Scalars['SimpleDate']['output'];
};

export type WinBackOpportunityAggregationByClient = {
  __typename?: 'WinBackOpportunityAggregationByClient';
  client: Client;
  count: Scalars['Int']['output'];
  lostDate: Scalars['SimpleDate']['output'];
  producers: Array<User>;
  revenue: Scalars['Float']['output'];
};

export type WinBackOpportunityPaginatedResult = {
  __typename?: 'WinBackOpportunityPaginatedResult';
  items: Array<WinBackOpportunity>;
  totalHits: Scalars['Int']['output'];
};

export type WinBackOpportunityV2 = {
  __typename?: 'WinBackOpportunityV2';
  id: Scalars['ID']['output'];
  policy: Policy;
  saved?: Maybe<SavedOpportunityWinBack>;
};

export type WinBackOpportunityV2PaginatedResult = {
  __typename?: 'WinBackOpportunityV2PaginatedResult';
  items: Array<WinBackOpportunityV2>;
  totalHits: Scalars['Int']['output'];
};

export enum WinBackSortingField {
  Client = 'CLIENT',
  LostDate = 'LOST_DATE',
  Premium = 'PREMIUM',
  Producer = 'PRODUCER',
  Product = 'PRODUCT',
  Revenue = 'REVENUE',
}
