import {Components, Theme} from '@mui/material/styles';
import {toolbarClasses} from '@mui/material/Toolbar';

import {containerWidth} from 'core/themes/themeDecisions/styles';

export const appBar = (
  theme: Theme
): {
  MuiAppBar: Components['MuiAppBar'];
} => ({
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.background.grey,
        borderRadius: 'unset',
        padding: theme.spacing(6, 5),
        gap: theme.spacing(6),
        boxShadow: 'none',

        [`& .${toolbarClasses.root}`]: {
          ...containerWidth(theme),
          width: '100%',
          padding: 0,
          margin: '0 auto',
          minHeight: 'auto',

          '&:first-of-type': {
            gap: theme.spacing(2),
          },

          [theme.breakpoints.down('sm')]: {
            '&:first-of-type': {
              gap: theme.spacing(4),
            },
          },
        },
      },
    },
  },
});
