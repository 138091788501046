import {listItemButtonClasses} from '@mui/material';
import {alpha, Components, Theme} from '@mui/material/styles';

export const listItem = (theme: Theme): Partial<Components> => ({
  MuiListItemButton: {
    styleOverrides: {
      root: {
        borderRadius: theme.shape.borderRadiusRegular,
        transitionDuration: `${theme.transitions.duration.standard}ms`,

        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, 0.04),
        },
        [`&.${listItemButtonClasses.selected}, &:focus`]: {
          backgroundColor: alpha(theme.palette.primary.main, 0.08),
        },
      },
    },
  },
});
