import {alpha, Components, Theme} from '@mui/material/styles';
import {chipClasses} from '@mui/material/Chip';

import CloseIcon from 'components/icons/cross.svg';

export const chip = (theme: Theme): Partial<Components> => ({
  MuiChip: {
    defaultProps: {
      deleteIcon: <CloseIcon />,
    },
    styleOverrides: {
      root: {
        ...theme.typography.buttonSmall,
        width: 'max-content',
        borderRadius: theme.shape.borderRadiusRegular,
        gap: theme.spacing(2),
        padding: theme.spacing(2),

        [`& > .${chipClasses.avatar}`]: {
          width: 16,
          height: 16,
          color: theme.palette.text.primary,
          margin: 0,
        },

        [`& > .${chipClasses.icon}`]: {
          margin: 0,
        },

        [`& > .${chipClasses.label}:empty`]: {
          display: 'none',
        },
      },
      deleteIcon: {margin: 0},
      label: {padding: 0},
      filledPrimary: params => ({
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.darkGrey,

        ...(params.ownerState.clickable
          ? {
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
              },
            }
          : {}),
      }),
      outlinedPrimary: {
        [`& > .${chipClasses.avatar}`]: {
          color: theme.palette.primary.main,
        },
      },
      avatarColorPrimary: {color: theme.palette.text.primary, backgroundColor: 'unset'},
      deleteIconFilledColorPrimary: {
        color: alpha(theme.palette.text.primary, 0.7),

        '&:hover, &:active': {
          color: theme.palette.text.primary,
        },
      },
      deleteIconOutlinedColorPrimary: {
        color: alpha(theme.palette.primary.main, 0.7),

        '&:hover, &:active': {
          color: theme.palette.primary.main,
        },
      },
    },
    variants: [
      {
        props: {variant: 'filled-rounded'},
        style: (params: any) => ({
          borderRadius: theme.shape.borderRadiusLarge,

          [`&.${chipClasses.colorPrimary}`]: {
            color: theme.palette.text.primary,
            backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),

            ...(params.ownerState.clickable
              ? {
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
                  },
                }
              : {}),
          },

          '& .MuiChip-deleteIconFilled-roundedColorPrimary': {
            color: alpha(theme.palette.text.primary, 0.7),

            '&:hover, &:active': {
              color: theme.palette.text.primary,
            },
          },
        }),
      },
      {
        props: {variant: 'outlined-rounded'},
        style: (params: any) => ({
          color: theme.palette[params.ownerState.color as 'primary' | 'secondary'].main,
          border: `1px solid ${alpha(theme.palette[params.ownerState.color as 'primary' | 'secondary'].main, 0.7)}`,
          borderRadius: theme.shape.borderRadiusLarge,
          backgroundColor: 'transparent',

          [`&.${chipClasses.clickable}:hover`]: {
            backgroundColor: alpha(
              theme.palette[params.ownerState.color as 'primary' | 'secondary'].main,
              theme.palette.action.hoverOpacity
            ),
          },

          [`& .${chipClasses.avatar}`]: {
            color: theme.palette[params.ownerState.color as 'primary' | 'secondary'].main,
          },

          [`& .${chipClasses.deleteIcon}`]: {
            color: alpha(theme.palette[params.ownerState.color as 'primary' | 'secondary'].main, 0.7),

            '&:hover, &:active': {
              color: theme.palette[params.ownerState.color as 'primary' | 'secondary'].main,
            },
          },
        }),
      },
    ],
  },
});
