import {isObject} from 'lodash/fp';

import {AuthToken} from 'core/types';

import {APP_LOGIN_URI} from 'config';
import {isWebViewApp, getWebViewAuthToken, refreshWebViewToken, logOutWebView} from 'core/utils/webView';
import {isCypressApp, getCypressAuthToken} from 'core/utils/cypress';
import {getAuth0AuthToken, authorize} from 'core/api/auth0';

export function redirectToLogin() {
  const url = new URL(APP_LOGIN_URI);
  url.searchParams.append('redirectUri', window.location.href);
  window.location.replace(url.toString());
}

export function logout() {
  if (isWebViewApp()) {
    logOutWebView();
    return;
  }

  const url = new URL(APP_LOGIN_URI);
  url.searchParams.append('logout', 'true');
  url.searchParams.append('redirectUri', window.location.href);

  window.location.replace(url.toString());
}

export async function getAuthToken(): Promise<AuthToken | null> {
  const isWebView = isWebViewApp();
  const isCypress = isCypressApp();

  try {
    if (isWebView) {
      return getWebViewAuthToken();
    }

    if (isCypress) {
      return getCypressAuthToken();
    }

    // await is required here to catch the error
    return await getAuth0AuthToken();
  } catch (error) {
    if (isWebView) {
      await refreshWebViewToken();
    }

    if (!isWebView && !isCypress) {
      if (isObject(error) && 'code' in error && error.code === 'consent_required') {
        authorize();
      } else {
        redirectToLogin();
      }
    }

    return null;
  }
}

export async function refreshAuthToken() {
  if (isWebViewApp()) {
    await refreshWebViewToken();
  }

  return getAuthToken();
}
