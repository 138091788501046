import {Breakpoint, Dialog, SwipeableDrawer, useTheme} from '@mui/material';
import {PropsWithChildren, useLayoutEffect} from 'react';
import {styled} from '@mui/material/styles';
import {paperClasses} from '@mui/material/Paper';
import useResizeObserver from 'use-resize-observer';

import {TestIdProp} from 'types';

import {useIsDownSm} from 'utils/hooks/useMediaQuery';
import {isIOS} from 'utils/deviceInfo';
import {shouldNotForwardProp} from 'utils/styled';

import {
  DecisionsDialogTitle,
  DecisionsDialogTitleProps,
} from 'modules/decisionsNext/components/DecisionsDialog/DecisionsDialogTitle';

const SSwipeableDrawer = styled(
  SwipeableDrawer,
  shouldNotForwardProp('minHeight', 'fixedSwiperHeight')
)<{minHeight?: number; fixedSwiperHeight?: boolean}>(({theme, minHeight, fixedSwiperHeight}) => ({
  zIndex: theme.zIndex.modal,
  [`.${paperClasses.root}`]: {
    maxHeight: [`calc(100vh - ${theme.spacing(6)})`, `calc(100dvh - ${theme.spacing(6)})`],
    ...(fixedSwiperHeight ? {height: `calc(100vh - ${theme.spacing(30)})`} : {}),
    ...(minHeight ? {minHeight: minHeight} : {}),
  },
}));

const SSwiperContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  overflow: 'hidden',
}));

const SDialog = styled(
  Dialog,
  shouldNotForwardProp('maxDialogWidth', 'minHeight')
)<{
  maxDialogWidth?: number;
  minHeight?: number;
}>(({maxDialogWidth, minHeight}) => ({
  [`.${paperClasses.root}`]: {
    ...(maxDialogWidth ? {maxWidth: maxDialogWidth} : {}),
    ...(minHeight ? {minHeight: minHeight} : {}),
  },
}));

type DecisionsDialogProps = TestIdProp &
  Pick<DecisionsDialogTitleProps, 'closeType'> & {
    open: boolean;
    title: string;
    maxWidth?: Breakpoint | false;
    maxDialogWidth?: number;
    minHeight?: number;
    headless?: boolean;
    fixedSwiperHeight?: boolean;
    onClose: () => void;
    onBack?: () => void;
  };

export const DecisionsDialog = ({
  dataTestId,
  children,
  open,
  onClose,
  maxWidth,
  maxDialogWidth,
  minHeight,
  title,
  headless,
  fixedSwiperHeight,
  closeType,
  onBack,
}: PropsWithChildren<DecisionsDialogProps>) => {
  const isDownSm = useIsDownSm();
  const theme = useTheme();
  const {ref, height} = useResizeObserver<HTMLDivElement>();

  useLayoutEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        const paperNode = document.querySelector<HTMLDivElement>(`.${paperClasses.root}`);

        if (paperNode) {
          paperNode.style.maxHeight = `${window.innerHeight - parseInt(theme.spacing(6))}px`;
        }
      }, 10);
    };

    if (open && isDownSm) {
      handleResize();
      window.addEventListener('focusin', handleResize);
      window.addEventListener('focusout', handleResize);
    }

    return () => {
      window.removeEventListener('focusin', handleResize);
      window.removeEventListener('focusout', handleResize);
    };
  }, [open, height, isDownSm, theme]);

  if (isDownSm) {
    return (
      <SSwipeableDrawer
        data-testid={dataTestId}
        anchor='bottom'
        variant='temporary'
        open={open}
        /**
         * This exact version of SwipeableDrawer (with enabled disableSwipeToOpen and disableDiscovery props)
         * is never going to fire onOpen, so it's safe to leave it as undefined to avoid passing unnecessary props
         */
        onOpen={() => undefined}
        onClose={onClose}
        ModalProps={{
          keepMounted: false,
        }}
        disableSwipeToOpen
        disableDiscovery
        disableBackdropTransition={isIOS}
        minHeight={minHeight}
        fixedSwiperHeight={fixedSwiperHeight}
      >
        {!headless && (
          <DecisionsDialogTitle
            title={title}
            titleAlign='left'
            onClose={onClose}
            onBack={onBack}
            closeType={closeType ?? 'text'}
          />
        )}
        <SSwiperContent ref={ref}>{children}</SSwiperContent>
      </SSwipeableDrawer>
    );
  }

  return (
    <SDialog
      data-testid={dataTestId}
      slotProps={{
        root: {
          'data-testid': dataTestId,
        } as any,
      }}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={maxWidth}
      maxDialogWidth={maxDialogWidth}
      minHeight={minHeight}
    >
      {!headless && <DecisionsDialogTitle title={title} titleAlign='center' onBack={onBack} onClose={onClose} />}
      {children}
    </SDialog>
  );
};
