// eslint-disable-next-line no-restricted-imports -- this is the wrapper itself
import * as Apollo from '@apollo/client';
import {NetworkStatus} from '@apollo/client';

import {injectOnError} from 'utils/apolloErrorUtils';

// eslint-disable-next-line no-restricted-imports -- this is the wrapper itself
export * from '@apollo/client';

export const useLazyQuery = injectOnError(Apollo.useLazyQuery) as typeof Apollo.useLazyQuery;

export const useMutation = injectOnError(Apollo.useMutation) as typeof Apollo.useMutation;

type CustomQueryHookOptions = {
  disableSkipSetsLoadingState?: boolean;
};
export type QueryHookOptions<
  TData = any,
  TVariables extends Apollo.OperationVariables = Apollo.OperationVariables
> = Apollo.QueryHookOptions<TData, TVariables> & CustomQueryHookOptions;

function useQueryWrapper<TData = any, TVariables extends Apollo.OperationVariables = Apollo.OperationVariables>(
  query: Apollo.DocumentNode | Apollo.TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables> = {}
): Apollo.QueryResult<TData, TVariables> {
  const {skip, disableSkipSetsLoadingState} = options;
  const result = Apollo.useQuery(query, options);
  const {previousData, loading, networkStatus} = result;

  const isSkippedWithNoData = !disableSkipSetsLoadingState && skip && previousData === undefined;

  const loadingComputed = isSkippedWithNoData ? true : loading;
  const networkStatusComputed = isSkippedWithNoData ? NetworkStatus.loading : networkStatus;

  return {
    ...result,
    loading: loadingComputed,
    networkStatus: networkStatusComputed,
  };
}

export const useQuery = injectOnError(useQueryWrapper) as typeof useQueryWrapper;
