import {toast} from 'sonner';

import {LocalStorageKey} from 'core/types';

import {IS_DEV_ENV} from 'config';
import {ModifyState} from 'modules/modifier/modifierStore';

import {parseJSON} from './json';

const withDevServiceWorker = IS_DEV_ENV && Boolean(localStorage.getItem(LocalStorageKey.WithDevServiceWorker));

const devChannel = withDevServiceWorker && new BroadcastChannel('dev-service-worker');

if (devChannel) {
  devChannel.addEventListener('message', event => {
    const {type, payload} = event.data;

    if (type === 'INFO') {
      toast.info(payload);
    }

    if (type === 'REQUEST') {
      toast.info(
        <div>
          Request <strong>{payload}</strong> was modified
        </div>
      );
    }
  });
}

export const initServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    if (withDevServiceWorker) {
      navigator.serviceWorker.register('/devServiceWorker.js', {
        scope: '/',
      });

      navigator.serviceWorker.ready.then(() => {
        updateModifiers();
      });

      return;
    }

    navigator.serviceWorker.register('/serviceWorker.js', {
      scope: '/',
    });
  }
};

export const updateModifiers = () => {
  if (devChannel) {
    devChannel.postMessage({
      type: 'UPDATE_MODIFIERS',
      payload:
        parseJSON<{state?: ModifyState}>(localStorage.getItem(LocalStorageKey.Modifiers), {})?.state?.modifiers ?? [],
    });
  }
};
