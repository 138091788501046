import {useMatch} from 'react-router-dom';

import {ENNABL_DECISIONS, ENNABL_COMMAND, ENNABL_GROWTH, ENNABL_CHAT_AI} from 'core/constants/paths';

export enum ProjectName {
  Decisions = 'DECISIONS',
  Command = 'COMMAND',
  Growth = 'GROWTH',
  AI = 'AI',
  UNKNOWN = 'UNKNOWN',
}

export function useProjectName() {
  const isDecisions = Boolean(useMatch({path: `${ENNABL_DECISIONS}/`, end: false}));
  const isCommand = Boolean(useMatch({path: `${ENNABL_COMMAND}/`, end: false}));
  const isGrowth = Boolean(useMatch({path: `${ENNABL_GROWTH}/`, end: false}));
  const isAI = Boolean(useMatch({path: `${ENNABL_CHAT_AI}/`, end: false}));

  let projectName: ProjectName = ProjectName.UNKNOWN;

  if (isDecisions) {
    projectName = ProjectName.Decisions;
  } else if (isCommand) {
    projectName = ProjectName.Command;
  } else if (isGrowth) {
    projectName = ProjectName.Growth;
  } else if (isAI) {
    projectName = ProjectName.AI;
  }

  return {isDecisions, isCommand, isGrowth, isAI, projectName};
}
