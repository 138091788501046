import {themeDecisions} from '.';

export const containerWidth = (theme: typeof themeDecisions, {upLg = {}, betweenSmLg = {}} = {}) => ({
  [theme.breakpoints.up('lg')]: {
    maxWidth: theme.shape.sectionContainerMaxWidth.lg,
    ...upLg,
  },

  [theme.breakpoints.between('sm', 'lg')]: {
    maxWidth: theme.shape.sectionContainerMaxWidth.sm,
    ...betweenSmLg,
  },
});
