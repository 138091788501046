import {useTheme} from '@mui/material';
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';

import {useIsDownSm} from 'utils/hooks/useMediaQuery';
import {useBackToHomeUrl} from 'utils/hooks/useBackToHomeUrl';

import {ErrorPageLayout} from 'components/ErrorPageLayout';
import {Placeholder} from 'components/Placeholder';
import Icon404 from 'components/icons/placeholders/404.svg';

type NotFoundPageProps = {
  backLink?: string;
};

export function PageContent({backLink}: NotFoundPageProps) {
  const theme = useTheme();
  const isDownSm = useIsDownSm();
  const backToHomeUrl = useBackToHomeUrl();

  const buttonSize = isDownSm ? 'small' : 'medium';

  return (
    <Placeholder
      icon={<Icon404 color={theme.palette.text.secondary} />}
      heading='Page not found'
      subheading='The link may be broken, or the page no longer exists.'
      actions={
        <Button variant='contained' size={buttonSize} component={Link} to={backLink ?? backToHomeUrl}>
          Back to Home
        </Button>
      }
    />
  );
}

export function NotFoundPage({backLink}: NotFoundPageProps) {
  return (
    <ErrorPageLayout>
      <PageContent backLink={backLink} />
    </ErrorPageLayout>
  );
}
