import bowser from 'bowser';

import {isWebViewApp} from 'core/utils/webView';

const ua = navigator.userAgent;
const browser = bowser.getParser(ua);
const osName = browser.getOSName();
const platform = browser.getPlatformType();
export const isMobile = osName.toLowerCase() === 'android' || osName.toLowerCase() === 'ios' || isWebViewApp();
const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || isMobile;

const isIPadPro = isTouch && osName === 'macOS' && platform === 'desktop';
export const isTablet = isIPadPro || platform === 'tablet';
export const isDesktop = !isTablet && !isMobile;

export const isIOS = osName === 'iOS';
export const isSafari = browser.getBrowserName(true) === 'safari';
