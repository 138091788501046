import {StrictMode} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {createBrowserRouter, Route, RouterProvider, Routes} from 'react-router-dom';

import {RouterErrorBoundary} from 'core/RouterErrorBoundary';
import {RootStyles} from 'core/RootStyles';
import {IMPERSONATION, PDF_REPORTS} from 'core/constants/paths';
import {themeDecisions} from 'core/themes/themeDecisions';

import App from 'components/App';
import {CheckBrowserSupport} from 'components/UnsupportedBrowserWarning/CheckBrowserSupport';

import {impersonationToken} from './impersonation';

import {AuthProvider} from 'core/components/AuthProvider';
import {AuthorizedArea} from 'core/components/AuthorizedArea';
import {UserInfoProvider} from 'core/components/UserInfoProvider';
import PDFReportsRoot from 'modules/pdfReports/components/Root';

const router = createBrowserRouter(
  [
    {
      path: '/*',
      element: (
        <ThemeProvider theme={themeDecisions}>
          <CheckBrowserSupport>
            <Routes>
              <Route path={`${PDF_REPORTS}/*`} element={<PDFReportsRoot />} />

              <Route
                path='*'
                element={
                  <AuthProvider>
                    <AuthorizedArea>
                      <UserInfoProvider>
                        <App />
                      </UserInfoProvider>
                    </AuthorizedArea>
                  </AuthProvider>
                }
              />
            </Routes>
          </CheckBrowserSupport>
        </ThemeProvider>
      ),
      errorElement: <RouterErrorBoundary />,
    },
  ],
  {basename: impersonationToken ? `/${IMPERSONATION}/${impersonationToken}` : undefined}
);

const Root = () => {
  return (
    <StrictMode>
      <RootStyles />
      <RouterProvider router={router} />
    </StrictMode>
  );
};

export default Root;
