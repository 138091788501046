export const shouldNotForwardProp = (...props: string[]) => ({
  shouldForwardProp: (prop: string) => !props.includes(prop),
});

type Gradient = {
  offset: number;
  color: string;
};

export const getSvgGradientUrl = (width: number, height: number, gradients: Gradient[]) => {
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          ${gradients.map(it => `<stop offset="${it.offset}%" style="stop-color: ${it.color}" />`).join('')}
        </linearGradient>
      </defs>
      <rect width="100%" height="100%" fill="url(#gradient)" />
    </svg>`;

  const base64Svg = window.btoa(svg);
  return `data:image/svg+xml;base64,${base64Svg}`;
};
