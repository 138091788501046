import {useCallback} from 'react';
import {toast} from 'sonner';

import * as Types from 'modules/decisionsNext/api/types';
import {UserReportStatus} from 'modules/decisionsNext/api/types';

import {urlDownload} from 'utils/misc';
import {addDownloadJob} from 'core/stores/downloadReports';

type FileToDownload = {
  id: string;
  url?: string | null;
  status: Types.UserReportStatus;
  filename: string;
};

type DownloadFileArgs = {
  file: FileToDownload;
  mutation: (args: Record<string, unknown>) => void;
  variables?: Record<string, unknown>;
};

export function useDownloadFile(defaultFilename: string, title?: string) {
  const downloadFile = useCallback(
    ({file, mutation, variables}: DownloadFileArgs) => {
      const {id, url, filename, status} = file;

      if (url && status === UserReportStatus.Ready) {
        urlDownload(url, filename || defaultFilename);
      } else if (id) {
        const onTryAgainClick = () => {
          if (variables) {
            mutation?.({variables});
          }
        };

        addDownloadJob({
          id,
          status,
          filename,
          title,
          onTryAgainClick,
        });
      } else {
        toast.error(`Unable to generate ${title || 'Report'}`);
      }
    },
    [title, defaultFilename]
  );

  return {
    downloadFile,
  };
}
