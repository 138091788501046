import {capitalize, isNumber, sortBy} from 'lodash/fp';

import {AggPolicyStatus, AggregatedPolicyByPolicyStatus} from 'modules/decisionsNext/api/types';
import {ProductInsuranceSegment} from 'modules/decisionsNext/types';

export const floatPercentileToUnion = (floatPercentile: number) => {
  if (floatPercentile < 0.25) {
    return 'bottom25';
  }

  if (floatPercentile >= 0.25 && floatPercentile < 0.75) {
    return 'middle50';
  }

  return 'top25';
};

export const compareByPolicyStatus = <T extends AggPolicyStatus>(a: T, b: T) => {
  const order = [
    AggPolicyStatus.NewNew,
    AggPolicyStatus.NewTotal,
    AggPolicyStatus.RenewalTotal,
    AggPolicyStatus.NewCrossSell,
  ];
  // all unknown elements go to the end
  const elementOrder = (e: AggPolicyStatus) => (order.includes(e) ? order.indexOf(e) : 100);
  const indexA = elementOrder(a);
  const indexB = elementOrder(b);
  return indexA - indexB;
};

export const compareByPolicyStatusEntity = <T extends {entity: AggPolicyStatus}>(a: T, b: T) => {
  return compareByPolicyStatus(a.entity, b.entity);
};

export const getFilteredByPolicyStatus = <T extends Omit<AggregatedPolicyByPolicyStatus, 'agg'>>(
  statuses: AggPolicyStatus[],
  byPolicyStatus?: T[]
) => {
  if (!byPolicyStatus) {
    return [];
  }

  const filteredByPolicyStatus = byPolicyStatus.filter(e => statuses.includes(e.status));
  return sortBy(type => statuses.indexOf(type.status), filteredByPolicyStatus);
};

export const getProductInsuranceSegmentIdsFilter = (segment: ProductInsuranceSegment, tenantId: string | null) => {
  if (segment === 'all') {
    return undefined;
  }

  return [`${tenantId}/${capitalize(segment)}`];
};

export const createBusinessOrgTitle = (name: string, parentName?: string) => {
  return name + (parentName ? ` (${parentName})` : '');
};

export const flattenTreeItems = <T extends {children: unknown[]}>(data: T[], result: T[] = []) => {
  data.forEach(item => {
    result.push({...item, children: undefined});

    if ('children' in item && item.children?.length) {
      flattenTreeItems(item.children as T[], result);
    }
  });

  return result;
};

export const convertRange = (
  range: {min?: number; max?: number},
  converter: (value: number) => number = value => value
) => {
  const numberedMin = Number(range.min);
  // max can't be set to 0. this has no sense
  const numberedMax = Number(range.max) || null;
  return {
    min: isNumber(numberedMin) ? converter(numberedMin) ?? undefined : undefined,
    max: isNumber(numberedMax) ? converter(numberedMax) ?? undefined : undefined,
  };
};

export {ALL_ENTITIES_ID} from 'modules/decisionsNext/utils/allEntities';
export {sortRatingIdMap} from 'modules/decisionsNext/utils/sortRating';
