export const ALL_ENTITIES_ID = 'all';

/**
 * Prepare filter ids for request
 */
export function getFilterEntityIds(entityId?: string) {
  if (!entityId || entityId === ALL_ENTITIES_ID) {
    return undefined;
  }

  return [entityId];
}
