import {SyntheticEvent, useCallback, useMemo, useState} from 'react';
import {useDebounce} from 'use-debounce';
import Fuse, {IFuseOptions} from 'fuse.js';

import {DeepKeys} from 'types';

export type FuseSearchKeys<T extends object> = DeepKeys<T>[]; // | FuseOptionKeyObject<T>[];

type Options<T extends object, Keys extends string[] = DeepKeys<T>[]> = Omit<IFuseOptions<T>, 'keys'> & {
  keys?: Keys;
};

const defaultOptions = {
  findAllMatches: true,
  isCaseSensitive: false,
  ignoreFieldNorm: true,
  ignoreLocation: true,
  threshold: 0.1,
};

export const useFuzzySearch = <T extends object>(data: T[] = [], options: Options<T>) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryDebounced] = useDebounce(searchQuery, 500);

  const fuse = useMemo(() => new Fuse(data, {...defaultOptions, ...options}), [data, options]);

  const filteredData = useMemo<T[]>(() => {
    if (searchQueryDebounced.trim()) {
      return fuse.search(searchQueryDebounced).map(i => i.item);
    }

    return data;
  }, [searchQueryDebounced, data, fuse]);

  const handleSearchQueryChangeEvent = useCallback((e: SyntheticEvent, val: string) => {
    setSearchQuery(val);
  }, []);

  const handleSearchQueryChange = useCallback((val: string) => {
    setSearchQuery(val);
  }, []);

  return {filteredData, searchQuery, searchQueryDebounced, handleSearchQueryChangeEvent, handleSearchQueryChange};
};

export function useSearchWithFuse<T extends object, Keys extends string[] = DeepKeys<T>[]>(
  data: T[] = [],
  searchText: string,
  options: Options<T, Keys>
): T[] {
  const fuse = useMemo(() => new Fuse(data, {...defaultOptions, ...options}), [data, options]);

  return useMemo<T[]>(() => {
    if (searchText.trim()) {
      return fuse.search(searchText).map(i => i.item);
    }

    return data;
  }, [data, fuse, searchText]);
}
