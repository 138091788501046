import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/apolloWrapper';
const defaultOptions = {} as const;
export type SetUserTimezoneMutationVariables = Types.Exact<{
  timezone: Types.Scalars['String']['input'];
}>;


export type SetUserTimezoneMutation = { __typename?: 'Mutation', setUserTimeZoneSettings: boolean };


export const SetUserTimezoneDocument = gql`
    mutation SetUserTimezone($timezone: String!) {
  setUserTimeZoneSettings(timeZone: $timezone)
}
    `;
export type SetUserTimezoneMutationFn = Apollo.MutationFunction<SetUserTimezoneMutation, SetUserTimezoneMutationVariables>;

/**
 * __useSetUserTimezoneMutation__
 *
 * To run a mutation, you first call `useSetUserTimezoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserTimezoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserTimezoneMutation, { data, loading, error }] = useSetUserTimezoneMutation({
 *   variables: {
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useSetUserTimezoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetUserTimezoneMutation, SetUserTimezoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SetUserTimezoneMutation, SetUserTimezoneMutationVariables>(SetUserTimezoneDocument, options);
      }
export type SetUserTimezoneMutationHookResult = ReturnType<typeof useSetUserTimezoneMutation>;
export type SetUserTimezoneMutationResult = Apollo.MutationResult<SetUserTimezoneMutation>;
export type SetUserTimezoneMutationOptions = Apollo.BaseMutationOptions<SetUserTimezoneMutation, SetUserTimezoneMutationVariables>;