import Bowser from 'bowser';

export function isBrowserOutdated() {
  const browser = Bowser.getParser(window.navigator.userAgent);

  const browserName = browser.getBrowserName();
  const browserVersion = browser.getBrowserVersion();

  const outdatedBrowsers: Record<string, number> = {
    'Internet Explorer': 11,
    'Microsoft Edge': 18,
    Firefox: 102,
    Chrome: 101,
    Safari: 14,
    Opera: 60,
  };

  if (browserName in outdatedBrowsers) {
    const minimumVersion = outdatedBrowsers[browserName];
    return parseFloat(browserVersion) < minimumVersion;
  }

  return false;
}
