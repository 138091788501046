import {FeatureFlag, LocalStorageKey} from 'core/types';

export const fetchLocalFeatureFlags = () => {
  try {
    return (
      (JSON.parse(localStorage.getItem(LocalStorageKey.Modifiers) || '{}')?.state?.featureFlags as Record<
        FeatureFlag['name'],
        FeatureFlag['enabled']
      >) || {}
    );
  } catch (e) {
    return {};
  }
};
