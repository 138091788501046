import {useEffect} from 'react';
import LogRocket from 'logrocket';

import {APP_VERSION} from 'core/constants/globalVariables';
import {useUserInfo} from 'core/hooks/authHooks';
import {getFullName, useAuthStore} from 'core/stores/auth';
import {impersonationToken} from 'core/impersonation';
import {IS_PROD_ENV} from 'config';

export const useInitializeLogRocket = () => {
  const fullName = useAuthStore(getFullName);
  const {id, profile: {email} = {}, roles, tenantId, tenantName} = useUserInfo();

  useEffect(() => {
    if (!email || !IS_PROD_ENV) {
      return;
    }

    const isInternal = email?.endsWith('@ennabl.com');
    LogRocket.init(isInternal ? 'jbnoc5/ennabl-internal' : 'jbnoc5/ennabl', {release: APP_VERSION});

    LogRocket.identify(id, {
      name: fullName,
      email,
      tenantId,
      tenantName,
      impersonated: Boolean(impersonationToken),
      roles: roles.join(', '),
    });
  }, [id, roles, tenantId, tenantName, email, fullName]);
};
