export function parseJSON<T>(
  jsonString: string | null,
  defaultValue: T,
  reviver?: ((this: any, key: string, value: any) => any) | undefined
): T {
  if (!jsonString) {
    return defaultValue;
  }

  try {
    return JSON.parse(jsonString, reviver);
  } catch (error) {
    return defaultValue;
  }
}
