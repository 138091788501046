import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  dialogContentClasses,
  styled,
  ThemeProvider,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';

import {LocalStorageKey} from 'core/types';

import {themeDecisions} from 'core/themes/themeDecisions';
import {isWebViewApp} from 'core/utils/webView';
import {isCypressApp} from 'core/utils/cypress';
import {isMobile} from 'utils/deviceInfo';
import {dayjs, diffDates, now} from 'utils/dateUtils';

import AppStoreBadgeIcon from 'components/icons/appStoreBadge.svg';
import PhoneSyncIcon from 'components/icons/phoneSync.svg';

import {DialogTitleCloseButton} from 'modules/decisionsNext/components/DialogTitle';

const WAIT_NEXT_DAYS = 7;

const APP_STORE_LINK = 'https://apps.apple.com/us/app/ennabl/id6464488629';

const SDialogTitleCloseButton = styled(DialogTitleCloseButton)(({theme}) => ({
  alignSelf: 'flex-end',
  margin: theme.spacing(4),
}));

const SDialogContent = styled(DialogContent)(({theme}) => ({
  [`&.${dialogContentClasses.root}`]: {
    borderTop: 'none',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(6),
  padding: theme.spacing(10),
}));

const SPhoneSyncIconBox = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.background.grey,
  padding: theme.spacing(7.5),
  borderRadius: '50%',
}));

const SAppStoreBadgeIcon = styled(AppStoreBadgeIcon)(() => ({
  flexShrink: 0,
}));

export function DownloadMobileAppDialog() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isMobile || isWebViewApp() || isCypressApp()) {
      return;
    }

    const lastDialog = localStorage.getItem(LocalStorageKey.DownloadMobileAppLastDialogTime);
    const lastDialogDate = lastDialog ? dayjs(lastDialog) : null;
    const isValidDate = lastDialogDate?.isValid();
    const isTimeOver = isValidDate && !!lastDialogDate && diffDates(now(), lastDialogDate, 'day') > WAIT_NEXT_DAYS;

    if (lastDialog === null || !isValidDate || isTimeOver) {
      setIsOpen(true);
      localStorage.setItem(LocalStorageKey.DownloadMobileAppLastDialogTime, now());
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  if (!isMobile || isWebViewApp() || isCypressApp()) {
    return null;
  }

  return (
    <ThemeProvider theme={themeDecisions}>
      <Dialog maxWidth='xs' open={isOpen} onClose={handleClose}>
        <SDialogTitleCloseButton onClose={handleClose} />

        <SDialogContent>
          <SPhoneSyncIconBox>
            <PhoneSyncIcon />
          </SPhoneSyncIconBox>
          <Typography align='center'>Get the most out of ennabl by installing the app on your phone</Typography>
          <SAppStoreBadgeIcon />
        </SDialogContent>

        <DialogActions>
          <Button
            fullWidth
            href={APP_STORE_LINK}
            target='_blank'
            variant='contained'
            color='primary'
            onClick={handleClose}
          >
            Download the app
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
