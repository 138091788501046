import {Components, Theme} from '@mui/material/styles';

export const fab = (
  theme: Theme
): {
  MuiFab: Components['MuiFab'];
} => {
  return {
    MuiFab: {
      styleOverrides: {
        root: {
          position: 'relative', // Ensure positioning context for pseudo-element
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.main.outlinedHover.actionHover}`,
          boxShadow: 'none',

          '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.main.outlinedHover.actionHover,
          },
        },
      },
    },
  };
};
