import useMuiMediaQuery, {UseMediaQueryOptions} from '@mui/material/useMediaQuery';
import type {Theme} from '@mui/material/styles';
import {BreakpointOverrides} from '@mui/material/styles';

export default function useMediaQuery(queryInput: (theme: Theme) => string, options?: UseMediaQueryOptions) {
  return useMuiMediaQuery(queryInput, {
    noSsr: true,
    ...options,
  });
}

export function useMediaQueryIsDown(breakpoint: keyof BreakpointOverrides, options?: UseMediaQueryOptions) {
  return useMediaQuery(theme => theme.breakpoints.down(breakpoint), options);
}

export function useMediaQueryIsUp(breakpoint: keyof BreakpointOverrides, options?: UseMediaQueryOptions) {
  return useMediaQuery(theme => theme.breakpoints.up(breakpoint), options);
}

export function useIsDownXl(options?: UseMediaQueryOptions) {
  return useMediaQuery(theme => theme.breakpoints.down('xl'), options);
}

export function useIsDownLg(options?: UseMediaQueryOptions) {
  return useMediaQuery(theme => theme.breakpoints.down('lg'), options);
}

export function useIsDownMd(options?: UseMediaQueryOptions) {
  return useMediaQuery(theme => theme.breakpoints.down('md'), options);
}

export function useIsDownSm(options?: UseMediaQueryOptions) {
  return useMediaQuery(theme => theme.breakpoints.down('sm'), options);
}

export function useIsDownXs(options?: UseMediaQueryOptions) {
  return useMediaQuery(theme => theme.breakpoints.down('xs'), options);
}

export function useIsUpSm(options?: UseMediaQueryOptions) {
  return useMediaQuery(theme => theme.breakpoints.up('sm'), options);
}

export function useIsUpMd(options?: UseMediaQueryOptions) {
  return useMediaQuery(theme => theme.breakpoints.up('md'), options);
}

export function useWithHover() {
  return useMuiMediaQuery('(hover: hover)');
}
