import {Navigate, Route, Routes} from 'react-router-dom';
import {Toaster} from 'sonner';

import {AppPermissionId} from 'modules/decisionsNext/api/types';

import {IS_DEV_ENV, IS_PROD_ENV} from 'config';
import {FeatureFlags} from 'core/constants/featureFlags';
import {
  ENNABL_CHAT_AI,
  ENNABL_COMMAND,
  ENNABL_DECISIONS,
  ENNABL_GROWTH,
  ENNABL_IMPERSONATE,
} from 'core/constants/paths';
import {
  useFeatureFlag,
  useOnlyLossRunsPermission,
  useOnlyReportStudioPermission,
  useWithEnnablAI,
} from 'core/hooks/authHooks';
import {useDefaultRoute} from 'core/hooks/useDefaultRoute';
import {impersonationToken} from 'core/impersonation';
import {ConfirmationManager} from 'utils/confirmation';
import {Ennabl} from 'utils/ennabl';
import {useProducerViewWithCarrierAppetiteFlag} from 'utils/hooks/useProducerViewWithCarrierAppetiteFlag';
import {lazyLoad} from 'utils/lazyLoadUtils';

import {DownloadMobileAppDialog} from 'components/DownloadMobileAppDialog';
import {Analytics} from 'components/Head/Analytics';
import {RevealPlugin} from 'components/Head/RevealPlugin';
import {LoadingScreen} from 'components/LoadingScreen';
import {OfflineBanner} from 'components/OfflineBanner';
import {RequirePermission} from 'components/RequirePermission';

import {useInitializeLogRocket} from './hooks/useInitializeLogRocket';
import {useInitializeWebViewApp} from './hooks/useInitializeWebViewApp';

const lazyOpts = {fallback: <LoadingScreen />};

const DevRoot = IS_DEV_ENV
  ? lazyLoad(() => import(/* webpackChunkName:'dev-root' */ 'modules/dev/DevRoot'), lazyOpts)
  : () => null;
const CommandRoot = lazyLoad(
  () => import(/* webpackChunkName:'command-root' */ 'modules/command/components/Root'),
  lazyOpts
);
const DecisionsRoot = lazyLoad(
  () => import(/* webpackChunkName:'decisions-root' */ 'modules/decisionsNext/components/Root'),
  lazyOpts
);
const GrowthRoot = lazyLoad(
  () => import(/* webpackChunkName:'growth-root' */ 'modules/growth/components/Root'),
  lazyOpts
);
const AIChatRoot = lazyLoad(
  () => import(/* webpackChunkName:'chat-ai-root' */ 'modules/aiChat/components/Root'),
  lazyOpts
);

const NotFoundPage = lazyLoad(
  () => import(/* webpackChunkName:'not-found-page' */ 'components/Errors/NotFoundPage'),
  lazyOpts
);
const ImpersonationBanner = lazyLoad(
  () => import(/* webpackChunkName:'impersonation-banner' */ 'components/ImpersonationBanner'),
  {
    fallback: null,
  }
);
const ImpersonateRoot = lazyLoad(
  () => import(/* webpackChunkName:'impersonate-root' */ 'modules/impersonate/ImpersonateRoot'),
  lazyOpts
);
const ModifierRoot = lazyLoad(
  () => import(/* webpackChunkName:'modifier-root' */ 'modules/modifier/ModifierRoot'),
  lazyOpts
);

const App = () => {
  const withRevealPlugin = useFeatureFlag(FeatureFlags.RevealPlugin);
  const onlyReportStudioPermission = useOnlyReportStudioPermission();
  const onlyLossRunsPermission = useOnlyLossRunsPermission();
  const restrictedAccess = onlyReportStudioPermission || onlyLossRunsPermission;

  const withEnnablGrowth = useFeatureFlag(FeatureFlags.ProducerPortal);
  const {isAIEnabled: withEnnablAI} = useWithEnnablAI();
  const defaultRoute = useDefaultRoute();
  const {isProducerWithCarrierAppetite} = useProducerViewWithCarrierAppetiteFlag();
  const withDecisions = !isProducerWithCarrierAppetite;

  Ennabl.logOnce(['Test'], 'App initialized');

  useInitializeWebViewApp();
  useInitializeLogRocket();

  return (
    <>
      {withRevealPlugin && <RevealPlugin />}
      {IS_PROD_ENV && <Analytics />}
      {impersonationToken && <ImpersonationBanner />}
      <ConfirmationManager />
      <DownloadMobileAppDialog />

      <Routes>
        <Route index element={<Navigate replace to={defaultRoute} />} />

        {withDecisions && (
          <Route element={<RequirePermission permissionId={AppPermissionId.ViewDecisions} />}>
            <Route path={`${ENNABL_DECISIONS}/*`} element={<DecisionsRoot />} />
          </Route>
        )}

        {!restrictedAccess && (
          <>
            <Route path={ENNABL_IMPERSONATE} element={<ImpersonateRoot />} />

            <Route element={<RequirePermission permissionId={AppPermissionId.EditCommand} />}>
              <Route path={`${ENNABL_COMMAND}/*`} element={<CommandRoot />} />
            </Route>

            {withEnnablGrowth && <Route path={`${ENNABL_GROWTH}/*`} element={<GrowthRoot />} />}

            {withEnnablAI && <Route path={`${ENNABL_CHAT_AI}/*`} element={<AIChatRoot />} />}
          </>
        )}

        {IS_DEV_ENV && (
          <>
            <Route path='/modifier' element={<ModifierRoot />} />
            <Route path='/dev/*' element={<DevRoot />} />
          </>
        )}

        <Route
          path='*'
          element={restrictedAccess ? <Navigate replace to={defaultRoute} /> : <NotFoundPage backLink={defaultRoute} />}
        />
      </Routes>

      <Toaster expand closeButton visibleToasts={5} />

      <OfflineBanner />
    </>
  );
};

export default App;
