import {Components, Theme} from '@mui/material/styles';

export const divider = (theme: Theme): {MuiDivider: Components['MuiDivider']} => ({
  MuiDivider: {
    styleOverrides: {
      vertical: {
        borderRightWidth: 2,
        borderRadius: theme.shape.borderRadiusRegular,
      },
    },
  },
});
