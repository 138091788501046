import {create} from 'zustand';

import {UserReportStatus} from 'modules/decisionsNext/api/types';

type DownloadJob = {
  id: string;
  status: UserReportStatus;
  filename?: string;
  url?: string | null;
  title?: string;
  onTryAgainClick?: () => void;
};

type DownloadReportStore = {
  jobs: DownloadJob[];
};

export const useDownloadReportStore = create<DownloadReportStore>()(() => ({
  jobs: [],
}));

export const addDownloadJob = (job: DownloadJob) =>
  useDownloadReportStore.setState(state => ({jobs: [...state.jobs, job]}));
export const updateDownloadJob = (updated: DownloadJob) =>
  useDownloadReportStore.setState(state => ({
    jobs: state.jobs.map(job => (job.id === updated.id ? {...job, ...updated} : job)),
  }));

export const deleteDownloadJob = (jobId: string) => {
  useDownloadReportStore.setState(state => ({
    jobs: [...state.jobs.filter(job => job.id !== jobId)],
  }));
};
