export const colorOrange = '#F15727';
export const colorBlue = '#041553';
export const colorBlackish = '#212121';
export const colorLightBlue = '#f6f8fe';
export const colorMediumBlue = '#d9ddec';
export const colorSkyBlue = '#0576cb';
export const colorBrightBlue = '#0091ff';
export const colorMidnightBlue = '#2a439e';
export const colorNightBlue = '#383874';
export const colorVioletBlue = '#6B84DF';
export const colorPaleBlue = '#748CE6';
export const colorGrayBlue = '#E3E6F2';
export const colorGrayBright = '#F5F7FE';
export const colorLightGrayBlue = '#F2F3F6';
export const colorBoldBlue = '#1C2438';
export const colorBoldBlueLight = '#3E4F7A';
export const colorBoldBlue200 = '#37466D';
export const colorDarkBlue = '#173293';
export const colorDarkerBlue = '#0F1941';
export const colorDarkestBlue = '#0B044F';
export const colorGray = '#69708b';
export const colorGreyNew = '#7F859F';
export const colorBlueishGray = '#4f5b87';
export const colorWhite = '#fff';
export const colorBlack = '#000';
export const colorRed = '#B00B0B';
export const colorLightRed = '#FC5A5A';
export const colorAlertRed = '#FB2323';
export const colorGreen = '#81A932';
export const colorGreenGrowth = '#7DAB24';
export const colorGreenGrowthUpdated = '#BCF1E3';
export const colorGreenNeon = '#3DD598';
export const colorOrangeBackground = '#FA5E0D';
export const colorGrayThin = '#6A718C';
export const colorGrayLight = '#EEF0FA';
export const colorBlueNeon = '#0062FF';
export const colorGrayChartGrid = '#EEEFF6';
export const colorRedSolid = '#A90F26';

export type PaletteColor =
  | 'blueBoldness'
  | 'ennablOrange'
  | 'redAlert'
  | 'reliableRed'
  | 'passionatePurple'
  | 'bluetiful'
  | 'activeBlue'
  | 'tenaciousTeal'
  | 'electricTeal'
  | 'greenGrowth'
  | 'honeyYellow'
  | 'elefontGrey'
  | 'coolGrey'
  | 'periwinkleGrey'
  | 'lavenderGrey';

export type PaletteLightColorIndex = 10 | 20 | 30;
export type PalettePrimaryColorIndex = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
export type PaletteDarkColorIndex = 1000 | 1100 | 1200;
export type PaletteColorIndex = PaletteLightColorIndex | PalettePrimaryColorIndex | PaletteDarkColorIndex;

// ððððð
const lightColorMap = {
  'blueBoldness/10': 'hsl(223, 33%, 88%)',
  'blueBoldness/20': 'hsl(223, 33%, 84%)',
  'blueBoldness/30': 'hsl(223, 33%, 80%)',

  'ennablOrange/10': 'hsl(21, 96%, 96%)',
  'ennablOrange/20': 'hsl(21, 96%, 92%)',
  'ennablOrange/30': 'hsl(21, 96%, 84%)',

  'redAlert/10': 'hsl(0, 96%, 98%)',
  'redAlert/20': 'hsl(0, 96%, 92%)',
  'redAlert/30': 'hsl(0, 96%, 86%)',

  'reliableRed/10': 'hsl(351, 84%, 96%)',
  'reliableRed/20': 'hsl(351, 84%, 88%)',
  'reliableRed/30': 'hsl(351, 84%, 94%)',

  'passionatePurple/10': 'hsl(260, 100%, 96%)',
  'passionatePurple/20': 'hsl(260, 100%, 90%)',
  'passionatePurple/30': 'hsl(260, 100%, 84%)',

  'bluetiful/10': 'hsl(227, 21%, 92%)',
  'bluetiful/20': 'hsl(227, 21%, 88%)',
  'bluetiful/30': 'hsl(227, 21%, 84%)',

  'activeBlue/10': 'hsl(206, 100%, 96%)',
  'activeBlue/20': 'hsl(206, 100%, 90%)',
  'activeBlue/30': 'hsl(206, 100%, 84%)',

  'tenaciousTeal/10': 'hsl(180, 92%, 94%)',
  'tenaciousTeal/20': 'hsl(180, 92%, 88%)',
  'tenaciousTeal/30': 'hsl(180, 92%, 84%)',

  'electricTeal/10': 'hsl(165, 65%, 92%)',
  'electricTeal/20': 'hsl(165, 65%, 88%)',
  'electricTeal/30': 'hsl(165, 65%, 84%)',

  'greenGrowth/10': 'hsl(80, 54%, 94%)',
  'greenGrowth/20': 'hsl(80, 54%, 88%)',
  'greenGrowth/30': 'hsl(80, 54%, 84%)',

  'honeyYellow/10': 'hsl(45, 86%, 92%)',
  'honeyYellow/20': 'hsl(45, 86%, 88%)',
  'honeyYellow/30': 'hsl(45, 86%, 84%)',

  'elefontGrey/10': 'hsl(228, 14%, 94%)',
  'elefontGrey/20': 'hsl(228, 14%, 88%)',
  'elefontGrey/30': 'hsl(228, 14%, 84%)',

  'coolGrey/10': 'hsl(228, 21%, 94%)',
  'coolGrey/20': 'hsl(228, 14%, 88%)',
  'coolGrey/30': 'hsl(224, 14%, 82%)',

  'periwinkleGrey/10': 'hsl(226, 25%, 96%)',
  'periwinkleGrey/20': 'hsl(226, 25%, 92%)',
  'periwinkleGrey/30': 'hsl(226, 25%, 84%)',

  'lavenderGrey/10': 'hsl(228, 83%, 93%)',
  'lavenderGrey/20': 'hsl(227, 79%, 94%)',
  'lavenderGrey/30': 'hsl(231, 87%, 97%)',
};

const colorFactory =
  (colorName: PaletteColor, h: number, s: number, l: number, a: number = 1) =>
  (index: PaletteColorIndex, alphaOverride?: number) => {
    if ([10, 20, 30].includes(index)) {
      // We have to hardcode values for light colors unfortunately
      // Designers need specific color that do not fit into our regular logic
      return lightColorMap[`${colorName}/${index as PaletteLightColorIndex}`];
    }

    return `hsla(${h}, ${s}%, ${l + ((900 - index) / 100) * 4}%, ${alphaOverride ?? a})`;
  };

// https://www.figma.com/file/ETRUqgZzEiVhz7NM3NA28D/Design-Library?node-id=7%3A8
export const colorPalette = {
  blueBoldness: colorFactory('blueBoldness', 223, 33, 4),
  ennablOrange: colorFactory('ennablOrange', 21, 96, 40),
  redAlert: colorFactory('redAlert', 0, 96, 56),
  reliableRed: colorFactory('reliableRed', 351, 84, 36),
  passionatePurple: colorFactory('passionatePurple', 260, 100, 52),
  bluetiful: colorFactory('bluetiful', 227, 90, 4),
  activeBlue: colorFactory('activeBlue', 206, 100, 40),
  tenaciousTeal: colorFactory('tenaciousTeal', 180, 92, 24),
  electricTeal: colorFactory('electricTeal', 165, 65, 38),
  greenGrowth: colorFactory('greenGrowth', 80, 54, 32),
  honeyYellow: colorFactory('honeyYellow', 45, 86, 50),
  elefontGrey: colorFactory('elefontGrey', 226, 14, 36),
  coolGrey: colorFactory('coolGrey', 228, 21, 36),
  periwinkleGrey: colorFactory('periwinkleGrey', 226, 24, 68),
  lavenderGrey: colorFactory('lavenderGrey', 227, 82, 66),
};

export type PaletteColorFactory = typeof colorPalette.ennablOrange;

export const colorNames = Object.keys(colorPalette).reduce(
  (acc, val, key) => ({
    ...acc,
    [key]: key,
  }),
  {}
) as Record<keyof typeof colorPalette, keyof typeof colorPalette>;

// Used in index.css
export const backgroundColor = colorLightBlue;
// Used in index.css
export const textColor = colorPalette.blueBoldness(600);
export const textColorSecondary = colorGray;
export const textColorSecondaryNew = colorGreyNew;
export const titleColor = colorBlueishGray;
export const fontPrimary = 'Inter';
export const fontSecondary = fontPrimary;
export const fontSizeRegular = 14;
export const fontSizeReduced = 12;
export const fontSizeSmall = 10;
export const fontSizeBigger = 16;
export const fontSizeBig = 32;
export const fontSizeHeader1 = 18;
export const fontSizeHeader2 = 20;
export const fontSizeHeader3 = 24;
export const fontSizeHeader4 = 30;
export const fontSizeHeader5 = 48;
export const letterSpacingExtraSmall = 0.1;
export const letterSpacingSmall = 0.18;
export const letterSpacingRegular = 0.27;
export const letterSpacingMedium = 0.55;
export const letterSpacingLarge = 1;
export const letterSpacingHuge = 2;
export const defaultPadding = 20;
export const regularPadding = 16;
export const smallPadding = 8;
export const largePadding = 28;
export const shadowColor = 'rgba(0, 0, 0, 0.08)';
export const shadowColorDarker = 'rgba(0, 0, 0, 0.18)';
export const mainShadow = '0 10px 16px rgba(108, 73, 172, 0.0503198), 0 5px 8px rgba(108, 73, 172, 0.0417275)';
export const mainShadowFilterNew =
  'drop-shadow(0px 10px 32px rgba(108, 73, 172, 0.0503198)) drop-shadow(0px 5px 16px rgba(108, 73, 172, 0.0417275))';
export const mainShadowNew = '0px 10px 32px rgba(108, 73, 172, 0.0503198), 0px 5px 16px rgba(108, 73, 172, 0.0417275)';
export const borderColor = '#c8cdde';
export const borderColorDecisions = '#E6E8F7';
export const borderRadius = 18;
export const borderRadiusLarge = borderRadius;
export const borderRadiusMedium = 12;
export const borderRadiusRegular = 8;
export const borderRadiusSmall = 4;
export const gap = 16;
export const defaultTransitionDuration = '0.1s';
export const defaultTransitionCurve = 'ease-in-out';
export const defaultTransition = `${defaultTransitionDuration} ${defaultTransitionCurve}`;

export const paperBackgroundColor = colorWhite;
export const paperBackgroundColorAccent = colorLightBlue;

export const headerBackgroundColor = colorBlue;

export const topbarBackgroundColor = colorWhite;
export const topbarTitleColor = colorBoldBlue;
export const topbarInputBackgroundColor = colorGrayLight;
export const topbarInputColor = colorBoldBlue;

export const sidebarBackgroundColor = colorWhite;
export const sidebarAccentColor = colorBoldBlue;
export const sidebarIconColor = colorBoldBlue;
export const sidebarSelectedIconColor = colorWhite;
export const sidebarSubNavigationActiveItemColor = colorBoldBlue200;
export const sidebarSubNavigationSelectedItemColor = colorBoldBlue200;
export const sidebarUseMenuIconColor = colorGray;

export const statusOkColor = '#15b630';
export const statusWarningColor = '#fbb40f';
export const statusFailedColor = '#b00b0b';

export const inputTextColor = colorBlack;
export const inputLabelColor = textColorSecondary;
export const inputBorderColor = borderColor;
export const inputBorderActiveColor = colorDarkBlue;
// TODO: migrate to vvv and after that remove ^^^
export const inputBorderActiveColorNew = colorBrightBlue;
export const inputBorderWidth = 1;
export const inputErrorColor = colorLightRed;

export const multiToggleBorderColor = borderColorDecisions;
export const multiToggleBackgroundColor = colorWhite;
export const multiToggleActiveBackgroundColor = colorPalette.activeBlue(600);
export const multiToggleActiveColor = colorWhite;
export const multiToggleHoverBackgroundColor = colorPalette.activeBlue(600, 0.05);
export const multiToggleHoverColor = colorPalette.activeBlue(600);

export const dataGridBorderColor = borderColor;

export const scrollbarColor = colorPalette.periwinkleGrey(600);

export const SIDEBAR_WIDTH = 256;

export const breakpointMobile = 768;
export const breakpointMd = 1024;
export const breakpointLg = 1440;

export const minWidthMobile = `min-width: ${breakpointMobile + 1}px`;
export const maxWidthMobile = `max-width: ${breakpointMobile}px`;

export const minWidthMd = `min-width: ${breakpointMd + 1}px`;
export const maxWidthMd = `max-width: ${breakpointMd}px`;

export const minWidthLg = `min-width: ${breakpointLg + 1}px`;
export const maxWidthLg = `max-width: ${breakpointLg}px`;

// lg-breakpoint and up
export const smallWidgetLgHeight = 140;
export const mediumWidgetLgHeight = 218;
export const largeWidgetLgHeight = 296;
export const xLargeWidgetLgHeight = 374;
export const largestWidgetLgHeight = 452;

// md-breakpoint an up
export const smallWidgetMdHeight = 112;
export const mediumWidgetMdHeight = 176;
export const largeWidgetMdHeight = 240;
export const xLargeWidgetMdHeight = 304;
export const largestWidgetMdHeight = 368;

export const decisionsLogoColor = '#7C3CFF';
export const commandLogoColor = '#07A8A8';

export const chartTooltipDelay = 500;
