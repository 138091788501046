import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import {Button} from '@mui/material';

import UnsupportedIcon from 'components/icons/unsupported.svg';

const SRoot = styled('div')(({theme}) => ({
  minHeight: '100vh',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.background.white,
}));

const SPlaceholder = styled('div')(({theme}) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(10),
}));

const SPlaceholderContent = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  alignItems: 'center',
  textAlign: 'center',
  maxWidth: 720,
}));

const SIconWrapper = styled('div')(({theme}) => ({
  width: 'max-content',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  padding: theme.spacing(9.5),
  borderRadius: '50%',
  backgroundColor: theme.palette.background.grey,
}));

const SList = styled('ul')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: 0,
  padding: theme.spacing(0, 12),
}));

const SLink = styled(Link)(({theme}) => ({
  textAlign: 'left',
  textDecoration: 'underline',

  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

type UnsupportedBrowserWarningProps = {
  setIsWarningSeen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function UnsupportedBrowserWarning({setIsWarningSeen}: UnsupportedBrowserWarningProps) {
  function handleClick() {
    setIsWarningSeen(true);
  }

  return (
    <SRoot>
      <SPlaceholder>
        <SPlaceholderContent>
          <SIconWrapper>
            <UnsupportedIcon />
          </SIconWrapper>
          <Typography variant='h4'>Browser not supported</Typography>
          <Typography variant='body1'>
            Your browser’s version is outdated or unsupported. To get full experience we recommend updating your browser
            to a newer version:
          </Typography>
          <SList>
            <li>
              <SLink to='https://bestvpn.org/outdatedbrowser/'>
                <Typography variant='body1'>Check the latest versions of browsers</Typography>
              </SLink>
            </li>
            <li>
              <Typography textAlign='left' variant='body1'>
                If you need any assistance, please feel free to{' '}
                <SLink to='https://www.ennabl.com/about/contact-us'>
                  <Typography display='inline' variant='body1'>
                    contact us
                  </Typography>
                </SLink>
              </Typography>
            </li>
          </SList>

          <Button onClick={handleClick} variant='outlined'>
            Proceed Anyway
          </Button>
        </SPlaceholderContent>
      </SPlaceholder>
    </SRoot>
  );
}
