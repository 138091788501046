import {ComponentPropsWithoutRef, ElementType, useMemo} from 'react';
import {styled} from '@linaria/react';
import {css, LinariaClassName} from '@linaria/core';
import {isString} from 'lodash/fp';
import cx from 'clsx';

import {styleVariables} from 'core/constants';
import type {PaletteColor, PaletteColorIndex} from 'core/constants/styleVariables';

export type ColorVariants = 'primary' | 'secondary';

const colorMap = {
  primary: styleVariables.textColor,
  secondary: styleVariables.textColorSecondary,
};

export const fontSizeMap = {
  regular: styleVariables.fontSizeRegular,
  reduced: styleVariables.fontSizeReduced,
  bigger: styleVariables.fontSizeBigger,
  big: styleVariables.fontSizeBig,
  h1: styleVariables.fontSizeHeader1,
  h2: styleVariables.fontSizeHeader2,
  h3: styleVariables.fontSizeHeader3,
  h4: styleVariables.fontSizeHeader4,
  h5: styleVariables.fontSizeHeader5,
  button: styleVariables.fontSizeRegular,
};

export const letterSpacingMap = {
  small: styleVariables.letterSpacingSmall,
  regular: styleVariables.letterSpacingRegular,
  medium: styleVariables.letterSpacingMedium,
  large: styleVariables.letterSpacingLarge,
  huge: styleVariables.letterSpacingHuge,
};

export type TypoVariant =
  | 'text'
  | 'bolded-text'
  | 'caption-text'
  | 'caption'
  | 'input-placeholder'
  | 'subheading'
  | 'standard'
  | 'medium-text'
  | 'main-page-heading'
  | 'modal-heading'
  | 'modal-data'
  | 'chart-heading'
  | 'legend-key-label'
  | 'chart-graphic-percentage'
  | 'chart-xy-main'
  | 'chart-xy-small'
  | 'cell-data'
  | 'menu-text'
  | 'menu-text-bolded'
  | 'column-heading'
  | 'small-capitalized-title'
  | 'button-label'
  | 'button-text'
  | 'main-display-data'
  | 'large-data-h1'
  | 'widget-heading-h2'
  | 'widget-data-and-subheading-h3'
  | 'widget-growth-indicator'
  | 'widget-text'
  | 'widget-medium-text'
  | 'widget-bold-text'
  | 'widget-semi-bold-label'
  | 'error-page-heading'
  | 'error-page-subheading';

type Props<C extends ElementType> = {
  className?: string;
  color?: string | ColorVariants | [string, PaletteColorIndex];
  uppercase?: boolean;
  capitalize?: boolean;
  variant?: TypoVariant;
  noWrap?: boolean;
  as?: C;
};

export type TypoProps<C extends ElementType = 'span'> = Props<C> & Omit<ComponentPropsWithoutRef<C>, keyof Props<C>>;

function textTransform(props: {uppercase?: boolean; capitalize?: boolean}) {
  if (props.uppercase) {
    return 'uppercase';
  }

  if (props.capitalize) {
    return 'capitalize';
  }

  return 'none';
}

function getColor(color?: string | ColorVariants | [PaletteColor, PaletteColorIndex]) {
  if (Array.isArray(color)) {
    return styleVariables.colorPalette[color[0]](color[1]);
  }

  if (isString(color)) {
    return colorMap[color as ColorVariants] ?? color;
  }

  return 'inherit';
}

type STypoProps = {
  color: string;
  uppercase?: TypoProps['uppercase'];
  capitalize?: TypoProps['capitalize'];
};

const STypo = styled.span<STypoProps & {trans: string}>`
  color: ${p => p.color};
  text-transform: ${p => p.trans};
  font-feature-settings: 'tnum' on, 'lnum' on;
`;

const sText = css`
  font-size: 12px;
  line-height: 16px;
`;

const sBoldedText = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

const sCaptionText = css`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

const sCaption = css`
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
`;

const sMainPageHeading = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

const sSubheading = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const sChartsHeading = css`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
`;

const sLegendKeyLabel = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

const sStandard = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: 'tnum' on, 'lnum' on;
`;
const sMediumText = css`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'tnum' on, 'lnum' on;
`;
const sChartsXYSmall = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

const sChartGraphicPercentage = css`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;

const sChartsXYMain = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

const sModalHeading = css`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
`;

const sModalData = css`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
`;

const sSmallCapitalizedTitle = css`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
`;

const sColumnHeading = css`
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
`;

const sButtonLabel = css`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
`;
const sButtonText = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;

const sMainDisplayData = css`
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
`;

const sCellData = css`
  font-size: 16px;
  line-height: 19px;
`;

const sLargeDataH1 = css`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
`;

const sMenuText = css`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`;

const sMenuTextBolded = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
`;

const sWidgetHeadingH2 = css`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

const sWidgetDataAndSubheadingH3 = css`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
`;

const sWidgetGrowthIndicator = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

const sWidgetText = css`
  font-size: 12px;
  line-height: 16px;
`;

const sWidgetMediumText = css`
  font-size: 14px;
  line-height: 16px;
`;

const sWidgetBoldText = css`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
`;

const sWidgetSemiBoldLabel = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

const sErrorPageHeading = css`
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
`;

const sErrorPageSubheading = css`
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
`;
const sInputPlaceholder = css`
  font-size: 15px;
  line-height: 18px;
  font-weight: 300;
`;

const sNoWrap = css`
  white-space: nowrap;
`;

const variantToClassName: Record<TypoVariant, LinariaClassName> = {
  caption: sCaption,
  text: sText,
  'bolded-text': sBoldedText,
  'caption-text': sCaptionText,
  'input-placeholder': sInputPlaceholder,
  'main-page-heading': sMainPageHeading,
  subheading: sSubheading,
  standard: sStandard,
  'medium-text': sMediumText,
  'modal-heading': sModalHeading,
  'modal-data': sModalData,
  'chart-heading': sChartsHeading,
  'legend-key-label': sLegendKeyLabel,
  'chart-xy-small': sChartsXYSmall,
  'chart-graphic-percentage': sChartGraphicPercentage,
  'chart-xy-main': sChartsXYMain,
  'cell-data': sCellData,
  'menu-text': sMenuText,
  'menu-text-bolded': sMenuTextBolded,
  'column-heading': sColumnHeading,
  'small-capitalized-title': sSmallCapitalizedTitle,
  'button-label': sButtonLabel,
  'button-text': sButtonText,
  'main-display-data': sMainDisplayData,
  'large-data-h1': sLargeDataH1,
  'widget-heading-h2': sWidgetHeadingH2,
  'widget-data-and-subheading-h3': sWidgetDataAndSubheadingH3,
  'widget-growth-indicator': sWidgetGrowthIndicator,
  'widget-text': sWidgetText,
  'widget-medium-text': sWidgetMediumText,
  'widget-bold-text': sWidgetBoldText,
  'widget-semi-bold-label': sWidgetSemiBoldLabel,
  'error-page-heading': sErrorPageHeading,
  'error-page-subheading': sErrorPageSubheading,
};

function Typo<C extends ElementType>({style, children, className, color, variant, noWrap, as, ...props}: TypoProps<C>) {
  const memoizedColor = useMemo(() => getColor(color as PaletteColor), [color]);

  return (
    <STypo
      as={as}
      style={style}
      color={memoizedColor}
      // all other prop names I tried are conflicted w/ DOM
      trans={textTransform(props)}
      className={cx(className, variant && variantToClassName[variant], noWrap && sNoWrap)}
      {...props}
    >
      {children}
    </STypo>
  );
}

export default Typo;
