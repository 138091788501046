import {Components, Theme} from '@mui/material/styles';

export const avatar = (theme: Theme): Partial<Components> => ({
  MuiAvatar: {
    styleOverrides: {
      circular: {
        ...theme.typography.body2,
        color: theme.palette.text.primary,
        width: 34,
        height: 34,
        border: `1px solid ${theme.palette.text.primary}`,
      },
      colorDefault: {
        backgroundColor: theme.palette.background.grey,
      },
    },
  },
});
