const {version} = require('../../../package.json');

export const BANDING_REVENUE = 'BANDING_REVENUE';
export const BANDING_PREMIUM = 'BANDING_PREMIUM';
export const BANDING_LIVES = 'BANDING_LIVES';

export enum EnnablEnv {
  development = 'development',
  stage = 'stage',
  production = 'production',
}

export const ENV_DEV_URL = 'https://dev.ennabl-test.com';

export const MIN_ALLOWED_YEAR = 1900;

export const DRAWER_ZINDEX = 1200;

export const APP_VERSION = version;

export const NO_VALUE_CELL = '–';

export const IS_TOUCH_DEVICE = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
