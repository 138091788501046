import {invert} from 'lodash/fp';

export const sortRatingIdMap: Record<string, number> = {
  'A++': 1,
  'A+': 2,
  A: 3,
  'A-': 4,
  'B++': 5,
  'B+': 6,
  B: 7,
  'B-': 8,
  'C++': 9,
  'C+': 10,
  C: 11,
  'C-': 12,
  D: 13,
  E: 14,
  F: 15,
  NR: 16,
  S: 17,
};

export const ratingSortIndexToTitle = invert(sortRatingIdMap);
