import {styled} from '@mui/material/styles';

import PrimaryLogo from 'components/icons/primary-logo.svg';

const SContainer = styled('header')(({theme}) => ({
  display: 'grid',
  placeItems: 'center',
  padding: theme.spacing(4),
}));

export const LogoHeader = () => {
  return (
    <SContainer>
      <PrimaryLogo />
    </SContainer>
  );
};
