import LoadingButton, {LoadingButtonProps} from '@mui/lab/LoadingButton';

import {useIsDownSm} from 'utils/hooks/useMediaQuery';

type PrimaryActionButtonProps = Omit<LoadingButtonProps, 'variant' | 'fullWidth'>;

export const PrimaryActionButton = (props: PrimaryActionButtonProps) => {
  const isDownSm = useIsDownSm();

  return <LoadingButton variant='contained' fullWidth={isDownSm} {...props} />;
};
