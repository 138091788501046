import {isNumber, isObject} from 'lodash/fp';

import type {SidebarSection} from 'modules/decisionsNext/hooks/sidebar/types';

import {WrongEnvironmentError} from 'core/utils/errors';
import {parseJSON} from 'utils/json';

const WAIT_FOR_MESSAGE_TIMEOUT = 10000;

type Action<TType, TPayload = undefined> = {
  type: TType;
  payload: TPayload;
};

const enum ActionType {
  REFETCH_TOKEN = 'REFETCH_TOKEN',
  OPEN_DRAWER = 'OPEN_DRAWER',
  LOG_OUT = 'LOG_OUT',
  LOG = 'LOG',
  SIDEBAR = 'SIDEBAR',
}

type WebViewAction =
  | Action<ActionType.REFETCH_TOKEN>
  | Action<ActionType.OPEN_DRAWER>
  | Action<ActionType.LOG_OUT>
  | Action<ActionType.LOG, string[]>
  | Action<ActionType.SIDEBAR, SidebarSection[]>;

type ExtractedAction<T> = Extract<WebViewAction, {type: T}>;

export const isWebViewApp = () => {
  return 'ReactNativeWebView' in window && isObject(window.ReactNativeWebView);
};

const dispatchMessage = <T extends ActionType>(type: T, payload?: ExtractedAction<T>['payload']) => {
  window.ReactNativeWebView.postMessage(JSON.stringify({type, payload}));
};

const listenForMessage = <T extends ActionType>(type: T, timeout?: number) => {
  return new Promise<ExtractedAction<T> | null>(resolve => {
    const listener = (event: MessageEvent<string>) => {
      const data = parseJSON<ExtractedAction<T> | null>(event.data, null);

      if (data?.type === type) {
        resolve(data);
        window.removeEventListener('message', listener);
      }
    };

    window.addEventListener('message', listener);

    if (timeout) {
      setTimeout(() => {
        window.removeEventListener('message', listener);
        resolve(null);
      }, timeout);
    }
  });
};

export const getWebViewAuthToken = () => {
  if (!isWebViewApp()) {
    throw new WrongEnvironmentError('WebView');
  }

  const {token, expiresAt} = window.ReactNativeAuth0;

  if (!token || !isNumber(expiresAt) || !isFinite(expiresAt)) {
    throw new Error('WebView auth token not found');
  }

  return {token, expiresAt: expiresAt * 1000};
};

export const refreshWebViewToken = async () => {
  if (!isWebViewApp()) {
    throw new WrongEnvironmentError('WebView');
  }

  const message = listenForMessage(ActionType.REFETCH_TOKEN, WAIT_FOR_MESSAGE_TIMEOUT);

  dispatchMessage(ActionType.REFETCH_TOKEN);

  await message;
};

export const openWebViewMenu = () => {
  if (!isWebViewApp()) {
    throw new WrongEnvironmentError('WebView');
  }

  dispatchMessage(ActionType.OPEN_DRAWER);
};

export const logOutWebView = () => {
  if (!isWebViewApp()) {
    throw new WrongEnvironmentError('WebView');
  }

  dispatchMessage(ActionType.LOG_OUT);
};

export const updateWebViewSidebar = (sections: SidebarSection[]) => {
  if (!isWebViewApp()) {
    throw new WrongEnvironmentError('WebView');
  }

  dispatchMessage(ActionType.SIDEBAR, sections);
};
