export const shape = {
  borderRadiusSmall: 2,
  borderRadius: 4,
  borderRadiusRegular: 8,
  borderRadiusMedium: 12,
  borderRadiusLarge: 16,
  borderRadiusExtraLarge: 24,
  sectionContainerMaxWidth: {
    lg: 1200,
    sm: 864,
  },
} as const;

export type DecisionsShape = typeof shape;
