import {Helmet} from 'react-helmet';
import {useEffect} from 'react';

import {useProjectName} from 'utils/hooks/useProjectName';
import {useUserInfo} from 'core/hooks/authHooks';

import {setIsInitialized} from './store';

const COMMAND_SITE_KEY = 'rev-site-.3VSSBNY-TVSEH2A-S6IKSII-K2VELXI';
const DECISIONS_SITE_KEY = 'rev-site-.DRPB2QI-J7IEEZI-SA7LOVA-5CBE4JI';
const REVEAL_API_KEY = 'rev-.LUV63BA-5G2UYYQ-ROZ5PRQ-ZB6MIOI';
const LOADED_EVENT = 'revealCopilotLoaded';

/**
 * RevealPlugin
 * https://academy.reveal.pub/knowledge-bases/cloo8t9y90000jv0frb93wxo8/pages/cltxv211w0002qav5scnnydii
 * @constructor
 */
export function RevealPlugin() {
  const {isCommand} = useProjectName();
  const userInfo = useUserInfo();
  const siteKey = isCommand ? COMMAND_SITE_KEY : DECISIONS_SITE_KEY;

  useEffect(() => {
    setTimeout(() => {
      try {
        window?.reveal?.launchCopilot(REVEAL_API_KEY, siteKey);
        window?.reveal?.identify({
          userId: userInfo?.id,
          name: userInfo?.profile?.name,
          email: userInfo?.profile?.email,
          role: userInfo?.roleType,
          company: {
            id: userInfo?.tenantId,
            name: userInfo?.tenantName,
          },
        });
      } catch (e) {
        console.error(e);
      }
    });

    const handleEvent = () => {
      setIsInitialized(true);
    };

    document.addEventListener(LOADED_EVENT, handleEvent);

    return () => {
      document.removeEventListener(LOADED_EVENT, handleEvent);
    };
  }, [siteKey, userInfo]);

  return (
    <Helmet>
      <link href='https://reveal-plugin.s3.amazonaws.com/style.css' rel='stylesheet' />

      <script
        type='module'
        src='https://reveal-plugin.s3.amazonaws.com/index.js'
        id='revealScript'
        data-api-key='rev-.LUV63BA-5G2UYYQ-ROZ5PRQ-ZB6MIOI'
        data-site-key={siteKey}
      />
    </Helmet>
  );
}
