import {Components, Theme} from '@mui/material/styles';

export const bottomNavigation = (theme: Theme): Partial<Components> => ({
  MuiBottomNavigation: {
    defaultProps: {
      showLabels: true,
    },
    styleOverrides: {
      root: {
        height: 'max-content',
        borderTop: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.grey,
        transition: theme.transitions.create(['backgroundColor'], {
          duration: theme.transitions.duration.shortest,
        }),
      },
    },
  },
  MuiBottomNavigationAction: {
    styleOverrides: {
      root: {
        ...theme.typography.subtitle3,
        color: theme.palette.text.primary,
        padding: theme.spacing(2, 0),
        gap: theme.spacing(1),
        minWidth: 60,

        '& > svg': {
          color: theme.palette.text.secondary,
          width: 24,
          height: 24,
        },

        '&.Mui-selected': {
          backgroundColor: theme.palette.action.hover,

          '&:not(.active), > svg': {
            color: theme.palette.text.primary,
          },
        },

        '&.active': {
          color: theme.palette.primary.main,

          '& > svg': {
            color: theme.palette.primary.main,
          },
        },
      },
      label: {
        '&.Mui-selected': {
          fontSize: theme.typography.subtitle3.fontSize,
        },
      },
    },
  },
});
