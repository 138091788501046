import {PropsWithChildren, useEffect, useRef} from 'react';

import {getAuthToken, refreshAuthToken} from 'core/utils/auth';
import {setTokenAndAuthorized} from 'core/stores/auth';

const REFRESH_INTERVAL = 1000 * 2;
const REFRESH_THRESHOLD = 1000 * 20;

export const AuthProvider = ({children}: PropsWithChildren) => {
  const expiresAt = useRef<number>(0);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const updateAuthToken = (props: {token: string; expiresAt: number}) => {
      expiresAt.current = props.expiresAt;
      setTokenAndAuthorized(props.token);
    };

    const refreshToken = async () => {
      let timeToExpire = expiresAt.current - Date.now();

      // Refresh token before it expires
      if (timeToExpire < REFRESH_THRESHOLD) {
        const authToken = await refreshAuthToken();

        if (!authToken) {
          return;
        }

        timeToExpire = authToken.expiresAt - Date.now();
        updateAuthToken(authToken);
      }

      // Schedule next refresh attempt after half of the time to expire
      timeoutRef.current = setTimeout(refreshToken, Math.max(timeToExpire / 2, REFRESH_INTERVAL));
    };

    const fetchToken = async () => {
      const authToken = await getAuthToken();

      if (!authToken) {
        return;
      }

      updateAuthToken(authToken);
      timeoutRef.current = setTimeout(refreshToken, REFRESH_INTERVAL);
    };

    fetchToken();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return children;
};
