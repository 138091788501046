import {PropsWithChildren, useEffect, useState} from 'react';
import {ApolloError} from '@apollo/client';
import {isString} from 'lodash';

import {NoPermissionError} from 'core/utils/errors';
import {dayjs} from 'utils/dateUtils';
import {setFeatureFlags, setUserInfo} from 'core/stores/auth';
import {getMyInfo, MyInfo} from 'core/api/userInfo';
import {setUserTimezone} from 'core/api/timezone';
import {fetchFeatureFlags} from 'core/api/featureFlags';

import {LoadingScreen} from 'components/LoadingScreen';

export enum AuthorizeErrorType {
  FlagsError = 'FLAGS_ERROR',
  UserInfoError = 'USER_INFO_ERROR',
  NoPermissionError = 'NO_PERMISSION_ERROR',
}

export const UserInfoProvider = ({children}: PropsWithChildren) => {
  const [errorType, setErrorType] = useState<AuthorizeErrorType | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        const featureFlags = await fetchFeatureFlags();
        setFeatureFlags(featureFlags);
      } catch (error) {
        setErrorType(AuthorizeErrorType.FlagsError);
        return;
      }

      let myInfo: MyInfo;

      try {
        myInfo = await getMyInfo();
      } catch (error) {
        const isForbiddenError =
          error instanceof ApolloError &&
          error?.networkError &&
          'result' in error.networkError &&
          !isString(error.networkError.result) &&
          error.networkError.result?.errors?.[0]?.extensions?.code === 'FORBIDDEN';

        setErrorType(isForbiddenError ? AuthorizeErrorType.NoPermissionError : AuthorizeErrorType.UserInfoError);
        return;
      }

      const userInfo = myInfo.userInfo;
      const profile = userInfo.profile;
      const userTimezone = dayjs.tz.guess();

      if (userTimezone !== userInfo.timeZone) {
        await setUserTimezone(userTimezone);
      }

      setUserInfo({
        id: userInfo.id,
        ennablEmployeeId: userInfo.ennablEmployeeId || null,
        permissions: userInfo.permissions.map(permission => permission.id),
        roles: userInfo.roles.map(role => role.id),
        roleType: userInfo.roleType,
        tenantId: userInfo.tenantId,
        tenantName: userInfo.tenantName,
        timeZone: userTimezone,
        profile: profile
          ? {
              email: profile.email || undefined,
              familyName: profile.familyName || undefined,
              givenName: profile.givenName || undefined,
              name: profile.name || undefined,
            }
          : undefined,
      });
    };

    loadData().finally(() => setLoading(false));
  }, []);

  if (errorType && errorType === AuthorizeErrorType.NoPermissionError) {
    throw new NoPermissionError();
  }

  if (errorType) {
    throw new Error('Error while authorizing');
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return children;
};
