import {ENNABL_DECISIONS, ENNABL_COMMAND, ENNABL_GROWTH, ENNABL_CHAT_AI} from 'core/constants/paths';
import {ProjectName, useProjectName} from 'utils/hooks/useProjectName';

const projectToUrl = {
  [ProjectName.Decisions]: ENNABL_DECISIONS,
  [ProjectName.Command]: ENNABL_COMMAND,
  [ProjectName.Growth]: ENNABL_GROWTH,
  [ProjectName.AI]: ENNABL_CHAT_AI,
  [ProjectName.UNKNOWN]: ENNABL_DECISIONS,
};

export function useBackToHomeUrl() {
  const {projectName} = useProjectName();
  return projectToUrl[projectName];
}
