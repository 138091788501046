import {Components, Theme, alpha} from '@mui/material/styles';

export const stepper = (
  theme: Theme
): {
  MuiStepper: Components['MuiStepper'];
} => ({
  MuiStepper: {
    styleOverrides: {
      root: {
        '& .MuiStepLabel-iconContainer': {
          '& > .MuiSvgIcon-root': {
            color: theme.palette.text.primary,
          },

          '&.Mui-active > .MuiSvgIcon-root': {
            color: theme.palette.primary.main,
          },
          '&.Mui-disabled > .MuiSvgIcon-root': {
            color: theme.palette.text.secondary,
          },
        },

        '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
          marginTop: theme.spacing(2),
          color: theme.palette.text.primary,
          ...theme.typography.pillTitle,

          '&.Mui-active': {
            color: theme.palette.primary.main,
          },
          '&.Mui-disabled': {
            color: theme.palette.text.secondary,
          },
        },

        '& .MuiStepConnector-line': {
          borderRadius: theme.shape.borderRadiusRegular,
          borderColor: alpha(theme.palette.text.secondary, 1),
        },
      },
      alternativeLabel: {
        '& .MuiStepLabel-iconContainer': {
          '& > .MuiSvgIcon-root': {
            color: theme.palette.text.primary,
          },

          '&.Mui-active > .MuiSvgIcon-root': {
            color: theme.palette.primary.main,
          },
          '&.Mui-disabled > .MuiSvgIcon-root': {
            color: theme.palette.text.secondary,
          },
        },

        '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
          marginTop: theme.spacing(2),
          color: theme.palette.text.primary,
          ...theme.typography.pillTitle,

          '&.Mui-active': {
            color: theme.palette.primary.main,
          },
          '&.Mui-disabled': {
            color: theme.palette.text.secondary,
          },
        },

        '& .MuiStepConnector-line': {
          borderRadius: theme.shape.borderRadiusRegular,
          borderColor: alpha(theme.palette.text.secondary, 1),
        },
      },
    },
  },
});
