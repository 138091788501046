import {Components, Theme} from '@mui/material/styles';

export const drawer = (
  theme: Theme
): {
  MuiDrawer: Components['MuiDrawer'];
} => {
  return {
    MuiDrawer: {
      styleOverrides: {
        paperAnchorBottom: {
          borderTopLeftRadius: theme.shape.borderRadiusRegular,
          borderTopRightRadius: theme.shape.borderRadiusRegular,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
    },
  };
};
