import {RefObject} from 'react';
import useResizeObserver from 'use-resize-observer';

export type Sizes = {
  width: number;
  height: number;
};

export const useElementSize = <T extends HTMLElement = HTMLElement>(target: RefObject<T> | T | null): Sizes => {
  const {width, height} = useResizeObserver({ref: target});

  return {
    width: width ?? 0,
    height: height ?? 0,
  };
};
