import {LocalStorageKey} from 'core/types';

import {EnnablEnv} from 'core/constants/globalVariables';

const RDE_API_HOST = localStorage.getItem(LocalStorageKey.EnnablRdeApiHost);

export const ENNABL_ENV = process.env.ENNABL_ENV || EnnablEnv.development;
export const IS_DEV_ENV = ENNABL_ENV === EnnablEnv.development;
export const IS_PROD_ENV = ENNABL_ENV === EnnablEnv.production;
export const IS_DEV_MODE = process.env.NODE_ENV === EnnablEnv.development;

export const API_HOST = process.env.ENNABL_API_HOST || RDE_API_HOST || 'https://api-v2.ennabl-test.com';
export const GRAPH_QL_DECISIONS_API_HOST =
  process.env.ENNABL_GRAPH_QL_DECISIONS_API_HOST ||
  (IS_DEV_ENV && RDE_API_HOST ? `${RDE_API_HOST}/decisions` : false) ||
  'https://api-v2.ennabl-test.com/decisions';
export const GRAPH_QL_COMMAND_API_HOST =
  process.env.ENNABL_GRAPH_QL_COMMAND_API_HOST ||
  (IS_DEV_ENV && RDE_API_HOST ? `${RDE_API_HOST}/command` : false) ||
  'https://api-v2.ennabl-test.com/command';
export const AI_WEB_SOCKET =
  process.env.ENNABL_AI_WEB_SOCKET || 'wss://plrlc95wck.execute-api.us-east-1.amazonaws.com/main';

export const AUTH0_DOMAIN = process.env.ENNABL_AUTH0_DOMAIN || 'auth.ennabl-test.com';
export const AUTH0_CLIENT_ID = process.env.ENNABL_AUTH0_CLIENT_ID || '7iirboO9QKHDouuOiYpKSW4M4rpN6m0R';
export const AUTH0_API_AUDIENCE = process.env.ENNABL_AUTH0_API_AUDIENCE || 'https://api-v2.ennabl-test.com';
export const APP_LOGIN_URI = process.env.ENNABL_APP_LOGIN_URI || 'https://login.ennabl-test.com';

export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY || 'AIzaSyB9Hw9NA82d4IMkjGZ-tis_wJqQgZaj6KE';
export const GOOGLE_GEOCODING_API_KEY =
  process.env.GOOGLE_GEOCODING_API_KEY || 'AIzaSyCZB905fZLUklEySQiQiP5x9qDi70jksiw';
export const FEATURE_FLAGS_URL = process.env.ENNABL_FEATURE_FLAGS_URL || '/flags.json';
