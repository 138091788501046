import {useCallback, useState} from 'react';

export function useBooleanToggle(initialValue: boolean = false) {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    setTrue: useCallback(() => setValue(true), []),
    setFalse: useCallback(() => setValue(false), []),
    toggle: useCallback(() => setValue(val => !val), []),
  };
}
