/**
 * Returns first letters of firstName & lastName combined.
 * e.g. getNameInitials('Sanjar', 'Mirakhmedov') // Output: 'SM'
 */
import {capitalize, isNull, isUndefined, upperFirst} from 'lodash/fp';

export const getNameInitials = (firstName?: string | null, lastName?: string | null) => {
  const firstInitial = firstName?.[0];
  const lastInitial = lastName?.[0];

  return upperFirst([firstInitial, lastInitial].filter(Boolean).join(''));
};

const listOfCapitalizedExceptions = ['LLC', 'LLP', 'LP'];

export const capitalizeWords = (someString: string) => {
  return someString.replace(/\w{3,}/g, match => {
    return listOfCapitalizedExceptions.includes(match) ? match : capitalize(match);
  });
};

/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export function hashCode(str: string) {
  let hash = 0;

  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
}

export function smartSorting(value: null | undefined | number | string) {
  if (isNull(value) || isUndefined(value)) {
    return -1;
  }

  return value;
}

// https://stackoverflow.com/a/44622300/7252759
export const genRandomString = (length = 16) =>
  Array.from(Array(length), () => Math.floor(Math.random() * 36).toString(36)).join('');
