import {DialogActions} from '@mui/material';
import {styled} from '@mui/material/styles';
import {ReactElement} from 'react';

import {Falsy} from 'types';

import {useIsDownSm} from 'utils/hooks/useMediaQuery';

type DecisionsDialogActionsProps = {
  primaryAction?: ReactElement;
  secondaryAction?: ReactElement;
  additionalActions?: Falsy<ReactElement[]>;
};

const SDialogActions = styled(DialogActions)(({theme}) => ({
  '&&': {
    flexDirection: 'column',
    gap: theme.spacing(2),

    padding: theme.spacing(2, 4),

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      padding: theme.spacing(4),
    },
  },
}));

const SDivider = styled('div')(() => ({
  flex: 1,
}));

export const DecisionsDialogActions = ({
  primaryAction,
  secondaryAction,
  additionalActions,
}: DecisionsDialogActionsProps) => {
  const isDownSm = useIsDownSm();

  if (isDownSm) {
    return (
      <SDialogActions>
        {additionalActions}
        {primaryAction}
      </SDialogActions>
    );
  }

  return (
    <SDialogActions>
      {additionalActions}
      <SDivider />
      {secondaryAction}
      {primaryAction}
    </SDialogActions>
  );
};
