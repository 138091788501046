import {styled} from '@linaria/react';
import {css} from '@linaria/core';
import cx from 'clsx';
import {Link} from 'react-router-dom';
import {styled as muiStyled} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import {ButtonHTMLAttributes, ElementType, ForwardedRef, forwardRef, ReactNode, useMemo} from 'react';

import {colorVariables, styleVariables} from 'core/constants';

import Typo, {TypoProps} from 'components/Typo';

const CONTAINED = 'contained';
const OUTLINED = 'outlined';
const TEXT = 'text';

const BIG = 'big';
const MEDIUM = 'medium';

const PRIMARY = 'primary';
const GREY = 'grey';

type Variant = 'contained' | 'outlined' | 'text';
export type Size = 'medium' | 'big';
type Color = 'primary' | 'secondary' | 'red' | 'grey';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: Variant;
  size?: Size;
  /**
   * Forcibly active state for button
   */
  active?: boolean;
  /**
   * pass icon as a single child
   */
  iconOnly?: boolean;
  fullWidth?: boolean;
  color?: Color;
  loading?: boolean;
  /**
   * Icon located BEFORE the text
   */
  startIcon?: ReactNode;
  /**
   * Icon located AFTER the text
   */
  endIcon?: ReactNode;
  noWrap?: boolean;
  to?: string;
  href?: string;
  /**
   * Override label props
   */
  TypoProps?: TypoProps;
};

const SButton = styled.button`
  cursor: pointer;
  user-select: none;
  outline: none;

  padding-left: 12px;
  padding-right: 12px;
  border-radius: ${styleVariables.borderRadiusRegular}px;

  /* using 'flex' is wrong for the button, because it tries to stretch to parent width
   but button shouldn't do that w/o 'fullWidth' prop */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  /* for loading indicator */
  position: relative;

  &:disabled {
    cursor: default;
  }
`;

const STypo = styled(Typo)`
  /* required for svg icons to not break layout */
  display: flex;
`;

const sContained = css`
  border: none;
`;

const sOutlined = css`
  padding: 9px;
  border-width: 1px;
  border-style: solid;
`;

const sText = css`
  border: none;
  background-color: transparent;
`;

const sDefaultPrimary = css`
  color: ${colorVariables.Global['text-primary-default']};
  background-color: ${colorVariables.Global['background-primary']};

  &:hover,
  &:focus-visible {
    background-color: ${colorVariables.Global['background-primary-hover']};
  }

  &:active {
    background-color: ${colorVariables.Global['background-primary-active']};
  }

  &:disabled {
    color: ${colorVariables.Global['text-primary-default']};
    background-color: ${colorVariables.Global['background-disabled']};
  }
`;

const sOutlinedPrimary = css`
  color: ${colorVariables.Global['text-secondary']};
  background-color: ${colorVariables.Global['background-secondary']};
  border-color: ${colorVariables.Global['border']};

  &:hover,
  &:focus-visible {
    background-color: ${colorVariables.Global['background-secondary-hover']};
    border-color: ${colorVariables.Global['border-active']};
  }

  &:active {
    background-color: ${colorVariables.Global['background-secondary-active']};
  }

  &:disabled {
    color: ${colorVariables.Global['text-secondary-disabled']};
    background-color: ${colorVariables.Global['background-secondary-disabled']};
    border-color: ${colorVariables.Global['border-disabled']};
  }
`;

const sTextPrimary = css`
  color: ${colorVariables.Global['text-tertiary']};

  &:hover,
  &:focus-visible {
    color: ${colorVariables.Global['text-tertiary-hover']};
  }

  &:active {
    color: ${colorVariables.Global['text-tertiary-active']};
  }

  &:disabled {
    color: ${colorVariables.Global['text-tertiary-disabled']};
  }
`;

const sTextDanger = css`
  color: ${colorVariables.Global['text-tertiary-danger']};

  &:hover,
  &:focus-visible {
    color: ${colorVariables.Global['text-tertiary-hover-danger']};
  }

  &:active {
    color: ${colorVariables.Global['text-tertiary-active-danger']};
  }

  &:disabled {
    color: ${colorVariables.Global['text-tertiary-disabled-danger']};
  }
`;

const sOutlinedRed = css`
  color: ${colorVariables.Global['text-secondary-danger']};
  background-color: ${colorVariables.Global['background-secondary']};
  border-color: ${colorVariables.Global['border']};

  &:hover,
  &:focus-visible {
    background-color: ${colorVariables.Global['background-secondary-hover']};
    border-color: ${colorVariables.Global['border-danger']};
  }

  &:active {
    background-color: ${colorVariables.Global['background-secondary-active-danger']};
  }

  &:disabled {
    color: ${colorVariables.Global['text-secondary-disabled-danger']};
    background-color: ${colorVariables.Global['background-secondary-disabled']};
    border-color: ${colorVariables.Global['border-secondary-danger']};
  }
`;

const sOutlinedGrey = css`
  color: ${styleVariables.colorPalette.coolGrey(600)};
  background-color: ${colorVariables.Global['background-secondary']};
  border-color: ${colorVariables.Global['border']};

  &:hover,
  &:focus-visible {
    color: ${colorVariables.Global['text-secondary']};
    background-color: ${colorVariables.Global['background-secondary-hover']};
    border-color: ${colorVariables.Global['border-active']};
  }

  &:active {
    color: ${colorVariables.Global['text-secondary']};
    background-color: ${colorVariables.Global['background-secondary-active']};
  }

  &:disabled {
    color: ${colorVariables.Global['text-secondary-disabled']};
    background-color: ${colorVariables.Global['background-secondary-disabled']};
    border-color: ${colorVariables.Global['border-disabled']};
  }
`;

const sBig = css`
  padding: 10px 24px;
`;

const sBigIconOnly = css`
  padding: 10px;
`;

const sMediumIconOnly = css`
  padding: 6px;
`;

const sMedium = css`
  padding: 8px 16px;
`;

const sBigOutlined = css`
  padding: 9px 23px;
`;

const sMediumOutlined = css`
  padding: 7px 15px;
`;

const sBigOutlinedIconOnly = css`
  padding: 9px;
`;

const sMediumOutlinedIconOnly = css`
  padding: 5px;
`;

const sLoading = css`
  & > :not(.button-circular-progress) {
    visibility: hidden;
  }
`;

const sLoadingDefaultPrimary = css`
  &:disabled {
    background-color: ${colorVariables.Global['background-primary-active']};
  }
`;

const sLoadingOutlinedPrimary = css`
  &:disabled {
    background-color: ${colorVariables.Global['background-secondary-active']};
    border: 1px solid transparent;
  }
`;

const sLoadingOutlinedRed = css`
  &:disabled {
    background-color: ${colorVariables.Global['background-secondary-active-danger']};
    border: 1px solid transparent;
  }
`;

const sActiveDefaultPrimary = css`
  background-color: ${colorVariables.Global['background-primary-active']};
`;

const sActiveDefaultRed = css`
  background-color: ${colorVariables.Global['background-primary-active']};
`;

const sActiveOutlinedPrimary = css`
  background-color: ${colorVariables.Global['background-secondary-active']};
  border-color: ${colorVariables.Global['border-active']};
`;

const sActiveOutlinedRed = css`
  background-color: ${colorVariables.Global['background-secondary-active-danger']};
  border-color: ${colorVariables.Global['border-danger']};
`;

const sActiveOutlinedGrey = css`
  color: ${colorVariables.Global['text-secondary']};
  background-color: ${colorVariables.Global['background-secondary-active']};
  border-color: ${colorVariables.Global['border-active']};
`;

const sActiveTextPrimary = css`
  color: ${colorVariables.Global['text-tertiary-active']};
`;

const sActiveTextRed = css`
  color: ${colorVariables.Global['text-tertiary-active-danger']};
`;

const sNoWrap = css`
  white-space: nowrap;
`;

const sFullWidth = css`
  width: 100%;
`;

const sDefaultIcon = css`
  color: ${colorVariables.Global['icon-primary']};
`;

const sOutlinedIcon = css`
  color: ${colorVariables.Global['icon-secondary']};
`;

const sOutlinedRedIcon = css`
  color: ${colorVariables.Global['icon-tertiary-active-danger']};
`;

const sTextIcon = css`
  color: ${colorVariables.Global['icon-tertiary-active']};
`;

const sTextRedIcon = css`
  color: ${colorVariables.Global['icon-tertiary-danger']};
`;

const SCircularProgress = muiStyled(CircularProgress)({
  visibility: 'visible',
  position: 'absolute',
  left: 0,
  right: 0,
  margin: '0 auto',
});

const BaseButton = (
  {
    active = false,
    iconOnly = false,
    disabled = false,
    loading = false,
    variant = CONTAINED,
    color = PRIMARY,
    size = BIG,
    fullWidth = false,
    type = 'button',
    children,
    startIcon = null,
    endIcon = null,
    noWrap = false,
    className,
    to,
    href,
    TypoProps = {},
    ...props
  }: ButtonProps,
  ref: ForwardedRef<HTMLElement>
) => {
  const linkOrButtonProps = useMemo(() => {
    if (to) {
      return {
        as: Link,
        to,
      };
    }

    if (href) {
      return {
        as: 'a' as ElementType,
        href,
      };
    }

    return {
      'aria-disabled': disabled || loading,
      disabled: disabled || loading,
      type,
    };
  }, [to, href, disabled, loading, type]);

  return (
    <SButton
      ref={ref as any}
      {...linkOrButtonProps}
      className={cx(
        className,
        iconOnly
          ? {
              [sBigIconOnly]: (variant === CONTAINED || variant === TEXT) && size === BIG && iconOnly,
              [sMediumIconOnly]: (variant === CONTAINED || variant === TEXT) && size === MEDIUM && iconOnly,
            }
          : {
              [sBig]: (variant === CONTAINED || variant === TEXT) && size === BIG,
              [sMedium]: (variant === CONTAINED || variant === TEXT) && size === MEDIUM,
            },
        {
          [sBigOutlined]: variant === OUTLINED && size === BIG,
          [sMediumOutlined]: variant === OUTLINED && size === MEDIUM,

          [sBigOutlinedIconOnly]: variant === OUTLINED && size === BIG && iconOnly,
          [sMediumOutlinedIconOnly]: variant === OUTLINED && size === MEDIUM && iconOnly,

          [sContained]: variant === CONTAINED,
          [sOutlined]: variant === OUTLINED,
          [sText]: variant === TEXT,

          [sDefaultPrimary]: variant === CONTAINED && color === PRIMARY,
          [sOutlinedPrimary]: variant === OUTLINED && color === PRIMARY,
          [sTextPrimary]: variant === TEXT && color === PRIMARY,

          [sOutlinedRed]: variant === OUTLINED && color === 'red',
          [sTextDanger]: variant === TEXT && color === 'red',
          [sOutlinedGrey]: variant === OUTLINED && color === GREY,

          [sLoading]: loading,
          [sLoadingDefaultPrimary]: loading && variant === CONTAINED && color === PRIMARY,
          [sLoadingOutlinedPrimary]: loading && variant === OUTLINED && color === PRIMARY,
          [sLoadingOutlinedRed]: loading && variant === OUTLINED && color === 'red',

          [sActiveDefaultPrimary]: variant === CONTAINED && color === PRIMARY && active,
          [sActiveDefaultRed]: variant === CONTAINED && color === 'red' && active,
          [sActiveOutlinedPrimary]: variant === OUTLINED && color === PRIMARY && active,
          [sActiveOutlinedRed]: variant === OUTLINED && color === 'red' && active,
          [sActiveOutlinedGrey]: variant === OUTLINED && color === GREY && active,
          [sActiveTextPrimary]: variant === TEXT && color === PRIMARY && active,
          [sActiveTextRed]: variant === TEXT && color === 'red' && active,

          [sNoWrap]: noWrap,

          [sFullWidth]: fullWidth,
        }
      )}
      tabIndex={0}
      {...props}
    >
      {startIcon}
      {loading && (
        <SCircularProgress
          disableShrink
          size={16}
          classes={{
            root: 'button-circular-progress',
            circle: cx({
              [sDefaultIcon]: variant === CONTAINED,
              [sOutlinedIcon]: variant === OUTLINED && color === PRIMARY,
              [sOutlinedRedIcon]: variant === OUTLINED && color === 'red',
              [sTextIcon]: variant === TEXT && color === PRIMARY,
              [sTextRedIcon]: variant === TEXT && color === 'red',
            }),
          }}
        />
      )}
      <STypo variant={size === 'medium' ? 'medium-text' : 'button-text'} {...TypoProps}>
        {children}
      </STypo>
      {endIcon}
    </SButton>
  );
};

const Button = forwardRef(BaseButton);

export default Button;
