import {ElementType, SVGProps} from 'react';

export type TestIdProp = {
  dataTestId?: string;
};

export type AsProp<C extends ElementType> = {
  as?: C;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SetValueType<T, V> = {[key in keyof T]: V};

export type Falsy<T> = T | null | undefined | false | '' | 0;

export type ExcludeNil<T> = Exclude<T, undefined | null>;

export type MapValue<A> = A extends Map<any, infer V> ? V : never;

export type SVGIcon = ElementType<SVGProps<SVGSVGElement>>;

/**
 * Extract required keys from type and make them optional
 * of none of them were passed
 *
 * @example
 *
 * type MetricsProps = {
 *   metric: Metric
 *   metrics: Metrics[]
 *   onMetricChange: (metric: Metric) => void
 *   metricsLoading?: boolean
 * }
 *
 * type TabsProps = {
 *   tab: Tab
 *   tabs: Tab[]
 *   onTabChange: (tab: Tab) => void
 *   tabsLoading?: boolean
 * }
 *
 * type Props = RequireAllRequiredOrNone<MetricsProps> & <TabsProps>
 *
 * function MetricSelector(props: Props) {
 *   return <Selector {...props} />
 * }
 *
 * ...
 *
 * <MetricSelector
 *   metric={metric} // Error. `metrics` and `onMetricChange` is missed
 * />
 *
 */
export type RequireAllRequiredOrNone<T extends Record<string, unknown>> =
  | Required<T>
  | {
      [K in keyof T]?: never;
    };

/**
 * Get all the possible paths of an object or array
 *
 * @example
 * type Keys = NestedPaths<{ a: { b: { c: string } }>
 * // 'a' | 'a.b' | 'a.b.c'
 */
export type DeepKeys<T, K extends keyof T = keyof T> = K extends string | number
  ? T[K] extends infer R
    ?
        | `${K}`
        | (R extends Record<string, unknown> ? `${K}.${DeepKeys<R>}` : never)
        | (R extends Record<string, unknown>[] ? `${K}.${DeepKeys<R[number]>}` : never)
    : never
  : never;

export type AnyRecord = Record<string, any>;
