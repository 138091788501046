export enum SectionQueryParam {
  SingleSection = '-',
  Alerts = 'alerts',
  AverageAccountSize = 'avgAccountSize',
  AverageProductPerClient = 'avgProductPerClient',
  BookProfile = 'bookProfile',
  Commissions = 'commissions',
  Details = 'details',
  Goals = 'goals',
  Growth = 'growth',
  Home = 'home',
  Leaderboard = 'leaderboard',
  Lost = 'lost',
  Network = 'network',
  Opportunities = 'opportunities',
  O360Score = 'o360Score',
  Policies = 'policies',
  Clients = 'clients',
  PremiumFlow = 'premiumFlow',
  Ratings = 'ratings',
  ProducersSupported = 'producersSupported',
  ProductionCredit = 'productionCredit',
  PremiumSize = 'premiumSize',
  Renewals = 'renewals',
  Retention = 'retention',
  TopCarriers = 'topCarriers',
  TopIndustries = 'topIndustries',
  TopProducts = 'topProducts',
  KpiCatalog = 'kpiCatalog',
}
