import {Components, Theme} from '@mui/material/styles';

export const tooltip = (theme: Theme): Partial<Components> => {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          ...theme.typography.body3,
          padding: theme.spacing(3),
          borderRadius: 4,
          color: theme.palette.common.white,
          backgroundColor: theme.palette.common.black,
        },
      },
    },
  };
};
