import {ButtonProps} from '@mui/material';
import Button from '@mui/material/Button';

import {TestIdProp} from 'types';

import {useIsDownSm} from 'utils/hooks/useMediaQuery';

type SecondaryActionButtonProps = TestIdProp & Omit<ButtonProps, 'variant' | 'fullWidth'>;

export const AdditionalActionButton = (props: SecondaryActionButtonProps) => {
  const isDownSm = useIsDownSm();

  return <Button variant='text' fullWidth={isDownSm} size={isDownSm ? 'medium' : 'small'} {...props} />;
};
