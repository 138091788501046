import {CSSProperties} from 'react';
import {styled} from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import {Typography, Button} from '@mui/material';

import CloseIcon from 'components/icons/cross.svg';
import ArrowLeftIcon from 'components/icons/arrowLeft.svg';

import {DECISIONS_DIALOG_RESET_PORTAL_ID} from 'modules/decisionsNext/components/DecisionsDialog/DecisionsDialogResetPortal';

const SCloseButton = styled(Button)(({theme}) => ({
  color: theme.palette.text.primary,
}));

export type DecisionsDialogTitleProps = {
  title: string;
  withBackButton?: boolean;
  onBack?: () => void;
  onClose?: () => void;
  titleAlign?: CSSProperties['textAlign'];
  closeType?: 'icon' | 'text' | 'none';
};

const SResetContainer = styled('div')({
  display: 'flex',
  marginRight: 8,
});

const SRoot = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(4),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 4, 2, 4),
  },
}));

const STypography = styled(Typography)(() => ({
  flex: 1,
}));

export const DecisionsDialogTitle = ({
  title,
  onBack,
  onClose,
  titleAlign,
  closeType = 'icon',
}: DecisionsDialogTitleProps) => {
  return (
    <SRoot>
      <Collapse in={!!onBack} orientation='horizontal' mountOnEnter unmountOnExit>
        <IconButton size='small' onClick={onBack}>
          <ArrowLeftIcon width={20} height={20} />
        </IconButton>
      </Collapse>

      <STypography textAlign={titleAlign} variant='h4'>
        {title}
      </STypography>

      <SResetContainer id={DECISIONS_DIALOG_RESET_PORTAL_ID} />

      {closeType === 'icon' && (
        <IconButton size='small' onClick={onClose}>
          <CloseIcon width={20} height={20} />
        </IconButton>
      )}

      {closeType === 'text' && (
        <SCloseButton onClick={onClose} variant='text' size='small'>
          Cancel
        </SCloseButton>
      )}
    </SRoot>
  );
};
