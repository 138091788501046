import {FeatureFlag} from 'core/types';

import {FEATURE_FLAGS_URL} from 'config';

export async function fetchFeatureFlags(): Promise<FeatureFlag[]> {
  const response = await fetch(FEATURE_FLAGS_URL, {
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
  return response.json();
}
