import {createRoot} from 'react-dom/client';

import {initServiceWorker} from 'utils/serviceWorkerInit';
import Root from 'core/Root';
import {watchAppVersion} from 'utils/appVersionUtils';
import './mui-init';
import {IS_DEV_MODE} from 'config';
import {migrateLocalStorageKeys} from 'core/localStorageMigration';

initServiceWorker();
migrateLocalStorageKeys();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(<Root />);

// Reload the app when new version is deployed
if (!IS_DEV_MODE) {
  watchAppVersion();
}
