import {autocompleteClasses, filledInputClasses, Theme} from '@mui/material';
import {Components} from '@mui/material/styles';

export const autocomplete = (theme: Theme): Partial<Components> => ({
  MuiAutocomplete: {
    defaultProps: {
      componentsProps: {
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: () => [0, 4],
              },
            },
          ],
        },
      },
    },
    styleOverrides: {
      root: {
        [`&.Mui-focused .${filledInputClasses.root} .${filledInputClasses.input}`]: {
          width: '100%',
        },
        [`&&.${autocompleteClasses.hasClearIcon}`]: {
          [`& .${filledInputClasses.root}`]: {
            paddingRight: '50px',
          },
        },
      },
      inputRoot: {
        gap: 0,
        [`&.${filledInputClasses.root}`]: {
          padding: theme.spacing(4, 3, 0, 3),
        },
        [`&.${filledInputClasses.root} .${filledInputClasses.input}`]: {
          paddingBottom: 5,
        },
        [`& span.${autocompleteClasses.tagSizeMedium}`]: {
          margin: 0,
          padding: '7px 3px 5px 3px',
        },
      },
      groupLabel: {
        // rest this because group can be several lines and it looks ugly w/
        // default line-height
        lineHeight: 'initial',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
      option: {
        ...theme.typography.body2,
      },
      popupIndicator: params => {
        return {
          transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.shortest,
          }),
          color: params.ownerState.focused ? theme.palette.text.primary : theme.palette.text.secondary,
        };
      },
      paper: {
        border: `1px solid ${theme.palette.divider}`,
      },
      tag: {
        maxWidth: 'min(calc(100% - 6px), 200px)',
      },
    },
  },
});
