import {makeVar} from 'utils/apolloWrapper';

type PolicyTable = {
  query: string;
};

export const policyTableVar = makeVar<PolicyTable>({
  query: '',
});

export const reportsPageMobileNavbarVar = makeVar<boolean>(false);

export const localFields = {
  policyTable() {
    return policyTableVar();
  },
  reportsPageMobileNavbar() {
    return reportsPageMobileNavbarVar();
  },
};
