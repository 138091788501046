/* eslint-disable no-restricted-imports */
// exception to dayjs import rule
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import preciseDiff from 'dayjs-precise-range';
import {chunk} from 'lodash/fp';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import {NO_VALUE_CELL} from 'core/constants/globalVariables';

// TODO: move to some intialization file because this won't be called if nobody uses this utils but uses dayjs
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
// Load additional plugins there
dayjs.extend(preciseDiff);
dayjs.extend(dayOfYear);
dayjs.extend(advancedFormat);

dayjs.Ls.en.weekStart = 0; // Sunday is start day of week

export {dayjs};

export {Dayjs} from 'dayjs';

export type DateType = string;
export type DateRange = [DateType, DateType];
export type RelaxedDateRange = [DateType | null, DateType] | [DateType, DateType | null];
export type NullableDateRange = [DateType | null, DateType | null];

export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT_LONGER = 'MMM D, YYYY';
export const DATE_FORMAT_SHORT = 'MMM D';
export const DATE_FORMAT_LONGEST = 'MMMM D, YYYY';
export const DATE_FORMAT_MONTH_AND_YEAR = 'MMMM YYYY';
export const DATE_FORMAT_MONTH_AND_YEAR_SHORTER = 'MMM YYYY';
export const DATE_PICKER_FORMAT = 'MM-DD-YYYY';
export const DATE_FORMAT_ISO8601_LOCAL = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

export const DATE_FORMAT_BE = 'YYYY-MM-DD';

export const dateUtc = (date: dayjs.ConfigType = dayjs()) => dayjs(date).utc();
export const getUtcDate = () => dayjs().utc(true);
export const getLocalDate = (date: dayjs.ConfigType) =>
  dayjs.utc(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone, true);

export const durationPreciseFormatter = (startDate: number, endDate: number) => {
  const result = (dayjs as any).preciseDiff(dayjs(startDate), dayjs(endDate)) as string;
  const parts = result.split(/\s/);

  const pairs = chunk(2, parts);

  return pairs.map(([value, label]) => `${value}${label.slice(0, 1)}`).join(' ');
};

export const convertDateToFilterTimestamp = (date: dayjs.Dayjs) => {
  return date.unix() * 1000;
};

/**
 * Used for showing data to user in UTC timezone keeping local timezone
 * @param value
 * @param format
 */
export const dateFormatter = (value?: dayjs.ConfigType, format = DATE_FORMAT) =>
  value ? dayjs(value).utc(true).format(format) : NO_VALUE_CELL;

export const dateFormatterLocal = (value?: dayjs.ConfigType, format = DATE_FORMAT) =>
  value ? dayjs(value).format(format) : NO_VALUE_CELL;

export const dateTimeFormatter = (value: string | number) => new Date(value).toLocaleString('en-US');

export const datePickerFormatter = (value?: dayjs.ConfigType) => (value ? dayjs(value).toISOString() : '');

export function isSameDay(dateA: dayjs.Dayjs, dateB: dayjs.Dayjs) {
  return dateA.startOf('day').isSame(dateB.startOf('day'));
}

export function isToday(date: dayjs.ConfigType, isUtc = false) {
  return isSameDay(dayjs(date), isUtc ? dayjs().utc() : dayjs());
}

export function isTomorrow(date: dayjs.ConfigType, isUtc = false) {
  return isSameDay(dayjs(date), (isUtc ? dayjs().utc() : dayjs()).add(1, 'days'));
}

export function isYesterday(date: dayjs.Dayjs | DateType | number, isUtc = false) {
  return isSameDay(dayjs(date), (isUtc ? dayjs().utc() : dayjs()).subtract(1, 'day'));
}

/**
 * @returns Number of units between two dates
 */
export function diffDates(date1: dayjs.ConfigType, date2: dayjs.ConfigType, unit: dayjs.QUnitType | dayjs.OpUnitType) {
  const date = dayjs(date1);
  const compDate = dayjs(date2);
  return date.diff(compDate, unit, true);
}

export function getRelativeDateString({
  description,
  relativeDate,
  date = dayjs().format(DATE_FORMAT),
}: {
  description: string;
  relativeDate: string;
  date?: string;
}) {
  const daysDifference = getDifferenceInDays(relativeDate, date);

  if (daysDifference < 0) {
    return `Expired ${dayjs(relativeDate).to(date, true)} ago`;
  }

  if (daysDifference === 0) {
    return `${description} today`;
  }

  if (daysDifference === 1) {
    return `${description} tomorrow`;
  }

  return `${description} ${dayjs(relativeDate).from(date)}`;
}

export const getDifferenceInDays = (date1: string, date2: string) => {
  return dayjs(date1).startOf('day').diff(dayjs(date2).startOf('day'), 'day');
};

export function getNextNDays(days: number) {
  return dayjs().utc().add(days, 'days').toISOString();
}

export function getStartOfTheDayAsUTC(date: dayjs.ConfigType = dayjs()) {
  return dayjs(`${dayjs(date).format(DATE_FORMAT)}Z`);
}

export function formatDateToString(date: dayjs.ConfigType = dayjs()) {
  return dayjs(date).utc(true).format(DATE_FORMAT_BE);
}

export function formatDateToNumber(date: dayjs.ConfigType = dayjs()) {
  return dayjs(date).valueOf();
}

export function cutTimeFromDateString(date?: string | null) {
  if (!date) {
    return null;
  }

  return date.split('T')[0];
}

export function now() {
  return dayjs().toISOString();
}
