import {styled} from '@mui/material/styles';

import {createPortalComponent} from 'utils/portalUtils';

export const DECISIONS_DIALOG_RESET_PORTAL_ID = 'decisions-dialog-reset-portal';

const DecisionsDialogReset = styled('div')({
  display: 'flex',
});

export const DecisionsDialogResetPortal = createPortalComponent(DECISIONS_DIALOG_RESET_PORTAL_ID, DecisionsDialogReset);
