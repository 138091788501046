import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';

/**
 * This is useful for a long page layout. we have to
 * always scroll to top when go to the next page
 *
 * @see https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
 */
export function useScrollToTop() {
  const {pathname} = useLocation();
  const oldPage = useRef(pathname);

  useEffect(() => {
    if (pathname !== oldPage.current) {
      window.scrollTo(0, 0);
      oldPage.current = pathname;
    }
  }, [pathname]);
}
