const breakpoints = {
  values: {
    xs: 400,
    sm: 600,
    md: 900,
    lg: 1201,
    xl: 1440,
  },
};

export default breakpoints;
