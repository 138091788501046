import {lazy, Suspense, ReactNode, ComponentProps, ComponentType} from 'react';

import LoadingIndicator from 'components/LoadingIndicator';

type Unpromisify<T> = T extends Promise<infer P> ? P : never;

type Opts<T, U> = {
  fallback: ReactNode;
  selectorFunc?: (s: Unpromisify<T>) => U;
};

export const lazyLoad = <T extends Promise<any>, U extends ComponentType<any>>(
  importFunc: () => T,
  opts?: Opts<T, U>
) => {
  const {selectorFunc = (module: {default: U}) => module.default, fallback = <LoadingIndicator />} = opts || {};

  const lazyFactory = () => importFunc().then(module => ({default: selectorFunc(module)} as {default: U}));

  const LazyComponent = lazy(lazyFactory);

  return (props: ComponentProps<U>): JSX.Element => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};
