import {styled} from '@mui/material/styles';
import {DialogContent} from '@mui/material';
import {PropsWithChildren} from 'react';

const SDialogContent = styled(DialogContent)(({theme}) => ({
  '&&': {
    [theme.breakpoints.down('sm')]: {
      borderTop: 'none',
    },

    padding: theme.spacing(4),
  },
}));

export const DecisionsDialogContent = ({children, className}: PropsWithChildren<{className?: string}>) => {
  return <SDialogContent className={className}>{children}</SDialogContent>;
};
