import {onError} from '@apollo/client/link/error';

import {
  ActivitiesPaginatedResult,
  Activity,
  CarrierAppetitePaginatedResult,
  CarrierAppetiteRow,
  ProducerFullCreditPaginatedResult,
  ProducerFullCreditRow,
  ProducerSharedCreditPaginatedResult,
  ProducerSharedCreditRow,
} from 'modules/decisionsNext/api/types';

import {ApolloClient, InMemoryCacheConfig, NormalizedCacheObject, TypePolicy} from 'utils/apolloWrapper';
import {graphQLClientFactory} from 'utils/apiUtils';
import {GRAPH_QL_DECISIONS_API_HOST} from 'config';
import {cursorMerge, lazyLoadingMergeFactory} from 'utils/apolloUtils';
import {hashCode} from 'utils/stringUtils';
import {RequestsLimitsDocument} from 'modules/decisionsNext/api/limits/requestLimits.generated';
import {LIMIT_ERROR_CODE} from 'modules/decisionsNext/constants';
import {getLimitsErrorEmulationFlag} from 'modules/decisionsNext/utils/getLimitsErrorEmulationFlag';
import {localFields} from 'modules/decisionsNext/api/cache';

const mergePaginatedFields = lazyLoadingMergeFactory<{items: any[]}>({
  listExtractor: data => data?.items,
  dataBuilder: (mergedList, incoming) => {
    return {
      ...incoming,
      items: mergedList,
    };
  },
});

const mergeItemsByPaginationOffset = lazyLoadingMergeFactory<ActivitiesPaginatedResult, Activity>({
  listExtractor: data => data?.items,
  dataBuilder: (mergedList, incoming) => {
    return {
      ...incoming,
      items: mergedList,
    };
  },
  offsetPath: 'pagination.offset',
});

const paginatedQueryCacheConfig: TypePolicy = {
  fields: {
    paginated: {
      keyArgs: ['filter', 'primaryRole', 'query', 'pagination', ['sortBy', 'sortOrder', 'limit']],
      merge: mergePaginatedFields,
    },
  },
};

const rankEdgePolicy: TypePolicy = {
  keyFields: ['cursor', 'metricType', 'filter'],
  fields: {
    metricType(_, {variables}) {
      return variables?.metric;
    },
    filter(_, {variables}) {
      return hashCode(JSON.stringify(variables?.filter));
    },
  },
};

const rankPolicy: TypePolicy = {
  keyFields: ['metricType', 'filter', 'item', ['id']],
  fields: {
    metricType(_, {variables}) {
      return variables?.metric;
    },
    filter(_, {variables}) {
      return hashCode(JSON.stringify(variables?.filter));
    },
  },
};

const cacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        activities: {
          merge: true,
        },
        aggregateCrossSellOpportunity: {
          merge: true,
        },
        aggregateMonolineOpportunity: {
          merge: true,
        },
        aggregatePolicy: {
          merge: true,
        },
        documents: {
          merge: true,
        },
        ranking: {
          merge: true,
        },
        topStatistics: {
          merge: true,
        },
        carrierAppetite: {
          merge: true,
        },
        producerCredit: {
          merge: true,
        },
        client: {
          merge: true,
        },
        clients: {
          merge: true,
        },
        customReporting: {
          merge: true,
        },
        globalCarrierAppetite: {
          merge: true,
        },
        premiumFlow: {
          merge: true,
        },
        premiumOutflowInflow: {
          merge: true,
        },
        producerPortal: {
          merge: true,
        },
        products: {
          merge: true,
        },
        productsPerClient: {
          merge: true,
        },
        users: {
          merge: true,
        },
        markets: {
          merge: true,
        },
        departments: {
          merge: true,
        },
        industries: {
          merge: true,
        },
        lossRuns: {
          merge: true,
        },
        opportunities: {
          merge: true,
        },
        opportunityReports: {
          merge: true,
        },
        crossSellGenerator: {
          merge: true,
        },
        integrations: {
          merge: true,
        },
        retention: {
          merge: true,
        },
        savedOpportunities: {
          merge: true,
        },
        score360: {
          merge: true,
        },
        storedFilters: {
          merge: true,
        },
        userPageSettings: {
          merge: true,
        },
        userReportPresets: {
          merge: true,
        },
        userKpi: {
          merge: true,
        },
        ...localFields,
      },
    },
    Client: {
      merge: true,
      fields: {
        primaryAddress: {
          merge: true,
        },
        dnbCompany: {
          merge: true,
        },
        statistics: {
          merge: true,
        },
      },
    },
    Activities: {
      fields: {
        byClient: {
          keyArgs: ['clientId', 'pagination', ['limit', 'sortBy', 'sortOrder']],
          merge: mergeItemsByPaginationOffset,
        },
      },
    },
    AggregatedPolicy: {
      fields: {
        // cache by metric for all these fields
        byCarrier: {
          keyArgs: ['$metric', 'limit', 'offset', 'filter', 'sortBy', 'sortOrder', 'level'],
        },
        byIntermediary: {
          keyArgs: ['$metric', 'limit', 'offset', 'filter', 'sortBy', 'sortOrder', 'level'],
        },
        byIndustry: {
          keyArgs: ['$metric', 'level', 'limit', 'offset', 'filter', 'parentId', 'sortBy', 'sortOrder'],
        },
        byProducer: {
          keyArgs: ['$metric', 'limit', 'offset', 'filter', 'sortBy', 'sortOrder'],
        },
        byProducerWithMissing: {
          keyArgs: ['$metric', 'limit', 'offset', 'filter', 'sortBy', 'sortOrder'],
        },
        byAccountManager: {
          keyArgs: ['$metric', 'limit', 'offset', 'filter', 'sortBy', 'sortOrder'],
        },
        byProduct: {
          keyArgs: ['$metric', 'limit', 'offset', 'level', 'filter', 'sortBy', 'sortOrder'],
        },
      },
    },
    CarrierAppetite: {
      fields: {
        table: {
          keyArgs: ['componentFilter', 'globalFilter', 'level', 'pagination', ['sortBy', 'sortOrder', 'limit']],
          merge: lazyLoadingMergeFactory<CarrierAppetitePaginatedResult, CarrierAppetiteRow>({
            listExtractor: data => data?.items,
            dataBuilder: (mergedList, incoming) => {
              return {
                ...incoming,
                items: mergedList,
              };
            },
            offsetPath: 'pagination.offset',
          }),
        },
      },
    },
    GlobalCarrierAppetite: {
      fields: {
        marketTree: {
          keyArgs: ['primaryRole'],
        },
      },
    },
    GlobalInsuranceCompany: {
      fields: {
        // We use same entities for different trees, so we need to keep them separately
        children: {
          merge: (existing, incoming, {variables}) => {
            const result = {
              CARRIER: existing?.CARRIER || [],
              INTERMEDIARY: existing?.INTERMEDIARY || [],
            };

            if (variables?.role) {
              result[variables?.role as keyof typeof result] = incoming;
            }

            return result;
          },
          read(existing, {variables}) {
            return existing?.[variables?.role] || null;
          },
        },
      },
    },
    ProducerCredit: {
      fields: {
        fullCredit: {
          keyArgs: ['producerId', 'filter', 'pagination', ['sortBy', 'sortOrder', 'limit']],
          merge: lazyLoadingMergeFactory<ProducerFullCreditPaginatedResult, ProducerFullCreditRow>({
            listExtractor: data => data?.items,
            dataBuilder: (mergedList, incoming) => {
              return {
                ...incoming,
                items: mergedList,
              };
            },
            offsetPath: 'pagination.offset',
          }),
        },
        sharedCredit: {
          keyArgs: ['producerId', 'filter', 'pagination', ['sortBy', 'sortOrder', 'limit']],
          merge: lazyLoadingMergeFactory<ProducerSharedCreditPaginatedResult, ProducerSharedCreditRow>({
            listExtractor: data => data?.items,
            dataBuilder: (mergedList, incoming) => {
              return {
                ...incoming,
                items: mergedList,
              };
            },
            offsetPath: 'pagination.offset',
          }),
        },
      },
    },
    Industries: paginatedQueryCacheConfig,
    Integrations: {
      fields: {
        rocketReachPeople: {
          merge: true,
        },
        companyByIndustry: {
          merge: true,
        },
        companies: {
          keyArgs: ['request'],
        },
        dolCompanies: {
          merge: true,
        },
        homeCached: {
          keyArgs: ['request'],
        },
        dolCompanyByFein: {
          keyArgs: ['fein'],
        },
      },
    },
    Ranking: {
      fields: {
        byCarrier: {
          keyArgs: ['metric', 'carrierId', 'filter', 'sortBy', 'sortOrder'],
          merge: cursorMerge('carrierId'),
        },
        byIndustry: {
          keyArgs: ['metric', 'industryId', 'filter', 'sortBy', 'sortOrder'],
          merge: cursorMerge('industryId'),
        },
        byProduct: {
          keyArgs: ['metric', 'productId', 'filter', 'sortBy', 'sortOrder'],
          merge: cursorMerge('productId'),
        },
      },
    },
    CarrierRankEdge: rankEdgePolicy,
    CarrierRank: rankPolicy,
    DolCompany: {
      keyFields: ['fein'],
    },
    IndustryRankEdge: rankEdgePolicy,
    IndustryRank: rankPolicy,
    ProducerPortal: {
      fields: {
        netNew: {
          merge: true,
        },
        crossSell: {
          merge: true,
        },
        renewals: {
          merge: true,
        },
        winBack: {
          merge: true,
        },
      },
    },
    ProductRankEdge: rankEdgePolicy,
    ProductRank: rankPolicy,
    Clients: paginatedQueryCacheConfig,
    Users: paginatedQueryCacheConfig,
    Markets: paginatedQueryCacheConfig,
    Products: paginatedQueryCacheConfig,
    Opportunities: {
      fields: {
        crossSellPaginated: {
          keyArgs: ['filter', 'pagination', ['limit', 'sortBy', 'sortOrder']],
          merge: mergeItemsByPaginationOffset,
        },
        monolinePaginated: {
          keyArgs: ['filter', 'pagination', ['limit', 'sortBy', 'sortOrder']],
          merge: mergeItemsByPaginationOffset,
        },
        winBackPaginated: {
          keyArgs: ['filter', 'pagination', ['limit', 'sortBy', 'sortOrder']],
          merge: mergeItemsByPaginationOffset,
        },
      },
    },
    OutflowInflowMarket: {
      keyFields: ['id', 'policyCount', 'premium'],
    },
    OutflowInflowProduct: {
      keyFields: ['id', 'policyCount', 'premium'],
    },
    SavedOpportunity: {
      fields: {
        savedCrossSell: {
          merge: (_, incoming) => incoming,
        },
        savedRenewals: {
          merge: (_, incoming) => incoming,
        },
        savedWinBack: {
          merge: (_, incoming) => incoming,
        },
      },
    },
    SavedOpportunities: {
      fields: {
        paginated: {
          keyArgs: ['filter', 'pagination', ['limit', 'sortBy', 'sortOrder']],
          merge: true,
        },
      },
    },
    Score360: {
      fields: {
        averageScore: {
          merge: true,
        },
        clients: {
          keyArgs: ['filter', 'query', 'pagination', ['limit', 'sortBy', 'sortByCategoryId', 'sortOrder']],
          merge: mergeItemsByPaginationOffset,
        },
        producers: {
          keyArgs: ['filter', 'query', 'pagination', ['limit', 'sortBy', 'sortOrder']],
          merge: mergeItemsByPaginationOffset,
        },
      },
    },
    Lifetime: {
      fields: {
        paginatedClients: {
          keyArgs: ['filter', 'pagination', ['limit', 'sortBy', 'sortOrder']],
          merge: mergeItemsByPaginationOffset,
        },
        paginatedIndustries: {
          keyArgs: ['filter', 'pagination', ['limit', 'sortBy', 'sortOrder']],
          merge: mergeItemsByPaginationOffset,
        },
      },
    },
    UserKpiEntityDeclaration: {
      keyFields: ['id', 'type', 'carrierMode'],
    },
    OpportunityReports: {
      fields: {
        reportRecords: {
          merge: true,
        },
      },
    },
    Reminder: {
      keyFields: false,
    },
  },
};

export type Exception = {
  statusCode?: number;
};

const decisionsGraphQLClientRef: {client: ApolloClient<NormalizedCacheObject> | undefined} = {client: undefined};

const externalServicesLimitsLink = onError(({graphQLErrors}) => {
  const hasLimitsException = graphQLErrors?.find(
    error => (error.extensions.exception as Exception)?.statusCode === LIMIT_ERROR_CODE
  );

  if (hasLimitsException) {
    const emulateLimitError = getLimitsErrorEmulationFlag();

    if (emulateLimitError) {
      decisionsGraphQLClientRef.client?.cache.writeQuery({
        query: RequestsLimitsDocument,
        data: {
          integrations: {
            monthlyLimit: {calls: 0, limit: 0},
          },
        },
      });
    } else {
      decisionsGraphQLClientRef.client?.refetchQueries({
        include: [RequestsLimitsDocument],
      });
    }
  }
});

export const graphQLClient = graphQLClientFactory(GRAPH_QL_DECISIONS_API_HOST, cacheConfig, [
  externalServicesLimitsLink,
]);

decisionsGraphQLClientRef.client = graphQLClient;
