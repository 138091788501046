import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';

import {
  ApolloClient,
  ApolloLink,
  from,
  fromPromise,
  HttpLink,
  InMemoryCache,
  InMemoryCacheConfig,
} from 'utils/apolloWrapper';
import {IMPERSONATION_HTTP_REQUEST_HEADER, impersonationToken} from 'core/impersonation';
import {setTokenAndAuthorized, useAuthStore} from 'core/stores/auth';
import {IS_DEV_ENV} from 'config';
import {refreshAuthToken} from 'core/utils/auth';
import {Ennabl} from 'utils/ennabl';

export {gql} from 'utils/apolloWrapper';

const isStatusCode401 = (error: any) => 'statusCode' in (error ?? {}) && error?.statusCode === 401;

let isTokenRefreshing = false;
let pendingRequests: Function[] = [];

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback());
  pendingRequests = [];
};

const refreshTokenOnExpireLink = onError(({networkError, operation, forward}) => {
  if (!isStatusCode401(networkError)) {
    return;
  }

  if (isTokenRefreshing) {
    return fromPromise(
      new Promise<void>(resolve => {
        pendingRequests.push(() => resolve());
      })
    ).flatMap(() => {
      return forward(operation);
    });
  }

  isTokenRefreshing = true;

  return fromPromise(refreshAuthToken()).flatMap(authToken => {
    if (authToken) {
      setTokenAndAuthorized(authToken.token);
      resolvePendingRequests();
      isTokenRefreshing = false;
    }

    return forward(operation);
  });
});

const captureExceptionsLink = onError(({graphQLErrors}) => {
  if (!Array.isArray(graphQLErrors) || !graphQLErrors.length) {
    return;
  }

  graphQLErrors.forEach(error =>
    Ennabl.captureException(new Error(error.message, {cause: 'graphql error'}), {
      extra: {graphQLSchemaPath: error.path?.join(' -> ')},
    })
  );
});

const tokenLink = setContext((_, {headers}) => {
  const token = useAuthStore.getState().getToken();

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

export const graphQLClientFactory = (uri: string, cacheConfig: InMemoryCacheConfig = {}, links?: ApolloLink[]) => {
  const headers: Record<string, string> = {};

  if (impersonationToken) {
    headers[IMPERSONATION_HTTP_REQUEST_HEADER] = impersonationToken;
  }

  const httpLink = new HttpLink({
    uri,
    headers,
  });

  return new ApolloClient({
    connectToDevTools: IS_DEV_ENV,
    cache: new InMemoryCache(cacheConfig),
    link: from([...(links ?? []), refreshTokenOnExpireLink, captureExceptionsLink, tokenLink, httpLink]),
  });
};
