import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

import {EnnablRoleType} from 'modules/decisionsNext/api/types';
import {FeatureFlag} from 'core/types';

import {IS_DEV_ENV} from 'config';

export type AuthState = {
  authorized: boolean;
  getToken: () => string;
  flags?: FeatureFlag[];
  token: string | null;
  userInfo?: {
    id: string;
    ennablEmployeeId: string | null;
    tenantId: string;
    tenantName: string;
    permissions: string[];
    roles: string[];
    roleType: EnnablRoleType;
    timeZone?: string;
    profile?: {
      email?: string;
      familyName?: string;
      givenName?: string;
      name?: string;
    };
  };
};

export const useAuthStore = create<AuthState>()(
  devtools(
    (_, get) => ({
      authorized: false,
      token: null,
      getToken: () => {
        const token = get().token;

        if (!token) {
          throw new Error('Token is not set');
        }

        return token;
      },
    }),
    {
      enabled: IS_DEV_ENV,
      name: 'Auth',
    }
  )
);

export const setTokenAndAuthorized = (token: AuthState['token']) => {
  useAuthStore.setState({token, authorized: Boolean(token)}, false, 'setToken');
};

export const setUserInfo = (userInfo: AuthState['userInfo']) => {
  useAuthStore.setState({userInfo}, false, 'setUserInfo');
};

export const setFeatureFlags = (flags: AuthState['flags']) => {
  useAuthStore.setState({flags}, false, 'setFeatureFlags');
};

export const getFullName = (state: AuthState) =>
  state.userInfo?.profile?.givenName && state.userInfo?.profile?.familyName
    ? `${state.userInfo.profile.givenName} ${state.userInfo.profile.familyName}`
    : state.userInfo?.profile?.name || '';
