import {
  Button,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogProps,
  ThemeProvider,
  styled,
} from '@mui/material';
import {ReactNode} from 'react';
import {Merge} from 'type-fest';

import {themeDecisions} from 'core/themes/themeDecisions';
import {useIsDownSm} from 'utils/hooks/useMediaQuery';
import {shouldNotForwardProp} from 'utils/styled';

import {DecisionsDialog} from 'modules/decisionsNext/components/DecisionsDialog';

export type ConfirmDialogProps = Merge<
  DialogProps,
  {
    title?: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    onClose: () => void;
    onCancel: () => void;
    onConfirm: () => void;
    noCancelButton?: boolean;
    noConfirmButton?: boolean;
    content?: ReactNode;
    fullWidthFooterButtons?: boolean;
  }
>;

const SDialogActions = styled(
  DialogActions,
  shouldNotForwardProp('fullWidthFooterButtons')
)<
  DialogActionsProps & {
    fullWidthFooterButtons?: boolean;
  }
>(({theme, fullWidthFooterButtons}) => ({
  display: 'flex',
  gap: theme.spacing(4),
  '& > *': fullWidthFooterButtons
    ? {
        flex: '1',
        width: '100%',
      }
    : {},
}));

export default function ConfirmDialog({
  title = 'Confirmation',
  maxWidth = 'xs',
  cancelButtonText = 'Cancel',
  confirmButtonText = 'OK',
  children,
  onCancel,
  onClose,
  onConfirm,
  noCancelButton,
  noConfirmButton,
  content,
  fullWidthFooterButtons,
  ...props
}: ConfirmDialogProps) {
  return (
    <ThemeProvider theme={themeDecisions}>
      <DecisionsDialog
        {...props}
        onClose={onClose}
        maxWidth={maxWidth}
        title={title}
        closeType={useIsDownSm() && !noCancelButton ? 'none' : undefined}
      >
        <DialogContent>{content || children}</DialogContent>

        {(!noCancelButton || !noConfirmButton) && (
          <SDialogActions fullWidthFooterButtons={fullWidthFooterButtons}>
            {!noCancelButton && (
              <Button variant='outlined' size='large' onClick={onCancel}>
                {cancelButtonText}
              </Button>
            )}

            {!noConfirmButton && (
              <Button variant='contained' size='large' color='primary' onClick={onConfirm}>
                {confirmButtonText}
              </Button>
            )}
          </SDialogActions>
        )}
      </DecisionsDialog>
    </ThemeProvider>
  );
}
