import {graphQLClient} from 'modules/decisionsNext/api';
import {
  SetUserTimezoneDocument,
  SetUserTimezoneMutation,
} from 'modules/decisionsNext/api/mutations/setUserTimezone.generated';

export async function setUserTimezone(timezone: string) {
  try {
    const result = await graphQLClient.mutate<SetUserTimezoneMutation>({
      mutation: SetUserTimezoneDocument,
      variables: {
        timezone,
      },
    });

    if (result.errors?.length) {
      return null;
    }

    return result.data || null;
  } catch (e) {
    return null;
  }
}
