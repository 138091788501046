import {Helmet} from 'react-helmet';

export function Analytics() {
  return (
    <Helmet>
      <script async src='https://www.googletagmanager.com/gtag/js?id=G-6EKR1KJNKN' />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-6EKR1KJNKN');
        `}
      </script>

      <script type='text/javascript'>
        {`
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "k507yxbut1");
        `}
      </script>
    </Helmet>
  );
}
