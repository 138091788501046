import {PaletteMode} from '@mui/material';
import {alpha} from '@mui/material/styles';

import {colorVariablesNext} from 'core/constants';

export type DecisionsPalette = ReturnType<typeof defaultPalette>;
export type DecisionsTypeAction = DecisionsPalette['action'];
export type DecisionsBackground = DecisionsPalette['background'];

const defaultPalette = (mode: PaletteMode) => {
  return {
    mode: mode,

    common: {
      black: '#1E1E1E',
      white: '#FFFFFF',
    },
    primary: {
      light: '#6139F9',
      main: '#0000C5',
      dark: '#000093',
    },

    // Secondary - Temporary solution for 'See All button' under charts on Account Managers page
    // Remove when design is ready.
    secondary: {
      light: '#FFFFFF',
      main: '#F5F8FD',
      dark: '#EEF3FC',
      contrastText: '#0000C5',
    },
    error: {
      light: '#FF8C6F',
      main: '#FC5943',
      dark: '#C22019',
    },
    success: {
      light: '#67EBAD',
      main: '#3DD598',
      dark: '#008751',
      contrastText: '#FFFFFF',
    },
    warning: {
      light: '#FFE751',
      main: '#FFC542',
      dark: '#C78600',
    },
    text: {
      primary: 'rgba(30, 30, 30, 0.87)',
      secondary: 'rgba(130, 135, 176, 0.8)',
      disabled: 'rgba(30, 30, 30, 0.38)',
    },

    main: {
      action: 'rgba(0,0,197,1)',
      containedHover: {
        actionHover: 'rgba(0,0,148,1)',
      },
      outlinedHover: {
        actionHover: 'rgba(229,229,255,1)',
      },
      disabled: {
        item: 'rgba(154,160,167,1)',
        background: 'rgba(228,229,231,1)',
      },
    },

    divider: 'hsla(217, 68%, 94%, 1)',
    logo: '#1B2337',

    action: {
      fadedOpacity: 0.87,
      // primary.main
      hover: alpha('#0000C5', 0.04),
    },

    background: {
      black: colorVariablesNext.dataTypes.Unknown[10],
      grey: colorVariablesNext.Background.Grey,
      darkGrey: colorVariablesNext.Background.DarkGrey,
      white: colorVariablesNext.Background.White,
    },

    dataTypes: {
      accountMangers: colorVariablesNext.dataTypes.AccountMangers,
      accounts: colorVariablesNext.dataTypes.Accounts,
      benefits: colorVariablesNext.dataTypes.Benefits,
      book: colorVariablesNext.dataTypes.Book,
      carriers: colorVariablesNext.dataTypes.Carriers,
      clients: colorVariablesNext.dataTypes.Clients,
      commercial: colorVariablesNext.dataTypes.Commercial,
      industries: colorVariablesNext.dataTypes.Industries,
      intermediaries: colorVariablesNext.dataTypes.Intermediaries,
      personal: colorVariablesNext.dataTypes.Personal,
      policies: colorVariablesNext.dataTypes.Policies,
      premium: colorVariablesNext.dataTypes.Premium,
      producers: colorVariablesNext.dataTypes.Producers,
      unknown: colorVariablesNext.dataTypes.Unknown,
      revenue: colorVariablesNext.dataTypes.Revenue,
    },
  } as const;
};

export default defaultPalette;
