import {dialogContentClasses} from '@mui/material';
import {Components, Theme} from '@mui/material/styles';

export const dialog = (theme: Theme): Partial<Components> => {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: theme.shape.borderRadiusRegular,

          [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(8, 2),
            width: '100%',
          },
        },
        paperWidthXs: {
          maxWidth: 360,
          [theme.breakpoints.down('sm')]: {
            maxWidth: 343,
          },
        },
        paperWidthLg: {
          maxWidth: 1024,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          ...theme.typography.h4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: theme.spacing(4),

          [`&&+.${dialogContentClasses.root}`]: {
            paddingTop: theme.spacing(4),
          },
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: true,
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(4),
        },
        dividers: {
          borderColor: theme.palette.divider,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: theme.spacing(4),
          display: 'flex',
          gap: theme.spacing(4),

          '&>:not(:first-of-type)': {
            marginLeft: 'unset',
          },
        },
      },
    },
  };
};
