import {Components, Theme} from '@mui/material/styles';

export const accordion = (
  theme: Theme
): {
  MuiAccordion: Components['MuiAccordion'];
  MuiAccordionDetails: Components['MuiAccordionDetails'];
  MuiAccordionSummary: Components['MuiAccordionSummary'];
} => {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            backgroundColor: 'transparent',
          },

          '&:not(.Mui-expanded)': {
            borderColor: 'transparent',
          },

          '&.Mui-expanded': {
            margin: 0,
            borderRadius: theme.shape.borderRadius,
            borderColor: theme.palette.primary.main,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2, 4, 4),
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0, 4),
        },
        content: {
          margin: theme.spacing(4, 0),

          '&.Mui-expanded': {
            margin: theme.spacing(4, 0),
          },
        },
      },
    },
  };
};
