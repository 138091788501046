import {concat, get, isString, orderBy, toLower} from 'lodash';

import {SortOrder} from 'types';

/**
 * Determines if the object is a filled array or not and executes arr.map with
 * the given callback
 *
 * Can be used in React components like instead of such cases:
 * return (
 *   <Select>
 *     {Boolean(Array.isArray(arr) && arr.length) && arr.map(a => (
 *       <MenuItem key={a.id} value={a.id}>
 *         {a.name}
 *       </MenuItem>
 *     ))}
 *   </Select>
 * );
 *
 * to this:
 *
 * return (
 *   <Select>
 *     {arrayMap(arr)(a => (
 *       <MenuItem key={a.id} value={a.id}>
 *         {a.name}
 *       </MenuItem>
 *     ))}
 *   </Select>
 * );
 *
 * @param {Array} arr - possible array
 * @function {function} callback - map condition
 * @return {function(function): Array | null}
 */
export const arrayMap =
  <T>(arr?: T[]) =>
  (callback: (arg: T, index: number) => JSX.Element) =>
    Array.isArray(arr) && arr.length ? arr.map(callback) : null;

type StringOnly<T> = T extends string ? T : string;

/**
 * Makes autocompletable type for an object nested path like 'key.nestedKey.anotherNestedKey'
 * T is the object, K must be keyof T
 * */
type NestedPropPath<T, K extends keyof T> =
  | K
  | `${StringOnly<K>}.${StringOnly<keyof T[K]>}`
  | `${StringOnly<K>}.${StringOnly<keyof T[K]>}.${StringOnly<keyof T[K][keyof T[K]]>}`;

/**
 * A wrapper for lodash sortBy to sort case insensitively (if sortBy key is string)
 */
export const sortCaseInsensitively = <T extends object, K extends keyof T>(
  arr: T[],
  /**
   * key or path like 'key.nestedKey.anotherNestedKey'
   */
  sortBy: NestedPropPath<T, K>,
  order: 'ASC' | 'DESC' | undefined = SortOrder.Asc
) => {
  const orderValue = isString(order) ? (toLower(order) as Lowercase<SortOrder>) : false;

  return orderBy(
    arr,
    item => {
      const value = get(item, sortBy);

      if (isString(value)) {
        return toLower(value);
      } else {
        return value;
      }
    },
    orderValue
  );
};

export type TreeToFlatMapParams<T extends Record<string, any>, ID> = {
  idGetter: (item: T) => ID;
  leavesGetter: (item: T) => T[] | undefined | null;
  treeMap?: Map<ID, T>;
  parentId?: ID;
};

/**
 * Makes the map of all nodes in the tree
 */
export function treeToFlatMap<T extends Record<string, any>, ID>(
  items: T[] | null | undefined,
  {leavesGetter, idGetter, treeMap = new Map(), parentId}: TreeToFlatMapParams<T, ID>
) {
  items?.forEach(item => {
    treeMap.set(idGetter(item), {...item, parentId});
    treeToFlatMap(leavesGetter(item) || [], {leavesGetter, idGetter, treeMap, parentId: idGetter(item)});
  });
  return treeMap;
}

export type RegularTreeItemWithChildren<ItemData = {}> = {
  id: string;
  children?: RegularTreeItemWithChildren<ItemData>[] | null;
};

export function regularTreeToFlatMap<T extends RegularTreeItemWithChildren>(tree: T[]) {
  return treeToFlatMap(tree, {
    leavesGetter: item => item.children as any,
    idGetter: item => item.id,
  });
}

export function concatMixed<T>({arrays, step}: {step: number; arrays: T[][]}) {
  let result: T[] = [];

  const maxLength = Math.max(...arrays.map(arr => arr.length));

  for (let i = 0; i <= maxLength; i += step) {
    const parts = arrays.map(arr => arr.slice(i, i + step));
    result = concat(result, ...parts);
  }

  return result;
}

export function arrayIncludes(arr: any[], subArr: any[]) {
  if (subArr.length > arr.length) {
    return false;
  }

  for (let i = 0; i < subArr.length; i++) {
    if (arr[i] !== subArr[i]) {
      return false;
    }
  }

  return true;
}

export function singleToArray<T>(item: T | T[]): T[] {
  return Array.isArray(item) ? item : [item];
}

export function checkIncludes<T>(arr: T[], value: unknown): value is T {
  return arr.includes(value as T);
}
