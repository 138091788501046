import {IMPERSONATION} from 'core/constants/paths';

export const impersonationToken = (() => {
  const pathnameList = window.location.pathname.split('/');
  const firstSegment = pathnameList[1];
  const token = pathnameList[2];

  if (firstSegment === IMPERSONATION) {
    return token;
  }

  return undefined;
})();

export const IMPERSONATION_HTTP_REQUEST_HEADER = 'x-ennabl-impersonation-token';
